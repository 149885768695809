<template>
  <gm-expandable-list
    expanded-height="300px"
    :mandatory="mandatory"
    :title="$t('dashboard.amberBrakeAlerts')"
    :value="poorBrakeAlerts.length"
    :data="poorBrakeAlerts"
    :showDelete="configurationMode"
    :panel-default-open="panelDefaultOpen"
    :expantion-absolute="expantionAbsolute"
    @clean-option="cleanOption"
  >
    <template #leadingTitle>
      <v-icon
        class="ml-3"
        color="yellow darken-2"
      >
        mdi-alert
      </v-icon>
    </template>

    <template #headerTrail>
      <v-spacer />

      <div
        style="z-index: 10;"
        class="d-flex align-center"
      >
        <!-- <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-y
        >
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                mdi-magnify
              </v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-text class="pa-1">
              <v-text-field
                v-model="search"
                label="Search"
                placeholder="Search"
                outlined
                prepend-inner-icon="mdi-magnify"
              />
            </v-card-text>
          </v-card>
        </v-menu> -->

        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-swap-vertical-bold</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item-group>
              <v-list-item
                v-for="(item, index) in sortOptions"
                :key="index"
                @click="sortValue = item.value"
              >
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>

        <v-avatar
          size="32"
          color="accent"
        >
          <b class="white--text pa-0 ma-0" style="font-size: 14px;">
            {{ poorBrakeAlerts.length }}
          </b>
        </v-avatar>

        <!-- <v-divider
          vertical
        /> -->
      </div>
    </template>
    <template #default="{ item, index }">
      <gm-brake-list-item
        :index="index"
        :value="item.brakePerformanceRate.rate"
        icon="mdi-truck"
        :icon-label="item.name"
        :date="item.brakePerformanceRate.calculatedAt * 1000"
        :is-amber="true"
        :calculated-by="item.brakePerformanceRate.calculatedBy"
        :isValid="item.brakePerformanceRate.isValid"
        event-type="AMBERBRAKEALERT"
        @click.native="showDialog(item)"
      />
    </template>
  </gm-expandable-list>
</template>

<script>

import dayjs from 'dayjs'

import {
  ExpandableList,
  BrakeListItem
} from 'Components/monitor'

export default {
  components: {
    'gm-expandable-list': ExpandableList,
    'gm-brake-list-item': BrakeListItem
  },
  props: {
    configurationMode: {
      type: Boolean,
      default: () => false
    },
    panelDefaultOpen: {
      type: Boolean,
      default: () => true
    },
    expantionAbsolute: {
      type: Boolean,
      default: () => false
    },
    mandatory: {
      type: Boolean,
      default: () => true
    },
    search: {
      type: String,
      default: () => ''
    },
    // sortValue: {
    //   type: String,
    //   default: () => ''
    // },
    dailyValue: {
      type: Number,
      default: () => 0
    },
    dailyOptions: {
      type: Array,
      default: () => [
      ]
    }
  },
  data () {
    return {
      // search: null
      sortValue: 'n2o'
    }
  },
  computed: {
    units () { return this.$store.state.units.basicList },
    poorBrakeAlerts () {
      let result = this.$store.state.units.amberBrakeList

      // filter by date
      result = this.filterByDate(result)

      // sort by date or rate
      if (this.sortValue === 'n2o') {
        result = this.sortByDate(result, 2)
      } else if (this.sortValue === 'o2n') {
        result = this.sortByDate(result)
      } else if (this.sortValue === 'h2l') {
        result = this.sortByRate(result, 2)
      } else if (this.sortValue === 'l2h') {
        result = this.sortByRate(result)
      }

      // filter by search
      if (this.search) {
        result = result
          .filter(el =>
            el.name &&
            el.name.toLowerCase().includes(this.search.toLowerCase())
          )
      }
      return result
    },
    sortOptions () {
      return [
        {
          text: 'Newest to oldest',
          value: 'n2o'
        },
        {
          text: 'Oldest to newest',
          value: 'o2n'
        },
        {
          text: 'Hight to low',
          value: 'h2l'
        },
        {
          text: 'Low to hight',
          value: 'l2h'
        }
      ]
    }
  },
  methods: {
    cleanOption (value) {
      this.$emit('clean-option', value)
    },
    showDialog (unit) {
      this.$showUnitDialog(unit)
    },
    sortByDate (array, val = 0) {
      array.sort(function (a, b) {
        if (a?.brakePerformanceRate?.calculatedAt < b?.brakePerformanceRate?.calculatedAt) {
          return -1 + val
        }
        if (a?.brakePerformanceRate?.calculatedAt > b?.brakePerformanceRate?.calculatedAt) {
          return 1 - val
        }
        return 0
      })
      return array
    },
    sortByRate (array, val = 0) {
      array.sort(function (a, b) {
        if (a?.brakePerformanceRate?.rate < b?.brakePerformanceRate?.rate) {
          return -1 + val
        }
        if (a?.brakePerformanceRate?.rate > b?.brakePerformanceRate?.rate) {
          return 1 - val
        }
        return 0
      })
      return array
    },
    filterByDate (array) {
      const result = [...array]
      const dailyValue = this.dailyOptions[this.dailyValue]
      const today = dayjs()

      switch (dailyValue) {
        case 'today':
          return result
            .filter(el => dayjs(el.brakePerformanceRate?.calculatedAt * 1000)
              .isSame(today, 'day'))

        case 'yesterday':
          return result
            .filter(el => dayjs(el.brakePerformanceRate?.calculatedAt * 1000)
              .isSame(today.subtract(1, 'day'), 'day'))

        case 'thisWeek':
          return result
            .filter(el => dayjs(el.brakePerformanceRate?.calculatedAt * 1000)
              .isSame(today, 'week'))

        case 'lastWeek':
          return result
            .filter(el => dayjs(el.brakePerformanceRate?.calculatedAt * 1000)
              .isSame(today.subtract(1, 'week'), 'week'))

        default:
          return result
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.unit__icon{
  font-size: 28px;
}

.unit__title{
  justify-content: center;
  font-size: 30px;
  font-weight: 700 !important;
}

.unit__subtitle {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

</style>