export default {
  methods: {
    loadFields (fields) {
      this.fields = []
      this.errors = {}
      for (const i in fields) {
        this.fields.push(fields[i])
      }
    },

    parseErrors (errors) {
      const errs = {}
      for (const i in this.fields) {
        const field = this.fields[i]
        errs[field] = []
  
        if (errors[field] !== undefined) {
          for (const j in errors[field]) {
            const err = errors[field][j]
  
            switch (err.code) {
              case 'invalid':
                errs[field].push(this.$t('errors.orm.invalid'))
                break
              case 'minLength':
                errs[field].push(this.$t('errors.orm.length.min', { expected: err.expected, received: err.received }))
                break
              case 'maxLength':
                errs[field].push(this.$t('errors.orm.length.max', { expected: err.expected, received: err.received }))
                break
              case 'minValue':
                errs[field].push(this.$t('errors.orm.value.min', { expected: err.expected, received: err.received }))
                break
              case 'maxValue':
                errs[field].push(this.$t('errors.orm.value.max', { expected: err.expected, received: err.received }))
                break
              case 'required':
                errs[field].push(this.$t('errors.orm.required'))
                break
              case 'taken':
                errs[field].push(this.$t('errors.orm.taken'))
                break
              case 'empty':
                errs[field].push(this.$t('errors.orm.empty'))
                break
              case 'format':
                errs[field].push(this.$t('errors.orm.format'))
                break
              case 'choices':
                errs[field].push(this.$t('errors.orm.choices'))
                break
            }
          }
        }
      }

      for (const field in errs) {
        errs[field] = errs[field].join(', ')
      }

      return errs
    },

    convertToCamelCase (str) {
      str = str.toLowerCase().replace(/(?:(^.)|([-_\s]+.))/g, match => {
        return match.charAt(match.length - 1).toUpperCase()
      })
      return str.charAt(0).toLowerCase() + str.substring(1)
    },

    resetErrors () {
      this.loadFields(Object.keys(this.defaultObject))
      this.errors = this.rawErrors()
    },

    rawErrors () {
      const errs = {}

      for (const i in this.defaultObject) {
        errs[i] = ''
      }

      return errs
    }
  }
}