function arrayToDict (array, key) {
  const result = {}
  for (let i = 0; i < array.length; i++) {
    result[array[i][key]] = array[i]
  }
  return result
}

/*
  Unit mutations
*/
export default {
  setEventList (state, payload) {
    // Sort payload
    payload.sort(function (a, b) {
      if (a.activatedAt < b.activatedAt) {
        return -1
      }
      if (a.activatedAt > b.activatedAt) {
        return 1
      }
      return 0
    })
    state.eventList = payload
    state.eventDictList = Object.assign({}, arrayToDict(payload, 'id'))
    // console.log(state)
  },

  setCodeList (state, payload) {
    state.codeList = payload
    state.codeDictList = Object.assign({}, arrayToDict(payload, 'id'))
  },

  newItem (state, payload) {
    state.list.push(payload)
    state.dictList[payload.id] = payload
  },

  updateItem (state, payload) {
    const list = [...state.list]
    for (let i = 0; i < list.length; i++) {
      if (list[i].id.toString() === payload.id.toString()) {
        list[i] = Object.assign({}, payload)
      }
    }

    state.list = []
    state.list = [...list]
    state.dictList = Object.assign({}, arrayToDict(list, 'id'))
  },

  deleteItem (state, id) {
    const list = state.list.filter(item => item.id.toString() !== id.toString())
    state.list = []
    state.list = [...list]
    state.dictList = Object.assign({}, arrayToDict(list, 'id'))
  },

  setTimeList (state, { rootState }) {
    const compareDates = (date, firstDate, endDate) => {
      return date >= firstDate && date <= endDate
    }

    const getStartOfDay = (date) => {
      date.setHours(0, 0, 0, 0)
      return date
    }

    const getEndOfDay = (date) => {
      date.setHours(23, 59, 59, 999)
      return date
    }

    const setToMonday = (date) => {
      const day = date.getDay() || 7
      if (day !== 1) {
        date.setHours(-24 * (day - 1)) 
      }
      return date
    }
    const today = []
    const yesterday = []
    const thisWeek = []
    const lastWeek = []
    const events = state.eventList.map(event => {
      return {
        ...event,
        unit: rootState.units.dictList[event.unitId],
        dtcCode: state.codeDictList[event.dtcCodeId]
      }
    })
    const unitsToday = {}
    const unitsYesterday = {}
    const unitsThisWeek = {}
    const unitsLastWeek = {}
    // Init dates
    const baseDate = new Date()
    const todayStart = getStartOfDay(new Date())
    const todayEnd = getEndOfDay(new Date())
    const yesterdayStart = getStartOfDay(new Date((baseDate.getTime()) - 86400 * 1000))
    const yesterdayEnd = getEndOfDay(new Date((baseDate.getTime()) - 86400 * 1000))
    const thisWeekStart = setToMonday(getStartOfDay(new Date()))
    const lastWeekStart = setToMonday(getStartOfDay(new Date(new Date().getTime() - 86400 * 7 * 1000)))
    for (const i in events) {
      const event = events[i] 
      if (compareDates(new Date(event.activatedAt * 1000), todayStart, todayEnd)) {
        if (unitsToday[event.unitId] === undefined) {
          unitsToday[event.unitId] = {}
          unitsToday[event.unitId].events = []
          unitsToday[event.unitId].unit = event.unit
        }
        unitsToday[event.unitId].events.push(event)
      } 
      if (compareDates(new Date(event.activatedAt * 1000), yesterdayStart, yesterdayEnd)) {
        if (unitsYesterday[event.unitId] === undefined) {
          unitsYesterday[event.unitId] = {}
          unitsYesterday[event.unitId].events = []
          unitsYesterday[event.unitId].unit = event.unit
        }
        unitsYesterday[event.unitId].events.push(event)
      } 
      if (compareDates(new Date(event.activatedAt * 1000), thisWeekStart, todayEnd)) {
        if (unitsThisWeek[event.unitId] === undefined) {
          unitsThisWeek[event.unitId] = {}
          unitsThisWeek[event.unitId].events = []
          unitsThisWeek[event.unitId].unit = event.unit
        }
        unitsThisWeek[event.unitId].events.push(event)
      } 
      if ((compareDates(new Date(event.activatedAt * 1000), lastWeekStart, thisWeekStart))) {
        if (unitsLastWeek[event.unitId] === undefined) {
          unitsLastWeek[event.unitId] = {}
          unitsLastWeek[event.unitId].events = []
          unitsLastWeek[event.unitId].unit = event.unit
        }
        unitsLastWeek[event.unitId].events.push(event)
      }
    }
    // Convert dict to list
    for (const i in unitsToday) {
      today.push({
        ...unitsToday[i].events[0],
        ...unitsToday[i]
      })
    }
    for (const i in unitsThisWeek) {
      thisWeek.push({
        ...unitsThisWeek[i].events[0],
        ...unitsThisWeek[i]
      })
    }
    for (const i in unitsYesterday) {
      yesterday.push({
        ...unitsYesterday[i].events[0],
        ...unitsYesterday[i]
      })
    }
    for (const i in unitsLastWeek) {
      lastWeek.push({
        ...unitsLastWeek[i].events[0],
        ...unitsLastWeek[i]
      })
    }
    state.timeList = {
      today: today,
      yesterday: yesterday,
      thisWeek: thisWeek,
      lastWeek: lastWeek
    }

    state.redTimeList = {
      today: [...today],
      yesterday: [...yesterday],
      thisWeek: [...thisWeek],
      lastWeek: [...lastWeek]
    }
    state.amberTimeList = {
      today: [...today],
      yesterday: [...yesterday],
      thisWeek: [...thisWeek],
      lastWeek: [...lastWeek]
    }
  },

  // eslint-disable-next-line no-unused-vars
  toggle (_, payload) {}
}
