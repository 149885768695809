export default {
  id: null,
  name: '',
  email: '',
  username: '',
  permission: {},
  mqttToken: null,
  token: {},
  telegram: {},
  avatar: null,
  apiToken: '',
  homeDepotId: '',
  dashboardConfiguration: {
    dashboardWidgets: []
  },
  endAt: null
}