<template>
  <v-card
    class="pa-2 my-2"
    :style="`height: ${$vuetify.breakpoint.mdAndDown ? 220 : 180}px;`"
    @click="clickEvent"
  >
    <v-card-title class="d-flex justify-space-between">
      <div class="d-flex align-center">
        <v-icon
          large
        >
          mdi-truck
        </v-icon>
        <p class=" ma-0 ml-1 label__subtitle grey--text">{{ unit.name }}</p>
      </div>
      <div class="d-flex align-center">
        <template v-for="(icon, index) in icons">
          <v-icon
            :key="index"
            :color="icon.color"
            class="mx-1"
          >
            {{ icon.icon }}
          </v-icon>
        </template>
        <v-btn
          icon
          color="error"
          @click.stop="clickClear"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </div>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          cols="2"
          md="2"
        >
          <p class="ma-0 subtitle-1">
            State
          </p>
          <v-chip :color="getUnitStateColor()" text-color="white" small>
            {{ getUnitState() }}
          </v-chip>
        </v-col>
        <v-col
          cols="2"
        >
          <p class="ma-0 subtitle-1">
            Speed
          </p>
          <p class="ma-0 gm_black--text font-weight-bold">
            {{ getSpeed() }}
          </p>
        </v-col>
        <v-col
          cols="2"
        >
          <p class="ma-0 subtitle-1">
            Load weight
          </p>
          <p class="ma-0 gm_black--text font-weight-bold">
            {{ getWeight() }}
          </p>
        </v-col>
        <v-col
          cols="6"
        >
          <p class="ma-0 subtitle-1">
            Address/Geozone
          </p>
          <p class="ma-0 gm_black--text font-weight-bold text-truncate">
            {{ getAddressOrGeozone() }}
          </p>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'

const advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)
export default {
  props: {
    unit: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    icons () {
      return [
        {
          color: 'green darken-2',
          icon: 'mdi-weight'
        },
        {
          color: 'orange',
          icon: 'mdi-email-alert'
        },
        {
          color: 'grey lighten-1',
          icon: 'mdi-hazard-lights'
        },
        {
          color: 'blue',
          icon: 'mdi-car-brake-alert'
        }
      ]
    },
    ...mapGetters(['isMobile'])
  },
  methods: {
    clickEvent () {
      this.$emit('click')
    },
    clickClear () {
      this.$store.commit('units/popSelectedUnit', this.unit)
    },
    getSpeed () {
      if (this.unit.telemetry?.position?.speed) {
        return `${this.unit.telemetry.position.speed} km/h`
      } else {
        return 'Not available'
      }
    },
    getWeight () {
      if (this.unit.telemetry && this.unit.telemetry.axeLoadSum) {
        return `${this.unit.telemetry.axeLoadSum}kg`
      } else {
        return 'Not Available'
      }
    },
    getBrakePerfomanceRate () {
      if (this.unit.brakePerformanceRate) {
        return `${Math.round(this.unit.brakePerformanceRate.rate)} %`
      } else {
        return 'Not Available'
      }
    },
    getAddressOrGeozone () {
      if (this.unit.telemetry) {
        return `${this.unit.telemetry.address}`
      } else {
        return 'Not Available'
      }
    },
    getUnitState () {
      if (this.unit.trailerProfile) {
        if (this.unit.trailerProfile.vor) {
          return 'VOR'
        }
      }
      if (this.unit.telemetry) {
        if (this.unit.telemetry.position.speed > 0 && this.compareDatesForRoute(this.unit.telemetry.receivedAt)) {
          let state = 'Moving'
          if (this.unit.telemetry.inDepot) {
            state += ' at home depot'
          }
          return state
        } else {
          // Validate is in geofence
          let state = 'Parked'
          if (this.unit.telemetry.inDepot) {
            state += 'at home depot'
          }
          return state
        }
      } else {
        return 'Parked'
      }
    },
    getUnitStateColor () {
      if (this.unit.trailerProfile) {
        if (this.unit.trailerProfile.vor) {
          return 'warning'
        }
      }
      if (this.unit.telemetry) {
        if (this.unit.telemetry.position.speed > 0 && this.compareDatesForRoute(this.unit.telemetry.receivedAt)) {
          return 'green'
        } else {
          // Validate is in geofence
          if (this.unit.telemetry.inDepot) {
            return 'green'
          }
          return 'warning'
        }
      } else {
        return 'warning'
      }
    },
    compareDatesForRoute (date) {
      const seconds = 30 * 60 // #30 minutes
      return dayjs().unix() - date < seconds
    }
  }
}
</script>