<template>
  <div>
    <v-card
      :style="`height: ${height};`"
      class="grey scrollbar__seamless"
    >
      <v-card-title>
        <span style="font-family: Lato; font-weight: 700 !important; font-size: 20pxl text-transform: uppercase;" class="white--text">WATCH LIST</span>
        <v-spacer />
        <v-btn
          color="error"
          icon
          @click="emptySelectedUnits"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div>
          <v-virtual-scroll
            :height="`calc(${height} - 90px)`"
            :item-height="$vuetify.breakpoint.mdAndDown ? 224 : 184"
            :items="items"
            class="scrollbar__seamless"
          >
            <template #default="{ item, index }">
              <gm-unit-detail-card
                :key="index"
                :unit="item"
                class="ma-1"
                @click="() => showDialog(item)"
              />
            </template>
          </v-virtual-scroll>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import UnitDetailCard from './UnitDetailCard.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    'gm-unit-detail-card': UnitDetailCard
  },
  props: {
    height: {
      type: String,
      default: () => '200px'
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      dialog: false,
      tab: 0,
      unit: {
        events: [],
        brakePerformanceRates: []
      }
    }
  },
  computed: {
    user () { return this.$store.state.session },
    ...mapGetters(['isMobile'])
  },
  methods: {
    async showDialog (unit) {
      this.$emit('show-dialog', unit)
    },
    setDialog (value) {
      this.dialog = value
    },
    updateUnitTrailerProfile (trailerProfile) {
      this.unit.trailerProfile = trailerProfile
      this.$store.commit('units/updateItem', this.unit)
    },
    emptySelectedUnits () {
      this.$store.commit('units/emptySelectedUnits')
    }
  }
}
</script>