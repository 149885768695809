export default {
  setEntity (state, payload) {
    state.id = payload.id
    state.name = payload.name
    state.email = payload.email
    state.apiToken = payload.apiToken
    state.username = payload.username
    state.mqttToken = payload.mqttToken
    state.companyName = payload.companyName
    state.metricUnit = payload.metricUnit
    state.homeDepotId = payload.homeDepotId?.toString()
    state.timeZone = payload.timeZone
    // Validate the dashboardWidgets if empty
    if (payload.dashboardConfiguration.dashboardWidgets.length === 0) {
      state.dashboardConfiguration = {
        
      }
      state.dashboardConfiguration = {
        id: payload.dashboardConfiguration.id,
        dashboardWidgets: [
          {
            widgetType: 'amberdtcs'
          },
          {
            widgetType: 'reddtcs'
          },
          {
            widgetType: 'overweightevents'
          },
          {
            widgetType: 'poorbrakealerts'
          },
          {
            widgetType: 'vor'
          },
          {
            widgetType: 'mostdistance'
          }
        ]
      }
    } else {
      state.dashboardConfiguration = payload.dashboardConfiguration
    }
  },

  setEntityDashboardConfiguration (state, payload) {
    state.dashboardConfiguration = payload
  },

  clearEntity (state) {
    state.id = null
    state.name = ''
    state.email = ''
    state.username = ''
    state.permission = {}
    state.token = {}
    state.isDeveloper = false
    state.avatar = null
    state.telegram = {}
    state.mqttToken = null
    state.apps = []
    state.childs = []
    state.container = {}
    state.companyName = ''
    state.metricUnit = ''
    state.homeDepotId = ''
    state.timeZone = ''
  },

  setEndAt (state, payload) {
    state.endAt = payload
  }
}