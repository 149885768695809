/*
  monitor mutations
*/
export default {
  setConfiguration (state, payload) {
    state.configuration = payload
  },

  toggleCounters (state) {
    state.countersMode = state.countersMode === 'maintenance' ? 'utilization' : 'maintenance' 
  },

  // eslint-disable-next-line no-unused-vars
  toggle (_, payload) {}
}
