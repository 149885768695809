<template>
  <transition name="component-fade">
    <v-avatar
      v-if="!isSelected"
      size="35"
      :color="color"
      class="elevation-1"
      style="cursor: pointer;"
      @click="$emit('toggle')"
    >
      <v-img v-if="hasPicture" :src="avatar" cover />
      <template v-else>
        <v-icon v-if="icon !== ''" size="20" :class="textTheme">{{ icon }}</v-icon>
        <span v-else :class="textTheme">{{ avatar }}</span>
      </template>
    </v-avatar>

    <v-avatar
      v-else
      size="40"
      color="red darken-2"
      class="elevation-1"
      style="cursor: pointer;"
      @click="$emit('toggle')"
    >
      <v-icon color="white">mdi-checkbox-marked-circle-outline</v-icon>
    </v-avatar>
  </transition>
</template>

<script>

import colors from './colors'

export default {
  props: {
    hasPicture: {
      type: Boolean,
      default: () => false
    },
    avatar: {
      type: String,
      default: () => 'NA'
    },
    icon: {
      type: String,
      default: () => ''
    },
    color: {
      type: String,
      default: () => 'primary'
    },
    textColor: {
      type: String,
      default: () => 'white'
    },
    isSelected: {
      type: Boolean,
      default: () => false
    }
  },

  data () {
    return {
      colors: colors.vColors()
    }
  },

  computed: {
    textTheme () {
      if (this.useBlack(this.color)) {
        return 'black--text'
      } else {
        return 'white--text'
      }
    }
  },

  methods: {
    convertToHex (color) {
      if (color in this.colors) {
        return this.colors[color]
      }
      return this.colors.primary
    },

    hexToRgb (hex) {
      if (!hex.includes('#')) {
        hex = this.convertToHex(hex)
      }
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
      return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
          }
        : null
    },

    useBlack (color) {
      const rgb = this.hexToRgb(color)
      const a = 1 - (0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b) / 255
      return (a < 0.5)
    }
  }

}
</script>
