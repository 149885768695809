// Main imports
import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { baseUrl as uri } from './constants'

// Apollo client dependencies
import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'

// Create component
Vue.use(VueApollo)

const httpLink = new HttpLink({
  uri: `${uri}/graphql`
})

const defaultOptions = {
  query: {
    fetchPolicy: 'no-cache'
  },
  mutate: {
    fetchPolicy: 'no-cache'
  }
}

const apolloClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache({ addTypename: false }),
  defaultOptions,
  headers: {
    'content-type': 'multipart/form-data'
  }
})

export default new VueApollo({
  defaultClient: apolloClient,
  defaultOptions
})

export { apolloClient as apollo }
