<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-card class="px-2 py-2" v-bind="attrs" v-on="on">
        <v-card-title
          class="unit__title gm_black--text"
        >
          {{ value }}
        </v-card-title>
        <v-card-subtitle class="d-flex align-center justify-center">
          <v-icon
            class="unit__icon grey--text mr-1"
          >
            {{ icon }}
          </v-icon>
          <p class="pa-0 ma-0 unit__subtitle grey--text">
            {{ title }}
          </p>
        </v-card-subtitle>
      </v-card>
    </template>
    <v-list style="max-height: 45vh">
      <v-list-item
        v-for="(item, index) in items"
        :key="index"
        :style="isDark ? 'background-color: #1e1e1e' : `background-color: #fff;`"
        @click="showDialog(item)"
      >
        <v-list-item-title>{{ item.name }}</v-list-item-title>
      </v-list-item>
      <template v-if="value === '0'">
        <v-list-item>
          <v-list-item-title>No Items to show</v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    value: {
      type: String,
      default: () => ''
    },
    title: {
      type: String,
      default: () => ''
    },
    icon: {
      type: String,
      default: () => ''
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState(['isDark'])
  },
  methods: {
    async showDialog (unit) {
      this.$showUnitDialog(unit) 
    }
  }
}
</script>

<style lang="scss" scoped>

.unit__icon{
  font-size: 28px;
}

.unit__title{
  justify-content: center;
  font-size: 30px;
  font-weight: 700 !important;
}

.unit__subtitle {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

</style>