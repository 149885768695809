<template>
  <v-dialog v-model="dialogValue" width="800">
    <v-card>
      <div style="position: relative;">
        <address-label :event="event" :address="address" style="position: absolute; width: 65%; bottom: 15px; z-index: 200; margin-left: auto;
          margin-right: auto;
          left: 0;
          right: 0;"
        />
        <trips-map :data="getEventPosition()" :showMap="dialogValue" :drawTrips="drawTrip" :trips="trips" :height="250" />
      </div>
      <v-card-text>
        <v-tabs v-model="currentTab">
          <v-tab :key="0">Information</v-tab>
          <v-tab v-if="eventType === 'dtc'" :key="1">DTC Details</v-tab>
        </v-tabs>
        <v-tabs-items v-model="currentTab">
          <v-tab-item :key="0" class="pt-4">
            <event-historic-tab :address="address" :event="event" :eventType="eventType" />
          </v-tab-item>
          <v-tab-item v-if="eventType === 'dtc'" :key="1" class="pt-4">
            <detail-tab v-if="eventType === 'dtc'" address="address" :event="event" :eventType="eventType" :hasInstructions="true" />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn color="primary" outlined class="px-4" @click="drawTrips">{{ drawTrip ? 'Show Position' : 'Show Trips' }}</v-btn>
        <v-spacer />
        <v-btn color="error" outlined class="px-4" @click="dialogValue = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import {
  HistoricTab,
  AddressLabel,
  DetailTab
} from 'Components/events'
import { EventsMap } from 'Components/units'
import axios from 'axios'
import dayjs from 'dayjs'
import historicTrips from 'GraphQL/queries/messages/historicTrips.gql'
import { gisUrl } from '@/plugins/constants'

const advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

export default {
  components: {
    'event-historic-tab': HistoricTab,
    'address-label': AddressLabel,
    'trips-map': EventsMap,
    'detail-tab': DetailTab
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    event: {
      type: Object,
      default: () => {}
    },
    eventType: {
      type: String,
      default: () => 'dtc'
    }
  },
  data () {
    return {
      open: true,
      seletedTab: 'detail',
      currentTab: 0,
      mapValue: true,
      testPosition: {
        latitude: 5,
        longitude: 5
      },
      address: 'Address',
      trips: {},
      drawTrip: false,
      retrievedTrip: false
    }
  },
  computed: {
    ...mapState(['isDark']),
    ...mapGetters(['colorText']),
    user () { return this.$store.state.session },
    dialogValue: {
      get: function () {
        return this.dialog
      },
      set: function (value) {
        this.$emit('dialog-change', value)
      }
    }
  },
  watch: { 
    dialog (_) {
      if (this.dialog) {
        this.address = 'Loading...'
        this.currentTab = 0
        this.calculateAddress()
        this.drawTrip = false
        this.retrievedTrip = false
      }
    } 
  },
  
  methods: {
    formatDate (date) {
      if (date) {
        return dayjs(date).format('Do MMMM YYYY - hh:mm a')
      } else {
        return ''
      }
    },
    formatValue (value) {
      return parseFloat(value).toFixed(2)
    },
    updateMapValue () {
      this.mapValue = !this.mapValue
    },
    getEventPosition () {
      return this.event.position
    },
    calculateAddress () {
      // eslint-disable-next-line no-unreachable
      const vueObject = this
      const url = `${gisUrl}/reverse?format=jsonv2&lat=${this.event.position.latitude}&lon=${this.event.position.longitude}`
      axios.get(url)
        .then(function (response) {
          // handle success
          vueObject.address = response.data.display_name
        })
        .catch(function (error) {
          // handle error
          console.error(error)
          vueObject.address = 'the address was unable to retrieve'
        })
        .then(function () {
          // always executed
        })
    },
    async drawTrips () {
      if (this.retrievedTrip) {
        this.drawTrip = !this.drawTrip
        return
      }
      // Get trips
      const mutation = historicTrips
      const initTimestamp = this.eventType === 'dtc' ? this.event.activatedAt : this.event.receivedAt
      const finalTimestamp = this.event.finishAt
      const variables = {
        apiToken: this.user.apiToken,
        unitId: this.event.unitId,
        initTimestamp: initTimestamp,
        endTimestamp: finalTimestamp
      }
      this.$apollo
        .mutate({
          mutation,
          variables,
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          const { status, errors, result } = response.data.historicTrips
          const result2 = response.data.historicTrips.result
          switch (status) {
            case 'OK':
              this.calculateTrips(result, result2)
              this.$store.commit('toggleSnackbar', {
                message: this.$i18n.t('trips.messages.success'),
                color: 'success'
              })
              break

            case 'NOTFOUND':
              this.$store.commit('toggleSnackbar', {
                message: 'Theres no brake events for the the date selected',
                color: 'orange darken-2'
              })
              break
            case 'UNPROCESSABLE':
              this.$store.commit('toggleSnackbar', {
                message: errors.days,
                color: 'orange darken-2'
              })
              break

            case 'INTERNALERROR':
              this.$store.commit('toggleSnackbar')
              break
          }
        })
        .catch(_ => {
          this.$store.commit('toggleSnackbar')
        })
        .finally(() => {
        })
    },

    calculateTrips (messages, trips) {
      this.retrievedTrip = true
      // Clean trips
      this.trips = []
      // For in trips
      for (const i in trips) {
        const currentTrip = trips[i]
        this.trips.push({
          ...currentTrip
        })
      }
      this.drawTrip = true
    }

  }
}
</script>

<style lang="scss" scoped>
.address--label {
  position: absolute; width: 70%; bottom: 15px;
}
</style>