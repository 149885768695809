<template>
  <gm-expandable-list
    :mandatory="mandatory"
    expanded-height="300px"
    :title="$t('dashboard.overdueServices')"
    :value="upcomingServiceList.length"
    :data="upcomingServiceList"
    :showDelete="configurationMode"
    :panel-default-open="panelDefaultOpen"
    :expantion-absolute="expantionAbsolute"
    @clean-option="cleanOption"
  >
    <template #headerTrail>
      <div
        style="z-index: 10;"
        class="d-flex"
      >
        <!-- <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-y
        >
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                mdi-magnify
              </v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-text class="pa-1">
              <v-text-field
                v-model="search"
                label="Search"
                placeholder="Search"
                outlined
                prepend-inner-icon="mdi-magnify"
              />
            </v-card-text>
          </v-card>
        </v-menu> -->

        <!-- <v-divider
          vertical
        /> -->
      </div>
    </template>
    <template #default="{ item, index }">
      <gm-monitor-list-item
        :index="index"
        :title="item.name"
        :subtitle="item.subtitle"
        icon="mdi-truck"
        :date="getNextServiceDate(item)"
        :formarDateTime="false"
        @click.native="showDialog(item)"
      />
    </template>
  </gm-expandable-list>
</template>

<script>

import dayjs from 'dayjs'

import {
  ExpandableList,
  MonitorListItem
} from 'Components/monitor'

export default {
  components: {
    'gm-expandable-list': ExpandableList,
    'gm-monitor-list-item': MonitorListItem
  },
  props: {
    configurationMode: {
      type: Boolean,
      default: () => false
    },
    panelDefaultOpen: {
      type: Boolean,
      default: () => true
    },
    expantionAbsolute: {
      type: Boolean,
      default: () => false
    },
    mandatory: {
      type: Boolean,
      default: () => true
    },
    search: {
      type: String,
      default: () => ''
    },
    sortValue: {
      type: String,
      default: () => ''
    },
    dailyValue: {
      type: Number,
      default: () => 0
    },
    dailyOptions: {
      type: Array,
      default: () => [
      ]
    }
  },
  data () {
    return {
      menu: false,
      upcomingService: []
      // dailyValue: 0,
      // dailyOptions: [
      //   '7days',
      //   '14days',
      //   '1month'
      // ],
      // sortValue: 'asc',
      // search: null
    }
  },
  computed: {
    values () { return this.$store.state.units.overdueServicesList },
    upcomingServiceList () {
      let result = this.sortBydate(this.filteredByDate(this.values), this.sortValue === 'desc' ? 2 : 0)

      if (this.search) {
        result = result
          .filter(el =>
            el.name &&
            el.name.toLowerCase().includes(this.search.toLowerCase())
          )
      }
      return result
    }
  }, 
  methods: {
    cleanOption (value) {
      this.$emit('clean-option', value)
    },
    showDialog (unit) {
      this.$showUnitDialog(unit)
    },
    dailyDateRangeLabel () {
      return this.$t(`monitor.vehicles.distance.${this.dailyOptions[this.dailyValue]}`)
    },
    // changeSortValue () {
    //   this.sortValue = this.sortValue === 'desc' ? 'asc' : 'desc' 
    // },
    // changeDailyValue (index) {
    //   this.dailyValue = index
    // },
    getNextServiceDate (unit) {
      return dayjs(unit.trailerProfile.nextServiceDate).unix() * 1000
    },
    sortBydate (array, val = 0) {
      const self = this
      array.sort(function (a, b) {
        if (self.getNextServiceDate(a) < self.getNextServiceDate(b)) {
          return -1 + val
        }
        if (self.getNextServiceDate(a) > self.getNextServiceDate(b)) {
          return 1 - val
        }
        return 0
      })
      return array
    },
    filteredByDate (array) {
      // filter array by dailyValue and dailyOptions
      return array.filter((el) => {
        const date = dayjs(el.trailerProfile.nextServiceDate)
        const today = dayjs()
        switch (this.dailyOptions[this.dailyValue]) {
          case 'today':
            return date.isSame(today, 'day')
          case 'yesterday':
            return date.isSame(today.subtract(1, 'day'), 'day')
          case 'thisWeek':
            return date.isSame(today, 'week')
          case 'lastWeek':
            return date.isSame(today.subtract(1, 'week'), 'week')
          default:
            return true
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.unit__icon{
  font-size: 28px;
}

.unit__title{
  justify-content: center;
  font-size: 30px;
  font-weight: 700 !important;
}

.unit__subtitle {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

</style>