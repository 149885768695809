// import { i18n } from '@/plugins/i18n'

export default {
  defaultObject () {
    return {
      id: null,
      name: '',
      description: '',
      unitsIds: [],
      groupsIds: [],
      validFrom: null,
      validTo: null,
      availableUsers: '',
      frequency: '',
      items: []
    }
  },
  defaultCheckItem () {
    return {
      id: null,
      name: '',
      description: '',
      checkType: 'TEXT',
      subType: '',
      options: [],
      requirePhoto: false,
      requireComment: false
    }
  }
}