<template>
  <gm-expandable-list
    :mandatory="mandatory"
    expanded-height="300px"
    :title="$t('dashboard.upcomingMOT')"
    :value="upcomingMotList.length"
    :data="upcomingMotList"
    :showDelete="configurationMode"
    :panel-default-open="panelDefaultOpen"
    :expantion-absolute="expantionAbsolute"
    @clean-option="cleanOption"
  >
    <template #headerTrail>
      <div
        style="z-index: 10;"
        class="d-flex"
      >
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-y
        >
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                mdi-magnify
              </v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-text class="pa-1">
              <v-text-field
                v-model="search"
                label="Search"
                placeholder="Search"
                outlined
                prepend-inner-icon="mdi-magnify"
              />
            </v-card-text>
          </v-card>
        </v-menu>

        <!-- <v-divider
          vertical
        /> -->
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="changeSortValue"
            >
              <v-icon>
                {{ sortValue === 'desc' ? 'mdi-arrow-down-bold-circle' : 'mdi-arrow-up-bold-circle' }}
              </v-icon>
            </v-btn>
          </template>
          <span>Sort by Date</span>
        </v-tooltip>
        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <v-chip
              class="ma-2"
              color="grey darken-2"
              text-color="white"
              small
              v-bind="attrs" 
              v-on="on"
            >
              {{ dailyDateRangeLabel() }}
            </v-chip>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in dailyOptions"
              :key="index"
              @click="changeDailyValue(index)"
            >
              <v-list-item-title>{{ $t(`monitor.vehicles.distance.${item}`) }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>
    <template #default="{ item, index }">
      <gm-monitor-list-item
        :index="index"
        :title="item.name"
        :subtitle="item.subtitle"
        icon="mdi-truck"
        :date="getNextMotDate(item)"
        :formarDateTime="false"
        @click.native="showDialog(item)"
      />
    </template>
  </gm-expandable-list>
</template>

<script>

import dayjs from 'dayjs'

import {
  ExpandableList,
  MonitorListItem
} from 'Components/monitor'

export default {
  components: {
    'gm-expandable-list': ExpandableList,
    'gm-monitor-list-item': MonitorListItem
  },
  props: {
    configurationMode: {
      type: Boolean,
      default: () => false
    },
    panelDefaultOpen: {
      type: Boolean,
      default: () => true
    },
    expantionAbsolute: {
      type: Boolean,
      default: () => false
    },
    mandatory: {
      type: Boolean,
      default: () => true
    }
  },
  data () {
    return {
      menu: false,
      upcomingMot: [],
      dailyValue: 0,
      dailyOptions: [
        '7days',
        '14days',
        '1month'
      ],
      sortValue: 'asc',
      search: null
    }
  },
  computed: {
    values () { return this.$store.state.units.upcomingMOTDict },
    upcomingMotList () {
      let result = this.sortBydate(this.values[this.dailyOptions[this.dailyValue]], this.sortValue === 'desc' ? 2 : 0)

      if (this.search) {
        result = result
          .filter(el =>
            el.name &&
            el.name.toLowerCase().includes(this.search.toLowerCase())
          )
      }
      return result
    }
  }, 
  methods: {
    cleanOption (value) {
      this.$emit('clean-option', value)
    },
    showDialog (unit) {
      this.$showUnitDialog(unit)
    },
    dailyDateRangeLabel () {
      return this.$t(`monitor.vehicles.distance.${this.dailyOptions[this.dailyValue]}`)
    },
    changeSortValue () {
      this.sortValue = this.sortValue === 'desc' ? 'asc' : 'desc' 
    },
    changeDailyValue (index) {
      this.dailyValue = index
    },
    getNextMotDate (unit) {
      return dayjs(unit.trailerProfile.nextMotDoe).unix() * 1000
    },
    sortBydate (array, val = 0) {
      const self = this
      array.sort(function (a, b) {
        if (self.getNextMotDate(a) < self.getNextMotDate(b)) {
          return -1 + val
        }
        if (self.getNextMotDate(a) > self.getNextMotDate(b)) {
          return 1 - val
        }
        return 0
      })
      return array
    }
  }
}
</script>

<style lang="scss" scoped>

.unit__icon{
  font-size: 28px;
}

.unit__title{
  justify-content: center;
  font-size: 30px;
  font-weight: 700 !important;
}

.unit__subtitle {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

</style>