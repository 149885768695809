<template>
  <gm-inverted-expandable
    header-height="77px"
    :content-height="expandedHeight"
  >
    <template #header>
      <v-card
        tile
        class="pa-4"
      >
        <v-row no-gutters align="center">
          <!-- Title -->
          <v-col
            cols="8"
            class="d-flex align-center"
          >
            <h3
              :class="isDark ? 'white--text' : 'grey--text text--darken-1'"
            >
              {{ title.toUpperCase() }}
            </h3>
            <slot
              name="leadingTitle"
            />
          </v-col>
          <v-col cols="4" class="d-flex justify-end align-center">
            <slot name="headerTrail">
              <div
                class="ma-2 accent pa-2 counter__circle d-flex justify-center align-center"
              >
                <b class="white--text pa-0 ma-0" style="font-size: 14px;">
                  {{ value }}
                </b>
              </div>
            </slot>
            <div
              v-if="showDelete"
              class="ma-2 pa-2 counter__circle d-flex justify-center align-center"
            >
              <v-icon color="red darken-4" @click="cleanOption">mdi-close</v-icon>
            </div>
          </v-col>
          <!-- Subtitles -->
        </v-row>
      </v-card>
    </template>
    <template #content>
      <v-card>
        <v-divider />
        <v-list>
          <v-list-item-group>
            <v-virtual-scroll
              class="scrollbar__seamless"
              :height="expandedHeight"
              item-height="70px"
              :items="data"
            >
              <template #default="{ item, index }">
                <slot
                  name="default"
                  :item="item"
                  :index="index"
                />
                <v-divider :key="`divider__${index}`" />
              </template>
            </v-virtual-scroll>
          </v-list-item-group>
        </v-list>
      </v-card>
    </template>
  </gm-inverted-expandable>
</template>

<script>
import {
  InvertedExpandable as GmInvertedExpandable
} from 'Components/utils'
import { mapState } from 'vuex'

export default {
  components: {
    GmInvertedExpandable
  },
  props: {
    title: {
      type: String,
      default: () => ''
    },
    value: {
      type: Number,
      default: () => 0
    },
    data: {
      type: Array,
      default: () => []
    },
    expandedHeight: {
      type: String,
      default: () => '200px'
    },
    headerHeight: {
      type: String,
      default: () => '64px'
    },
    expantionAbsolute: {
      type: Boolean,
      default: () => false
    },
    panelDefaultOpen: {
      type: Boolean,
      default: () => false
    },
    showDelete: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...mapState(['isDark'])
  }
}
</script>