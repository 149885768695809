<template>
  <div>
    <v-chip style="width: 100%" class="chip" color="primary">
      <span>{{ address }}</span>
      <v-spacer />
      <span>Copy</span>
      <v-icon right class="mr-2" @click="copyToClipboard">
        mdi-content-copy
      </v-icon>
      <span>Share</span>
      <v-icon right class="mr-2" @click="generateLocator">
        mdi-share-variant-outline
      </v-icon>
    </v-chip>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import generateLocator from 'GraphQL/mutations/units/generateLocator.gql'

export default {
  props: {
    address: {
      type: String,
      default: () => 'Address'
    },
    event: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState(['isDark']),
    ...mapGetters(['colorText']),
    user () { return this.$store.state.session },
    unit () { return this.$store.state.units.dictList[this.event.unitId] }
  },
  methods: {
    copyToClipboard () {
      navigator.clipboard.writeText(this.address)
      this.$store.commit('toggleSnackbar', {
        message: 'The address has been copy to your clipboard',
        color: 'success'
      })
    },
    async generateLocator () {
      const mutation = generateLocator
      const name = 'generateLocator'
      const variables = {
        apiToken: this.user.apiToken,
        unitId: this.unit.id
      }
      this.$store.commit('toggleSnackbar', {
        message: 'Generating locator link',
        color: 'primary'
      })
      this.$apollo
        .mutate({
          mutation,
          variables,
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          const { status, errors, result } = response.data[name]

          switch (status) {
            case 'OK':
              navigator.clipboard.writeText(result)
              this.$store.commit('toggleSnackbar', {
                message: 'Your share link has been copy to your clipboard',
                color: 'success'
              })
              break

            case 'NOTFOUND':
              this.$store.commit('toggleSnackbar', {
                message: 'Theres no brake events for the the date selected',
                color: 'orange darken-2'
              })
              break
            case 'UNPROCESSABLE':
              this.$store.commit('toggleSnackbar', {
                message: errors.days,
                color: 'orange darken-2'
              })
              break

            case 'INTERNALERROR':
              this.$store.commit('toggleSnackbar')
              break
          }
        })
        .catch(_ => {
          this.$store.commit('toggleSnackbar')
        })
        .finally(() => {
        })
    }
  }
}
</script>

<style lang="scss" scoped>

.chip {
  width: 100% !important
}
.chip span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>