// import { i18n } from '@/plugins/i18n'

export default {
  defaultObject () {
    return {
      id: null,
      checkResultItem: null,
      unit: null,
      mechanic: null,
      driver: null,
      status: null,
      createdAt: null,
      comments: []
    }
  }
}