import { i18n } from './i18n'

export class OrmParser {
  constructor () {
    this.fields = []
  }

  loadFields (fields) {
    this.fields = []
    this.errors = {}
    for (const i in fields) {
      this.fields.push(fields[i])
    }
  }

  parseErrors (errors) {
    const errs = {}
    for (const i in this.fields) {
      const field = this.fields[i]
      errs[field] = []

      if (errors[field] !== undefined) {
        for (const j in errors[field]) {
          const err = errors[field][j]

          switch (err.code) {
            case 'invalid':
              errs[field].push(i18n.t('errors.orm.invalid'))
              break
            case 'minLength':
              errs[field].push(i18n.t('errors.orm.length.min', { expected: err.expected, received: err.received }))
              break
            case 'maxLength':
              errs[field].push(i18n.t('errors.orm.length.max', { expected: err.expected, received: err.received }))
              break
            case 'minValue':
              errs[field].push(i18n.t('errors.orm.value.min', { expected: err.expected, received: err.received }))
              break
            case 'maxValue':
              errs[field].push(i18n.t('errors.orm.value.max', { expected: err.expected, received: err.received }))
              break
            case 'required':
              errs[field].push(i18n.t('errors.orm.required'))
              break
            case 'taken':
              errs[field].push(i18n.t('errors.orm.taken'))
              break
            case 'empty':
              errs[field].push(i18n.t('errors.orm.empty'))
              break
            case 'format':
              errs[field].push(i18n.t('errors.orm.format'))
              break
            case 'choices':
              errs[field].push(i18n.t('errors.orm.choices'))
              break
          }
        }
      }
    }

    for (const field in errs) {
      errs[field] = errs[field].join(', ')
    }

    return errs
  }
}

const orm = {}

orm.install = (Vue) => {
  Vue.prototype.$orm = new OrmParser()
}

export default orm