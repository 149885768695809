/*
  Unit state
*/
export default {
  basicList: [],
  list: [],
  dictList: [],
  selectedUnits: [],
  // Unit Sheet
  unitDialog: false,
  resetDialog: false,
  unit: {
    events: [],
    brakePerformanceRates: [],
    trailerProfile: {}
  },
  tpmsMessagesUnit: [],
  unitsEvent: {
    today: [],
    yesterday: [],
    thisWeek: [],
    lastWeek: []
  },
  // Counters List
  faultUnitsList: [],
  homeDepotUnitsList: [],
  emptyRunningList: [],
  loadedList: [],
  customerDepotList: [],
  // Usable in couters and in Widgets
  flaggedVorList: [],
  upcomingServicesList: [],
  upcomingServicesDict: {
    '7days': [],
    '14days': [],
    '1month': []
  },
  upcomingMOTList: [],
  upcomingMOTDict: {
    '7days': [],
    '14days': [],
    '1month': []
  },
  overdueServicesList: [],
  overdueMOTList: [],
  // Widgets
  poorBrakelist: [],
  notVorList: [],
  amberBrakeList: [],
  // Tpms Widgets
  temperatureList: [],
  pressureList: [],
  tpmsEvents: [],
  changeTpmsEvents: []
}
