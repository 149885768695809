<template>
  <div>
    <v-sheet
      class="better-scrollbar"
      :style="{
        height: 'calc(80vh - 100px)',
        'overflow-y': 'auto',
        'overflow-x': 'hidden'
      }"
    >
      <v-container fluid>
        <v-row no-gutters>
          <v-col cols="6" class="pa-2 d-flex align-end">
            <h4 class="primary--text">Last rotation</h4>
          </v-col>
          <v-col cols="6" class="pa-2 pt-0 d-flex align-center">
            <v-text-field
              v-model="search"
              :label="$t('actions.search')"
              hide-details
              outlined
              dense
              prepend-inner-icon="mdi-magnify"
            />
          </v-col>
          <v-col cols="12" class="pa-2">
            <v-data-iterator
              :items="items"
              item-key="id"
              :items-per-page="-1"
              :search="search"
              :custom-filter="tyreChangesFilter"
              hide-default-footer
            >
              <template #default="{ items }">
                <v-row>
                  <v-col
                    v-for="(item, i) in items"
                    :key="i"
                    cols="12"
                  >
                    <v-card tile elevation="0" class="py-2">
                      <v-container fluid class="px-0">
                        <v-row no-gutters>
                          <v-col cols="3" class="d-flex align-center justify-start">
                            <span>
                              {{ item.text }}
                            </span>
                          </v-col>
                          <v-col cols="3" class="d-flex align-center justify-center">
                            <span>
                              {{ item.lastRotation }}
                            </span>
                          </v-col>
                          <v-col cols="6" class="d-flex align-center justify-end">
                            <gm-trailer-render key="tyre-changes-trailer" trailer-id="tyre-changes-trailer" height="60px" />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                    <v-divider v-if="i < items.length - 1" />
                  </v-col>
                </v-row>
              </template>
            </v-data-iterator>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { orm, filters } from '@/mixins'

import dayjs from 'dayjs'

import {
  TrailerRender
} from 'Components/utils/'
const advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

export default {
  components: {
    'gm-trailer-render': TrailerRender
  },
  mixins: [filters, orm],

  model: {
    prop: 'data',
    event: 'set-object'
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },

    unit: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      currentDay: null,
      lastRotation: null,
      search: ''
    }
  },

  computed: {
    ...mapState(['session', 'width', 'isLoading', 'isDark', 'units']),
    ...mapGetters(['color', 'isMobile']),

    object: {
      get () {
        return this.data
      },

      set (value) {
        this.$emit('toggle-object', value)
      }
    },

    items () {
      return new Array(10).fill({}).map((_, i) => {
        return {
          id: i,
          name: `Lorem ${i + 1}`,
          text: `Lorem ipsum ${i + 1}`,
          lastRotation: this.lastRotation
        }
      })
    }
  },

  watch: {
  },

  beforeDestroy () {
  },

  created () {
    this.currentDay = dayjs().format('MMMM DD, YYYY')
    this.lastRotation = dayjs().format('DD/MM/YYYY')
  },

  methods: {
    getRndInteger (min = 0, max = 100) {
      return Math.floor(Math.random() * (max - min)) + min
    }
  }
}
</script>
