<template>
  <v-card style="border-radius: 11px !important;" :elevation="6">
    <v-card-title class="ma-2" style="display: flex; justify-content: center;">
      <v-icon :color="color" large style="margin-right: 10px">{{ icon }}</v-icon>
      <h3 class="sensor--pill-title">{{ title }}</h3>
    </v-card-title>
    <v-card-text class="ma-2" style="display: flex; justify-content: center; align-items: center;">
      <p style="margin: 0px; margin-right: 10px" class="sensor--pill-text">{{ value }}</p>
      <p v-if="value !== $t('units.message.collectingData')" style="margin: 0px" class="sensor--pill-text">{{ measure }}</p>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  props: {
    icon: {
      type: String,
      default: () => 'mdi-truck'
    },
    color: {
      type: String,
      default: () => 'grey'
    },
    title: {
      type: String,
      default: () => 'name of the sensor'
    },
    value: {
      type: String,
      default: () => '120'
    },
    measure: {
      type: String,
      default: () => 'kg'
    }
  }
}
</script>

<style lang="scss">
  .sensor--pill-text {
    text-align: left;
    font-size: 24px;
    letter-spacing: -0.3px;
    color: #7E7E7E;
    opacity: 1;
    font-weight: bold !important;
  }

  .sensor--pill-title {
    text-align: left;
    font-size: 20px;
    letter-spacing: -0.3px;
    color: #7E7E7E;
    opacity: 1;
  }
</style>