<template>
  <div class="d-flex align-center my-1">
    <v-icon color="grey darken-2" class="mr-1">
      {{ icon }}
    </v-icon>
    <h4 class="label--text">
      {{ label }}
    </h4>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: () => 'mdi-truck'
    },
    label: {
      type: String,
      default: () => 'Label'
    }
  }
}
</script>

<style lang="scss" scoped>
.label--text {
  text-transform: uppercase;
}

</style>