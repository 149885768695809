/*
  Dtcs state
*/
export default {
  underinflationEvents: [],
  dictUndernflationEvents: [],
  fastPressureLostEvents: [],
  dictFastPressureLostEvents: [],
  overheatingEvents: [],
  dictOverheatingEvents: [],
  list: [],
  dictList: {},
  tpmsWarnings: [],
  tpmsEvents: [],
  timeListWarnings: {
    today: [],
    yesterday: [],
    thisWeek: [],
    lastWeek: []
  },
  timeListEvents: {
    today: [],
    yesterday: [],
    thisWeek: [],
    lastWeek: []
  },
  activeList: []
}
