<template>
  <v-list-item
    :key="index"
    @click="clickedElement"
  >
    <!-- <v-list-item-icon
      class="d-flex flex-column"
    >
      <v-icon
        class="primary--text"
        dark
        :large="!iconLabel"
      >
        {{ icon }}
      </v-icon>
      {{ iconLabel }}
    </v-list-item-icon> -->

    <v-list-item-content class="pa-1">
      <v-list-item-title class="d-flex align-center justify-space-between">
        <h3 :class="colorText">
          {{ title }}
        </h3>
        <p
          class="pb-4 mb-0 grey--text"
          style="font-size: 14px;"
        >
          {{ formatDate(date) }}
        </p>
      </v-list-item-title>

      <v-list-item-subtitle
        :class="colorText"
        style="text-align: initial;"
      >
        {{ subtitle }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action v-if="showDetailButton">
      <v-btn
        icon
        color="primary"
        @click="onClickHandler"
      >
        <v-icon>
          mdi-eye
        </v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import dayjs from 'dayjs'
const advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

export default {
  props: {
    title: {
      type: String,
      default: () => ''
    },
    subtitle: {
      type: String,
      default: () => ''
    },
    icon: {
      type: String,
      default: () => ''
    },
    iconLabel: {
      type: String,
      default: () => ''
    },
    index: {
      type: Number,
      default: () => 0
    },
    date: {
      type: Number,
      default: () => undefined
    },
    onClick: {
      type: Function,
      default: () => {}
    },
    unit: {
      type: Object,
      default: () => {}
    },
    showDetailButton: {
      type: Boolean,
      default: true
    },
    formarDateTime: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState(['isDark']),
    ...mapGetters(['colorText'])
  },
  methods: {
    formatDate (date) {
      if (date) {
        const formatter = this.formarDateTime ? 'Do MMMM YYYY - hh:mm a' : 'Do MMMM YYYY'
        return dayjs(date).format(formatter)
      } else {
        return ''
      }
    },
    onClickHandler () {
      this.onClick(this.unit)
    },
    clickedElement () {
      this.$emit('clicked-element', this.unit)
    }
  }
}
</script>