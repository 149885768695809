/*
  General actions
*/
// graphql files
import { apollo } from '@/plugins/apollo'
import validateVersion from 'GraphQL/queries/versions/validateVersion.gql'

export default {
  validateLastVersion ({ rootState, commit }) {
    apollo.query({
      query: validateVersion,
      variables: {
        version: rootState.general.appVersion
      }
    }).then((response) => {
      const { status, result } = response.data.validateAppVersion

      switch (status) {
        case 'OK':
          commit('setLastestVersion', result)
          break
        default:
          commit('toggleSnackbar', undefined, { root: true })
          break
      }
    }).catch((error) => {
      console.error(error)
      commit('toggleSnackbar', undefined, { root: true })
    })
  }
}