import { render, staticRenderFns } from "./TpmsEventLog.vue?vue&type=template&id=47b634cf"
import script from "./TpmsEventLog.vue?vue&type=script&lang=js"
export * from "./TpmsEventLog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports