<template>
  <v-list-item
    :key="index"
    @click="clickedElement"
  >
    <!-- <v-list-item-icon
      class="d-flex flex-column"
    >
      <v-icon
        class="primary--text"
        dark
        :large="!iconLabel"
      >
        {{ icon }}
      </v-icon>
      {{ iconLabel }}
    </v-list-item-icon> -->

    <v-list-item-content class="pa-1">
      <v-list-item-title class="d-flex align-center justify-space-between">
        <h3 :class="colorText">
          {{ title }}
        </h3>
        <p
          class="pb-4 mb-0 grey--text"
          style="font-size: 14px;"
        >
          {{ formatDate(date) }}
        </p>
      </v-list-item-title>

      <v-list-item-subtitle
        :class="colorText"
        style="text-align: initial;"
      >
        {{ subtitle ? subtitle : address }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action v-if="showDetailButton">
      <v-btn
        icon
        color="primary"
        @click="onClickHandler"
      >
        <v-icon>
          mdi-eye
        </v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import dayjs from 'dayjs'
import getAddress from 'GraphQL/queries/address/getAddress.gql'
const advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

export default {
  props: {
    title: {
      type: String,
      default: () => ''
    },
    subtitle: {
      type: String,
      default: () => ''
    },
    icon: {
      type: String,
      default: () => ''
    },
    iconLabel: {
      type: String,
      default: () => ''
    },
    index: {
      type: Number,
      default: () => 0
    },
    date: {
      type: Number,
      default: () => undefined
    },
    onClick: {
      type: Function,
      default: () => {}
    },
    unit: {
      type: Object,
      default: () => {}
    },
    showDetailButton: {
      type: Boolean,
      default: true
    }
  },
  asyncComputed: {
    address: {
      get () {
        const positionObject = {}
        let geofenceIds = []
        if (this.unit.telemetry.address) {
          return this.unit.telemetry.address
        }
        if (this.unit.telemetry?.position?.latitude) {
          positionObject.latitude = this.unit.telemetry.position.latitude
          positionObject.longitude = this.unit.telemetry.position.longitude
          geofenceIds = this.unit.telemetry.geofencesIds
        } else {
          return 'the address was unable to retrieve'
        }
        const object = Object.assign({}, this.unit)
        // Get the address using apollo
        return this.$apollo.query({
          query: getAddress,
          variables: {
            apiToken: this.user.apiToken,
            position: positionObject,
            geofenceIds
          }
        }).then(response => {
          const { status, result } = response.data.getAddress
          switch (status) {
            case 'OK':
              object.telemetry.address = result
              // this.$store.commit('units/updateUnit', object)
              // this.$store.commit('units/updateUnitDialog', true)
              this.$store.dispatch('units/setUnitsEvent', this.$store.state)
              return result
            case 'ACCESSDENIED':
              this.$store.commit('toggleSnackbar', { message: this.$i18n.t('errors.accessDenied'), color: 'red darken-2' })
              break
            case 'BADREQUEST':
              this.$store.commit('toggleSnackbar', { message: this.$i18n.t('errors.invalidFields'), color: 'orange darken-2' })
              break
            case 'UNPROCESSABLE':
              this.$store.commit('toggleSnackbar', { message: this.$i18n.t('errors.invalidFields'), color: 'orange darken-2' })
              break
            case 'INTERNALERROR':
              this.$store.commit('toggleSnackbar', { message: this.$i18n.t('helpers.errors.disaster'), color: 'red darken-2' })
              break
          }
        }).catch(err => {
          console.error(err)
          this.$store.commit('toggleSnackbar', { message: this.$i18n.t('helpers.errors.disaster'), color: 'red darken-2' })
        }).finally(() => {
        })
      },
      default: 'Address'
    }
  },
  computed: {
    ...mapState(['isDark']),
    ...mapGetters(['colorText']),
    user () { return this.$store.state.session }
  },
  methods: {
    formatDate (date) {
      if (date) {
        return dayjs(date).format('Do MMMM YYYY - hh:mm a')
      } else {
        return ''
      }
    },
    onClickHandler () {
      this.onClick(this.unit)
    },
    clickedElement () {
      this.$emit('clicked-element', this.unit)
    }
  }
}
</script>