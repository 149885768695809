import Axios from 'axios'
import { baseUrl as baseURL } from './constants'

const axiosInstance = Axios.create({
  baseURL
})

function AxiosPlugin (Vue) {
  Vue.prototype.$axios = axiosInstance
}

export default AxiosPlugin

export { axiosInstance as axios }