<template>
  <v-card class="px-2 py-2">
    <v-card-title
      class="unit__title gm_black--text"
    >
      {{ value }}
    </v-card-title>
    <v-card-subtitle class="d-flex align-center justify-center">
      <v-icon
        class="unit__icon grey--text mr-1"
      >
        {{ icon }}
      </v-icon>
      <v-icon
        v-if="secondIcon !== undefined"
        class="unit__icon grey--text mr-1"
      >
        {{ secondIcon }}
      </v-icon>
      <p class="pa-0 ma-0 unit__subtitle grey--text">
        {{ title }}
      </p>
    </v-card-subtitle>
  </v-card>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: () => ''
    },
    title: {
      type: String,
      default: () => ''
    },
    icon: {
      type: String,
      default: () => ''
    },
    secondIcon: {
      type: String,
      default: () => undefined
    }
  }

}
</script>

<style lang="scss" scoped>

.unit__icon{
  font-size: 28px;
}

.unit__title{
  justify-content: center;
  font-size: 30px;
  font-weight: 700 !important;
}

.unit__subtitle {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

</style>