export default {
  list: [],
  selectedData: ['all'],
  selectedGroups: [],
  selectedUnits: [],
  selectedTime: null,
  globalSearch: '',

  // objects
  checksObject: {},
  tasksObject: {},
  purchaseOrdersObject: {},

  // dialogs
  checksDialog: false,
  assignDefectsDialog: false,
  tasksDialog: false,
  purchaseOrdersDialog: false
}
