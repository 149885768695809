/*
  Asset state
*/
export default {
  list: [],
  dictList: [],

  object: {},
  detailsDialog: false
}
