<template>
  <div style="position: relative; min-height: 70vh;">
    <div
      v-if="loadingChart"
      cols="12"
      class="loader"
      style=""
    >
      <v-card class="pa-4 primary">
        <v-progress-circular
          indeterminate
          color="white"
        />
      </v-card>
    </div>
    <v-row v-if="!listView" class="mt-4" style="position: relative;">
      <v-col cols="8">
        <v-select
          v-model="selectedType"
          :items="types"
          dense
          label="Select Type of chart"
          outlined
          item-text="label"
          item-value="value"
        />
      </v-col>
      <v-col cols="4">
        <v-select
          v-model="selectedTime"
          :items="timeRanges"
          dense
          label="Time Range"
          outlined
          item-text="label"
          item-value="value"
          @change="getBrakeEvents"
        />
      </v-col>
      <v-col cols="6" style="font-size: 18px;">
        <div class="text__container">
          <v-icon color="success" class="mr-2">
            mdi-check
          </v-icon>
          <div class="mr-4">
            {{ $t('unitsDialog.brakePerformanceRate.label') }}:
          </div>
          <div class="green__text">
            {{ getBrakePerfomanceRate() }}
          </div>
        </div>
        <div v-if="unit.brakePerformanceResets !== undefined" class="text__container">
          <v-icon color="success" class="mr-2">
            mdi-clock-outline
          </v-icon>  
          <div class="mr-4">
            {{ $t('unitsDialog.brakePerformanceRate.calculated') }}: 
          </div>
          <div class="green__text">
            {{ getBrakePerfomanceCalculatedAt() }}
          </div>
        </div>
        <div class="text__container">
          <v-icon color="success" class="mr-2">
            mdi-clock-outline
          </v-icon>  
          <div class="mr-4">
            {{ $t('unitsDialog.brakePerformanceRate.calculatedReset') }}: 
          </div>
          <div class="green__text">
            {{ getLastResetCalculated() }}
          </div>
        </div>
        <div class="text__container mt-2">
          <v-btn text style="background: rgba(128, 128, 128, 0.15);" @click="changePage">See all the EBPMS Events</v-btn> 
        </div>
        <div class="text__container mt-2">
          <v-btn text style="background: rgba(115, 22, 14, 0.25);" @click="openResetDialog">Reset Brake Performance Rate</v-btn> 
        </div>
        <!-- <div class="text__container">
          <v-icon small color="warning" class="mr-2">
            mdi-clock-outline
          </v-icon>  
          <div class="mr-4">
            {{ $t('unitsDialog.brakePerformanceRate.criticalDay') }}: 
          </div>
          <div class="green__text">
            {{ getEstimatedBrakePerformanceRateCriticalDay() }}
          </div>
        </div> -->
      </v-col>
      <v-col cols="6">
        <v-row v-if="unit.brakePerformanceRate" class="snackbar__container" :class="brakePerformanceRatePassed() ? 'warning__snackbar__container' : ''" no-gutters>
          <v-col cols="4" class="d-flex justify-center flex-column align-center snackbar__title">
            {{ brakePerformanceRatePassed() ? $t('unitsDialog.brakePerformanceRate.warning') : $t('unitsDialog.brakePerformanceRate.congratulations') }}
          </v-col>
          <v-col cols="8" class="d-flex justify-center flex-column align-center snackbar__subtitle">
            {{ brakePerformanceRatePassed() ? $t('unitsDialog.brakePerformanceRate.below', {name: unit.name}) : $t('unitsDialog.brakePerformanceRate.above', {name: unit.name}) }}
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="!loadingChart" cols="12">
        <!-- Brake Events chart -->
        <div v-if="selectedType === 'brakeEvent'" class="mx-4" style="max-height: 30%">
          <apexchart
            width="100%"
            type="line"
            :height="vhToPixels(45)"
            :options="chartOptions"
            :series="series"
          />
        </div>
      </v-col>
      <v-col v-else cols="12">
      </v-col>
    </v-row>
    <v-card v-else flat>
      <v-card-title
        class="grey--text text--darken-2 font-weight-bold list_title headline pb-1 mb-2"
      >
        <v-btn
          icon
          class="mr-2"
          @click="changePage"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        Go Back
      </v-card-title>
      <div
        class="scrollbar__seamless pa-1"
        style="height: 60vh;"
      >
        <v-list
          class="scrollbar__seamless"
          style="height: 100%; overflow: auto;"
        >
          <template
            v-for="(item, index) in brakePerformanceRatesData"
          >
            <v-divider :key="`divider__${index}`" />
            <v-list-item
              :key="index"
            >
              <v-list-item-content>
                <v-list-item-title
                  class="d-flex align-center justify-space-between"
                >
                  <template v-if="item.type == 'brakePerformanceRate'">
                    <!-- Prepare a flex div   -->
                    <div class="d-flex align-center justify-space-between">
                      <h5 class="gm_black--text mr-4">
                        {{ $t(`units.ebpms.type.${item.type}`) }}: {{ item.value.toFixed(2) }}%
                      </h5>
                      <v-tooltip v-if="item.calculatedBy === 'USER'" top class="mr-4">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-account-group
                          </v-icon>
                        </template>
                        <span>{{ $t(`units.ebpms.calculatedByUser`) }}</span>
                      </v-tooltip>
                      <v-tooltip v-if="!item.isValid" top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-exclamation-thick
                          </v-icon>
                        </template>
                        <span>{{ $t(`units.ebpms.invalid`) }}</span>
                      </v-tooltip>
                    </div>
                  </template>
                  <h5 v-else class="gm_black--text">
                    {{ $t(`units.ebpms.type.${item.type}`) }}
                  </h5>
                  <p
                    class="pb-4 mb-0 blue--text text--darken-4"
                    style="font-size: 14px;"
                  >
                    {{ parseDate(item.date) }}
                  </p>
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ item.subtitle }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import dayjs from 'dayjs'

import list from 'GraphQL/queries/brakeEvents/list.gql'

const utc = require('dayjs/plugin/utc')

export default {
  props: {
    unit: {
      type: Object,
      default: () => {}
    },
    currentTab: {
      type: Number,
      default: () => 0
    },
    sheetOpen: {
      required: true,
      type: Boolean
    }
  },
  data () {
    return {
      listView: false,
      loadingChart: true,
      selectedType: 'brakeEvent',
      selectedTime: 'last500',
      chartOptions: {
        chart: {
          id: 'vuechart',
          toolbar: {
            show: false
          },
          animations: {
            enabled: false,
            easing: 'easeinout',
            speed: 1000,
            animateGradually: {
              enabled: true,
              delay: 10
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350
            }
          },
          zoom: {
            enabled: false,
            autoScaleXaxis: true
          }
        },
        markers: {
          size: 5
        },
        xaxis: {
          type: 'numeric',
          title: {
            text: 'Demand pressure - bar',
            offsetX: 0,
            offsetY: 0
          }
        },
        yaxis: {
          type: 'numeric',
          opposite: true,
          tickAmount: 8,
          title: {
            text: 'Deceleration - % of g',
            offsetX: 0,
            offsetY: 0
          }
        },
        stroke: {
          width: 3
        },
        colors: ['#db1233', '#4287f5', '#FDD835', '#4ad138']
      }
    }
  },
  computed: {
    ...mapState(['isDark']),
    user () { return this.$store.state.session },
    dialogValue: {
      get: function () {
        return this.dialog
      },
      set: function (value) {
        this.$emit('dialog-change', value)
      }
    },
    types () {
      return [
        {
          label: 'Brake Events',
          value: 'brakeEvent'
        }
        // {
        //   label: 'Brake Perfomance Value Trend line',
        //   value: 'brakePerformanceTrend'
        // }
      ]
    },
    timeRanges () {
      return [
        {
          label: 'Last 500',
          value: 'last500'
        },
        {
          label: 'Today',
          value: 'day'
        },
        {
          label: 'Week',
          value: 'week'
        },
        {
          label: 'Month',
          value: 'month'
        }
      ]
    },
    series () {
      return [
        {
          name: 'Lower limit',
          type: 'line',
          data: [[1, 0], [4.5, 29], [6.5, 45]]
        },
        {
          name: 'Points',
          type: 'scatter',
          data: this.calculateChart()
        },
        {
          name: 'Upper Limit',
          type: 'line',
          data: [[0.2, 0], [4.5, 41], [6.5, 65]]
        },
        {
          name: 'Forecast Line',
          type: 'line',
          data: this.calculateForecasTLine()
        }
      ]
    },
    initialTimestampProps () {
      return {
        max: new Date().toISOString()
      }
    },
    finalTimestampProps () {
      return {
        min: new Date(this.initialTimestamp).toISOString(),
        max: new Date().toISOString()
      }
    },
    brakePerformanceRatesData () {
      // List with brake performance rates and brake performance resets
      const data = []
      // Each brake performance rate object.brakePerformanceRates
      this.unit.brakePerformanceRates.forEach((item) => {
        // Each brake performance rate object.brakePerformanceRates
        data.push({
          type: 'brakePerformanceRate',
          value: item.rate,
          date: item.calculatedAt,
          calculatedBy: item.calculatedBy,
          isValid: item.isValid
        })
      })
      // Each brake performance reset object.brakePerformanceResets
      this.unit.brakePerformanceResets.forEach((item) => {
        // Each brake performance reset object.brakePerformanceResets
        data.push({
          type: 'brakePerformanceReset',
          date: item.createdAt
        })
      })
      // Sort data by date
      data.sort((a, b) => {
        return new Date(a.date) - new Date(b.date)
      })
      return data
    }
  },

  watch: {
    currentTab () {
      this.loadingChart = false
      this.updateLoadingChart()
    },
    sheetOpen () {
      this.loadingChart = false
      this.updateLoadingChart()
    },
    unit () {
      this.updateLoadingChart()
    }
  },
  created () {
    this.updateLoadingChart()
  },
  methods: {
    getBrakeEvents (value) {
      this.getEvents(value)
    },
    async getEvents (value) {
      const object = Object.assign({}, this.unit)
      this.loadingChart = true
      await this.$apollo.query({
        query: list,
        variables: {
          apiToken: this.user.apiToken,
          unitId: object.id,
          rangeType: value
        }
      }).then(response => {
        const { status, result } = response.data.brakeEvents
        const resultRates = response.data.brakePerformanceRates.result
        const resultBrakePerformanceRates = response.data.brakePerformanceSlope.result
        switch (status) {
          case 'OK':
            object.events = result
            object.brakePerformanceRates = resultRates
            object.brakePerformanceSlope = resultBrakePerformanceRates
            break
          case 'ACCESSDENIED':
            this.$store.commit('toggleSnackbar', { message: this.$i18n.t('errors.accessDenied'), color: 'red darken-2' })
            break
          case 'BADREQUEST':
            this.$store.commit('toggleSnackbar', { message: this.$i18n.t('errors.invalidFields'), color: 'orange darken-2' })
            break
          case 'UNPROCESSABLE':
            this.$store.commit('toggleSnackbar', { message: this.$i18n.t('errors.invalidFields'), color: 'orange darken-2' })
            break
          case 'INTERNALERROR':
            this.$store.commit('toggleSnackbar', { message: this.$i18n.t('helpers.errors.disaster'), color: 'red darken-2' })
            break
        }
      }).catch(err => {
        console.error(err)
        this.$store.commit('toggleSnackbar', { message: this.$i18n.t('helpers.errors.disaster'), color: 'red darken-2' })
      }).finally(() => {
        this.updateLoadingChart()
      })
      this.$store.commit('units/updateUnit', object)
      this.$store.commit('units/updateUnitDialog', true)
      this.$store.dispatch('units/setUnitsEvent', this.$store.state)
    },
    calculateChart () {
      this.loadingChart = false
      if (this.unit) {
        this.loadingChart = false
        return this.unit.events.map((event) => [event.meanDemand.toFixed(2), event.meanDecel.toFixed(2)])
      }
      this.updateLoadingChart()
      this.loadingChart = false
      return []
    },
    calculateForecasTLine () {
      const avgX = this.getAverage(this.unit.events, 'meanDemand')
      const avgY = this.getAverage(this.unit.events, 'meanDecel')
      const constant = avgY - (8.29 * avgX)
      this.updateLoadingChart()
      return [[1, this.calculateForecastPoint(1, constant)], [4.5, this.calculateForecastPoint(4.5, constant)], [6.5, this.calculateForecastPoint(6.5, constant)]]
    },
    updateLoadingChart () {
      const vueObj = this
      setTimeout(() => { vueObj.loadingChart = false }, 1000)
    },
    getAverage (array, key) {
      const sum = array.reduce((a, b) => parseFloat(a) + parseFloat(b[key]), 0)
      return (sum / array.length) || 0
    },
    calculateForecastPoint (x, constant) {
      return (x * 8.29 + constant).toFixed(2)
    },
    brakePerformanceRatePassed () {
      return this.unit.brakePerformanceRate.rate < 45
    },
    changePage () {
      this.listView = !this.listView
    },
    getTimeAgo (time) {
      const difference = dayjs().unix() - time
      let text = ''
      if (difference < 3600) {
        const minutes = Math.floor(difference / 60)
        if (minutes === 1) {
          text = 'A minute ago'
        } else {
          text = `${minutes} minutes ago`
        }
      } else if (difference < 86400) {
        const hours = Math.floor(difference / 3600)
        if (hours === 1) {
          text = 'A hour ago'
        } else {
          text = `${hours} hours ago`
        }
      } else {
        const days = Math.floor(difference / 86400)
        if (days === 1) {
          text = 'A day ago'
        } else {
          text = `${days} days ago`
        }
      }
      return text
    },
    getBrakePerfomanceRate () {
      if (this.unit.brakePerformanceRate) {
        return `${Math.round(this.unit.brakePerformanceRate.rate)}%`
      } else {
        return 'Not Available'
      }
    },
    getBrakePerfomanceCalculatedAt () {
      if (this.unit.brakePerformanceRate) {
        return this.getTimeAgo(this.unit.brakePerformanceRate.calculatedAt)
      } else {
        return 'Not Available'
      }
    },
    getEstimatedBrakePerformanceRateCritical () {
      const brakePerformanceSlope = this.unit.brakePerformanceSlope
      if (this.unit.brakePerformanceSlope) {
        return (45 - brakePerformanceSlope.intercept) / brakePerformanceSlope.slope
      }
      return null
    },
    getEstimatedBrakePerformanceRateCriticalDayTimestamp () {
      const days = this.getEstimatedBrakePerformanceRateCritical()
      if (this.unit.brakePerformanceRates.length > 0) {
        return this.unit.brakePerformanceRates[0].calculatedAt + (Math.abs(days) * 86400)
      }
      return null
    },
    getEstimatedBrakePerformanceRateCriticalDay () {
      const date = this.getEstimatedBrakePerformanceRateCriticalDayTimestamp()
      if (date) {
        return this.parseDate(date)
      }
      return 'Not available'
    },

    parseDate (date) {
      dayjs.extend(utc)
      if (date === null) {
        return dayjs()
      } else {
        return dayjs(date * 1000).format('DD/MM/YYYY HH:mm')
      }
    },

    vhToPixels (vh) {
      return Math.round(window.innerHeight / (100 / vh))
    },
    openResetDialog () {
      this.$store.commit('units/updateResetDialog', true)
    },
    getLastResetCalculated () {
      if (this.unit.brakePerformanceResets) {
        const length = this.unit.brakePerformanceResets.length
        if (length > 0) {
          return this.parseDate(this.unit.brakePerformanceResets[length - 1].createdAt)
        } else {
          return 'Not Available'
        }
      } else {
        return 'Not Available'
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.list_title{
  border-bottom: 2px solid;
  border-color: #616161;
}

.tabs__styles {
  font-size: 16px;
}

.mapfiller{
  height: 400px;
  background-color: #67925b;
  width: 100%;
}

.image__container {
  width: 100%;
  height: 90px;
  display: flex; 
  justify-content: center;
  align-items: center;
  background: rgb(151,151,153);
  background: linear-gradient(180deg, rgba(151,151,153,1) 0%, rgba(185,185,185,1) 0%, rgba(247,249,250,1) 100%);
}

.card__actions {
  margin-top: -60px
}

.text__container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.green__text {
  color: #0B6E00;
  font-weight: bold !important;
}

.snackbar__container {
  background-color: #328428;
  color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0px 2px 8px #5D5D5D54;
  padding: 4px 12px;
  height: 100%;
}

.warning__snackbar__container {
  background-color: #F9A825;
}

.snackbar__title {
  color: #FFFFFF;
  font-weight: bold !important;
}

.snackbar__subtitle {
  color: #FFFFFF;
  font-size: 12px;
}

.link__container {
  color: #014E88;
}

.loader{
  position: absolute;
  z-index: 4;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(33, 33, 33, 0.46);
}

</style>