// import { i18n } from '@/plugins/i18n'

export default {
  defaultObject () {
    return {
      id: null,
      name: '',
      value: false,
      comment: '',
      photoUrl: '',
      parentId: ''
    }
  }
}