<template>
  <div>
    <v-sheet
      class="better-scrollbar"
    >
      <v-container fluid>
        <v-row no-gutters>
          <v-col cols="6" class="pa-2 text-center">
            <!-- Select for Tyres -->
            <v-select
              v-model="currentTyre"
              :items="tyreOptions"
              dense
              :label="$t('tyreAnalisis.currentTyre')"
              outlined
              prepend-inner-icon="mdi-tire"
              hide-details="auto"
            />
          </v-col>
          <v-col cols="6" class="pa-2 text-center">
            <!-- Select for Events type -->
            <v-select
              v-model="eventType"
              :items="eventOptions"
              dense
              :label="$t('tyreAnalisis.eventType')"
              outlined
              prepend-inner-icon="mdi-cube"
              hide-details="auto"
            />
          </v-col>
          <!-- <v-col cols="4" class="pa-2 text-center">
            <v-select
              v-model="timeInterval"
              :items="timeIntervalOptions"
              dense
              :label="$t('tyreAnalisis.timeInterval')"
              outlined
              prepend-inner-icon="mdi-calendar-range"
              hide-details="auto"
            />
          </v-col> -->
          <!-- Section with the events -->
          <v-col cols="12" class="pa-2">
            <v-list>
              <v-list-item-group>
                <v-virtual-scroll
                  :height="`calc(80vh - 100px)`"
                  item-height="70"
                  :bench="3"
                  class="scrollbar__seamless"
                  :items="filteredEvents"
                >
                  <template #default="{ item, index }">
                    <v-list-item
                      :key="index"
                    >
                      <v-list-item-icon
                        class="d-flex flex-column"
                      >
                        <v-icon
                          :class="isDark ? 'white--text' : 'grey--text text--darken-4 large'"
                          dark
                        >
                          mdi-alert
                        </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content class="pa-1">
                        <v-list-item-title class="d-flex align-center justify-space-between">
                          <span class="d-flex align-center">
                            <h3
                              class="font-weight-bold text--darken-2"
                            >
                              {{ $t(`tyreAnalisis.events.${item.type}`) }}
                            </h3>
                          </span>
                          <p
                            class="pb-4 mb-0 grey--text"
                            style="font-size: 14px;"
                          >
                            {{ formatDate(item.receivedAt * 1000) }}
                          </p>
                        </v-list-item-title>

                        <v-list-item-subtitle
                          class="font-weight-medium"
                          style="text-align: initial;"
                        >
                          <template v-if="item.samePosition">
                            {{ $t(`tyreAnalisis.tyres.${item.firstPosition}`) }}
                          </template>
                          <template v-else>
                            <template v-if="item.firstPosition">
                              {{ $t(`tyreAnalisis.tyres.${item.firstPosition}`) }} -
                            </template>
                            <template v-if="item.secondPosition">
                              {{ $t(`tyreAnalisis.tyres.${item.secondPosition}`) }}
                            </template>
                          </template>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider :key="`divider__${index}`" />
                  </template>
                </v-virtual-scroll>
              </v-list-item-group>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>

import { mapState, mapGetters } from 'vuex'
import { orm } from '@/mixins'

import dayjs from 'dayjs'
const advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

export default {
  mixins: [orm],

  model: {
    prop: 'data',
    event: 'set-object'
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },

    unit: {
      type: Object,
      default: () => {}
    },
    tpmsEvents: {
      type: Array,
      default: () => []
    },
    changeTpmsEvents: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      currentTyre: null,
      timeInterval: 'week',
      eventType: null,
      events: []
    }
  },

  computed: {
    ...mapState(['session', 'width', 'isLoading', 'isDark', 'units']),
    ...mapGetters(['color', 'isMobile']),
    tyreOptions () {
      return [
        { value: null, text: this.$t('tyreAnalisis.allTyres') },
        { value: 'nsr', text: this.$t('tyreAnalisis.tyres.nsr') },
        { value: 'nsm', text: this.$t('tyreAnalisis.tyres.nsm') },
        { value: 'nsf', text: this.$t('tyreAnalisis.tyres.nsf') },
        { value: 'osf', text: this.$t('tyreAnalisis.tyres.osf') },
        { value: 'osm', text: this.$t('tyreAnalisis.tyres.osm') },
        { value: 'osr', text: this.$t('tyreAnalisis.tyres.osr') }
      ]
    },
    eventOptions () {
      // null,
      // 'MISSING',
      // 'ROTATION',
      // 'REPLACEMENT',
      // 'SWAP'
      return [
        { value: null, text: this.$t('tyreAnalisis.allEvents') },
        { value: 'MISSING', text: this.$t('tyreAnalisis.events.MISSING') },
        { value: 'ROTATION', text: this.$t('tyreAnalisis.events.ROTATION') },
        { value: 'REPLACEMENT', text: this.$t('tyreAnalisis.events.REPLACEMENT') },
        { value: 'SWAP', text: this.$t('tyreAnalisis.events.SWAP') },
        { value: 'LOW_PRESSURE', text: this.$t('tyreAnalisis.events.LOW_PRESSURE') },
        { value: 'EXTREME_LOW_PRESSURE', text: this.$t('tyreAnalisis.events.EXTREME_LOW_PRESSURE') },
        { value: 'OVERINFLATED', text: this.$t('tyreAnalisis.events.OVERINFLATED') },
        { value: 'BLOWOUT', text: this.$t('tyreAnalisis.events.BLOWOUT') },
        { value: 'FAST_PRESSURE_LOST', text: this.$t('tyreAnalisis.events.FAST_PRESSURE_LOST') }
      ]
    },
    timeIntervalOptions () {
      // 'week',
      // 'yesterday',
      // 'today'
      return [
        { value: 'week', text: this.$t('tyreAnalisis.timeIntervals.week') },
        { value: 'yesterday', text: this.$t('tyreAnalisis.timeIntervals.yesterday') },
        { value: 'today', text: this.$t('tyreAnalisis.timeIntervals.today') }
      ]
    },
    object: {
      get () {
        return this.data
      },
      set (value) {
        this.$emit('toggle-object', value)
      }
    },
    filteredEvents () {
      const events = this.calculateEvents()
      const filteredEvents = []
      for (const i in events) {
        const event = events[i]
        let validation = true
        if (this.currentTyre) {
          if (event.firstPosition !== this.currentTyre && event.secondPosition !== this.currentTyre) {
            validation = false
          }
        }

        if (this.eventType) {
          if (event.type !== this.eventType) {
            validation = false
          }
        }
        if (validation) {
          filteredEvents.push(event)
        }
      }
      return filteredEvents
    }
  },

  beforeDestroy () {
  },

  created () {
    this.currentDay = dayjs().format('MMMM DD, YYYY')
    this.lastRotation = dayjs().format('DD/MM/YYYY')
  },

  methods: {
    getRndInteger (min = 0, max = 100) {
      return Math.floor(Math.random() * (max - min)) + min
    },
    calculateEvents () {
      const events = []
      // For in tpmsEvents
      for (const i in this.tpmsEvents) {
        const event = this.tpmsEvents[i]
        events.push({
          receivedAt: event.receivedAt,
          type: event.eventType,
          firstPosition: event.wheelPosition,
          secondPosition: event.wheelPosition,
          samePosition: true
        })
      }
      // For in changeTpmsEvents
      for (const i in this.changeTpmsEvents) {
        const event = this.changeTpmsEvents[i]
        events.push({
          receivedAt: event.receivedAt,
          type: event.eventType,
          firstPosition: event.firstWheelPosition,
          secondPosition: event.secondWheelPosition,
          samePosition: event.firstWheelPosition === event.secondWheelPosition
        })
      }
      // Sort by receivedAt
      events.sort((a, b) => {
        return new Date(b.receivedAt * 1000) - new Date(a.receivedAt * 1000)
      })
      // this.events = events
      return events
    },
    formatDate (date) {
      if (date) {
        return dayjs(date).format('Do MMMM YYYY - hh:mm a')
      } else {
        return ''
      }
    }
  }
}
</script>
