/*
  reports state mutations
*/
import { i18n } from '@/plugins/i18n'

function arrayToDict (array, key) {
  const result = {}
  for (let i = 0; i < array.length; i++) {
    result[array[i][key]] = array[i]
  }
  return result
}

export default {
  setList (state, payload) {
    const list = payload
    // Convert the scheduleTime to a string
    for (var i = 0; i < list.length; i++) {
      if (list[i].scheduleTime) {
        // Conver seconds to hour and minutes
        let hour = Math.floor(list[i].scheduleTime / 3600)
        if (hour < 10) {
          hour = `0${hour}`
        }
        let minute = Math.floor((list[i].scheduleTime % 3600) / 60)
        if (minute < 10) {
          minute = `0${minute}`
        }
        list[i].scheduleTime = `${hour}:${minute}`
      }
    }
    state.list = list
    // Convert state.list to dict list
    state.dictList = Object.assign({}, arrayToDict(state.list, 'id'))
  },

  addReport (state, payload) {
    state.list.push(payload)
    // Convert state.list to dict list
    state.dictList = Object.assign({}, arrayToDict(state.list, 'id'))
  },

  updateReport (state, payload) {
    const index = state.list.findIndex((item) => item.id === payload.id)
    state.list.splice(index, 1, payload)
  },

  updateList (state, { rootState, payload, commit }) {
    const generateUnitName = (unitIds) => {
      const units = rootState.units.list.filter((unit) =>
        unitIds.includes(unit.id)
      )
      if (units.length === 0) {
        return 'All Units'
      } else if (units.length >= 4) {
        // Cut the units to 4
        return `${units
          .slice(0, 4)
          .map((unit) => unit.name)
          .join(', ')}...`
      }
      return units.map((unit) => unit.name).join(', ')
    }
    // For Payload to update the state list
    const updateList = payload.map((item) => {
      return {
        ...item,
        unitName: generateUnitName(item.units)
      }
    })
    // Update the state list but vlaidating if the item exists and the status change
    state.list = state.list.map((item) => {
      const updateItem = updateList.find(
        (updateItem) => updateItem.id === item.id
      )
      if (updateItem && updateItem.status !== item.status) {
        // Commit an update for the snakbar
        commit(
          'toggleSnackbar',
          {
            message: i18n.t('reports.success.reports'),
            color: 'green darken-2'
          },
          { root: true }
        )
        return updateItem
      }
      return item
    })
    state.dictList = Object.assign({}, arrayToDict(state.list, 'id'))
  },
  newItem (state, payload) {
    state.list.push(payload)
  },

  updateItem (state, payload) {
    const list = [...state.list]
    for (let i = 0; i < list.length; i++) {
      if (list[i].id.toString() === payload.id.toString()) {
        list[i] = Object.assign({}, payload)
      }
    }

    state.list = []
    state.list = [...list]
  },
  // eslint-disable-next-line no-unused-vars
  toggle (_, payload) {}
}
