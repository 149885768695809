import { apollo } from '@/plugins/apollo'

import countries from 'GraphQL/queries/countries/list.gql'
import measureUnits from 'GraphQL/queries/utils/measureUnits.gql'

export default {
  async getCountries ({ commit }, payload = false) {
    if (payload) {
      commit('setLoading', true, { root: true })
    }

    await apollo.query({
      query: countries
    }).then((response) => {
      const { status, result } = response.data.countries

      switch (status) {
        case 'OK':
          commit('setCountries', result)
          break

        case 'BADREQUEST':
          commit('toggleSnackbar', undefined, { root: true })
          break
      }
    }).catch(err => {
      console.error(err)
      commit('toggleSnackbar', undefined, { root: true })
    }).finally(() => {
    })
  },

  async getMeasureUnits ({ commit }, payload = false) {
    if (payload) {
      commit('setLoading', true, { root: true })
    }

    await apollo.query({
      query: measureUnits
    }).then((response) => {
      const { status, result } = response.data.measureUnits

      switch (status) {
        case 'OK':
          commit('setMeasureUnits', result)
          break

        case 'BADREQUEST':
          commit('toggleSnackbar', undefined, { root: true })
          break
      }
    }).catch(err => {
      console.error(err)
      commit('toggleSnackbar', undefined, { root: true })
    }).finally(() => {
    })
  }
}
