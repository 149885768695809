<template>
  <div
    id="event_map"
    ref="map"
    class="grey"
    style="width: 100%;"
    :style="`height: ${height}px;`"
  />
</template>

<script>
import { mapState } from 'vuex'
import { inititalCoordinates } from '@/plugins/constants'
export default {
  props: {
    data: {
      type: Object,
      default: () => null
    },
    showMap: {
      type: Boolean,
      default: () => true
    },
    height: {
      type: Number,
      default: () => 400
    },
    mapTypeControl: {
      type: Boolean,
      default: () => true
    },
    drawTrips: {
      type: Boolean,
      default: () => false
    },
    trips: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      map: null, // Google Maps Map entity
      google: null, // Google Maps object
      defaultCenter: null,
      polylines: [],
      marker: null,
      markers: []
    }
  },
  computed: {
    ...mapState(['width', 'isLoading'])
  },

  watch: {
    showMap (newVal) {
      if (newVal) {
        this.loadMap()
      } else {
        // 
      }
    },
    drawTrips (newVal) {
      if (newVal) {
        this.clearMarker(this.marker)
        this.clearPolylines()
        this.clearMarkers()
        this.drawTripsMethod()
      } else {
        this.clearMarker(this.marker)
        this.clearMarkers()
        this.clearPolylines()
        this.drawMarker()
      }
    }
  },

  mounted () {
    if (this.showMap) {
      this.loadMap()
    }
  },

  methods: {
    loadMap () {
      if (!this.$googlemaps.done) {
        this.$googlemaps
          .load()
          .then((google) => {
            this.google = google
            // to use the label drawer "class" window.google must exist and the file is called to be require
            window.google = google
            this.initMap()
            // this.initDrawer()
            // this.startDrawing()
          })
      } else {
        this.google = window.google
        this.initMap()
        // this.initDrawer()
        // this.startDrawing()
      }
    },
    /**
     * Initialize Singleton instance of Google maps
     */
    initMap () {
      if (this.data !== null) {
        this.defaultCenter = new this.google.maps.LatLng(this.data.latitude, this.data.longitude)
      } else {
        this.defaultCenter = new this.google.maps.LatLng(inititalCoordinates.latitude, inititalCoordinates.longitude)
      }

      this.map = new this.google.maps.Map(
        this.$refs.map,
        {
          mapTypeId: 'roadmap',
          zoom: 20,
          center: this.defaultCenter,
          disableDefaultUI: true,
          zoomControl: true,
          mapTypeControl: this.mapTypeControl,
          mapTypeControlOptions: {
            position: this.google.maps.ControlPosition.RIGHT_TOP
          }
        }
      )

      setTimeout(() => {
        if (this.marker) {
          this.clearMarker(this.marker)
          this.marker = null
        }
        this.drawMarker()
      }, 0)

      const self = this
      setTimeout(() => {
        self.google.maps.event.trigger(self.map, 'resize')
        self.map.setCenter(self.defaultCenter)
      }, 1500)
    },

    drawMarker () {
      if (this.data !== null) {
        const coordinates = new this.google.maps.LatLng(this.data.latitude, this.data.longitude)
        this.marker = this.addMarker(coordinates)
      }
      this.google.maps.event.addListenerOnce(this.map, 'idle', () => {
        this.google.maps.event.trigger(this.map, 'resize')
        this.map.setCenter(this.defaultCenter)
      })
    },

    drawPolilyne (points) {
      const polyline = new this.google.maps.Polyline({
        path: points,
        geodesic: true,
        strokeColor: '#FF0000',
        strokeOpacity: 1.0,
        strokeWeight: 2
      })
      polyline.setMap(this.map)
      return polyline
    },

    addMarker (coordinates) {
      return new this.google.maps.Marker({
        position: coordinates,
        map: this.map
      })
    },

    clearMarker (marker) {
      if (marker) {
        marker.setMap(null)
      }
    },

    clearPolylines () {
      for (const i in this.polylines) {
        if (this.polylines[i]) {
          this.polylines[i].setMap(null)
        }
      }
      this.polylines = []
    },

    clearMarkers () {
      for (const i in this.markers) {
        if (this.markers[i]) {
          this.markers[i].setMap(null)
        }
      }
      this.markers = []
    },

    drawTripsMethod () {
      // Generate polylines and bounds
      const bounds = new this.google.maps.LatLngBounds()
      const generalPoints = []
      for (const i in this.trips) {
        const trip = this.trips[i]
        const points = []
        for (const j in trip.messages) {
          if (trip.messages[j]) {
            if (trip.messages[j].latitude) {
              const point = {
                lat: trip.messages[j].latitude,
                lng: trip.messages[j].longitude
              }
              const marker = new this.google.maps.Marker({
                position: point,
                map: this.map
              })
              this.markers.push(marker)
              points.push(point)
              generalPoints.push(point)
              bounds.extend(point)
            }
          }
        }
        //  Draw polyline
        this.polylines.push(this.drawPolilyne(points))
      }
      
      this.map.fitBounds(bounds)
    }

    // Center Map
  }
}
</script>

<style lang="scss">
</style>