export default {
  defaultObject () {
    return {
      id: undefined,
      name: '',
      plate: '',
      ident: '',
      lastMessage: null,
      brakePerformanceRate: null,
      lastBrakeEvent: null
    }
  },
  // Maintenance
  // eslint-disable-next-line no-unused-vars
  faultUnits (state, getters, rootState) {
    return state.list.filter((unit) => {
      return (unit.telemetry?.axeLoadSum / unit.trailerProfile?.weight > 1 || rootState.dtcs.eventList.filter((dtcEvent) => dtcEvent.unitId === unit.id).length > 0)
    })
  },
  homeDepotUnits (state) {
    return state.list.filter((unit) => unit.telemetry?.isDepot)
  },
  flaggedVor (state) {
    return state.list.filter((unit) => (unit.trailerProfile?.vor))
  },
  upcomingServices (state) {
    const compareDates = (date, firstDate, endDate) => {
      return date >= firstDate && date <= endDate
    }

    const getStartOfDay = (date) => {
      date.setHours(0, 0, 0, 0)
      return date
    }

    const getEndOfDay = (date) => {
      date.setHours(23, 59, 59, 999)
      return date
    }

    const upcomingServices = []
    const todayStart = getStartOfDay(new Date())
    const endTimestamp = getEndOfDay(new Date(new Date().getTime() + 86400 * 1000 * 14))
    for (const i in state.list) {
      const unit = state.list[i]
      if (unit.trailerProfile) {
        if (unit.trailerProfile.lastService && unit.trailerProfile.serviceInterval) {
          const compareDate = new Date(new Date(unit.trailerProfile.lastService).getTime() + 86400 * 1000 * unit.trailerProfile.serviceInterval)
          if (compareDates(compareDate, todayStart, endTimestamp)) {
            upcomingServices.push(unit)
          }
        }
      }
    }
    return upcomingServices
  },
  emptyRunning (state) {
    return state.list.filter((unit) => (unit.telemetry?.axeLoadSum / unit.trailerProfile?.weight < 0.2))
  },
  loaded (state) {
    return state.list.filter((unit) => !(unit.telemetry?.axeLoadSum / unit.trailerProfile?.weight < 0.2) && !(unit.telemetry?.axeLoadSum / unit.trailerProfile?.weight > 1))
  },
  // eslint-disable-next-line no-unused-vars
  customerDepot (state, getters, rootState) {
    const geofences = rootState.geofences.list
    const result = []
    for (const i in state.list) {
      const unit = state.list[i]
      const customerGeofences = geofences.filter(geofence => geofence.category === 'CUSTOMER' && unit?.telemetry?.geofencesIds.includes(parseInt(geofence.id)))
      if (customerGeofences.length > 0) {
        result.push(unit)
      }
    }
    return result
  }

}
