import general from './general'
import session from './session'
import users from './users'
import languages from './languages'
import assets from './assets'
import units from './units'
import geofences from './geofences'
import dtcs from './dtcs'
import events from './events'
import dailyOdometers from './dailyOdometers'
import monitor from './monitor'
import mapMonitor from './map_monitor'
import historicVor from './historicVor'
import notifications from './notifications'
import emptyRunningTrips from './emptyRunningTrips'
import stoodStatus from './stoodStatus'
import groups from './groups'
import scheduleReports from './scheduleReports'
import tpmsEvents from './tpmsEvents'
import reports from './reports'
import newReports from './newReports'
import templates from './templates'
import maintenance from './maintenance'
import checkResults from './checkResults'
import activityDrivers from './activityDrivers'
import defectChecks from './defectChecks'
import mechanics from './mechanics'
import checkResultItems from './checkResultItems'
import purchaseOrders from './purchaseOrders'

export default {
  general,
  session,
  users,
  languages,
  assets,
  units,
  geofences,
  dtcs,
  events,
  dailyOdometers,
  monitor,
  mapMonitor,
  historicVor,
  notifications,
  emptyRunningTrips,
  stoodStatus,
  groups,
  scheduleReports,
  tpmsEvents,
  reports,
  newReports,
  templates,
  maintenance,
  checkResults,
  activityDrivers,
  defectChecks,
  mechanics,
  checkResultItems,
  purchaseOrders
}
