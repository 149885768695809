<template>
  <v-app :dark="isDark" style="display: flex;">
    <layrz-sidebar
      :is-mobile="isMobile"
      :dark="true"
      :links="sidebarLinks"
      background-color="primary"
      :permanent="!isMobile"
      clipped
      :mini-variant="!isMobile"
      :state="drawer"
      :name="appName"
      :logo="app.logo"
      :has-picture="hasAvatar"
      :avatar="avatar"
      :full-name="fullName"
      :actions="actions"
      :has-whitelabel="hasWhitelabel"
      :mobile-links="headerLinks"
      @set-state="(value) => $store.commit('setDrawer', value)"
    />
    <layrz-header
      :is-mobile="isMobile"
      :dark="isDark"
      :links="headerLinks"
      name=""
      :actions="actions"
      :logo="app.logo"
      :breakpoint="breakpoint"
      :avatar="app.profileLogo"
      :has-picture="true"
      :full-name="fullName"
      :has-whitelabel="hasWhitelabel"
      clipped-left
      rounded-links
      @set-drawer="(value) => $store.commit('setDrawer', value)"
    />

    <v-main>
      <transition name="component-fade" mode="out-in">
        <router-view />
      </transition>
    </v-main>
    <!-- <layrz-footer
      v-if="showFooter"
      is-full
      inset
      absolute
      :mode="footerFormat"
      :made-with="$t('copyright.helpers.madeWith')"
      :by="$t('copyright.helpers.by')"
      all-rights="Vanguarder"
    /> -->
    <!-- Unit Folder -->
    <!-- <gm-units-dialog
      :dialog="unitDialog"
      :unit.sync="unit"
      @dialog-change="setDialog"
      @update-trailer-profile="updateUnitTrailerProfile"
    /> -->

    <!-- Unit bottom sheet/ Unit details -->
    <gm-unit-detail-sheet
      v-if="unitDialog"
      :open="unitDialog"
      :unit.sync="unit"
      @update:open="setDialog"
      @update-trailer-profile="updateUnitTrailerProfile"
    />
    <gm-unit-reset-performance
      v-if="resetDialog"
      :open="resetDialog"
      :unit="unit"
      @toggle-dialog="state => $store.commit('units/updateResetDialog', state)"
      @update:open="setResetDialog"
    />
  </v-app>
</template>

<script>
// Golden M Packages
import {
  Sidebar as LayrzSidebar,
  Header as LayrzHeader
} from '@goldenm-software/generic-layout'

import {
  UnitsDialog
} from 'Components/units'

import UnitDetailSheet from 'Components/units/unit_sheet/UnitDetailSheet'
import UnitResetPerformance from 'Components/units/reset_performance_rate/Dialog'
import { mapState, mapGetters } from 'vuex'
import whitelabel from '@/mixins/whitelabels'
import mqttjs from 'mqtt'
import lodash from 'lodash'
export default {
  components: {
    LayrzSidebar,
    LayrzHeader,
    // eslint-disable-next-line
    'gm-units-dialog': UnitsDialog,
    // eslint-disable-next-line
    'gm-unit-detail-sheet': UnitDetailSheet,
    'gm-unit-reset-performance': UnitResetPerformance
  },

  mixins: [whitelabel],

  data () {
    return {
      mqtt: null,
      intervalId: null
    }
  },

  computed: {
    ...mapState(['isDark', 'whitelabel', 'session', 'drawer']),
    ...mapGetters({
      has: 'session/canSee',
      hasAvatar: 'session/hasAvatar',
      fullName: 'session/fullName',
      avatar: 'session/avatar',
      breakpoint: 'breakpoint',
      isMobile: 'isMobile'
    }),
    app () {
      return this.$store.state.general.app
    },
    countertsMode () { return this.$store.state.monitor.countersMode },
    unitDialog () { return this.$store.state.units.unitDialog },
    resetDialog () { return this.$store.state.units.resetDialog },
    unit () { return this.$store.state.units.unit },
    reports () { return this.$store.state.reports.dictList },
    mqttToken () {
      return this.session.mqttToken
    },

    showFooter () {
      return this.$route.name !== 'layrz.monitor'
    },

    actions () {
      const items = [
        // {
        //   name: this.$i18n.t(`actions.toggle.counters.${this.countertsMode}`),
        //   action: () => this.$store.commit('monitor/toggleCounters')
        // },
        {
          name: this.$i18n.t(`actions.darkMode.${this.isDark}`),
          action: () => this.$store.commit('toggleDark')
        }
      ]

      items.push({
        name: this.$i18n.t('pages.profile'),
        to: '/Profile'
      })
      items.push({
        name: this.$i18n.t('users.signOut'),
        action: () => this.$store.dispatch('session/logout')
      })
      return items
    },

    headerLinks () {
      return [
        {
          icon: 'mdi-home-city',
          to: '/Home',
          name: this.$i18n.t('pages.monitor.home'),
          can: true
        }
      ]
    },

    sidebarLinks () {
      const links = [
        {
          icon: 'mdi-home',
          to: '/Home',
          name: this.$i18n.t('pages.monitor.map'),
          can: true
        },
        {
          icon: 'mdi-speedometer',
          to: '/Dashboard',
          name: this.$i18n.t('pages.monitor.home'),
          can: true
        },
        {
          icon: 'mdi-history',
          to: '/History',
          name: this.$i18n.t('pages.monitor.history'),
          can: true
        },
        {
          icon: 'mdi-file',
          to: '/NewReports',
          name: this.$i18n.t('pages.monitor.reports'),
          can: true
        },
        // {
        //   icon: 'mdi-bell',
        //   to: '/Notifications',
        //   name: this.$i18n.t('pages.monitor.notifications'),
        //   can: true
        // },
        {
          icon: 'mdi-bell',
          to: '/NotificationsEvents',
          name: this.$i18n.t('pages.monitor.notifications'),
          can: true
        },
        {
          icon: 'mdi-account-group-outline',
          to: '/Groups',
          name: this.$i18n.t('pages.monitor.groups'),
          can: true
        },  
        {
          icon: 'mdi-map-marker-radius',
          to: '/Geofences',
          name: this.$i18n.t('pages.monitor.geofences'),
          can: true
        },
        {
          icon: 'mdi-text-box',
          to: '/Templates',
          name: this.$i18n.t('pages.monitor.templates'),
          can: true
        },
        {
          icon: 'mdi-calendar',
          to: '/Maintenance',
          name: this.$i18n.t('pages.monitor.maintenance'),
          can: true
        }
      ]
      // Validate is current user username is 'developer'
      if (this.session.username === 'developer') {
        links.push({
          icon: 'mdi-monitor',
          to: '/DriverStatus',
          name: this.$i18n.t('pages.monitor.driverStatus'),
          can: true
        })
      }
      return links
    }
  },
  watch: {
    isDark (newVal) {
      this.$vuetify.theme.dark = newVal
    },

    mqttToken () {
      // if (newVal) {
      //   this.initMqtt()
      // }
    }
  },
  beforeDestroy () {
    if (this.mqtt) {
      this.mqtt.end(true)
      this.mqtt = null
    }
    if (this.intervalId) {
      clearInterval(this.intervalId)
      this.intervalId = null
    }
  },

  mounted () {
    if (!this.mqtt) {
      this.initMqtt()
    }
    if (!this.intervalId) {
      this.initReportInverval()
    }
  },

  methods: {
    initMqtt () {
      this.mqtt = mqttjs.connect('mqtts://mqtt.flespi.io', {
        username: 'FlespiToken RghbhuOMbhTkg3QPqhJHuA8PYhTHjhXWy2ozAAS7Vl3TFyNUjdLDjpigNmMdqBeX'
      })

      this.mqtt.on('connect', (err) => {
        if (!err) {
          console.log('[MQTT] Error connecting with Flespi')
        } else {
          console.log('[MQTT] Connected')

          let topic = 'vanguarder/+/+/+/1'

          if (process.env.NODE_ENV === 'development') {
            topic += 'dev'
          }

          this.mqtt.subscribe(topic, (err) => {
            if (!err) {
              console.log('[MQTT] Subscribed successfully to', topic)
            } else {
              console.log('[MQTT] Error subscribint to topic', topic, err)
            }
          })
        }
      })

      this.mqtt.on('message', (topic, message) => {
        let topicEnd = '/1'
        if (process.env.NODE_ENV === 'development') {
          topicEnd += 'dev'
        }
        topic = topic.replace(topicEnd, '')
        topic = topic.replace('vanguarder/', '')
        topic = topic.split('/')
        const moduleRec = topic[1]
        const submodule = topic[2]
        const command = topic[0]

        message = JSON.parse(message)

        switch (moduleRec) {
          case 'units':
            this.handleUnitsUpdate(command, submodule, message)
            break

          case 'dtcevents':
            this.handleDtcEventsUpdate(command, submodule, message)
            break

          case 'overweightEvents':
            this.handleOverweightEventsUpdate(command, submodule, message)
            break

          case 'lastbrakeperformancerate':
            this.handleLastBrakePerforamnceRateUpdate(command, submodule, message)
            break
          case 'reports':
            this.handleReportsUpdate(command, submodule, message)
            break
          default:
            console.log('[MQTT] Unhandled topic', topic)
            break
        }
      })
    },
    toCamel (o) {
      let newO, origKey, newKey, value
      if (o instanceof Array) {
        return o.map(function (value) {
          if (typeof value === 'object') {
            value = this.toCamel(value)
          }
          return value
        })
      } else {
        newO = {}
        for (origKey in o) {
          // eslint-disable-next-line no-prototype-builtins
          if (o.hasOwnProperty(origKey)) {
            newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString()
            value = o[origKey]
            if (value instanceof Array || (value !== null && value.constructor === Object)) {
              value = this.toCamel(value)
            }
            newO[newKey] = value
          }
        }
      }
      return newO
    },
    handleUnitsUpdate (command, submodule, message) {
      const finalMessage = lodash.mapKeys(message, (v, k) => lodash.camelCase(k))
      // console.log('[MQTT] Units update', command, submodule, finalMessage)
      if (command === 'new') {
        switch (submodule) {
          case 'dtcevents':
            // this.$store.commit('units/updatePropertyItem', {
            //   unitId: finalMessage.unitId,
            //   property: 'dtcEvent',
            //   payload: finalMessage
            // })
            break
          case 'overweightEvents':
            console.log('WIP')
            break
          
          case 'messages':
            this.$store.commit('units/updatePropertyItem', {
              unitId: finalMessage.unitId,
              property: 'telemetry',
              payload: finalMessage
            })
            break

          case 'lastbrakeperformancerate':
            // this.$store.commit('units/updatePropertyItem', {
            //   unitId: finalMessage.unitId,
            //   property: 'brakeperformancerate',
            //   payload: finalMessage
            // })
            break
        }
      }
    },
    handleReportsUpdate (command, submodule, message) {
      if (message.report_name === '') {
        message.status = 'failed'
      } else {
        message.status = 'generated'
      }
      const payload = Object.assign({}, this.reports[message.report_id])
      payload.status = message.status
      payload.reportName = message.report_name
      payload.errorResponse = 'Theres no brake events for the the date selected'
      this.$store.commit('reports/updateReport', payload)
      // Generate a snackbar with the message of the report its ready and the status
      if (message.status === 'generated') {
        this.$store.commit('toggleSnackbar', {
          message: this.$i18n.t('reports.success.reports'),
          color: 'success'
        })
      } else {
        this.$store.commit('toggleSnackbar', {
          message: 'Theres no brake events for the the date selected',
          color: 'orange darken-2'
        })
      }
    },
    setDialog (value) {
      this.$store.commit('units/updateUnitDialog', value)
    },
    setResetDialog (value) {
      this.$store.commit('units/updateResetDialog', value)
    },
    updateUnitTrailerProfile (trailerProfile) {
      this.unit.trailerProfile = trailerProfile
      this.$store.commit('units/updateItem', this.unit)
    },
    initReportInverval () {
      this.intervalId = setInterval(() => {
        this.$store.dispatch('reports/getList')
        this.$store.dispatch('units/getList', false, { root: true })
        this.$store.dispatch('tpmsEvents/getActiveList', false, { root: true })
        this.$store.dispatch('tpmsEvents/getList', false, { root: true })
        this.$store.dispatch('dtcEvents/getList', false, { root: true })
        this.$store.dispatch('checkResults/getList', false, { root: true })
        this.$store.dispatch('defectChecks/getList', false, { root: true })
        this.$store.dispatch('events/getList', false, { root: true })
      }, 1000 * 120)
    }
  }
}
</script>

<style lang="scss">
</style>
