<template>
  <v-list-item
    :key="index"
  >
    <v-list-item-icon
      class="d-flex flex-column"
    >
      <v-icon
        class="secondary--text"
        dark
        :large="!iconLabel"
      >
        {{ icon }}
      </v-icon>
      {{ iconLabel }}
    </v-list-item-icon>

    <v-list-item-content class="pa-1">
      <v-list-item-title
        class="d-flex align-center justify-space-between"
      >
        <h3
          class="text--darken-2 font-weight-bold" :class="isAmber ? 'yellow--text' : 'red--text'"
        >
          <!-- eslint-disable-next-line -->
          {{ `${formatValue(value)}${measureUnit}` }}
        </h3>
        <p
          class="pb-6 mb-0 grey--text"
          style="font-size: 14px;"
        >
          {{ formatDate(date) }}
        </p>
      </v-list-item-title>
      <v-list-item-subtitle
        :class="colorText"
        style="text-align: initial;"
      >
        Tyre Location: {{ subtitle }}
      </v-list-item-subtitle>
    </v-list-item-content>

    <!-- <v-list-item-action>
      <v-btn color="amber" icon @click.stop="showCommentDialog()">
        <v-icon>mdi-file-edit-outline</v-icon>
      </v-btn>
    </v-list-item-action> -->

    <gm-comment-dialog v-model="commentDialog" :event-type="eventType" />
  </v-list-item>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import CommentDialog from 'Components/monitor/dialogs/CommentDialog.vue'

import dayjs from 'dayjs'
const advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

export default {
  components: {
    'gm-comment-dialog': CommentDialog
  },
  props: {
    title: {
      type: String,
      default: () => ''
    },
    subtitle: {
      type: String,
      default: () => ''
    },
    icon: {
      type: String,
      default: () => ''
    },
    iconLabel: {
      type: String,
      default: () => ''
    },
    index: {
      type: Number,
      default: () => 0
    },
    value: {
      type: Number,
      default: () => 0
    },
    date: {
      type: Number,
      default: () => undefined
    },
    isAmber: {
      type: Boolean,
      default: () => false
    },
    measureUnit: {
      type: String,
      default: () => ''
    },
    eventType: {
      type: String,
      default: () => ''
    }
  },
  data () {
    return {
      commentDialog: false
    }
  },
  computed: {
    ...mapState(['isDark']),
    ...mapGetters(['colorText'])
  },
  methods: {
    formatDate (date) {
      if (date) {
        return dayjs(date).format('Do MMMM YYYY - hh:mm a')
      } else {
        return ''
      }
    },
    formatValue (value) {
      return parseFloat(value).toFixed(2)
    },
    showCommentDialog () {
      this.commentDialog = true
    }
  }
}
</script>