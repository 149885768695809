<template>
  <v-layout row wrap>
    <v-flex xs12>
      <v-card class="ml-4 mr-4 elevation-0">
        <v-card-title
          class="grey--text text--darken-2 font-weight-bold list_title headline pb-1 mb-2"
        >
          Trips History for {{ unit.name }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <!-- SELECT REPORT TYPE AND DATETIME -->
            <v-col cols="5">
              <v-datetime-picker
                v-model="initialTimestamp"
                label="Initial Date"
                hide-details="auto"
                dense
                outlined
                prepend-inner-icon="mdi-calendar"
                :datePickerProps="initialTimestampProps"
              />
            </v-col>
            <v-col cols="5">
              <v-datetime-picker
                v-model="finalTimestamp"
                hide-details="auto"
                dense
                outlined
                label="Final Date"
                prepend-inner-icon="mdi-calendar"
                :datePickerProps="finalTimestampProps"
              />
            </v-col>
            <v-col cols="1" class="d-flex justify-center">
              <v-btn
                class="mt-1"
                fab
                dark
                small
                color="primary"
                @click="getTrips"
              >
                <v-icon dark>
                  mdi-magnify
                </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="1" class="d-flex justify-center">
              <v-btn
                class="mt-1"
                fab
                dark
                small
                color="primary"
                :disabled="retrieveObject.retrieve === false"
                @click="getReport"
              >
                <v-icon dark>
                  mdi-file
                </v-icon>
              </v-btn>
            </v-col>
            <!-- <v-col cols="1" class="d-flex justify-center">
              <v-btn
                class="mt-1"
                fab
                dark
                small
                color="primary"
                @click="getTrips"
              >
                <v-icon dark>
                  mdi-menu
                </v-icon>
              </v-btn>
            </v-col> -->
            <v-flex xs12>
              <v-card class="mx-4 elevation-0">
                <v-card-title
                  class="list_title headline pb-1 mb-2 d-flex flex-column"
                >
                  <div class="d-flex align-center" style="width: 100%">
                    <h4 class="grey--text text--darken-2 font-weight-bold">Summary</h4>
                    <v-spacer />
                  </div>
                  <v-divider style="width: 100%" class="my-2" />
                </v-card-title>
                <!-- {{ notifications[0] }} -->
                <v-card-text>
                  <v-data-table
                    :headers="summaryHeaders"
                    :items="tripsSummary"
                  >
                    <template
                      #body="{ items }"
                    >
                      <tbody>
                        <tr
                          v-for="(item, index) in items"
                          :key="index"
                        >
                          <td>{{ formatDate(item.initAt * 1000) }}</td>
                          <td>{{ formatDate(item.endAt * 1000) }}</td>
                          <td>{{ item.initLocation }}</td>
                          <td>{{ item.endLocation }}</td>
                          <td>{{ item.tripOdometer }} km</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex xs12 class="pb-4 mb-4">
              <v-card class="mx-4 elevation-0">
                <v-card-title
                  class=" list_title headline pb-1 mb-2 d-flex flex-column"
                >
                  <div class="d-flex align-center" style="width: 100%">
                    <h4 class="grey--text text--darken-2 font-weight-bold">Trips List</h4>
                    <v-spacer />
                  </div>
                  <v-divider style="width: 100%" class="my-2" />
                </v-card-title>
                <!-- {{ notifications[0] }} -->
                <v-card-text>
                  <v-data-table
                    :headers="headers"
                    :items="tripsArray"
                  >
                    <template
                      #body="{ items }"
                    >
                      <tbody>
                        <tr
                          v-for="(item, index) in items"
                          :key="index"
                        >
                          <td>
                            <v-checkbox
                              v-model="item.selected"
                              :value="item.selected"
                              @change="(value) => onChangeHandler(value, index)"
                            />
                          </td>
                          <td>{{ formatDate(item.initAt * 1000) }}</td>
                          <td>{{ formatDate(item.endAt * 1000) }}</td>
                          <td>
                            <div class="d-flex align-center">
                              <span
                                class="text-linked"
                                @click="getMessagesCoords(item.messages[0])"
                              >
                                {{ item.initLocation }}
                              </span>

                              <v-btn
                                icon
                                small
                                class="ml-2"
                                color="secondary"
                                @click="addRadialGeofence(item.messages, 0)"
                              >
                                <v-icon small>
                                  mdi-map-marker
                                </v-icon>
                              </v-btn>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex align-center">
                              <span
                                class="text-linked"
                                @click="getMessagesCoords(item.messages[item.messages.length - 1])"
                              >
                                {{ item.endLocation }}
                              </span>

                              <v-btn
                                icon
                                small
                                class="ml-2"
                                color="secondary"
                                @click="addRadialGeofence(item.messages, item.messages.length - 1)"
                              >
                                <v-icon small>
                                  mdi-map-marker
                                </v-icon>
                              </v-btn>
                            </div>
                          </td>
                          <td>{{ item.tripOdometer.toFixed(2) }} km</td>
                          <td>
                            <v-btn
                              icon
                              color="secondary"
                              @click="addLinearGeofence(item.messages)"
                            >
                              <v-icon>
                                mdi-vector-polyline
                              </v-icon>
                            </v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-col cols="12">
              <v-card flat>
                <trips-map
                  :height="540"
                  :drawTrips="drawTrip"
                  :trips="tripsArray"
                />
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-flex>
    <geofence-dialog
      v-model="geofenceObject"
      :show-dialog="geofenceDialog"
      :readonly="readonly"
      :units="unitsItems"
      :has-path="objectHasPath"
      @set-readonly="(value) => readonly = value"
      @toggle-dialog="toggleGeofenceDialog"
    />
  </v-layout>
</template>

<script>
import historicTrips from 'GraphQL/queries/messages/historicTrips.gql'
import TripsMap from 'Components/units/unit_sheet/tabs/trips/TripsMap.vue'
import tripsReport from 'GraphQL/mutations/reports/tripsReport.gql'
import {
  Dialog as GeofenceDialog

} from 'Components/geofences'
import dayjs from 'dayjs'
const advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

export default {
  components: {
    'trips-map': TripsMap,
    GeofenceDialog
  },
  props: {
    unit: {
      type: Object,
      default: () => {}
    },
    sheetOpen: {
      required: true,
      type: Boolean
    },
    tabKey: {
      required: true,
      type: Number
    }
  },
  data () {
    return {
      initialTimestamp: this.getStartOftheDate(new Date()),
      finalTimestamp: new Date(),
      trips: {},
      tripsArray: [],
      drawTrip: false,
      unitId: '',
      retrieveObject: {
        retrieve: false
      },
      alreadyLoaded: true,
      geofenceDialog: false,
      geofenceObject: {},
      readonly: false
    }
  },
  computed: {
    objectHasPath () {
      return this.geofenceObject.path && this.geofenceObject.path.length > 0
    },
    unitsItems () {  
      // List of all units in the system but filtered by the selectedUnit
      return this.$store.state.units.basicList.filter(unit => unit.id === this.unit.id)
    },
    user () { return this.$store.state.session },
    units () { return this.$store.state.units.basicList },
    initialTimestampProps () {
      return {
        max: new Date().toISOString()
      }
    },
    finalTimestampProps () {
      return {
        min: new Date(this.initialTimestamp).toISOString(),
        max: new Date().toISOString()
      }
    },
    totalKilometers () {
      let result = 0
      if (this.tripsArray.length > 0) {
        this.tripsArray.forEach(item => {
          result += item.tripOdometer
        })
      }
      return result.toFixed(2)
    },
    tripsSummary () {
      if (this.tripsArray.length === 0) {
        return []
      }
      const result = []
      // Build a result using the first and last trip for initAt, endAt, initLocation, endLocation
      const firstTrip = this.tripsArray[0]
      const lastTrip = this.tripsArray[this.tripsArray.length - 1]
      result.push({
        initAt: firstTrip.initAt,
        endAt: lastTrip.endAt,
        initLocation: firstTrip.initLocation,
        endLocation: lastTrip.endLocation,
        tripOdometer: this.totalKilometers
      })
      return result
    },
    summaryHeaders () {
      return [
        {
          text: 'Initial Date',
          align: 'left',
          sortable: false
        },
        {
          text: 'Final Date',
          align: 'left',
          sortable: false
        },
        {
          text: 'Initial Location',
          align: 'left',
          sortable: false
        },
        {
          text: 'Final Location',
          align: 'left',
          sortable: false
        },
        {
          text: 'Total Distance',
          align: 'left',
          sortable: false
        }
      ]
    },
    headers () {
      return [
        {
          text: 'Show',
          align: 'left',
          sortable: false
        },
        {
          text: 'Initial Date',
          align: 'left',
          sortable: false
        },
        {
          text: 'Final Date',
          align: 'left',
          sortable: false
        },
        {
          text: 'Initial Location',
          align: 'left',
          sortable: false
        },
        {
          text: 'Final Location',
          align: 'left',
          sortable: false
        },
        {
          text: 'Distance',
          align: 'left',
          sortable: false
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'right',
          sortable: false
        }
      ]
    }
  },
  watch: {
    unit () {
      this.alreadyLoaded = false
    },
    sheetOpen (value) {
      if (!value) {
        setTimeout(() => {
          this.initialTimestamp = this.getStartOftheDate(new Date())
          this.finalTimestamp = new Date()
          this.trips = {}
          this.tripsArray = []
          this.drawTrip = false
          this.unitId = ''
        }, 0)
      }
    },
    tabKey (value) {
      if (value === 2 && !this.alreadyLoaded) {
        this.alreadyLoaded = true
        this.getTrips()
      }
    }
  },
  created () {
    this.getTrips()
  },
  methods: {
    getMessagesCoords (trip) {
      this.openOnGoogleMaps(trip.latitude, trip.longitude)
    },
    openOnGoogleMaps (lat, lng) {
      const url = `https://www.google.com/maps/?q=${lat},${lng}`
      window.open(url)
    },
    getPolylinePath (points) {
      const result = []
      points.forEach(point => {
        result.push(
          {
            latitude: point.latitude,
            longitude: point.longitude,
            radius: 10
          }
        )
      })
      return result
    },

    addLinearGeofence (messages) {
      this.readonly = false
      this.geofenceObject = Object.assign({}, this.$store.getters['geofences/defaultObject'])
      this.geofenceObject.mode = 'LINEAR'
      this.geofenceObject.path = [...this.getPolylinePath(messages)]
      this.toggleGeofenceDialog(true)
    },
    addRadialGeofence (messages, index) {
      this.readonly = false
      this.geofenceObject = Object.assign({}, this.$store.getters['geofences/defaultObject'])
      this.geofenceObject.mode = 'RADIAL'
      this.geofenceObject.path = [...this.getPolylinePath([messages[index]])]
      this.toggleGeofenceDialog(true)
    },
    toggleGeofenceDialog (value) {
      this.geofenceDialog = value
    },
    async getReport () {
      const variables = {
        apiToken: this.user.apiToken,
        unitId: [this.retrieveObject.unitId],
        initDate: Math.trunc(new Date(this.retrieveObject.initDate).getTime() / 1000),
        endDate: Math.trunc(new Date(this.retrieveObject.endDate).getTime() / 1000)
      }

      const mutation = tripsReport
      const name = 'tripsReport'
      variables.timeInterval = 'custom'

      this.$store.commit('setLoading', true)
      this.$apollo
        .mutate({
          mutation,
          variables,
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          const { status, errors, result } = response.data[name]

          switch (status) {
            case 'OK':
              // console.log('Report result :>> ', `/public/${result.url}`)
              // window.open(process.env.NODE_ENV === 'development' ? `http://localhost:8000/public/${result.url}` : `https://integrate.vanguarder.goldenm.dev/static/${result.url}`, '_blank')
              this.$store.commit('reports/addReport', {
                ...result,
                unitName: this.generateUnitName(result.units)

              }, { root: true })
              this.$store.commit('toggleSnackbar', {
                message: this.$i18n.t('reports.start.reports'),
                color: 'success'
              })
              break

            case 'NOTFOUND':
              this.$store.commit('toggleSnackbar', {
                message: 'Theres no brake events for the the date selected',
                color: 'orange darken-2'
              })
              break
            case 'UNPROCESSABLE':
              this.$store.commit('toggleSnackbar', {
                message: errors.days,
                color: 'orange darken-2'
              })
              break

            case 'INTERNALERROR':
              this.$store.commit('toggleSnackbar')
              break
          }
        })
        .catch(_ => {
          this.$store.commit('toggleSnackbar')
        }).finally(() => {
          this.$store.commit('setLoading', false)
        })
    },
    generateUnitName (_) {
      // Filter the units by the unitIds and get the name
      return this.unit.name
    },
    getStartOftheDate (date) {
      date.setHours(0, 0, 0, 0)
      return date
    },
    async getTrips () {
      this.drawTrip = false
      const mutation = historicTrips
      const initTimestamp = this.initialTimestamp
      const finalTimestamp = this.finalTimestamp
      const variables = {
        apiToken: this.user.apiToken,
        unitId: this.unit.id,
        initTimestamp: Math.trunc(new Date(initTimestamp).getTime() / 1000),
        endTimestamp: Math.trunc(new Date(finalTimestamp).getTime() / 1000)
      }

      this.$store.commit('setLoading', true)

      this.$apollo
        .mutate({
          mutation,
          variables,
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          const { status, errors, result } = response.data.historicTrips
          // const result2 = response.data.historicTrips.result
          switch (status) {
            case 'OK':
              if (result.length > 0) {
                this.retrieveObject = {
                  retrieve: true,
                  unitId: this.unit.id,
                  initDate: initTimestamp,
                  endDate: finalTimestamp
                }
                this.calculateTrips(result)
                this.$store.commit('toggleSnackbar', {
                  message: this.$i18n.t('trips.messages.success'),
                  color: 'success'
                })
              } else {
                this.tripsArray = []
                this.$store.commit('toggleSnackbar', {
                  message: this.$i18n.t('trips.messages.success.empty'),
                  color: 'warning'
                })
              }
              break

            case 'NOTFOUND':
              this.$store.commit('toggleSnackbar', {
                message: 'Theres no Trips for the the date selected',
                color: 'orange darken-2'
              })
              break
            case 'UNPROCESSABLE':
              this.$store.commit('toggleSnackbar', {
                message: errors.days,
                color: 'orange darken-2'
              })
              break

            case 'INTERNALERROR':
              this.$store.commit('toggleSnackbar')
              break
          }
        })
        .catch(_ => {
          // console.log(_)
          this.$store.commit('toggleSnackbar')
        })
        .finally(() => {
          this.$store.commit('setLoading', false)
        })
    },
    /**
     * clean the trip view and draw the new ones
     */
    calculateTrips (trips) {
      this.retrievedTrip = true
      // Clean trips
      this.tripsArray = []
      this.trips = {}
      // For in trips
      for (const i in trips) {
        const currentTrip = trips[i]
        this.trips[i] = {
          ...currentTrip,
          selected: true
        }
        this.tripsArray.push(this.trips[i])
      }
      this.drawTrip = true
    },
    formatDate (date) {
      if (date) {
        return dayjs(date).format('Do MMMM YYYY - hh:mm a')
      } else {
        return ''
      }
    },
    onChangeHandler (value, index) {
      this.drawTrip = false
      this.trips[index].selected = value
      const self = this

      setTimeout(() => {
        self.drawTrip = true
      }, 500)
    }
  }
}
</script>

<style>

</style>