<template>
  <v-app :dark="isDark" style="display: flex;">
    <v-app-bar
      app
      :mobile-breakpoint="breakpoint"
      :color="isDark ? 'grey darken-4' : 'white'"
      class="elevation-3"
      clipped-left
      clipped-right
      dense
    >
      <v-toolbar-title class="d-flex align-center text-truncate ml-0 pl-1">
        <img :src="app.logo" :height="40">
      </v-toolbar-title>

      <v-spacer />
      <v-toolbar-title class="d-flex align-center text-truncate ml-0 pl-1">
        {{ parseDuration() }}
      </v-toolbar-title>
    </v-app-bar>
    <v-main>
      <transition name="component-fade" mode="out-in">
        <router-view />
      </transition>
    </v-main>
    <!-- <layrz-footer
      v-if="showFooter"
      is-full
      inset
      absolute
      :mode="footerFormat"
      :made-with="$t('copyright.helpers.madeWith')"
      :by="$t('copyright.helpers.by')"
      all-rights="Vanguarder"
    /> -->
    <!-- Unit Folder -->
    <!-- <gm-units-dialog
      :dialog="unitDialog"
      :unit.sync="unit"
      @dialog-change="setDialog"
      @update-trailer-profile="updateUnitTrailerProfile"
    /> -->
  </v-app>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import whitelabel from '@/mixins/whitelabels'
export default {
  components: {
    // eslint-disable-next-line
    // eslint-disable-next-line
  },

  mixins: [whitelabel],

  data () {
    return {
      mqtt: null,
      endAtObject: null,
      duration: null
    }
  },

  computed: {
    ...mapState(['isDark', 'whitelabel', 'session', 'drawer']),
    ...mapGetters({
      has: 'session/canSee',
      hasAvatar: 'session/hasAvatar',
      fullName: 'session/fullName',
      avatar: 'session/avatar',
      breakpoint: 'breakpoint',
      isMobile: 'isMobile'
    }),
    endAt () {
      return this.$store.state.session.endAt
    },
    appName () {
      return this.$t('app.name')
    },
    app () {
      return this.$store.state.general.app
    }
  },
  watch: {
    isDark (newVal) {
      this.$vuetify.theme.dark = newVal
    },

    mqttToken () {
      // if (newVal) {
      //   this.initMqtt()
      // }
    },
    endAt (newVal) {
      // Caclculate This duration and set it to duration 
      this.endAtObject = newVal
      // this.endAt - now = duration
      this.duration = this.endAtObject - (new Date() / 1000)
      this.countDownDuration()
    }
  },
  methods: {
    parseDuration () {
      // Convert Seconds into HH:MM:SS
      if (this.duration === null) return ''
      const hours = Math.floor(this.duration / 3600)
      const minutes = Math.floor((this.duration - (hours * 3600)) / 60)
      const seconds = Math.floor(this.duration - (hours * 3600) - (minutes * 60))
      return `Access expires in: ${this.pad(hours, 2)}:${this.pad(minutes, 2)}:${this.pad(seconds, 2)}`
    },
    countDownDuration () {
      this.duration = this.duration - 1
      setTimeout(this.countDownDuration, 1000)
    },
    pad (num, size) {
      num = num.toString()
      while (num.length < size) num = '0' + num
      return num
    }
  }
}
</script>

<style lang="scss">
</style>
