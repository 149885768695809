import { i18n } from '@/plugins/i18n'
import { apollo } from '@/plugins/apollo'

// graphql files
import list from 'GraphQL/queries/historicVor/list.gql'

export default {
  async getList ({ rootState, commit }, payload = false) {
    if (payload) {
      await commit('setLoading', true, { root: true })
    }
    await apollo.query({
      query: list,
      variables: {
        apiToken: rootState.session.apiToken
      }
    }).then(response => {
      const { status, result } = response.data.historicVor

      switch (status) {
        case 'OK':
          commit('setEventList', result)
          break
        case 'ACCESSDENIED':
          commit('toggleSnackbar', { message: i18n.t('errors.accessDenied'), color: 'red darken-2' }, { root: true })
          break
        case 'BADREQUEST':
          commit('toggleSnackbar', { message: i18n.t('errors.invalidFields'), color: 'orange darken-2' }, { root: true })
          break
        case 'UNPROCESSABLE':
          commit('toggleSnackbar', { message: i18n.t('errors.invalidFields'), color: 'orange darken-2' }, { root: true })
          break
        case 'INTERNALERROR':
          commit('toggleSnackbar', { message: i18n.t('helpers.errors.disaster'), color: 'red darken-2' }, { root: true })
          break
      }
    }).catch(err => {
      console.error(err)
      commit('toggleSnackbar', { message: i18n.t('helpers.errors.disaster'), color: 'red darken-2' }, { root: true })
    }).finally(() => {
    })
  },

  async details ({ rootState, commit }, id) {
    await commit('setLoading', true, { root: true })
    await apollo.query({
      query: list,
      variables: {
        apiToken: rootState.session.token.token,
        id,
        tagsIds: [rootState.app.tagId]
      }
    }).then(response => {
      const { status, result } = response.data.assets

      switch (status) {
        case 'OK':
          if (result.length === 1) {
            commit('toggle', { method: 'details', status, result: result[0] })
          } else {
            commit('toggleSnackbar', { message: i18n.t('errors.itemNotFound'), color: 'red darken-2' }, { root: true })
            commit('toggle', { method: 'details', status: 'NOTFOUND', result: null })
          }
          break
        case 'ACCESSDENIED':
          commit('toggleSnackbar', { message: i18n.t('errors.accessDenied'), color: 'red darken-2' }, { root: true })
          commit('toggle', { method: 'details', status, result: null })
          break
        case 'BADREQUEST':
          commit('toggleSnackbar', { message: i18n.t('errors.invalidFields'), color: 'orange darken-2' }, { root: true })
          commit('toggle', { method: 'details', status, result: null })
          break
        case 'UNPROCESSABLE':
          commit('toggleSnackbar', { message: i18n.t('errors.invalidFields'), color: 'orange darken-2' }, { root: true })
          commit('toggle', { method: 'details', status, result: null })
          break
        case 'INTERNALERROR':
          commit('toggleSnackbar', { message: i18n.t('helpers.errors.disaster'), color: 'red darken-2' }, { root: true })
          commit('toggle', { method: 'details', status, result: null })
          break
      }
    }).catch(err => {
      console.error(err)
      commit('toggleSnackbar', { message: i18n.t('helpers.errors.disaster'), color: 'red darken-2' }, { root: true })
      commit('toggle', { method: 'details', status: 'INTERNALERROR', result: null })
    }).finally(() => {
    })
  }
}
