<template>
  <div>
    <gm-expandable-list
      :mandatory="mandatory"
      expanded-height="300px"
      title="Event List"
      :value="dtcData.length"
      :data="dtcData"
      :showDelete="configurationMode"
      :panel-default-open="panelDefaultOpen"
      :expantion-absolute="expantionAbsolute"
      @clean-option="cleanOption"
    >
      <template #headerTrail>
        <div
          style="z-index: 10;"
          class="d-flex"
        >
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-y
          >
            <!-- <template #activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  mdi-magnify
                </v-icon>
              </v-btn>
            </template> -->

            <v-card>
              <v-card-text class="pa-1">
                <v-text-field
                  label="Search"
                  placeholder="Search"
                  outlined
                  prepend-inner-icon="mdi-magnify"
                />
              </v-card-text>
            </v-card>
          </v-menu>

          <!-- <v-divider
            vertical
          /> -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="changeSortValue"
              >
                <v-icon>
                  {{ sortValue === 'desc' ? 'mdi-arrow-down-bold-circle' : 'mdi-arrow-up-bold-circle' }}
                </v-icon>
              </v-btn>
            </template>
            <span>Sort by Date</span>
          </v-tooltip>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                class="ma-2"
                color="grey darken-2"
                text-color="white"
                small
                v-bind="attrs" 
                v-on="on"
              >
                {{ dailyDateRangeLabel() }}
              </v-chip>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in dailyOptions"
                :key="index"
                @click="changeDailyValue(index)"
              >
                <v-list-item-title>{{ $t(`monitor.vehicles.distance.${item}`) }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
      <template #default="{ item, index }">
        <gm-monitor-list-item
          v-if="item.dtcCodeId"
          :index="index"
          :title="item.dtcCode.shortName"
          :subtitle="item.unit.name"
          icon="mdi-alert-circle-outline"
          :date="item.activatedAt * 1000"
          :onClick="openDtcEvent"
        />
        <gm-weight-list-item
          v-else
          :index="index"
          :subtitle="item.unit.name"
          :value="item.axeLoadSum"
          :percentage="getWeightEventPercentage(item)"
          icon="mdi-weight"
          :date="item.receivedAt * 1000"
          :onClick="openWeightEvent"
        />
      </template>
    </gm-expandable-list>
    <gm-event-dialog :dialog="eventDialog" :event="eventData" :eventType="eventType" @dialog-change="setDialog" />
  </div>
</template>

<script>

import {
  ExpandableList,
  MonitorListItem,
  WeightListItem,
  EventDialog
} from 'Components/monitor'

export default {
  components: {
    'gm-expandable-list': ExpandableList,
    'gm-monitor-list-item': MonitorListItem,
    'gm-event-dialog': EventDialog,
    'gm-weight-list-item': WeightListItem
  },
  props: {
    configurationMode: {
      type: Boolean,
      default: () => false
    },
    panelDefaultOpen: {
      type: Boolean,
      default: () => true
    },
    expantionAbsolute: {
      type: Boolean,
      default: () => false
    },
    mandatory: {
      type: Boolean,
      default: () => true
    },
    unit: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      eventDialog: false,
      eventData: {},
      eventType: 'dtc',
      dailyValue: 0,
      dailyOptions: [
        'today',
        'yesterday',
        'thisWeek',
        'lastWeek'
      ],
      sortValue: 'desc',
      menu: false
    }
  },
  computed: {
    values () { return this.$store.state.units.unitsEvent },
    dtcData () {
      return this.sortByDateArray(this.values[this.dailyOptions[this.dailyValue]], this.sortValue === 'desc' ? 2 : 0)
    }
  },
  methods: {
    changeDailyValue (index) {
      this.dailyValue = index
    },
    openDtcEvent (index) {
      this.eventData = this.values[this.dailyOptions[this.dailyValue]][index]
      this.eventType = 'dtc'
      this.eventDialog = true
    },
    setDialog (value) {
      this.eventDialog = value
    },
    cleanOption (value) {
      this.$emit('clean-option', value)
    },
    dailyDateRangeLabel () {
      return this.$t(`monitor.vehicles.distance.${this.dailyOptions[this.dailyValue]}`)
    },
    changeSortValue () {
      this.sortValue = this.sortValue === 'desc' ? 'asc' : 'desc' 
    },
    openWeightEvent (index) {
      this.eventData = this.values[this.dailyOptions[this.dailyValue]][index]
      this.eventType = 'overweight'
      this.eventDialog = true
    },
    getWeightEventPercentage (event) {
      const difference = event.axeLoadSum - event.maxWeightAllowed
      return ((difference / event.maxWeightAllowed) * 100).toFixed(0)
    },
    sortByDateArray (array, val = 0) {
      array.sort(function (a, b) {
        if (a.activatedAt < b.activatedAt) {
          return -1 + val
        }
        if (a.activatedAt > b.activatedAt) {
          return 1 - val
        }
        return 0
      })
      return array
    }
  }
}
</script>

<style lang="scss" scoped>

.unit__icon{
  font-size: 28px;
}

.unit__title{
  justify-content: center;
  font-size: 30px;
  font-weight: 700 !important;
}

.unit__subtitle {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

</style>