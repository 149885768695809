<template>
  <v-card
    :style="`height: ${height};`"
    class="container pa-0"
  >
    <v-row no-gutters>
      <v-col
        cols="12"
      >
        <gm-summary-map
          :height="200"
          :drawMarkerToggle="drawMarkerToggle"
          :data="mapCoordinates"
        />
      </v-col>
      <v-col cols="12" clas="mt-2">
        <h3 style="font-family: Lato; font-weight: 700 !important; font-size: 20px" class="ma-2"> {{ $t('unitsSummary.title').toUpperCase() }} </h3>
      </v-col>
      <v-col cols="12">
        <v-tabs
          v-model="tab"
          grow
        >
          <v-tab>Depot Geozone</v-tab>
          <v-tab>Customer Geozone</v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="12">
        <v-tabs-items v-model="tab">
          <v-tab-item
            key="1"
          >
            <v-row no-gutters class="px-3">
              <v-icon>
                mdi-magnify
              </v-icon>
              <v-autocomplete
                v-model="search"
                :items="depotGeofences"
                style="width: 75%;"
                chips
                clearable
                deletable-chips
                item-text="name"
                item-value="name"
              />
              <v-icon>
                mdi-filter-outline
              </v-icon>
            </v-row>
            <!--  -->
            <v-list
              :style="`height: calc(${height} - 330px);`"
            >
              <v-virtual-scroll
                :bench="3"
                :items="showUnits"
                :height="`calc(${height} - ${listHeightOverflow})`"
                class="scrollbar__seamless"
                item-height="64"
              >
                <template #default="{ item, index }">
                  <v-list-item
                    :key="index"
                    class=""
                    @click="() => selectUnit(item)"
                  >
                    <!-- <v-list-item-action>
                      <v-icon color="primary">
                        mdi-truck
                      </v-icon>
                    </v-list-item-action> -->
                    <v-list-item-content class="px-3">
                      <v-list-item-title>
                        {{ item.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ item.geofencesName }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content style="text-align: right;">
                      <v-list-item-title>
                        <v-chip
                          :color="getUnitStateColor(item)"
                          small
                        >
                          {{ getUnitState(item) }}
                        </v-chip>
                      </v-list-item-title>
                      <v-list-item-subtitle style="font-size: 12px;">
                        {{ calculateTimeAgo(item) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider
                    :key="`divider__${index}`"
                  />
                </template>
              </v-virtual-scroll>
            </v-list>
          </v-tab-item>
          <v-tab-item
            key="2"
          >
            <v-row no-gutters class="px-3">
              <v-icon>
                mdi-magnify
              </v-icon>
              <v-autocomplete
                v-model="search"
                :items="customerGeofences"
                style="width: 75%;"
                chips
                clearable
                deletable-chips
                item-text="name"
                item-value="name"
              />
              <v-icon>
                mdi-filter-outline
              </v-icon>
            </v-row>
            <!--  -->
            <v-list
              :style="`height: calc(${height} - 330px)`"
              class="scrollbar__seamless"
            >
              <v-virtual-scroll
                :bench="3"
                :items="showUnits"
                :height="`calc(${height} - ${listHeightOverflow})`"
                class="scrollbar__seamless"
                item-height="64"
              >
                <template #default="{ item, index }">
                  <v-list-item
                    :key="index"
                    class=""
                    @click="() => selectUnit(item)"
                  >
                    <!-- <v-list-item-action>
                      <v-icon color="primary">
                        mdi-truck
                      </v-icon>
                    </v-list-item-action> -->
                    <v-list-item-content class="px-3">
                      <v-list-item-title>
                        {{ item.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ item.geofencesName }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content style="text-align: right;">
                      <v-list-item-title>
                        <v-chip
                          :color="getUnitStateColor(item)"
                          small
                        >
                          {{ getUnitState(item) }}
                        </v-chip>
                      </v-list-item-title>
                      <v-list-item-subtitle style="font-size: 12px;">
                        {{ calculateTimeAgo(item) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider
                    :key="`divider__${index}`"
                  />
                </template>
              </v-virtual-scroll>
            </v-list>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import SummaryMap from './SummaryMap.vue'
import dayjs from 'dayjs'

const advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)
export default {
  components: {
    'gm-summary-map': SummaryMap
  },
  props: {
    height: {
      type: String,
      default: () => '200px'
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      tab: 0,
      drawMarkerToggle: false,
      mapCoordinates: null,
      depotUnits: [],
      customerUnits: [],
      showUnits: [],
      search: ''
    }
  },
  computed: {
    geofences () { return this.$store.state.geofences.list },
    depotGeofences () { return this.$store.state.geofences.list.filter((geofence) => geofence.category === 'DEPOT') },
    customerGeofences () { return this.$store.state.geofences.list.filter((geofence) => geofence.category === 'CUSTOMER') },
    listHeightOverflow () { return '340px' }
  },
  watch: {
    geofences () {
      this.calculateUnitsOnGeozone()
    },
    items () {
      this.calculateUnitsOnGeozone()
    },
    tab () {
      this.search = ''
      this.filterUnits()
    }
  },
  created () {
    this.calculateUnitsOnGeozone()
  },
  methods: {
    calculateTimeAgo (unit) {
      if (unit.telemetry?.receivedAt) {
        const difference = new Date().getTime() / 1000 - unit.telemetry.receivedAt
        if (difference > 86400) {
          const days = Math.trunc(difference / 86400)
          return `${days} days ago`
        } else if (difference > 3600) {
          const hours = Math.trunc(difference / 3600)
          return `${hours} hours ago`
        } else if (difference > 60) {
          const minutes = Math.trunc(difference / 60)
          return `${minutes} min ago`
        } else {
          return 'A few minutes ago'
        }
      } else {
        return 'Not Available'
      }
    },
    getUnitState (unit) {
      if (unit.trailerProfile) {
        if (unit.trailerProfile.vor) {
          return 'VOR'
        }
      }
      if (unit.telemetry) {
        if (unit.telemetry.position.speed > 0 && this.compareDatesForRoute(unit.telemetry.receivedAt)) {
          let state = 'Moving'
          if (unit.telemetry.inDepot) {
            state += ' at home depot'
          }
          return state
        } else {
          // Validate is in geofence
          let state = 'Parked'
          if (unit.telemetry.inDepot) {
            state += ' at home depot'
          }
          return state
        }
      } else {
        return 'Parked'
      }
    },
    getUnitStateColor (unit) {
      if (unit.trailerProfile) {
        if (unit.trailerProfile.vor) {
          return 'warning'
        }
      }
      if (unit.telemetry) {
        if (unit.telemetry.position.speed > 0 && this.compareDatesForRoute(unit.telemetry.receivedAt)) {
          return 'green'
        } else {
          // Validate is in geofence
          if (unit.telemetry.inDepot) {
            return 'green'
          }
          return 'warning'
        }
      } else {
        return 'warning'
      }
    },
    compareDatesForRoute (date) {
      const seconds = 30 * 60 // #30 minutes
      return dayjs().unix() - date < seconds
    },
    calculateUnitGeozone (unit) {
      if (unit.telemetry?.geofencesIds) {
        const geofences = this.geofences.filter(geofence => unit.telemetry.geofencesIds.includes(parseInt(geofence.id)))
        // console.log(geofences)
        return geofences.map(geofence => geofence.name).join(', ')
      } else {
        return 'Not available'
      }
    },
    selectUnit (unit) {
      if (unit.telemetry?.position?.latitude) {
        this.drawMarkerToggle = !this.drawMarkerToggle
        this.mapCoordinates = unit.telemetry.position
      }
    },
    calculateUnitsOnGeozone () {
      this.depotUnits = []
      this.customerUnits = []
      for (const i in this.items) {
        const unit = this.items[i]
        if (unit.telemetry?.geofencesIds.length > 0) {
          const depotGeofences = this.geofences.filter(geofence => geofence.category === 'DEPOT' && unit.telemetry.geofencesIds.includes(parseInt(geofence.id)))
          if (depotGeofences.length > 0) {
            this.depotUnits.push({
              ...unit,
              geofencesName: depotGeofences.map(geofence => geofence.name).join(', ')
            })
          }
          const customerGeofences = this.geofences.filter(geofence => geofence.category === 'CUSTOMER' && unit.telemetry.geofencesIds.includes(parseInt(geofence.id)))
          // console.log(customerGeofences)
          if (customerGeofences.length > 0) {
            this.customerUnits.push({
              ...unit,
              geofencesName: customerGeofences.map(geofence => geofence.name).join(', ')
            })
          }
        }
      }
      this.filterUnits()
    },
    filterUnits () {
      this.showUnits = []
      // console.log(this.tab)
      // console.log(this.search)
      const units = this.tab === 0 ? this.depotUnits : this.customerUnits
      if (this.search.length > 0) {
        this.showUnits = units.filter((unit) => unit.geofencesName.toDownCase().includes(this.search.toDownCase()))
      } else {
        this.showUnits = units
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.list__label {
  font-size: 24px;
}

.label__icon{
  font-size: 28px;
}

</style>