function arrayToDict (array, key) {
  const result = {}
  for (let i = 0; i < array.length; i++) {
    result[array[i][key]] = array[i]
  }
  return result
}

/*
  Unit mutations
*/
export default {
  setEventList (state, payload) {
    // Sort payload
    payload.sort(function (a, b) {
      if (a.endAt < b.endAt) {
        return -1
      }
      if (a.endAt > b.endAt) {
        return 1
      }
      return 0
    })
    state.eventList = payload
    state.eventDictList = Object.assign({}, arrayToDict(payload, 'id'))
    // console.log(state)
  },

  setCodeList (state, payload) {
    state.codeList = payload
    state.codeDictList = Object.assign({}, arrayToDict(payload, 'id'))
  },

  newItem (state, payload) {
    state.list.push(payload)
    state.dictList[payload.id] = payload
  },

  updateItem (state, payload) {
    // console.log('Update')
    const list = [...state.list]
    for (let i = 0; i < list.length; i++) {
      if (list[i].id.toString() === payload.id.toString()) {
        list[i] = Object.assign({}, payload)
      }
    }

    state.list = []
    state.list = [...list]
    state.dictList = Object.assign({}, arrayToDict(list, 'id'))
  },

  deleteItem (state, id) {
    const list = state.list.filter(item => item.id.toString() !== id.toString())
    state.list = []
    state.list = [...list]
    state.dictList = Object.assign({}, arrayToDict(list, 'id'))
  },

  // eslint-disable-next-line no-unused-vars
  toggle (_, payload) {}
}
