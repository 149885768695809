<template>
  <div>
    <v-dialog
      v-model="dialog"
      scrollable
      transition="dialog-transition"
      :width="width.dialog"
    >
      <v-card
        height="95vh"
      >
        <v-card-title>
          {{ title }}
          <v-spacer />
          <v-btn
            icon
            text
            @click="discardItem"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-row no-gutters style="position: relative;">
          <!-- Map -->
          <div
            id="geofence_map"
            ref="map"
            style="height: calc(95vh - 63px); width: 100%;"
          />
          <div class="map__form--container ma-4">
            <v-text-field
              v-model="object.name"
              :disabled="isReadOnly"
              :error="errors.name.length > 0"
              :error-messages="errors.name"
              :label="$t('geofences.name')"
              :color="color"
              solo
              outlined
            />

            <v-text-field
              v-model="object.address"
              :disabled="isReadOnly"
              :error="errors.address.length > 0"
              :error-messages="errors.address"
              :label="$t('geofences.address')"
              :color="color"
              solo
              outlined
            />

            <v-text-field
              v-model="object.town"
              :disabled="isReadOnly"
              :error="errors.town.length > 0"
              :error-messages="errors.town"
              :label="$t('geofences.town')"
              :color="color"
              solo
              outlined
            />

            <v-text-field
              v-model="object.county"
              :disabled="isReadOnly"
              :error="errors.county.length > 0"
              :error-messages="errors.county"
              :label="$t('geofences.county')"
              :color="color"
              solo
              outlined
            />

            <v-text-field
              v-model="object.postcode"
              :disabled="isReadOnly"
              :error="errors.postcode.length > 0"
              :error-messages="errors.postcode"
              :label="$t('geofences.postcode')"
              :color="color"
              solo
              outlined
            />

            <v-text-field
              v-model="object.phone"
              :disabled="isReadOnly"
              :error="errors.phone.length > 0"
              :error-messages="errors.phone"
              :label="$t('geofences.phone')"
              :color="color"
              solo
              outlined
            />

            <v-text-field
              v-model="object.email"
              :disabled="isReadOnly"
              :error="errors.email.length > 0"
              :error-messages="errors.email"
              :label="$t('geofences.email')"
              :color="color"
              solo
              outlined
            />

            <v-select
              v-model="object.category"
              :disabled="isReadOnly"
              :error="errors.category.length > 0"
              :error-messages="errors.category"
              :label="$t('geofences.category')"
              :items="categories"
              solo
              outlined
              :color="color"
            />

            <v-select
              v-if="object.category === 'SERVICE'"
              v-model="object.subCategory"
              :disabled="isReadOnly"
              :error="errors.subCategory.length > 0"
              :error-messages="errors.subCategory"
              :label="$t('geofences.subCategory')"
              :items="subCategories"
              solo
              outlined
              :color="color"
            />

            <v-text-field
              v-model="colorLabel"
              :disabled="isReadOnly"
              :label="$t('geofences.color')"
              solo
              outlined
              readonly
              :color="color"
              @click="toggleColorPicker('color')"
            >
              <template #append>
                <v-avatar size="24" :color="`${object.color}`" />
              </template>
            </v-text-field>
          </div>
          <div class="map__form--geofence_mode ma-4">
            <v-btn
              v-if="!isReadOnly"
              small
              fab
              class="mx-1"
              color="green darken-2 white--text"
              @click="drawLine"
            >
              <v-icon>mdi-vector-polyline</v-icon>
            </v-btn>
            <v-btn
              v-if="!isReadOnly"
              small
              fab
              class="mx-1"
              color="blue darken-2 white--text"
              @click="drawCircle"
            >
              <v-icon>mdi-circle-outline</v-icon>
            </v-btn>
            <v-btn
              v-if="!isReadOnly"
              small
              fab
              class="mx-1"
              color="orange darken-2 white--text"
              @click="drawPolygon"
            >
              <v-icon>mdi-pentagon-outline</v-icon>
            </v-btn>
          </div>
          <div class="map__form--actions ma-4">
            <v-btn
              v-if="!isReadOnly"
              small
              fab
              class="mx-1"
              color="red darken-2 white--text"
              @click="discardItem"
            >
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>

            <v-btn
              v-if="!isReadOnly"
              small
              fab
              class="mx-1"
              color="green darken-2 white--text"
              :disabled="isLoading || !validateSave()"
              @click="saveItem"
            >
              <v-icon>mdi-check-circle</v-icon>
            </v-btn>
            <v-btn
              v-if="isReadOnly"
              small
              fab
              class="mx-1"
              color="orange darken-2 white--text"
              @click="editItem()"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>

            <v-btn
              v-if="isReadOnly"
              small
              fab
              class="mx-1"
              color="primary white--text"
              @click="newItem"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </v-row>
      </v-card>
    </v-dialog>
    <color-picker-dialog
      v-model="object"
      :show-dialog="colorPickerDialog"
      :ref-color="refColor"
      @toggle-dialog="state => colorPickerDialog = state"
    />
  </div>
</template>

<script>
import { ColorPickerDialog } from '@goldenm-software/color-picker'
import { mapState, mapGetters, mapActions } from 'vuex'
import { inititalCoordinates } from '@/plugins/constants'
import { orm } from '@/mixins'

import loadashGet from 'lodash/get'

let observer = null

export default {
  components: {
    ColorPickerDialog
  },
  mixins: [orm],
  model: {
    prop: 'data',
    event: 'set-object'
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    showDialog: {
      type: Boolean,
      default: () => false
    },
    readonly: {
      type: Boolean,
      default: () => false
    },
    units: {
      type: Array,
      default: () => []
    },
    showUnits: {
      type: Boolean,
      default: () => true
    },
    hasPath: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      map: null, // Google Maps Map entity
      google: null, // Google Maps object
      defaultCenter: null,
      errors: {},
      colorPickerDialog: false,
      togglePolygon: false,
      refColor: undefined,
      drawer: undefined,
      mapGeofence: undefined,
      polygon: undefined,
      mapUnitsMarkers: {},
      colorLabel: 'Select colour of geozone here'
    }
  },
  computed: {
    ...mapState(['width', 'isLoading']),
    ...mapGetters({
      defaultObject: 'geofences/defaultObject',
      categories: 'geofences/categories',
      subCategories: 'geofences/subCategories'
    }),
    title () {
      if (this.object.id) {
        const name = this.object.name
        if (!this.isReadonly) {
          return this.$i18n.t('geofences.title.edit', { name })
        }
        return this.$i18n.t('geofences.title.show', { name })
      }
      return this.$i18n.t('geofences.title.new')
    },
    dialog: {
      get () {
        return this.showDialog
      },

      set (value) {
        this.$emit('toggle-dialog', value)
      }
    },
    object: {
      get () {
        return this.data
      },

      set (value) {
        this.$emit('set-object', value)
      }
    },
    isReadOnly: {
      get () {
        return this.readonly
      },
      set (value) {
        this.$emit('set-readonly', value)
      }
    },
    color () {
      if (this.isDark) {
        return 'white'
      }

      return 'primary'
    },
    editIcon () {
      if (!this.isReadOnly) {
        return 'mdi-pencil'
      } else {
        return 'mdi-eye-outline'
      }
    },
    pickerColor () {
      return this.object.color
    }

  },
  watch: {
    dialog (newVal) {
      if (newVal) {
        this.loadMap()
        if (this.showUnits) {
          this.removeAllUnitsFromMap()
          this.drawUnits()
        }
      }
    },
    showUnits (newVal) {
      if (newVal) {
        if (this.dialog) {
          this.removeAllUnitsFromMap()
          this.drawUnits()
        }
      }
    },
    pickerColor () {
      this.redrawPolygon()
    }
  },
  created () {
    this.resetErrors()
    observer = this.$store.subscribe(mutation => {
      if (mutation.type === 'geofences/toggle') {
        const payload = mutation.payload

        const {
          method,
          status,
          errors,
          result
        } = payload

        switch (status) {
          case 'OK':
            switch (method) {
              case 'add':
                this.object = Object.assign({}, result)
                this.isReadOnly = true
                this.reloadGeofenceEditingState()

                break
              case 'edit':
                this.object = Object.assign({}, result)
                this.isReadOnly = true
                this.reloadGeofenceEditingState()
                break
            }
            break
          case 'ACCESSDENIED':
            this.errors = this.parseErrors(errors)
            break
          case 'BADREQUEST':
            this.errors = this.parseErrors(errors)
            break
          case 'UNPROCESSABLE':
            this.errors = this.parseErrors(errors)
            break
          case 'INTERNALERROR':
            this.errors = this.parseErrors(errors)
            break
        }
      }
    })
  },
  beforeDestroy () {
    observer()
  },
  methods: {
    ...mapActions({
      addGeofence: 'geofences/add',
      editGeofence: 'geofences/edit'
    }),
    loadMap () {
      this.$googlemaps
        .load()
        .then((google) => {
          this.google = google
          // to use the label drawer "class" window.google must exist and the file is called to be require
          window.google = google
          this.UnitMarker = require('./UnitMarker.js')

          /// Adding google maps V2 like get bounds function
          if (!google.maps.Polygon.prototype.getBounds) {
            google.maps.Polygon.prototype.getBounds = function () {
              const bounds = new google.maps.LatLngBounds()
              this.getPath().forEach(function (element) { bounds.extend(element) })
              return bounds
            }
          }
          if (!google.maps.Polyline.prototype.getBounds) {
            google.maps.Polyline.prototype.getBounds = function () {
              const bounds = new google.maps.LatLngBounds()
              this.getPath().forEach(function (element) { bounds.extend(element) })
              return bounds
            }
          }

          setTimeout(() => {
            this.initMap()
            this.initDrawer()
            if (this.object.id !== undefined || this.hasPath) {
              this.drawGeofence()
            }
          }, 100)
        })
    },
    /**
     * Initialize Singleton instance of Google maps
     */
    initMap () {
      this.$store.commit('setGoogle', this.google)

      this.defaultCenter = new this.google.maps.LatLng(inititalCoordinates.latitude, inititalCoordinates.longitude)

      this.map = new this.google.maps.Map(
        document.getElementById('geofence_map'),
        {
          mapTypeId: 'roadmap',
          zoom: 10,
          center: this.defaultCenter,
          disableDefaultUI: true,
          zoomControl: true,
          mapTypeControl: true,
          mapTypeControlOptions: {
            position: this.google.maps.ControlPosition.RIGHT_TOP
          }
        }
      )

      setTimeout(() => {
        if (this.showUnits && this.dialog) {
          this.drawUnits()
        }
      }, 0)
    },
    /**
     * Initialize Google Map Drawer
     */
    initDrawer () {
      const google = this.google
      this.drawer = new google.maps.drawing.DrawingManager({
        drawingMode: google.maps.drawing.OverlayType.POLYGON,
        drawingControl: false,
        drawingControlOptions: {
          position: google.maps.ControlPosition.RIGHT,
          drawingModes: [
            google.maps.drawing.OverlayType.CIRCLE,
            google.maps.drawing.OverlayType.POLYGON,
            google.maps.drawing.OverlayType.POLYLINE
          ]
        },
        polygonOptions: {
          fillColor: this.object.color, // color
          strokeColor: this.object.color, // color
          fillOpacity: 0.3,
          strokeOpacity: 1,
          strokeWeight: 4,
          clickable: false,
          editable: true,
          zIndex: 1
        },
        circleOptions: {
          fillColor: this.object.color, // color
          strokeColor: this.object.color, // color
          fillOpacity: 0.3,
          strokeWeight: 4,
          clickable: false,
          editable: true,
          zIndex: 1
        },
        polylineOptions: {
          fillColor: this.object.color, // color
          strokeColor: this.object.color, // color
          fillOpacity: 0.3,
          strokeWeight: 7,
          clickable: false,
          editable: true,
          zIndex: 1
        }
      })

      google.maps.event.addListener(this.drawer, 'overlaycomplete', this.attach)
    },
    attach (event) {
      /**
       * Erase the previous overlay if it exist
       */
      if (this.mapGeofence) {
        this.mapGeofence.setMap(null)
      }
      this.stopDrawing()
      const { type, overlay } = event
      const path = []

      const modes = {
        polyline: 'LINEAR',
        circle: 'RADIAL',
        polygon: 'POLYGON'
      }

      this.object.mode = modes[type]

      if (type === 'circle') {
        this.object.path = [{
          latitude: overlay.center.lat(),
          longitude: overlay.center.lng(),
          radius: overlay.radius
        }]

        this.google.maps.event.addListener(overlay, 'radius_changed', this.geofenceBoundsChanged)
        this.google.maps.event.addListener(overlay, 'center_changed', this.geofenceBoundsChanged)
      } else {
        const vertices = overlay.getPath()

        for (let i = 0; i < vertices.getLength(); i++) {
          const xy = vertices.getAt(i)
          path.push({
            latitude: xy.lat(),
            longitude: xy.lng(),
            radius: type === 'polyline' ? 10 : 0
          })
        }
        this.google.maps.event.addListener(overlay.getPath(), 'set_at', this.geofenceBoundsChanged)
        this.google.maps.event.addListener(overlay.getPath(), 'insert_at', this.geofenceBoundsChanged)

        this.object.path = path
      }

      this.mapGeofence = overlay
    },
    /**
     * Save the path into the object
     * when the object is edited in the map
     */
    geofenceBoundsChanged () {
      const path = []

      if (this.object.mode === 'RADIAL') {
        this.object.path = [{
          latitude: this.mapGeofence.center.lat(),
          longitude: this.mapGeofence.center.lng(),
          radius: this.mapGeofence.radius
        }]
      } else {
        const vertices = this.mapGeofence.getPath()

        for (let i = 0; i < vertices.getLength(); i++) {
          const xy = vertices.getAt(i)
          path.push({
            latitude: xy.lat(),
            longitude: xy.lng(),
            radius: this.object.mode === 'LINEAR' ? 10 : 0
          })
        }

        this.object.path = path
      }
    },

    toggleColorPicker (key = '') {
      this.colorPickerDialog = true
      this.refColor = key
    },
    validateSave () {
      let check = true
      if (this.object.name === '') {
        check = false
      }

      if (this.object.color === '') {
        check = false
      }

      if (this.object.mode === '') {
        check = false
      }

      if (this.object.path !== undefined && this.object.path.length === 0) {
        check = false
      }

      return check
    },
    discardItem () {
      this.dialog = false
      this.isReadOnly = true
      this.stopDrawing()
      this.resetErrors()
      if (this.mapGeofence) {
        this.mapGeofence.setMap(null)
        this.mapGeofence = null
      }
    },
    saveItem () {
      this.resetErrors()
      if (this.object.id === undefined) {
        this.addGeofence(this.object)
      } else {
        this.editGeofence(this.object)
      }
    },
    editItem () {
      this.resetErrors()
      this.isReadOnly = false
      this.reloadGeofenceEditingState()
    },
    /**
     * Start draw circle mode
     */
    drawCircle () {
      this.drawer.setOptions({
        drawingMode: this.google.maps.drawing.OverlayType.CIRCLE,
        circleOptions: {
          fillColor: this.object.color,
          strokeColor: this.object.color, // color
          fillOpacity: 0.3,
          strokeOpacity: 1,
          strokeWeight: 4,
          clickable: false,
          editable: true,
          zIndex: 1
        }
      })

      this.drawer.setMap(this.map)
    },
    /**
     * Start draw polygon mode
     */
    drawPolygon () {
      this.drawer.setOptions({
        drawingMode: this.google.maps.drawing.OverlayType.POLYGON,
        polygonOptions: {
          fillColor: this.object.color, // color
          strokeColor: this.object.color, // color
          fillOpacity: 0.3,
          strokeOpacity: 1,
          strokeWeight: 4,
          clickable: false,
          editable: true,
          zIndex: 1
        }
      })

      this.drawer.setMap(this.map)
    },
    /**
     * Start draw line mode
     */
    drawLine () {
      this.drawer.setOptions({
        drawingMode: this.google.maps.drawing.OverlayType.POLYLINE,
        polylineOptions: {
          fillColor: this.object.color,
          strokeColor: this.object.color, // color
          fillOpacity: 0.3,
          strokeOpacity: 1,
          strokeWeight: 7,
          clickable: false,
          editable: true,
          zIndex: 1
        }
      })

      this.drawer.setMap(this.map)
    },
    /**
     * redraw polygon called while editing
     */
    redrawPolygon () {
      if (this.mapGeofence) {
        this.mapGeofence.setMap(null)
        this.mapGeofence.setOptions({
          fillColor: this.object.color,
          strokeColor: this.object.color
        })
        this.mapGeofence.setMap(this.map)
      }
    },
    /**
     * called to draw the geofence
     * when the method is called in show or edit mode
     */
    drawGeofence () {
      let overlay
      let paths
      const google = this.google

      switch (this.object.mode) {
        case 'LINEAR':
          paths = this.object.path.map((item) => {
            return {
              lat: item.latitude,
              lng: item.longitude
            }
          })
          overlay = new google.maps.Polyline({
            path: paths,
            geodesic: true,
            fillColor: this.object.color,
            strokeColor: this.object.color,
            fillOpacity: 0.3,
            strokeOpacity: 1,
            strokeWeight: 7,
            clickable: false,
            editable: !this.isReadOnly,
            zIndex: 1
          })
          this.map.setCenter(overlay.getBounds().getCenter())
          this.map.fitBounds(overlay.getBounds())
          break
        case 'RADIAL':
          overlay = new google.maps.Circle({
            center: {
              lat: this.object.path[0].latitude,
              lng: this.object.path[0].longitude
            },
            radius: this.object.path[0].radius,
            fillColor: this.object.color,
            strokeColor: this.object.color,
            fillOpacity: 0.3,
            strokeOpacity: 1,
            strokeWeight: 4,
            clickable: false,
            editable: !this.isReadOnly,
            zIndex: 1
          })

          this.map.setCenter({
            lat: this.object.path[0].latitude,
            lng: this.object.path[0].longitude
          })
          this.map.fitBounds(overlay.getBounds())

          break
        case 'POLYGON':
          paths = this.object.path.map((item) => {
            return {
              lat: item.latitude,
              lng: item.longitude
            }
          })
          overlay = new google.maps.Polygon({
            paths,
            fillColor: this.object.color,
            strokeColor: this.object.color,
            fillOpacity: 0.3,
            strokeOpacity: 1,
            strokeWeight: 4,
            clickable: false,
            editable: !this.isReadOnly,
            zIndex: 1
          })
          this.map.setCenter(overlay.getBounds().getCenter())
          this.map.fitBounds(overlay.getBounds())
          break
      }
      this.mapGeofence = overlay
      this.mapGeofence.setMap(this.map)

      setTimeout(() => {
        this.loadGeofenceEditingEvents()
      }, 0)
    },
    /**
     * Agrega los eventos de edicion del polygono para que se pueda editar
     * al cambiarlo
     */
    loadGeofenceEditingEvents () {
      const google = this.google
      if (this.mapGeofence) {
        if ((!this.isReadOnly && this.object.id !== undefined) || this.hasPath) {
          if (this.object.mode === 'RADIAL') {
            google.maps.event.addListener(
              this.mapGeofence,
              'radius_changed',
              this.geofenceBoundsChanged
            )
            google.maps.event.addListener(
              this.mapGeofence,
              'center_changed',
              this.geofenceBoundsChanged
            )
          } else {
            google.maps.event.addListener(
              this.mapGeofence.getPath(),
              'set_at',
              this.geofenceBoundsChanged
            )
            google.maps.event.addListener(
              this.mapGeofence.getPath(),
              'insert_at',
              this.geofenceBoundsChanged
            )
          }
        }
      }
    },
    reloadGeofenceEditingState () {
      if (this.mapGeofence) {
        this.mapGeofence.setMap(null)
        this.mapGeofence.setOptions({
          editable: this.isReadOnly
        })
        this.mapGeofence.setMap(this.map)
        setTimeout(() => {
          this.loadGeofenceEditingEvents()
        }, 0)
      }
    },
    /**
     *
     */
    stopDrawing () {
      this.drawer.setMap(null)
    },
    newItem () {
      this.discardItem()
      setTimeout(() => {
        this.$emit('new-item')
      }, 100)
    },

    /* Units methods */
    drawUnit ({ unit, latitude, longitude }) {
      const coordinates = new this.google.maps.LatLng(
        latitude,
        longitude
      )

      this.mapUnitsMarkers[unit.id] = new this.UnitMarker({
        position: coordinates,
        map: this.map,
        google: this.google,
        name: unit.name
      })
    },

    drawUnits () {
      if (this.map && this.showUnits) {
        this.units.forEach(unit => {
          const latitude = loadashGet(unit, 'telemetry.position.latitude')
          const longitude = loadashGet(unit, 'telemetry.position.longitude')
          if (latitude !== undefined && longitude !== undefined) {
            this.drawUnit({ unit, latitude, longitude })
          }
        })
      }
    },

    removeAllUnitsFromMap () {
      for (const key in this.mapUnitsMarkers) {
        this.mapUnitsMarkers[key].setMap(null)
        this.mapUnitsMarkers[key] = null
        delete this.mapUnitsMarkers[key]
      }
    }
  }
}
</script>

<style lang="scss">
.map__form--container{
  position: absolute;
  width: 35%;
  top: 0;
  left: 0;
}
.map__form--actions{
  position: absolute;
  width: 12%;
  bottom: 30px;
  right: 0;
}

.map__form--geofence_mode {
  position: absolute;
  top: 0;
  left: 50%;

}

</style>