<template>
  <v-dialog
    :value="dialog"
    :fullscreen="isMobile"
    :max-width="width.dialog"
    scrollable
    persistent
    transition="dialog-transition"
  >
    <v-card>
      <v-card-title>
        <v-toolbar flat>
          <v-toolbar-title class="subtitle-1">
            {{ title }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-row style="width: 100%; margin: 0">
          <v-col cols="12">
            <!-- Text That mention you need to put a password to continue -->
            <v-alert
              type="warning"
              border="left"
              outlined
              elevation="2"
              class="mb-4"
            >
              {{ $t('units.performanceReset.text') }}
            </v-alert>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="password"
              outlined
              dense
              :label="$t('units.performanceReset.password')"
              :error-messages="errors.password"
              :disabled="isLoading"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <!-- Cancel button -->
        <v-btn
          text
          :disabled="isLoading"
          @click="dialog = false"
        >
          {{ $t('buttons.cancel') }}
        </v-btn>
        <v-spacer />
        <!-- Reset button -->
        <v-btn
          color="primary"
          :loading="isLoading"
          @click="resetPerformanceRate"
        >
          {{ $t('units.performanceReset.reset') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { orm } from '@/mixins'

export default {
  mixins: [orm],

  model: {
    prop: 'data',
    event: 'set-object'
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    readonly: {
      type: Boolean,
      default: () => false
    },

    open: {
      type: Boolean,
      default: () => false
    },

    unit: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      // selectedTab: 'basic',
      // animation: 'happy',
      password: '',
      errors: {},
      hoverWheel: null,
      tab: null,
      loadingChart: false,
      showPassword: false,
      isLoading: false
    }
  },

  computed: {
    ...mapState(['session', 'width', 'isDark', 'units']),
    ...mapGetters(['color', 'isMobile']),
    // ...mapGetters({
    //   defaultObject: 'groups/defaultObject',
    //   canEdit: 'session/canEdit',
    //   canBilling: 'session/canBilling'
    // }),
    dialog: {
      get () {
        return this.open
      },

      set (value) {
        this.$emit('toggle-dialog', value)
      }
    },

    title () {
      // eslint-disable-next-line
      // return text + ' ' + this.object.name
      return this.$t('units.performanceReset.title', { name: this.unit.name })
    }
  },

  beforeDestroy () {
    // this.resetErrors()
    // observer()
  },

  created () {
    // this.resetErrors()

    // observer = this.$store.subscribe((mutation) => {
    //   if (mutation.type === 'groups/toggle') {
    //     const payload = mutation.payload
    //     switch (payload.method) {
    //       case 'add':
    //         this.evaluateResponse(payload)
    //         break
    //       case 'edit':
    //         this.evaluateResponse(payload)
    //         break
    //     }
    //   }
    // })
  },

  methods: {
    // ...mapActions({
    //   add: 'groups/add',
    //   edit: 'groups/edit'
    // }),

    resetPerformanceRate () {
      this.$store.dispatch('units/resetPerformanceRate', {
        unitId: this.unit.id,
        password: this.password
      })
    },
    discardItem () {
      // this.tab = 0
      this.dialog = false
      this.isEditting = false
      // this.resetErrors()
      // this.object = Object.assign({}, this.defaultObject)
      // this.$emit('set-object')
    },

    wheelClick (_) {
      // this.$emit('wheel-click', wheel)
    },

    wheelHover (wheel) {
      // this.$emit('wheel-hover', wheel)
      this.hoverWheel = wheel
      // console.log('hover enter', wheel)
    },

    wheelMouseout (_) {
      this.hoverWheel = null
      // console.log('hover out')
      // this.$emit('wheel-mouseout')
    }
  }
}
</script>
