<template>
  <v-row>
    <v-col cols="12" md="4">
      <icon-label icon="mdi-information-variant" label="Event name" />
      <v-chip
        :color="getDtcCodeColor(event)"
        text-color="white"
        small
      >
        {{ getEventName }}
        <v-icon right>
          {{ getEventIcon }}
        </v-icon>
      </v-chip>
    </v-col>
    <v-col cols="12" md="4">
      <icon-label icon="mdi-wifi" label="Value" />
      <h3 :class="eventType === 'dtc' ? '' : 'error--text'" style="font-weight: bold">{{ getValue() }}</h3>
    </v-col>
    <v-col cols="12" md="4">
      <icon-label icon="mdi-calendar" label="Date" />
      <h3>{{ formatDate() }}</h3>
    </v-col>
    <v-col cols="12" md="12">
      <icon-label icon="mdi-truck" label="Unit" /> 
      <v-chip style="background: rgba(115, 22, 14, 0.25);">{{ getUnitName() }}</v-chip>
    </v-col>
    <v-col v-if="eventType === 'dtc'" cols="12">
      <icon-label icon="mdi-clipboard-alert" label="Instructions" />
      <h3 style="white-space: pre-line">
        {{ getInstructions() }}
      </h3>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { IconLabel } from 'Components/utils'

import dayjs from 'dayjs'
const advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

export default {
  components: {
    'icon-label': IconLabel
  },
  props: {
    address: {
      type: String,
      default: () => 'Address'
    },
    event: {
      type: Object,
      default: () => {}
    },
    hasIntructions: {
      type: Boolean,
      default: () => false
    },
    eventType: {
      type: String,
      default: () => 'dtc'
    }
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState(['isDark']),
    ...mapGetters(['colorText']),
    unitsDict () { return this.$store.state.units.dictList },
    dtcCodes () { return this.$store.state.dtcs.codeDictList },
    getEventName () { 
      if (this.eventType === 'dtc') {
        return 'DTC Event'
      } else if (this.eventType === 'overweight') {
        return 'Overweight Event'
      } else {
        return ''
      }
    },
    getEventIcon () { 
      if (this.eventType === 'dtc') {
        return 'mdi-hazard-lights'
      } else if (this.eventType === 'overweight') {
        return 'mdi-weight'
      } else {
        return ''
      }
    }
  },
  methods: {
    getDtcCodeColor (unit) {
      if (unit.dtcAmberState === 16 || unit.dtcAmberState === 32) {
        return 'amber'
      } else if (unit.dtcRedState === 4 || unit.dtcRedState === 32) {
        return 'red'
      } else {
        return 'grey'
      }
    },
    formatDate () {
      const date = this.eventType === 'dtc' ? this.event.activatedAt : this.event.receivedAt
      if (date) {
        return dayjs(date * 1000).format('DD/MM/YYYY - hh:mm a')
      } else {
        return ''
      }
    },
    formatValue (value) {
      return parseFloat(value).toFixed(2)
    },
    getUnitName () {
      if (this.unitsDict[this.event.unitId]) {
        return this.unitsDict[this.event.unitId].name
      } else {
        return '---'
      }
    },
    getValue () {
      if (this.eventType === 'dtc') {
        return this.dtcValue()
      } else if (this.eventType === 'overweight') {
        return this.overweightValue()
      } else {
        return '---'
      }
    },
    dtcValue () {
      return this.dtcCodes[this.event.dtcCodeId].fullName
    },
    percentage () {
      const difference = this.event.axeLoadSum - this.event.maxWeightAllowed
      return ((difference / this.event.maxWeightAllowed) * 100).toFixed(0)
    },
    overweightValue () {
      return `${this.event.axeLoadSum} kg +${this.percentage()}%`
    },
    getInstructions () {
      return this.dtcCodes[this.event.dtcCodeId].description
    }
  }
}
</script>