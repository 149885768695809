<template>
  <v-dialog
    :value="isLoading || isI18nLoading"
    persistent
    dark
    width="100"
    :overlay-opacity="0.95"
  >
    <v-card
      color="primary"
    >
      <v-card-text class="pt-5 d-flex justify-center">
        <v-progress-circular
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: mapState(['isLoading', 'isI18nLoading'])
}
</script>