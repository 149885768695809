export default {
  loadingReports (state) {
    const reports = state.list.filter(report => report.status === 'pending')
    reports.sort((a, b) => {
      if (a.createdAt < b.createdAt) {
        return 1
      }
      if (a.createdAt > b.createdAt) {
        return -1
      }
      return 0
    })
    return reports
  },
  completedReports (state) {
    // Validate the reports are not pending and from more than 3 days ago
    const reports = state.list.filter(report => report.status !== 'pending' && report.createdAt * 1000 < (Date.now() - 259200000))
    reports.sort((a, b) => {
      if (a.createdAt < b.createdAt) {
        return 1
      }
      if (a.createdAt > b.createdAt) {
        return -1
      }
      return 0
    })
    return reports
  },
  completedSessionReports (state) {
    // Validate the reports are not pending and from the last 3 days
    const reports = state.list.filter(report => report.status !== 'pending' && report.createdAt * 1000 > (Date.now() - 259200000))
    reports.sort((a, b) => {
      if (a.createdAt < b.createdAt) {
        return 1
      }
      if (a.createdAt > b.createdAt) {
        return -1
      }
      return 0
    })
    return reports
  }
}