<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    width="min-content"
  >
    <v-card>
      <v-card-text class="pa-4 d-flex justify-center">
        <v-color-picker
          v-model="colorPicker"
          hide-inputs
          show-swatches
          :style="{
            'border-bottom-right-radius': '0px',
            'border-bottom-left-radius': '0px'
          }"
        />
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn text color="red" @click="discard">
          {{ $t('actions.discard') }}
        </v-btn>
        <v-btn text color="green" @click="saveColor">
          {{ $t('actions.ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  model: {
    prop: 'data',
    event: 'set-object'
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },

    showDialog: {
      type: Boolean,
      default: () => false
    },

    refColor: {
      type: String,
      default: () => ''
    },
    hideOpacity: {
      type: Boolean,
      default: () => false
    }
  },

  data () {
    return {
      // el valor es un string para eliminar el selector de opacidad
      colorPicker: this.hideOpacity ? this.$vuetify.theme.themes.light.primary : null
    }
  },

  computed: {

    object: {
      get () {
        return this.data
      },

      set (value) {
        this.$emit('toggle-object', value)
      }
    },

    dialog: {
      get () {
        return this.showDialog
      },

      set (value) {
        this.$emit('toggle-dialog', value)
      }
    }
  },

  methods: {
    saveColor () {
      this.$set(
        this.object,
        this.refColor,
        this.hideOpacity ? this.colorPicker : this.colorPicker.hex
      )
      this.discard()
    },

    discard () {
      this.colorPicker = this.hideOpacity ? this.$vuetify.theme.themes.light.primary : null
      this.dialog = false
    }
  }
}
</script>
