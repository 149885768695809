<template>
  <gm-expandable-list
    expanded-height="300px"
    :mandatory="mandatory"
    :title="$t('dashboard.underinflationEvents')"
    :value="underinflationEvents.length"
    :data="underinflationEvents"
    :showDelete="configurationMode"
    :panel-default-open="panelDefaultOpen"
    :expantion-absolute="expantionAbsolute"
    @clean-option="cleanOption"
  >
    <template #leadingTitle>
      <v-icon
        class="ml-3"
        color="yellow darken-2"
      >
        mdi-alert
      </v-icon>

      <v-spacer />

      <div
        style="z-index: 10;"
        class="d-flex"
      >
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-y
        >
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                mdi-magnify
              </v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-text class="pa-1">
              <v-text-field
                v-model="search"
                label="Search"
                placeholder="Search"
                outlined
                prepend-inner-icon="mdi-magnify"
              />
            </v-card-text>
          </v-card>
        </v-menu>

        <!-- <v-divider
          vertical
        /> -->
      </div>
    </template>
    <template #default="{ item, index }">
      <gm-tyre-list-item
        :index="index"
        :value="convertPressure(item.pressure)"
        icon="mdi-truck"
        :icon-label="getUnitName(item)"
        :date="item.receivedAt * 1000"
        :is-amber="item.isAmber"
        measureUnit="PSI"
        :subtitle="item.position.toUpperCase()"
        event-type="UNDERINFLATIONEVENT"
        @click.native="showDialog(item)"
      />
    </template>
  </gm-expandable-list>
</template>

<script>

import {
  ExpandableList,
  TyreListItem
} from 'Components/monitor'

export default {
  components: {
    'gm-expandable-list': ExpandableList,
    'gm-tyre-list-item': TyreListItem
  },
  props: {
    configurationMode: {
      type: Boolean,
      default: () => false
    },
    panelDefaultOpen: {
      type: Boolean,
      default: () => true
    },
    expantionAbsolute: {
      type: Boolean,
      default: () => false
    },
    mandatory: {
      type: Boolean,
      default: () => true
    }
  },
  data () {
    return {
      search: null
    }
  },
  computed: {
    units () { return this.$store.state.units.list },
    underinflationEvents () {
      let result = this.$store.state.tpmsEvents.underinflationEvents

      if (this.search) {
        result = result
          .filter(el =>
            el.unit &&
            el.unit.name &&
            el.unit.name.toLowerCase().includes(this.search.toLowerCase())
          )
      }
      return result
    }
  },
  methods: {
    cleanOption (value) {
      this.$emit('clean-option', value)
    },
    showDialog (item) {
      const unit = this.units.find(unit => unit.id === item.unitId)
      this.$showUnitDialog(unit)
    },
    convertPressure (value) {
      return (value / 6.895).toFixed(2)
    },
    getUnitName (item) {
      if (item.unit !== undefined) {
        return item.unit.name
      } 
      const unit = this.units.find(unit => unit.id === item.unitId)
      return unit ? unit.name : ''
    }
  }
}
</script>

<style lang="scss" scoped>

.unit__icon{
  font-size: 28px;
}

.unit__title{
  justify-content: center;
  font-size: 30px;
  font-weight: 700 !important;
}

.unit__subtitle {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

</style>