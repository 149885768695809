<template>
  <v-list-item
    :key="index"
  >
    <v-list-item-icon
      class="d-flex flex-column"
    >
      <v-icon
        class="primary--text"
        dark
        :large="!iconLabel"
      >
        {{ icon }}
      </v-icon>
      {{ iconLabel }}
    </v-list-item-icon>

    <v-list-item-content class="pa-1">
      <v-list-item-title class="d-flex align-center justify-space-between">
        <h3
          :class="colorText"
          class="font-weight-medium"
        >
          {{ title }}
        </h3>
        <p
          class="pb-4 mb-0 grey--text"
          style="font-size: 14px;"
        >
          {{ formatDate(date) }}
        </p>
      </v-list-item-title>

      <v-list-item-subtitle
        :class="colorText"
        style="text-align: initial;"
      >
        {{ subtitle }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-switch
        v-model="isActive"
        color="green"
      />
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import dayjs from 'dayjs'
const advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

export default {
  props: {
    title: {
      type: String,
      default: () => ''
    },
    subtitle: {
      type: String,
      default: () => ''
    },
    icon: {
      type: String,
      default: () => ''
    },
    iconLabel: {
      type: String,
      default: () => ''
    },
    index: {
      type: Number,
      default: () => 0
    },
    date: {
      type: Number,
      default: () => undefined
    },
    value: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapState(['isDark']),
    ...mapGetters(['colorText']),
    isActive: {
      get () {
        return this.value
      },
      set (value) {
        return this.$emit('toggle-value', value)
      }
    }
  },
  methods: {
    formatDate (date) {
      if (date) {
        return dayjs(date).format('Do MMMM YYYY - hh:mm a')
      } else {
        return ''
      }
    }
  }
}
</script>