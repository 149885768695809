<template>
  <v-card flat class="ml-4 mr-4">
    <v-card-title
      class="grey--text text--darken-2 font-weight-bold list_title headline pb-1 mb-2"
    >
      Reports for {{ unit.name }}
    </v-card-title>

    <v-row>
      <!-- SELECT REPORT TYPE AND DATETIME -->
      <v-col cols="6">
        <v-select
          v-model="reportType"
          :items="reportTypes"
          label="Report Type"
          outlined
          item-text="label"
          item-value="value"
          prepend-inner-icon="mdi-file"
          hide-details="auto"
          @change="timeInterval = ''"
        />
      </v-col>
      <v-col v-if="reportType !== 'ebpms' && reportType !== 'trailerProfile'" cols="6">
        <v-autocomplete
          v-model="timeInterval"
          :items="timeIntervals"
          label="Time Interval"
          outlined
          item-text="label"
          item-value="value"
          prepend-inner-icon="mdi-calendar-clock"
          hide-details="auto"
        />
      </v-col>
      <v-col v-else cols="6">
        <v-dialog
          ref="dialog"
          v-model="dateModal"
          :return-value.sync="reportTimestamp"
          persistent
          width="290px"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="reportTimestamp"
              label="Report Date"
              prepend-icon="mdi-calendar"
              readonly
              hide-details="auto"
              outlined
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="reportTimestamp"
            scrollable
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="dateModal = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.dialog.save(reportTimestamp)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-row v-if="timeInterval === 'custom'">
        <v-col cols="6">
          <v-dialog
            ref="initDateDialog"
            v-model="dateRangeModal.initDate"
            :return-value.sync="dateRange.initDate"
            persistent
            width="290px"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="dateRange.initDate"
                :label="$t('actions.startAt')"
                prepend-icon="mdi-calendar"
                readonly
                hide-details="auto"
                outlined
                v-bind="attrs"
                v-on="on"
                @change="initDateChange"
              />
            </template>
            <v-date-picker
              v-model="dateRange.initDate"
              scrollable
            >
              <v-spacer />
              <v-btn
                text
                color="primary"
                @click="dateRangeModal.initDate = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="saveInitDate"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col cols="6">
          <v-dialog
            ref="endDateDialog"
            v-model="dateRangeModal.endDate"
            :return-value.sync="dateRange.endDate"
            persistent
            width="290px"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="dateRange.endDate"
                :label="$t('actions.endAt')"
                prepend-icon="mdi-calendar"
                readonly
                hide-details="auto"
                outlined
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="dateRange.endDate"
              :min="dateRange.initDate"
              scrollable
            >
              <v-spacer />
              <v-btn
                text
                color="primary"
                @click="dateRangeModal.endDate = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.endDateDialog.save(dateRange.endDate)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>
    </v-row>
    <v-card-actions class="mt-4">
      <v-spacer />
      <v-btn
        depressed
        color="success"
        :disabled="!validateButton"
        @click="generateReport"
      >
        Generate Report
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import dayjs from 'dayjs'
import brakePerformanceRateReport from 'GraphQL/mutations/reports/brakePerformanceRateReport.gql'
import weeklyReport from 'GraphQL/mutations/reports/weeklyReport.gql'
import tripsReport from 'GraphQL/mutations/reports/tripsReport.gql'
import servicesReport from 'GraphQL/mutations/reports/servicesReport.gql'
import faultsReport from 'GraphQL/mutations/reports/faultsReport.gql'
import snapshotReport from 'GraphQL/mutations/reports/snapshotReport.gql'
import geofencesReport from 'GraphQL/mutations/reports/geofencesReport.gql'
import trailerProfileReport from 'GraphQL/mutations/reports/trailerProfileReport.gql'

export default {
  props: {
    unit: {
      type: Object,
      default: () => {}
    },
    sheetOpen: {
      required: true,
      type: Boolean
    }
  },
  data () {
    return {
      dateModal: false,
      reportTimestamp: '',
      reportType: 'ebpms',
      timeInterval: '',
      dateRangeModal: {
        initDate: false,
        endDate: false
      },
      dateRange: {
        initDate: '',
        endDate: ''
      }
    }
  },
  computed: {
    validateButton () {
      if (this.reportType === 'ebpms') {
        return this.reportTimestamp !== '' && this.unit?.id !== ''
      } else if (this.reportType === 'weekly') {
        return this.unit?.id !== '' && this.timeInterval !== ''
      } else if (this.reportType === 'trips') {
        return this.unit?.id !== '' && this.timeInterval !== ''
      }
      return true
    },
    timeIntervals () {
      return [
        {
          label: this.$i18n.t('timeIntervals.lastWeek'),
          value: 'lastWeek'
        },
        {
          label: this.$i18n.t('timeIntervals.thisWeek'),
          value: 'thisWeek'
        },
        {
          label: this.$i18n.t('timeIntervals.custom'),
          value: 'custom'
        }
      ]
    },

    reportTypes () {
      return [
        {
          label: 'Brake Performance Report',
          value: 'ebpms'
        },
        // {
        //   label: 'Weekly Report',
        //   value: 'weekly'
        // },
        {
          label: 'Trips Report',
          value: 'trips'
        },
        {
          label: 'Services Report',
          value: 'services'
        },
        {
          label: 'Faults Report',
          value: 'faults'
        },
        {
          label: 'Snapshot Report',
          value: 'snapshot'
        },
        {
          label: 'Geofence Report',
          value: 'geofence'
        },
        {
          label: 'Vehicle Profile Report',
          value: 'vehicleProfile'
        }
      ]
    },
    user () { return this.$store.state.session }
  },
  watch: {
    sheetOpen (value) {
      if (!value) {
        setTimeout(() => {
          this.dateModal = false
          this.reportTimestamp = ''
          this.reportType = 'ebpms'
          this.timeInterval = ''
        }, 0)
      }
    }
  },
  methods: {
    generateUnitName () {
      return this.unit.name
    },
    saveInitDate () {
      this.$refs.initDateDialog.save(this.dateRange.initDate) 
      this.initDateChange(this.dateRange.initDate)
    },
    initDateChange (date) {
      if (date) {
        // Validate if initDate is after endDate
        if (dayjs(date).isAfter(dayjs(this.dateRange.endDate))) {
          this.dateRange.endDate = dayjs(date).format('YYYY-MM-DD')
        }
      }
    },
    async generateReport () {
      let mutation = brakePerformanceRateReport
      let name = 'brakePerformanceRateReport'
      const timestamp = new Date(this.reportTimestamp)
      let unitId = ''
      if (this.reportType === 'ebpms') {
        unitId = this.unit.id
      } else {
        // For on unitIds and check the type is unit or group
        unitId = []
        unitId.push(this.unit.id)
      }
      let variables = {
        apiToken: this.user.apiToken,
        unitId: unitId,
        timestamp: timestamp / 1000
      }

      if (this.activeEmail) {
        variables.email = this.email
      }

      if (this.reportType === 'weekly') {
        mutation = weeklyReport
        name = 'weeklyReport'
        variables.timeInterval = this.timeInterval

        if (this.timeInterval === 'custom') {
          variables.initDate = new Date(this.dateRange.initDate) / 1000
          variables.endDate = new Date(this.dateRange.endDate) / 1000
        }
      }

      if (this.reportType === 'trips') {
        mutation = tripsReport
        name = 'tripsReport'
        variables.timeInterval = this.timeInterval

        if (this.timeInterval === 'custom') {
          variables.initDate = new Date(this.dateRange.initDate) / 1000
          variables.endDate = new Date(this.dateRange.endDate) / 1000
        }
      }

      if (this.reportType === 'services') {
        mutation = servicesReport
        name = 'servicesReport'
        variables.timeInterval = this.timeInterval

        if (this.timeInterval === 'custom') {
          variables.initDate = new Date(this.dateRange.initDate) / 1000
          variables.endDate = new Date(this.dateRange.endDate) / 1000
        }
      }

      if (this.reportType === 'faults') {
        mutation = faultsReport
        name = 'faultsReport'
        variables.timeInterval = this.timeInterval

        if (this.timeInterval === 'custom') {
          variables.initDate = new Date(this.dateRange.initDate) / 1000
          variables.endDate = new Date(this.dateRange.endDate) / 1000
        }
      }

      if (this.reportType === 'snapshot') {
        mutation = snapshotReport
        name = 'snapshotReport'
        variables.timeInterval = this.timeInterval

        if (this.timeInterval === 'custom') {
          variables.initDate = new Date(this.dateRange.initDate) / 1000
          variables.endDate = new Date(this.dateRange.endDate) / 1000
        }
      }

      if (this.reportType === 'geofences') {
        mutation = geofencesReport
        name = 'geofencesReport'
        variables.timeInterval = this.timeInterval

        if (this.timeInterval === 'custom') {
          variables.initDate = new Date(this.dateRange.initDate) / 1000
          variables.endDate = new Date(this.dateRange.endDate) / 1000
        }
      }

      if (this.reportType === 'trailerProfile') {
        mutation = trailerProfileReport
        name = 'trailerProfileReport'
        variables = {
          apiToken: this.user.apiToken,
          data: {
            unitIds: unitId
          }
        }
      }
      this.$store.commit('setLoading', true)
      
      this.$apollo
        .mutate({
          mutation,
          variables,
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          const { status, errors, result } = response.data[name]

          switch (status) {
            case 'OK':
              // window.open(process.env.NODE_ENV === 'development' ? `http://localhost:8000/public/${result.url}` : `https://integrate.vanguarder.goldenm.dev/static/${result.url}`, '_blank')
              // Add the url to the id for the variables object that need to be passed to the reports list
              this.$store.commit('reports/addReport', {
                ...result,
                unitName: this.generateUnitName(result.units)

              }, { root: true })
              this.$store.commit('toggleSnackbar', {
                message: this.$i18n.t('reports.start.reports'),
                color: 'success'
              })
              break

            case 'NOTFOUND':
              this.$store.commit('toggleSnackbar', {
                message: 'Theres no brake events for the the date selected',
                color: 'orange darken-2'
              })
              break
            case 'UNPROCESSABLE':
              this.$store.commit('toggleSnackbar', {
                message: errors.days,
                color: 'orange darken-2'
              })
              break

            case 'INTERNALERROR':
              this.$store.commit('toggleSnackbar')
              break
          }
        })
        .catch(_ => {
          this.$store.commit('toggleSnackbar')
        }).finally(() => {
          this.$store.commit('setLoading', false)
        })
    }
  }
}
</script>