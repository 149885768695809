const tyreArrays = [
  {
    name: 'NSR',
    number: '37'
  },
  {
    name: 'NSM',
    number: '27'
  },
  {
    name: 'NSF',
    number: '17'
  },
  {
    name: 'OSR',
    number: '39'
  },
  {
    name: 'OSM',
    number: '29'
  },
  {
    name: 'OSF',
    number: '19'
  }
]

const colors = [
  {
    name: 'grey',
    priority: 0,
    hex: '#9E9E9E'

  },
  {
    name: 'green',
    priority: 1,
    hex: '#4CAF50'
  },
  {
    name: 'amber',
    priority: 2,
    hex: '#FFEE58'
  },
  {
    name: 'red',
    priority: 3,
    hex: '#C62828'
  }
]

function arrayToDict (array, key) {
  const result = {}
  for (let i = 0; i < array.length; i++) {
    result[array[i][key]] = array[i]
  }
  return result
}

function getTemperature (message, tyreNumber) {
  if (message?.tpmsParams) {
    const tpmsParams = message?.tpmsParams === null ? {} : message?.tpmsParams
    if (tpmsParams[`stpms${tyreNumber}TyreTemp`]) {
      if (tpmsParams[`stpms${tyreNumber}TyreTemp`] > 200) {
        return 'N/A'
      } else {
        return `${tpmsParams[`stpms${tyreNumber}TyreTemp`]}°C`
      }
    }
  }
  return 'N/A'
}

function getPressure (message, tyreNumber) {
  if (message?.tpmsParams) {
    const tpmsParams = message?.tpmsParams === null ? {} : message?.tpmsParams
    if (tpmsParams[`stpms${tyreNumber}TyrePres`]) {
      if ((tpmsParams[`stpms${tyreNumber}TyrePres`] / 6.895).toFixed(2) > 200) {
        return 'N/A'
      } else {
        return (tpmsParams[`stpms${tyreNumber}TyrePres`] / 6.895).toFixed(2)
      }
    }
  }
  return 'N/A'
}

function getTyreAlertCounts (unit) {
  let tyreAlertCounts = 0
  if (unit.tpmsTelemetry) {
    for (const i in tyreArrays) {
      let color = colors[0]
      const temperature = getTemperature(unit.tpmsTelemetry, tyreArrays[i].number)
      // Validate temperature
      if (temperature !== 'N/A') {
        if (temperature <= 75) {
          color = colors[1]
        } else if (temperature > 75 && temperature < 85) {
          color = colors[2]
        } else if (temperature >= 85) {
          color = colors[3]
        }
      }
      const pressure = getPressure(unit.tpmsTelemetry, tyreArrays[i].number)
      // Validate pressure
      if (pressure !== 'N/A') {
        if (pressure >= 115) {
          if (color.priority < colors[1].priority) {
            color = colors[1]
          }
        } else if (pressure > 100 && pressure < 115) {
          if (color.priority < colors[2].priority) {
            color = colors[2]
          }
        } else if (pressure <= 100) {
          if (color.priority < colors[3].priority) {
            color = colors[3]
          }
        }
      }
      if (color.priority > 1) {
        tyreAlertCounts++
      }
    }
  }
  return tyreAlertCounts
}

/*
  Unit mutations
*/
export default {
  setList (state, payload) {
    // Sort payload
    // payload.sort(function (a, b) {
    //   if (a.telemetry !== null && b.telemetry !== null) {
    //     if (a.telemetry.receivedAt < b.telemetry.receivedAt) {
    //       return -1
    //     }
    //     if (a.telemetry.receivedAt > b.telemetry.receivedAt) {
    //       return 1
    //     }
    //   } else if (a.telemetry !== null) {
    //     return -1
    //   } else if (b.telemetry !== null) {
    //     return 1
    //   } 
    //   return 0
    // })

    // sort alphabetically
    payload = payload.sort((a, b) => a.name.localeCompare(b.name))
    state.list = payload
    const basicList = []
    for (const i in payload) {
      const unit = payload[i]
      if (unit.trailerProfile) {
        basicList.push(Object.assign({}, {
          id: unit.id,
          name: unit.name
        }))
      }
    }
    state.basicList = basicList
    state.dictList = Object.assign({}, arrayToDict(payload, 'id'))
  },

  newItem (state, payload) {
    state.list.push(payload)
    state.dictList[payload.id] = payload
  },

  updateItem (state, payload) {
    const list = [...state.list]
    for (let i = 0; i < list.length; i++) {
      if (list[i].id.toString() === payload.id.toString()) {
        list[i] = Object.assign({}, payload)
      }
    }

    state.list = []
    state.list = [...list]
    state.dictList = Object.assign({}, arrayToDict(list, 'id'))
  },

  deleteItem (state, id) {
    const list = state.list.filter(item => item.id.toString() !== id.toString())
    state.list = []
    state.list = [...list]
    state.dictList = Object.assign({}, arrayToDict(list, 'id'))
  },

  pushSelectedUnit (state, payload) {
    const list = state.selectedUnits.filter(item => item.id.toString() === payload.id.toString())
    if (list.length === 0) {
      state.selectedUnits.push(payload)
    }
  },

  popSelectedUnit (state, payload) {
    state.selectedUnits = state.selectedUnits.filter(item => item.id.toString() !== payload.id.toString())
  },

  emptySelectedUnits (state, _payload) {
    state.selectedUnits = []
  },

  updatePropertyItem (state, payload) {
    const list = [...state.list]
    const property = payload.property
    const unitId = payload.unitId
    const finalPayload = payload.payload
    for (let i = 0; i < list.length; i++) {
      if (list[i].id.toString() === unitId.toString()) {
        list[i][property] = Object.assign({}, finalPayload)
      }
    }

    state.list = []
    state.list = [...list]
    state.dictList = Object.assign({}, arrayToDict(list, 'id'))
  },

  updateUnit (state, payload) {
    state.unit = payload
  },

  updateUnitDialog (state, payload) {
    state.unitDialog = payload
  },

  updateSelectedUnit (state, payload) {
    const list = [...state.selectedUnits]
    const index = list.findIndex(item => item.id.toString() === payload.id)
    list[index] = payload
    state.selectedUnits = []
    state.selectedUnits = [...list]
  },
  // Counter values
  setFaultUnits (state, { rootState }) {
    state.faultUnitsList = state.list.filter((unit) => {
      return (
        // Validate if the unit have an overweight status
        unit.telemetry?.axeLoadSum / unit.trailerProfile?.weight > 1 ||
        // Validate if the unit have a recent dtc event
      rootState.dtcs.timeList.today.filter((dtcEvent) => dtcEvent?.unitId === unit.id).length > 0 ||
        // validate if the unit have a recent tpms alert
        getTyreAlertCounts(unit) > 1 ||
        // Validate if the unit have a current dtc fault
        unit.telemetry?.dtcEvent
      )
    })
  },

  setHomeDepotsUnits (state, { rootState }) {
    const user = rootState.session
    state.homeDepotUnitsList = state.list.filter((unit) => unit.telemetry?.inDepot || unit?.telemetry?.geofencesIds.includes(parseInt(unit?.trailerProfile?.ownerDepotId) || unit?.telemetry?.geofencesIds.includes(parseInt(user?.homeDepotId))))
  },

  setFlaggedVorList (state) {
    state.flaggedVorList = state.list.filter((unit) => (unit.trailerProfile?.vor))
    state.notVorList = state.list.filter((unit) => (!unit.trailerProfile?.vor))
  },

  setUpcomingServicesList (state) {
    const convertDateToCurrentTimezone = (date) => {
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
      return date
    }

    const compareDates = (date, firstDate, endDate) => {
      return date >= firstDate && date <= endDate
    }

    const getStartOfDay = (date) => {
      date.setHours(0, 0, 0, 0)
      return date
    }

    const getEndOfDay = (date) => {
      date.setHours(23, 59, 59, 999)
      return date
    }
    const upcomingServicesList = []
    const nextWeekList = []
    const next14DaysList = []
    const nextMonthList = []
    const baseDate = new Date()
    const startDate = getStartOfDay(new Date())
    const weekEnd = getEndOfDay(new Date((baseDate.getTime()) + 86400 * 7 * 1000))
    const next14Days = new Date((baseDate.getTime()) + 86400 * 14 * 1000)
    const nextMonth = new Date((baseDate.getTime()) + 86400 * 30 * 1000)
    for (const i in state.list) {
      const unit = state.list[i]
      // Validate the trailer profile of the unit
      if (unit.trailerProfile) {
        if (unit.trailerProfile.nextServiceDate) {
          const nextServiceDate = convertDateToCurrentTimezone(new Date(unit.trailerProfile.nextServiceDate))
          if (compareDates(nextServiceDate, startDate, weekEnd)) {
            nextWeekList.push(unit)
          }
          if (compareDates(nextServiceDate, startDate, next14Days)) {
            next14DaysList.push(unit)
            upcomingServicesList.push(unit)
          }
          if (compareDates(nextServiceDate, startDate, nextMonth)) {
            nextMonthList.push(unit)
          }
        }
      }
    }

    state.upcomingServicesDict = {
      '7days': nextWeekList,
      '14days': next14DaysList,
      '1month': nextMonthList
    }
    // Map units from the list to the upcoming services list

    state.upcomingServicesList = upcomingServicesList
  },

  setUpcomingMOTList (state) {
    const convertDateToCurrentTimezone = (date) => {
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
      return date
    }

    const compareDates = (date, firstDate, endDate) => {
      return date >= firstDate && date <= endDate
    }

    const getStartOfDay = (date) => {
      date.setHours(0, 0, 0, 0)
      return date
    }

    const getEndOfDay = (date) => {
      date.setHours(23, 59, 59, 999)
      return date
    }
    const upcomingMOTList = []
    const nextWeekList = []
    const next14DaysList = []
    const nextMonthList = []
    const baseDate = new Date()
    const startDate = getStartOfDay(new Date())
    const weekEnd = getEndOfDay(new Date((baseDate.getTime()) + 86400 * 7 * 1000))
    const next14Days = new Date((baseDate.getTime()) + 86400 * 14 * 1000)
    const nextMonth = new Date((baseDate.getTime()) + 86400 * 30 * 1000)
    for (const i in state.list) {
      const unit = state.list[i]
      // Validate the trailer profile of the unit
      if (unit.trailerProfile) {
        if (unit.trailerProfile.nextMotDoe) {
          const nextMotDoe = convertDateToCurrentTimezone(new Date(unit.trailerProfile.nextMotDoe))
          if (compareDates(nextMotDoe, startDate, weekEnd)) {
            nextWeekList.push(unit)
            upcomingMOTList.push(unit)
          }
          if (compareDates(nextMotDoe, startDate, next14Days)) {
            next14DaysList.push(unit)
          }
          if (compareDates(nextMotDoe, startDate, nextMonth)) {
            nextMonthList.push(unit)
          }
        }
      }
    }

    state.upcomingMOTDict = {
      '7days': nextWeekList,
      '14days': next14DaysList,
      '1month': nextMonthList
    }
    // Map units from the list to the upcoming services list

    state.upcomingMOTList = upcomingMOTList
  },

  setOverdueServicesList (state) {
    const overdueServicesList = []
    for (const i in state.list) {
      const unit = state.list[i]
      // Validate the trailer profile of the unit
      if (unit.trailerProfile) {
        if (unit.trailerProfile.nextServiceDate) {
          const nextServiceDate = new Date(unit.trailerProfile.nextServiceDate)
          if (nextServiceDate < new Date()) {
            overdueServicesList.push(unit)
          }
        }
      }
    }
    state.overdueServicesList = overdueServicesList
  },

  setOverdueMOTList (state) {
    const overdueMOTList = []
    for (const i in state.list) {
      const unit = state.list[i]
      // Validate the trailer profile of the unit
      if (unit.trailerProfile) {
        if (unit.trailerProfile.nextMotDoe) {
          const nextMotDoe = new Date(unit.trailerProfile.nextMotDoe)
          if (nextMotDoe < new Date()) {
            overdueMOTList.push(unit)
          }
        }
      }
    }
    state.overdueMOTList = overdueMOTList
  },

  setEmptyRunningList (state) {
    state.emptyRunningList = state.list.filter((unit) => ((unit.telemetry?.axeLoadSum / unit.trailerProfile?.weight < 0.2) && (unit.telemetry?.position?.speed > 10)))
  },

  setLoadedList (state) {
    state.loadedList = state.list.filter((unit) => ((unit.telemetry?.axeLoadSum / unit.trailerProfile?.weight > 0.2) && (unit.telemetry?.position?.speed > 10)))
  },
  setCustomerDepotList (state, { rootState }) {
    const geofences = rootState.geofences.list
    const result = []
    for (const i in state.list) {
      const unit = state.list[i]
      const customerGeofences = geofences.filter(geofence => geofence.category === 'CUSTOMER' && unit?.telemetry?.geofencesIds.includes(parseInt(geofence.id)))
      if (customerGeofences.length > 0) {
        result.push(unit)
      }
    }
    state.customerDepotList = result
  },

  setPoorBrakeList (state) {
    state.poorBrakelist = state.list.filter((unit) => (unit?.brakePerformanceRate?.rate < 45))
  },
  setAmberBrakeList (state) {
    state.amberBrakeList = state.list.filter((unit) => (unit?.brakePerformanceRate?.rate < 47 && unit?.brakePerformanceRate?.rate >= 45))
  },

  setUnitsEvent (state, { rootState }) {
    const baseState = {
      today: [],
      yesterday: [],
      thisWeek: [],
      lastWeek: []
    }
    const dtcEvents = rootState.dtcs.timeList
    const overWeightEvetns = rootState.events.timeList
    for (const i in baseState) {
      baseState[i] = [
        ...dtcEvents[i].filter(event => event.unitId === state.unit.id),
        ...overWeightEvetns[i].filter(event => event.unitId === state.unit.id)
      ]
    }
    state.unitsEvent = baseState
  },
  setUnitsTpms (state) {
    const temperatureList = []
    const pressureList = []
    const tyreArray = [
      {
        name: 'NSR',
        number: '37'
      },
      {
        name: 'NSM',
        number: '27'
      },
      {
        name: 'NSF',
        number: '17'
      },
      {
        name: 'OSR',
        number: '39'
      },
      {
        name: 'OSM',
        number: '29'
      },
      {
        name: 'OSF',
        number: '19'
      }
    ]
    const colors = [
      {
        name: 'grey',
        priority: 0,
        hex: '#9E9E9E'

      },
      {
        name: 'green',
        priority: 1,
        hex: '#4CAF50'
      },
      {
        name: 'amber',
        priority: 2,
        hex: '#FFEE58'
      },
      {
        name: 'red',
        priority: 3,
        hex: '#C62828'
      }
    ]

    const getTemperature = (message, tyreNumber) => {
      if (message?.tpmsParams) {
        const tpmsParams = message?.tpmsParams === null ? {} : message?.tpmsParams
        if (tpmsParams[`stpms${tyreNumber}TyreTemp`]) {
          if (tpmsParams[`stpms${tyreNumber}TyreTemp`] > 200) {
            return 'N/A'
          } else {
            return `${tpmsParams[`stpms${tyreNumber}TyreTemp`]}°C`
          }
        }
      }
      return 'N/A'
    }

    const getPressure = (message, tyreNumber) => {
      if (message?.tpmsParams) {
        const tpmsParams = message?.tpmsParams === null ? {} : message?.tpmsParams
        if (tpmsParams[`stpms${tyreNumber}TyrePres`]) {
          if ((tpmsParams[`stpms${tyreNumber}TyrePres`] / 6.895).toFixed(2) > 200) {
            return 'N/A'
          } else {
            return (tpmsParams[`stpms${tyreNumber}TyrePres`] / 6.895).toFixed(2)
          }
        }
      }
      return 'N/A'
    }

    for (const i in state.list) {
      const unit = state.list[i]
      // Validate the tpms data
      if (unit.tpmsTelemetry) {
        for (const i in tyreArray) {
          let color = colors[0]
          const temperature = getTemperature(unit.tpmsTelemetry, tyreArray[i].number)
          // Validate temperature
          if (temperature !== 'N/A') {
            if (temperature <= 75) {
              color = colors[1]
            } else if (temperature > 75 && temperature < 85) {
              color = colors[2]
            } else if (temperature >= 85) {
              color = colors[3]
            }
          }
          if (color.priority > colors[1].priority) {
            temperatureList.push({
              unit: unit,
              isAmber: color.priority === 2,
              tyreName: tyreArray[i].name,
              temperature: temperature,
              receivedAt: unit.tpmsTelemetry.receivedAt
            })
          }
          color = colors[0]
          const pressure = getPressure(unit.tpmsTelemetry, tyreArray[i].number)
          // Validate pressure
          if (pressure !== 'N/A') {
            if (pressure >= 115) {
              if (color.priority < colors[1].priority) {
                color = colors[1]
              }
            } else if (pressure > 100 && pressure < 115) {
              if (color.priority < colors[2].priority) {
                color = colors[2]
              }
            } else if (pressure <= 100) {
              if (color.priority < colors[3].priority) {
                color = colors[3]
              }
            }
          }
          if (color.priority > colors[1].priority) {
            pressureList.push({
              unit: unit,
              pressure: pressure,
              tyreName: tyreArray[i].name,
              isAmber: color.priority === 2,
              receivedAt: unit.tpmsTelemetry.receivedAt
            })
          }
        }
      }
    }
    state.temperatureList = temperatureList
    state.pressureList = pressureList
  },
  updateUnitTpmsMessages (state, payload) {
    state.tpmsMessagesUnit = payload
  },
  updateResetDialog (state, payload) {
    state.resetDialog = payload
  },

  resetBrakePerformance (state) {
    state.unit.brakePerformanceRate = null
  },
  resetBrakePerformanceList (state, payload) {
    const list = [...state.list]
    for (let i = 0; i < list.length; i++) {
      if (list[i].id.toString() === payload.toString()) {
        list[i] = Object.assign({}, list[i], { brakePerformanceRate: null })
      }
    }

    state.list = []
    state.list = [...list]
    state.dictList = Object.assign({}, arrayToDict(list, 'id'))
  },
  updateUnitTpmsEvents (state, payload) {
    state.tpmsEvents = payload
  },
  updateUnitChangeTpmsEvents (state, payload) {
    state.changeTpmsEvents = payload
  },
  // eslint-disable-next-line no-unused-vars
  toggle (_, _payload) {},

  setUnitsGeofencesIds (state, { payload, rootState }) {
    // Add geofences Ids to the units dictList
    const homeDepotUnitsList = []
    const customerDepotUnitsList = []
    const geofences = rootState.geofences.list.filter(geofence => geofence.category === 'CUSTOMER')
    for (const i in payload) {
      const unit = state.dictList[payload[i].unitId]
      if (unit) {
        unit.geofencesIds = payload[i].geofencesIds
        // Validate Home Depot units
        if ((unit.geofencesIds.includes(rootState.session.homeDepotId)) || (unit.telemetry?.inDepot) || (unit.geofencesIds.includes(unit.trailerProfile?.ownerDepotId))) {
          homeDepotUnitsList.push(unit)
        }
        // Validate Customer Depot units
        const customerGeofences = geofences.filter(geofence => unit.geofencesIds.includes(parseInt(geofence.id)))
        if (customerGeofences.length > 0) {
          customerDepotUnitsList.push(unit)
        }
      }
    }
    state.homeDepotUnitsList = homeDepotUnitsList
    state.customerDepotList = customerDepotUnitsList
  }
}
