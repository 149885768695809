import { i18n } from '@/plugins/i18n'
import { apollo } from '@/plugins/apollo'

// graphql files
import tpmsEvents from 'GraphQL/queries/tpms/generalTpmsEvents.gql'
import activeTpmsList from 'GraphQL/queries/tpms/activeTpmsList.gql'

export default {
  async getActiveList ({ rootState, commit }, payload = false) {
    if (payload) {
      await commit('setLoading', true, { root: true })
    }
    await apollo.query({
      query: activeTpmsList,
      variables: {
        apiToken: rootState.session.apiToken
      }
    }).then(response => {
      const { status, result } = response.data.activeTpmsEvents

      switch (status) {
        case 'OK':
          commit('setActiveTpmsEventsList', result)
          break
        case 'ACCESSDENIED':
          commit('toggleSnackbar', { message: i18n.t('errors.accessDenied'), color: 'red darken-2' }, { root: true })
          break
        case 'BADREQUEST':
          commit('toggleSnackbar', { message: i18n.t('errors.invalidFields'), color: 'orange darken-2' }, { root: true })
          break
        case 'UNPROCESSABLE':
          commit('toggleSnackbar', { message: i18n.t('errors.invalidFields'), color: 'orange darken-2' }, { root: true })
          break
        case 'INTERNALERROR':
          commit('toggleSnackbar', { message: i18n.t('helpers.errors.disaster'), color: 'red darken-2' }, { root: true })
          break
      }
    }).catch(err => {
      console.error(err)
      commit('toggleSnackbar', { message: i18n.t('helpers.errors.disaster'), color: 'red darken-2' }, { root: true })
    }).finally(() => {
      if (payload) {
        commit('setLoading', false, { root: true })
        commit('setUnitsToTheEvents', { rootState: rootState })
      }
    })
  },
  async getList ({ rootState, commit }, payload = false) {
    if (payload) {
      await commit('setLoading', true, { root: true })
    }
    await apollo.query({
      query: tpmsEvents,
      variables: {
        apiToken: rootState.session.apiToken
      }
    }).then(response => {
      const { status, result } = response.data.tpmsEvents

      switch (status) {
        case 'OK':
          commit('setTpmsEventsList', result)
          break
        case 'ACCESSDENIED':
          commit('toggleSnackbar', { message: i18n.t('errors.accessDenied'), color: 'red darken-2' }, { root: true })
          break
        case 'BADREQUEST':
          commit('toggleSnackbar', { message: i18n.t('errors.invalidFields'), color: 'orange darken-2' }, { root: true })
          break
        case 'UNPROCESSABLE':
          commit('toggleSnackbar', { message: i18n.t('errors.invalidFields'), color: 'orange darken-2' }, { root: true })
          break
        case 'INTERNALERROR':
          commit('toggleSnackbar', { message: i18n.t('helpers.errors.disaster'), color: 'red darken-2' }, { root: true })
          break
      }
    }).catch(err => {
      console.error(err)
      commit('toggleSnackbar', { message: i18n.t('helpers.errors.disaster'), color: 'red darken-2' }, { root: true })
    }).finally(() => {
      if (payload) {
        commit('setLoading', false, { root: true })
        commit('setUnitsToTheEvents', { rootState: rootState })
      }
    })
  }
}
