export default {
  defaultObject () {
    return {
      id: null,
      unitId: null,
      defectCount: 0,
      operativeDriverId: null,
      operativeDriver: {},
      defectCheckStart: null,
      defectCheckEnd: null,
      latestReadDate: null,
      latestActivityDate: null,
      createdAt: null,
      endAt: null,
      unit: {},
      duration: 0
    }
  }
}