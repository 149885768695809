export default {
  color (state) {
    return state.isDark ? 'white' : 'primary'
  },

  colorTitles (state) {
    return state.isDark ? 'white--text' : 'primary'
  },

  colorText (state) {
    return state.isDark ? 'white--text' : 'gm_black--text'
  },

  isMobile (state) {
    return state.width.full <= 890
  },

  breakpoint () {
    return 890
  },

  isDevelopment () {
    return process.env.NODE_ENV === 'development'
  },

  greyText (state) {
    return state.isDark ? 'grey--text text--lighten-2' : 'grey--text text--darken-1'
  },
  accentGreyText (state) {
    return state.isDark ? 'grey--text text--lighten-4' : 'grey--text text--darken-3'
  },

  colorNegativeText (state) {
    return state.isDark ? 'primary--text' : 'white--text'
  }
}