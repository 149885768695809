<template>
  <!-- Dialog with advertise for the last version -->
  
  <v-app :dark="isDark">
    <v-dialog 
      :value="!lastVersion"
      scrollable
      persistent
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title>{{ $t('app.about.title') }}</v-card-title>
        <v-card-text>
          <p>{{ $t('app.about.version') }}: {{ appVersion }}</p>
          <p> {{ $t('app.about.version.comparasion') }} </p>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-main :style="styles" class="default__background">
      <transition name="component-fade" mode="out-in">
        <router-view />
      </transition>
    </v-main>

    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          small
          depressed
          fab
          color="white"
          class="floating-back-button"
          @click="$store.commit('setLicensesDialog', true)"
          v-on="on"
        >
          <v-icon>mdi-help-circle-outline</v-icon>
        </v-btn>
      </template>

      <span>{{ $t('app.about.title') }}</span>
    </v-tooltip>
    <!-- <gm-footer
      is-full
      color="transparent"
      mode="MADEWITH"
      :made-with="$t('copyright.helpers.madeWith')"
      :by="$t('copyright.helpers.by')"
      :all-rights="$t('copyright.helpers.allRights')"
    /> -->
  </v-app>
</template>

<script>
// import Footer from '@goldenm-software/alpha-footer'
import { mapState } from 'vuex'

export default {
  components: {
    // 'gm-footer': Footer
  },

  data () {
    return {
      fab: false
    }
  },

  computed: {
    ...mapState(['isDark']),
    app () {
      return this.$store.state.general.app
    },
    background () {
      return this.app.background
    },
    appVersion () {
      return this.$store.state.general.appVersion
    },
    lastVersion () {
      return this.$store.state.general.lastestVersion
    },

    styles () {
      return `background-image: url(${this.background}); background-size: cover; background-position: center;`
    }
  }
}
</script>

<style>
.floating-back-button {
  position: fixed !important;
  bottom: 20px;
  right: 20px;
}
.default__background {
  background-color: var(--v-primary-base)
}
</style>