<template>
  <div>
    <v-row style="height: 100%;">
      <template v-if="unit.style === 'TRAILER_12'">
        <v-col v-for="(item, index) in arrayTop12Case" :key="`top-${index}-12`" cols="4">
          <gm-tyre-pill
            :tyre="item"
            :message="unit.tpmsTelemetry"
            :highlight="hoverWheel === item.name"
            :tpmsId="unit.lastTpmsId"
            :vehicleType="unit.vehicleType"
          />
        </v-col>
      </template>
      <v-col v-for="(item, index) in arrayTop" :key="`top-${index}`" cols="4">
        <gm-tyre-pill
          :tyre="item"
          :message="unit.tpmsTelemetry"
          :highlight="hoverWheel === item.name"
          :tpmsId="unit.lastTpmsId"
          :vehicleType="unit.vehicleType"
        />
      </v-col>
      <v-col cols="12" class="d-flex align-center justify-start">
        <div>
          <v-list dense>
            <v-list-item>
              <v-list-item-icon class="mr-0">
                <v-icon class="mr-1" small color="#C62828">mdi-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                Low pressure or over heating
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon class="mr-0">
                <v-icon class="mr-1" small color="#FFEE58">mdi-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                Minor issues
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon class="mr-0">
                <v-icon class="mr-1" small color="#4CAF50">mdi-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                All ok
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon class="mr-0">
                <v-icon class="mr-1" small>mdi-circle-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                Position changes / New sensors needing to be confirmed
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>

        <div v-if="unit.style === 'TRAILER'">
          <gm-trailer-render
            id="tpms-tab-render"
            key="tpms-tab-render"
            height="32vh"
            trailer-id="tpms-tab-render"
            :message="unit.tpmsTelemetry"
            @wheel-click="wheelClick"
            @wheel-hover="wheelHover"
            @wheel-mouseout="wheelMouseout"
          />
        </div>
        <div v-if="unit.style === 'TRAILER_12'">
          <gm-trailer-render-12
            id="tpms-tab-render"
            key="tpms-tab-render"
            height="32vh"
            trailer-id="tpms-tab-render"
            :message="unit.tpmsTelemetry"
            @wheel-click="wheelClick"
            @wheel-hover="wheelHover"
            @wheel-mouseout="wheelMouseout"
          />
        </div>
      <!-- style="height: 35vh; max-width: 100%;" -->
      <!-- <img :src="$assets.vanguarderTrailer" > -->
      </v-col>
      <v-col cols="12" class="d-flex align-center justify-end">
        <v-btn class="mx-2" icon color="primary">
          <v-icon>mdi-information</v-icon>
        </v-btn>
        <v-btn rounded color="primary" outlined @click="openDialog">
          <v-icon>mdi-chart-box-outline</v-icon>
          Go to Tyre Analysis
        </v-btn>
      </v-col>
      <v-col v-for="(item, index) in arrayBottom" :key="`bottom-${index}-12`" cols="4">
        <gm-tyre-pill
          :tyre="item"
          :message="unit.tpmsTelemetry"
          :highlight="hoverWheel === item.name"
          :tpmsId="unit.lastTpmsId"
          :vehicleType="unit.vehicleType"
          :tpmsChange="tpmsChange"
        />
      </v-col>
      <template v-if="unit.style === 'TRAILER_12'">
        <v-col v-for="(item, index) in arrayBottom12Case" :key="`bottom-${index}`" cols="4">
          <gm-tyre-pill
            :tyre="item"
            :message="unit.tpmsTelemetry"
            :highlight="hoverWheel === item.name"
            :tpmsId="unit.lastTpmsId"
            :vehicleType="unit.vehicleType"
            :tpmsChange="tpmsChange"
          />
        </v-col>
      </template>
    </v-row>

    <gm-tpms-dialog
      v-model="object"
      :show-dialog="dialog"
      :readonly="!isEditting"
      :apollo="$apollo"
      :unit="unit"
      :tpmsEvents="tpmsEvents"
      :changeTpmsEvents="changeTpmsEvents"
      @toggle-dialog="state => dialog = state"
      @set-readonly="state => isEditting = !state"
      @isLoading="state => $emit('isLoading', state)"
    />
  </div>
</template>

<script>

import tpmsEvents from 'GraphQL/queries/tpms/tpmsEvents.gql'
import changeTpmsEvents from 'GraphQL/queries/tpms/changeTpmsEvents.gql'
import tpmsChange from 'GraphQL/queries/tpms/tpmsChange.gql'

import {
  TyrePill,
  TrailerRender,
  TrailerRender12
} from 'Components/utils/'

import { TpmsDialog } from '@/components/units/unit_sheet/dialogs'

export default {
  components: {
    'gm-tyre-pill': TyrePill,
    'gm-tpms-dialog': TpmsDialog,
    'gm-trailer-render': TrailerRender,
    'gm-trailer-render-12': TrailerRender12
  },
  props: {
    unit: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      hoverWheel: null,
      dialog: false,
      object: {},
      isEditting: true,
      tpmsEvents: [],
      changeTpmsEvents: [],
      tpmsChange: []
    }
  },
  computed: {
    user () { return this.$store.state.session },
    arrayTop () {
      let suffix = ''
      if (this.unit.style === 'TRAILER_12') {
        suffix = 'I'
      }
      return [
        {
          name: 'NSR' + suffix,
          number: '37'
        },
        {
          name: 'NSM' + suffix,
          number: '27'
        },
        {
          name: 'NSF' + suffix,
          number: '17'
        }
      ]
    },
    arrayTop12Case () {
      return [
        {
          name: 'NSRO',
          number: '36'
        },
        {
          name: 'NSMO',
          number: '26'
        },
        {
          name: 'NSFO',
          number: '16'
        }
      ]
    },
    arrayBottom () {
      let suffix = ''
      if (this.unit.style === 'TRAILER_12') {
        suffix = 'I'
      }
      return [
        {
          name: 'OSR' + suffix,
          number: '39'
        },
        {
          name: 'OSM' + suffix,
          number: '29'
        },
        {
          name: 'OSF' + suffix,
          number: '19'
        }
      ]
    },
    arrayBottom12Case () {
      return [
        {
          name: 'OSRO',
          number: '3a'
        },
        {
          name: 'OSMO',
          number: '2a'
        },
        {
          name: 'OSFO',
          number: '1a'
        }
      ]
    }
  },

  mounted () {
    this.getTpmsChange()
  },

  methods: {
    wheelClick (_) {
      // this.$emit('wheel-click', wheel)
    },
    wheelHover (wheel) {
      // this.$emit('wheel-hover', wheel)
      this.hoverWheel = wheel
      // console.log('hover enter', wheel)
    },
    wheelMouseout (_) {
      this.hoverWheel = null
      // console.log('hover out')
      // this.$emit('wheel-mouseout')
    },

    async getTpmsChange () {
      await this.$store.commit('setLoading', true, { root: true })

      await this.$apollo.query({
        query: tpmsChange,
        variables: {
          apiToken: this.user.apiToken,
          unitId: this.unit.id,
          rangeType: 'last500'
        }
      }).then(response => {
        const { status, result } = response.data.tpmsChange
        switch (status) {
          case 'OK':
            this.tpmsChange = result
            break
          case 'ACCESSDENIED':
            this.$store.commit('toggleSnackbar', { message: this.$i18n.t('errors.accessDenied'), color: 'red darken-2' })
            break
          case 'BADREQUEST':
            this.$store.commit('toggleSnackbar', { message: this.$i18n.t('errors.invalidFields'), color: 'orange darken-2' })
            break
          case 'UNPROCESSABLE':
            this.$store.commit('toggleSnackbar', { message: this.$i18n.t('errors.invalidFields'), color: 'orange darken-2' })
            break
          case 'INTERNALERROR':
            this.$store.commit('toggleSnackbar', { message: this.$i18n.t('helpers.errors.disaster'), color: 'red darken-2' })
            break
        }
      }).catch(err => {
        console.error(err)
        this.$store.commit('toggleSnackbar', { message: this.$i18n.t('helpers.errors.disaster'), color: 'red darken-2' })
      }).finally(() => {
        this.$store.commit('setLoading', false, { root: true })
      })
    },

    async openDialog () {
      // Loader
      await this.$store.commit('setLoading', true, { root: true })
      // Get using graphql the data for the dialog
      await this.$apollo.query({
        query: tpmsEvents,
        variables: {
          apiToken: this.user.apiToken,
          unitId: this.unit.id,
          rangeType: 'last500'
        }
      }).then(response => {
        const { status, result } = response.data.tpmsEvents
        switch (status) {
          case 'OK':
            this.tpmsEvents = result
            break
          case 'ACCESSDENIED':
            this.$store.commit('toggleSnackbar', { message: this.$i18n.t('errors.accessDenied'), color: 'red darken-2' })
            break
          case 'BADREQUEST':
            this.$store.commit('toggleSnackbar', { message: this.$i18n.t('errors.invalidFields'), color: 'orange darken-2' })
            break
          case 'UNPROCESSABLE':
            this.$store.commit('toggleSnackbar', { message: this.$i18n.t('errors.invalidFields'), color: 'orange darken-2' })
            break
          case 'INTERNALERROR':
            this.$store.commit('toggleSnackbar', { message: this.$i18n.t('helpers.errors.disaster'), color: 'red darken-2' })
            break
        }
      }).catch(err => {
        console.error(err)
        this.$store.commit('toggleSnackbar', { message: this.$i18n.t('helpers.errors.disaster'), color: 'red darken-2' })
      }).finally(() => {
        
      })
      // Get using graphql the data for the dialog
      await this.$apollo.query({
        query: changeTpmsEvents,
        variables: {
          apiToken: this.user.apiToken,
          unitId: this.unit.id,
          rangeType: 'last500'
        }
      }).then(response => {
        const { status, result } = response.data.changeTpmsEvents
        switch (status) {
          case 'OK':
            this.changeTpmsEvents = result
            break
          case 'ACCESSDENIED':
            this.$store.commit('toggleSnackbar', { message: this.$i18n.t('errors.accessDenied'), color: 'red darken-2' })
            break
          case 'BADREQUEST':
            this.$store.commit('toggleSnackbar', { message: this.$i18n.t('errors.invalidFields'), color: 'orange darken-2' })
            break
          case 'UNPROCESSABLE':
            this.$store.commit('toggleSnackbar', { message: this.$i18n.t('errors.invalidFields'), color: 'orange darken-2' })
            break
          case 'INTERNALERROR':
            this.$store.commit('toggleSnackbar', { message: this.$i18n.t('helpers.errors.disaster'), color: 'red darken-2' })
            break
        }
      }).catch(err => {
        console.error(err)
        this.$store.commit('toggleSnackbar', { message: this.$i18n.t('helpers.errors.disaster'), color: 'red darken-2' })
      }).finally(() => {
        this.$store.commit('setLoading', false, { root: true })
      })
      this.dialog = true
    }
  }
}
</script>