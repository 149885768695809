/*
  Dtcs state
*/
export default {
  eventList: [],
  eventDictList: [],
  timeList: {
    today: [],
    yesterday: [],
    thisWeek: [],
    lastWeek: []
  }
}
