// import { i18n } from '@/plugins/i18n'

export default {
  defaultObject () {
    return {
      id: null,
      unit: {},
      defectCheck: {},
      name: '',
      comment: '',
      photoUrl: '',
      status: '',
      createdAt: '',
      updatedAt: ''
    }
  }
}