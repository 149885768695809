export default {
  pushAllSelectedUnits (state, payload) {
    state.selectedUnits = payload
  },
  pushSelectedUnit (state, payload) {
    const list = state.selectedUnits.filter(item => item.id.toString() === payload.id.toString())
    if (list.length === 0) {
      state.selectedUnits.push(payload)
    }
  },

  panToUnit (state, payload) {
    state.panToUnit = payload
  },

  setCenterAll (state, payload) {
    state.centerAll = payload
  },

  setClustering (state, payload) {
    state.clustering = payload
  },

  popSelectedUnit (state, payload) {
    state.selectedUnits = state.selectedUnits.filter(item => item.id.toString() !== payload.id.toString())
  },

  emptySelectedUnits (state) {
    state.selectedUnits = []
  },

  updateSelectedUnit (state, payload) {
    const index = state.selectedUnits.findIndex(item => item.id.toString() === payload.id)
    state.selectedUnits[index] = payload
  },
  // eslint-disable-next-line no-unused-vars
  toggle (_, payload) {}
}
