/*
  reports state mutations
*/
import { i18n } from '@/plugins/i18n'

function arrayToDict (array, key) {
  const result = {}
  for (let i = 0; i < array.length; i++) {
    result[array[i][key]] = array[i]
  }
  return result
}

export default {
  setList (state, payload) {
    state.list = payload
    // Convert state.list to dict list
    state.dictList = Object.assign({}, arrayToDict(state.list, 'id'))
  },
  addReport (state, payload) {
    state.list.push(payload)
    // Convert state.list to dict list
    state.dictList = Object.assign({}, arrayToDict(state.list, 'id'))
  },
  updateReport (state, payload) {
    const index = state.list.findIndex((item) => item.id === payload.id)
    state.list.splice(index, 1, payload)
  },
  setUnitsToList (state, { rootState, dispatch }) {
    const generateUnitName = (unitIds) => {
      const units = rootState.units.list.filter(unit => unitIds.includes(unit.id))
      if (units.length === 0) {
        return 'All Units'
      } else if (units.length >= 4) {
        // Cut the units to 4
        return `${units.slice(0, 4).map(unit => unit.name).join(', ')}...`
      }
      return units.map(unit => unit.name).join(', ')
    }
    state.list = state.list.map((item) => {
      return {
        ...item,
        unitName: generateUnitName(item.units)
      }
    })
    // Set interval to call updateList every 120 seconds
    state.intervalId = setInterval(() => { dispatch('reports/updateList', false, { root: true }) }, 1000 * 70)
  },
  updateList (state, { rootState, payload, commit }) {
    const generateUnitName = (unitIds) => {
      const units = rootState.units.list.filter(unit => unitIds.includes(unit.id))
      if (units.length === 0) {
        return 'All Units'
      } else if (units.length >= 4) {
        // Cut the units to 4
        return `${units.slice(0, 4).map(unit => unit.name).join(', ')}...`
      }
      return units.map(unit => unit.name).join(', ')
    }
    // For Payload to update the state list
    const updateList = payload.map((item) => {
      return {
        ...item,
        unitName: generateUnitName(item.units)
      }
    })
    // Update the state list but vlaidating if the item exists and the status change
    state.list = state.list.map((item) => {
      const updateItem = updateList.find((updateItem) => updateItem.id === item.id)
      if (updateItem && updateItem.status !== item.status) {
        // Commit an update for the snakbar
        commit('toggleSnackbar', { message: i18n.t('reports.success.reports'), color: 'green darken-2' }, { root: true })
        return updateItem
      }
      return item
    })
    state.dictList = Object.assign({}, arrayToDict(state.list, 'id'))
  },
  cleanInterval (state) {
    clearInterval(state.intervalId)
  },
  addIfToCompletedSessions (state, payload) {
    state.completedSessions.push(payload)
  },
  // eslint-disable-next-line no-unused-vars
  toggle (_, payload) {}
}
