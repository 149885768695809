<template>
  <div class="body">  
    <div class="">
      <!-- <layo-bot
        :loop="true"
        :autoplay="true"
        :animationData="require('@/assets/404.json')"
      /> -->
    </div>
    <h4 class="text-1">{{ $t('errors.notFound.title') }}</h4>
    <div class="link-container">
      <v-btn depressed color="white secondary--text mt-5" @click="goBack">
        {{ $t('errors.notFound.goto') }}
      </v-btn>
    </div>
  </div>
</template>
<script>
// import { Error } from '@/components/layo'
export default {
  components: {
    // 'layo-bot': Error
  },
  methods: {
    goBack () {
      window.history.back()
    }
  }
}
</script>

<style lang="scss" scoped>
/* 404 */
.body {
  display: flex;
  background-color: #ddd;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.layo-container {
  margin-bottom: 20px;
  width: 280px;
  height: 280px;
  padding: 20px;
  border: 10px solid #212121;
  border-radius: 50%;
  background-color: #001E60;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0,0,0,0.3)
}

/* text */
.text-1 {
  font-family: 'Cabin', helvetica, sans-serif;
  text-align: center;
  color: #001E60;
  font-size: 2em;
  text-transform: uppercase;
}
.link-container {
  text-align: center;
}
a.more-link {
  text-transform: uppercase;
  font-size: 13px;
    background-color: #92a4ad;
    padding: 10px 15px;
    border-radius: 0;
    color: #416475;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
    line-height: 1.5;
    text-decoration: none;
  margin-top: 50px;
  letter-spacing: 1px;
}
</style>