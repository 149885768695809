<template>
  <gm-expandable-list
    expanded-height="300px"
    :mandatory="mandatory"
    :title="$t('dashboard.tpmsWarnings')"
    :value="firstItems.length"
    :data="firstItems"
    :showDelete="configurationMode"
    :panel-default-open="panelDefaultOpen"
    :expantion-absolute="expantionAbsolute"
    @clean-option="cleanOption"
  >
    <template #leadingTitle>
      <v-icon
        class="ml-3"
        color="yellow darken-2"
      >
        mdi-alert
      </v-icon>
    </template>
    <template #headerTrail>
      <div
        style="z-index: 10;"
        class="d-flex align-center"
      >
        <v-autocomplete
          v-model="eventTypeFilter"
          :items="eventTypes"
          label="Filter by Event Type"
          outlined
          dense
          hide-details
          clearable
          item-text="name"
          item-value="value"
          @change="() => console.log(eventTypeFilter)"
        />

        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              color="accent"
              dark
              depressed
              fab
              class="ml-1"
              x-small
              v-bind="attrs"
              v-on="on"
              @click="showAllItems = !showAllItems"
            >
              {{ !showAllItems && tpmsWarning.length >= 20 ? '+' : '' }}{{ firstItems.length }}
            </v-btn>
          </template>
          <span>
            {{ showAllItems ? 'Show less' : 'Show all' }}
          </span>
        </v-tooltip>
      </div>
    </template>
    <template #default="{ item, index }">
      <gm-tyre-list-item
        :index="index"
        :title="item.unitName"
        :subtitle="formatSubtitle(item)"
        icon="mdi-alert"
        :date="getUnitDate(item)"
        :onClick="showDialog"
        :onClickMenu="showDialog"
        :event="item"
      />
    </template>
  </gm-expandable-list>
</template>

<script>

import {
  ExpandableList,
  TpmsListItemSingle
} from 'Components/monitor'

export default {
  components: {
    'gm-expandable-list': ExpandableList,
    'gm-tyre-list-item': TpmsListItemSingle
  },
  props: {
    configurationMode: {
      type: Boolean,
      default: () => false
    },
    panelDefaultOpen: {
      type: Boolean,
      default: () => true
    },
    expantionAbsolute: {
      type: Boolean,
      default: () => false
    },
    mandatory: {
      type: Boolean,
      default: () => true
    },
    search: {
      type: String,
      default: () => ''
    },
    sortValue: {
      type: String,
      default: () => ''
    },
    dailyValue: {
      type: Number,
      default: () => 0
    },
    dailyOptions: {
      type: Array,
      default: () => [
      ]
    },
    stateFilter: {
      type: String,
      default: () => 'ALL'
    }
  },
  data () {
    return {
      eventTypeFilter: 'ALL',
      showAllItems: false
    }
  },
  computed: {
    units () { return this.$store.state.units.dictList },
    values () { return this.$store.state.tpmsEvents.activeList },
    tpmsWarning () {
      let result = this.sortByDateArray(this.values, this.sortValue === 'desc' ? 2 : 0)
      if (this.eventTypeFilter !== 'ALL') {
        result = result
          .filter(el =>
            el.eventType &&
      el.eventType.includes(this.eventTypeFilter)
          )
      }
      if (this.search) {
        result = result
          .filter(el =>
            el.unitName.toLowerCase().includes(this.search.toLowerCase())
          )
      }
      return result
    },
    eventTypes () {
      // Check the values of the events and return the unique values
      let list = []
      for (const item of this.values) {
        if (!list.includes(item.eventType)) {
          list.push(item.eventType)
        }
      }
      list = ['ALL', ...list]
      return list.map((item) => {
        return {
          name: this.$t(`tpmsWarning.${item}`),
          value: item
        }
      })
    },

    // show first 20 items
    firstItems () {
      return this.showAllItems ? this.tpmsWarning : this.tpmsWarning.slice(0, 20)
    }
  },
  methods: {
    cleanOption (value) {
      this.$emit('clean-option', value)
    },
    showDialog (event) {
      const unit = this.units[event.unitId]
      this.$showUnitDialog(unit)
    },
    convertPressure (value) {
      return (value / 6.895).toFixed(2)
    },
    getUnitDate (event) {
      if (event !== undefined) {
        return event.receivedAt * 1000
      }
      return undefined
    },
    formatSubtitle (event) {
      return this.$t(`tpmsWarning.${event.eventType}`)
    },
    sortByDateArray (array, val = 0) {
      array.sort(function (a, b) {
        if (a.receivedAt < b.receivedAt) {
          return -1 + val
        }
        if (a.receivedAt > b.receivedAt) {
          return 1 - val
        }
        return 0
      })
      return array
    }
  }
}
</script>

<style lang="scss" scoped>

.unit__icon{
  font-size: 28px;
}

.unit__title{
  justify-content: center;
  font-size: 30px;
  font-weight: 700 !important;
}

.unit__subtitle {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

</style>
