<template>
  <div>
    Hello 3
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import dayjs from 'dayjs'
const advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

export default {
  props: {
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState(['isDark']),
    ...mapGetters(['colorText'])
  },
  methods: {
    formatDate (date) {
      if (date) {
        return dayjs(date).format('Do MMMM YYYY - hh:mm a')
      } else {
        return ''
      }
    },
    formatValue (value) {
      return parseFloat(value).toFixed(2)
    }
  }
}
</script>