<template>
  <v-col cols="12">
    <gm-event-list />
  </v-col>
</template>

<script>

import {
  EventList
} from 'Components/monitor/lists/components'

import { dragscroll } from 'vue-dragscroll'
export default {
  directives: {
    dragscroll
  },
  components: {
    'gm-event-list': EventList
  },
  props: {
    unit: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
  },
  computed: {
  }
}
</script>
