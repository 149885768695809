<template>
  <div>
    <v-card flat>
      <v-card-title v-if="showTitle" class="text-center justify-center pa-2">
        {{ title }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <div class="list-container">
            <v-card>
              <v-card-title class="d-flex justify-center pa-3 pb-0">
                {{ dataListTitle }}
              </v-card-title>
              <v-card-text class="pa-2">
                <v-text-field
                  v-if="showSearchBar"
                  v-model="dataSearchBar"
                  class="pt-1 mt-0"
                  solo
                  dense
                  :label="searchDataLabel"
                  append-icon="mdi-magnify"
                />
                <v-list>
                  <v-virtual-scroll
                    :benched="benched"
                    :items="dataSearchBar === '' ? itemsPool : searchedArrayData"
                    :height="scrollHeight"
                    :item-height="itemHeight"
                    class="better-scrollbar"
                  >
                    <template #default="context">
                      <v-list-item
                        :key="context.index"
                        v-ripple="{ class: disabled ? 'grey--text' : 'primary--text' }"
                        @click="selectItemForward(context.item, context.index, )"
                      >
                        <slot
                          name="item-render"
                          v-bind="{index: context.index, item: context.item !== undefined ? context.item.value : {}}"
                        />
                      </v-list-item>
                    </template>
                  </v-virtual-scroll>
                </v-list>
              </v-card-text>
            </v-card>
          </div>

          <div class="button-container">
            <v-btn
              :disabled="disabled"
              fab
              small
              depressed
              class="ma-2 button1"
              :color="selectAllBtnColor"
              @click="selectAll"
            >
              <v-icon>mdi-chevron-double-right</v-icon>
            </v-btn>
            <v-btn
              :disabled="disabled"
              fab
              small
              depressed
              class="ma-2 button2"
              :color="deselectAllBtnColor"
              @click="deselectAll"
            >
              <v-icon>mdi-chevron-double-left</v-icon>
            </v-btn>
          </div>

          <div class="list-container">
            <v-card>
              <v-card-title class="d-flex justify-center pa-3 pb-0">
                {{ selectedListTitle }}
              </v-card-title>
              <v-card-text class="pa-2">
                <v-text-field
                  v-if="showSearchBar"
                  v-model="selectedSearchBar"
                  class="pt-1 mt-0"
                  solo
                  dense
                  :label="searchSelectedLabel"
                  append-icon="mdi-magnify"
                />
                <v-list>
                  <v-virtual-scroll
                    :benched="benched"
                    :items="selectedSearchBar === '' ? selectedItems : searchedArraySelected"
                    :height="scrollHeight"
                    :item-height="itemHeight"
                    class="better-scrollbar"
                  >
                    <template #default="context">
                      <v-list-item
                        :key="context.index"
                        v-ripple="{ class: disabled ? 'grey--text' : 'primary--text' }"
                        @click="selectItemBackwards(context.item, context.index)"
                      >
                        <slot
                          name="item-render"
                          v-bind="{index: context.index, item: context.item !== undefined ? context.item.value : {}}"
                        />
                      </v-list-item>
                    </template>
                  </v-virtual-scroll>
                </v-list>
              </v-card-text>
            </v-card>
          </div>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
/**
 * TODO:
 *   - Search bar
 *   - si en v-model se pasa un arrey que estos se marquen como seleccionados
 */
export default {
  props: {
    /**
     * v-model that holds the selected data
     */
    value: {
      type: Array,
      required: true
    },
    /**
     * data pool
     */
    data: {
      type: Array,
      default: () => []
    },
    benched: {
      type: Number,
      default: () => 5
    },
    itemHeight: {
      type: Number,
      default: () => 64
    },
    scrollHeight: {
      type: Number,
      default: () => 300
    },
    title: {
      type: String,
      default: () => ''
    },
    selectAllLabel: {
      type: String,
      default: () => ''
    },
    deselectAllLabel: {
      type: String,
      default: () => ''
    },
    dataListTitle: {
      type: String,
      default: () => ''
    },
    selectedListTitle: {
      type: String,
      default: () => ''
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    searchDataLabel: {
      type: String,
      default: () => ''
    },
    searchSelectedLabel: {
      type: String,
      default: () => ''
    },
    /**
     * Design props
     */
    flat: {
      type: Boolean,
      default: () => false
    },
    showTitle: {
      type: Boolean,
      default: () => true
    },
    /**
     * Param name to compare selected
     */
    identifierParam: {
      type: String,
      required: true
    },
    /**
     * Param name to compare when searching
     */
    searchParam: {
      type: String,
      default: () => 'name'
    },
    showSearchBar: {
      type: Boolean,
      default: () => true
    },
    selectAllBtnColor: {
      type: String,
      default: () => 'primary'
    },
    deselectAllBtnColor: {
      type: String,
      default: () => 'primary'
    },
    /**
     * custom filter structure:
     * customFilter (currentValue, searchParam, index, array){ return Boolean}
     */
    customFilter: {
      type: Function,
      default: undefined
    }
  },
  data () {
    return {
      itemsPool: [],
      selectedItems: [],
      dataSearchBar: '',
      searchedArrayData: [],
      selectedSearchBar: '',
      searchedArraySelected: []
    }
  },
  computed: {
    selectedValues () {
      return this.selectedItems.slice(0).map((item) => { return item.value })
    }
  },
  watch: {
    dataSearchBar () {
      if (this.customFilter) {
        this.searchedArrayData = this.itemsPool.filter(
          (item, index, array) => this.customFilter(item.value, this.dataSearchBar, index, array)
        )
      } else {
        this.searchedArrayData = this.itemsPool.filter(
          item => item.value[this.searchParam].toLowerCase().includes(this.dataSearchBar.toLowerCase())
        )
      }
    },
    selectedSearchBar () {
      if (this.customFilter) {
        this.searchedArraySelected = this.selectedItems.filter(
          (item, index, array) => this.customFilter(item.value, this.selectedSearchBar, index, array)
        )
      } else {
        this.searchedArraySelected = this.selectedItems.filter(
          item => item.value[this.searchParam].toLowerCase().includes(this.selectedSearchBar.toLowerCase())
        )
      }
    },
    value () {
      this.evaluatePreselectedValues()
    },
    data () {
      this.evaluatePreselectedValues()
    }
  },
  mounted () {
    /*
      se copia el arreglo a un objeto que almacena el
      value original y un index
    */
    setTimeout(() => {
      this.evaluatePreselectedValues()
    }, 0)
  },
  methods: {
    /**
     * for each preselected element that is in this.value
     * the element is marked as selected in the list
     */
    evaluatePreselectedValues () {
      this.selectedItems = []
      this.itemsPool = this.buildPreparedArray()

      for (const i in this.value) {
        // let index
        const foundIndex = this.itemsPool.findIndex(
          (element) => element.value[this.identifierParam] === this.value[i][this.identifierParam]
        )
        this.markElementAsSelected(this.itemsPool[foundIndex], foundIndex)
      }
    },
    /**
     * Add item from the data pool
     * to the selected elements array
     *
     * @param item element to be selected
     * @param poolIndex element index in the this.itemsPool array
     */
    markElementAsSelected (item, poolIndex) {
      // se agrega el elemento al array de seleccionados
      this.selectedItems.push(item)
      // se elimina el elemento del array de selecciones
      this.itemsPool.splice(poolIndex, 1)
    },
    /**
     *
     */
    markSearchedElementAsSelected (item, indexFrontend) {
      // se agrega el elemento al array de seleccionados
      this.selectedItems.push(item)

      // se elimina el elemento del array de selecciones buscadas
      this.searchedArrayData.splice(indexFrontend, 1)

      // se busca el elemento en la lista original y se quita de ella tambien
      const foundIndex = this.itemsPool.findIndex(
        (element) => element.value[this.identifierParam] === item.value[this.identifierParam]
      )

      // se elimina el elemento del array de selecciones
      this.itemsPool.splice(foundIndex, 1)
    },
    /**
     * Actualiza el valor de seleccionados que se pasa en el v-model
     */
    updateSelected () {
      this.$emit('input', this.selectedValues)
    },
    prepareArray (dataList) {
      return dataList.slice(0).map((value, index) => { return { value, index } })
    },
    buildPreparedArray () {
      return this.prepareArray(this.data)
    },
    /**
     * Selecciona todos los elementos
     */
    selectAll () {
      this.itemsPool = []
      this.selectedItems = this.buildPreparedArray()

      // actualiza el v-model
      this.updateSelected()
    },
    /**
     * DeSelecciona todos los elementos
     */
    deselectAll () {
      this.selectedItems = []
      this.itemsPool = this.buildPreparedArray()

      // actualiza el v-model
      this.updateSelected()
    },
    /**
     * En base al indice del frontend se
     * toma el elemento y se hace push al array de seleccionados
     *
     */
    selectItemForward (item, indexFrontend) {
      if (!this.disabled) {
        if (this.dataSearchBar === '') {
          this.markElementAsSelected(item, indexFrontend)
        } else {
          // this.markElementAsSelected
          this.markSearchedElementAsSelected(item, indexFrontend)
        }
        // actualiza el v-model
        this.updateSelected()
      }
    },
    /**
     * Debido a la limpieza se toma el elemento seleccionado
     * se remueve del array de seleccionados
     * y se retorna al indice anterior en su array original
     * ordenando sus elementos adyacentes
     * manteniendo el pool de datos ordenado
     */
    selectItemBackwards (item, indexFrontend) {
      if (!this.disabled) {
        // se busca el index original
        const originalIndex = item.index

        if (this.selectedSearchBar === '') {
          this.unselectElement(item, indexFrontend)
        } else {
          this.unselectSearchedElement(item, indexFrontend)
        }

        // ordenar los items al volver
        this.sortNearbyItems(originalIndex)

        // actualiza el v-model
        this.updateSelected()
      }
    },
    unselectElement (item, indexFrontend) {
      // se agrega el elemento en su index original
      this.itemsPool.splice(item.index, 0, item)

      // se elimina el elemento en el array de seleccionados
      this.selectedItems.splice(indexFrontend, 1)
      // se elimina el indice del array de indices
    },
    unselectSearchedElement (item, indexFrontend) {
      // se agrega el elemento en su index original
      this.itemsPool.splice(item.index, 0, item)

      // se elimina el elemento en el array de seleccionados buyscados
      this.searchedArraySelected.splice(indexFrontend, 1)
      // se elimina el indice del array de indices

      // se busca el elemento en la lista original y se quita de ella tambien
      const foundIndex = this.selectedItems.findIndex(
        (element) => element.value[this.identifierParam] === item.value[this.identifierParam]
      )

      // se elimina el elemento en el array de seleccionados
      this.selectedItems.splice(foundIndex, 1)
      // se elimina el indice del array de indices
    },
    sortNearbyItems (originalIndex) {
      // se ordenan los elementos al regresar
      let arrayFragment = []
      let cutStart = originalIndex - 5
      let cutEnd = originalIndex + 5
      if ((originalIndex - 5) <= 0) {
        cutStart = 0
      } else if ((originalIndex + 5) >= this.itemsPool.length) {
        cutEnd = this.itemsPool.length
      }
      // se corta un fragmento del array donde se inserto el item
      arrayFragment = this.itemsPool.slice(cutStart, cutEnd)

      // se ordena este fragmento del array
      arrayFragment = arrayFragment.sort((a, b) => a.index - b.index)

      // indice del array ordenado
      let fragmentIndex = 0
      // el for usa el indice en los elementos del array de selecciones
      for (let i = cutStart; i < cutEnd; i++) {
        // solo si el elemento existe
        if (arrayFragment[fragmentIndex]) {
          this.itemsPool[i] = arrayFragment[fragmentIndex]
        }
        fragmentIndex++
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.button-container {
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.list-container {
  width: calc(calc(100% - 100px) / 2);
}

@media screen and (max-width: 811px) {
  .list-container {
    width: 100%;
  }

  .button-container {
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .button1 {
      transform: rotate(90deg);
    }

    .button2 {
      transform: rotate(90deg);
    }
  }
}
</style>