export default {
  defaultObject () {
    return {
      id: undefined,
      name: '',
      kindId: null,
      plate: '',
      vin: '',
      primaryId: null,
      customFields: [],
      containerId: '',
      devicesIds: [],
      devices: [],
      sensors: []
    }
  }
}