<template>
  <!-- eslint-disable -->
  <div
    :id="`${trailerId}-id`"
    :key="`${trailerId}-key`"
    :class="`d-flex justify-center trailer__container ${wheelCssClasses}`"
    :style="`height: ${height};`"
  >
    <!-- <img
      ref="trailerImage"
      :src="$assets.vanguarderTrailer"
      style="width: 100%;"
    /> -->

    <!-- <TrailerSVG
      ref="trailerImage"
    >
    </TrailerSVG> -->
    <!-- {{ $assets.vanguarderTrailer }} -->
    <TrailerSVG
      :id="trailerId"
      :ref="refererence"
    ></TrailerSVG>
    <!-- TrailerSVG -->
  </div>
</template>

<script>
import TrailerSVG from './Trailer12SVG.vue'
// import TrailerSVG from '@/assets/vanguarder-trailer.svg'
// import TrailerSVG from '@/assets/vanguarder-trailer_inline.svg'

export default {
  components: {
    TrailerSVG
    // TrailerSVG
  },
  props: {
    height: {
      type: String,
      default: '35vh'
    },
    message: {
      type: Object,
      default: () => {}
    },
    trailerId: {
      type: String,
      default: () => 'trailer-svg'
    },
    refererence: {
      type: String,
      default: () => 'trailerImage'
    }
    // imageId: {
    //   type: String,
    //   default: ''
    // }
  },
  computed: {
    tyres () {
      return {
        NSRO: {
          number: '36'
        },
        NSMO: {
          number: '26'
        },
        NSFO: {
          number: '16'
        },
        NSRI: {
          number: '37'
        },
        NSMI: {
          number: '27'
        },
        NSFI: {
          number: '17'
        },
        OSRI: {
          number: '39'
        },
        OSMI: {
          number: '29'
        },
        OSFI: {
          number: '19'
        },
        OSRO: {
          number: '3a'
        },
        OSMO: {
          number: '2a'
        },
        OSFO: {
          number: '1a'
        }
      }
    },
    priorities () {
      return [
        0,
        1,
        2,
        3
      ]
    },
    wheelCssClasses () {
      let cssClasses = ''
      for (const key in this.tyres) {
        cssClasses = cssClasses.concat(` wheel_${key}--priority_${this.getTyrePriority(this.tyres[key])}`)
      }
      return cssClasses
    }
  },
  mounted () {
    const trailer = this.$refs.trailerImage.$el
    // on click events
    trailer.getElementById('wheel-NSR').onclick = () => {
      this.$emit('wheel-click', 'NSR')
    }
    trailer.getElementById('wheel-NSM').onclick = () => {
      this.$emit('wheel-click', 'NSM')
    }
    trailer.getElementById('wheel-NSF').onclick = () => {
      this.$emit('wheel-click', 'NSF')
    }
    trailer.getElementById('wheel-OSR').onclick = () => {
      this.$emit('wheel-click', 'OSR')
    }
    trailer.getElementById('wheel-OSM').onclick = () => {
      this.$emit('wheel-click', 'OSM')
    }
    trailer.getElementById('wheel-OSF').onclick = () => {
      this.$emit('wheel-click', 'OSF')
    }

    // on hover events
    trailer.getElementById('wheel-NSR').onmouseover = () => {
      this.$emit('wheel-hover', 'NSR')
    }
    trailer.getElementById('wheel-NSM').onmouseover = () => {
      this.$emit('wheel-hover', 'NSM')
    }
    trailer.getElementById('wheel-NSF').onmouseover = () => {
      this.$emit('wheel-hover', 'NSF')
    }
    trailer.getElementById('wheel-OSR').onmouseover = () => {
      this.$emit('wheel-hover', 'OSR')
    }
    trailer.getElementById('wheel-OSM').onmouseover = () => {
      this.$emit('wheel-hover', 'OSM')
    }
    trailer.getElementById('wheel-OSF').onmouseover = () => {
      this.$emit('wheel-hover', 'OSF')
    }

    // on mouseout events
    trailer.getElementById('wheel-NSR').onmouseout = () => {
      this.$emit('wheel-mouseout', 'NSR')
    }
    trailer.getElementById('wheel-NSM').onmouseout = () => {
      this.$emit('wheel-mouseout', 'NSM')
    }
    trailer.getElementById('wheel-NSF').onmouseout = () => {
      this.$emit('wheel-mouseout', 'NSF')
    }
    trailer.getElementById('wheel-OSR').onmouseout = () => {
      this.$emit('wheel-mouseout', 'OSR')
    }
    trailer.getElementById('wheel-OSM').onmouseout = () => {
      this.$emit('wheel-mouseout', 'OSM')
    }
    trailer.getElementById('wheel-OSF').onmouseout = () => {
      this.$emit('wheel-mouseout', 'OSF')
    }
  },
  methods: {
    // refTest () {
    //   console.log(this.$refs.trailerImage)
    // },
    getTemperature (tyre) {
      if (this.message?.tpmsParams) {
        const tpmsParams = this.message?.tpmsParams === null ? {} : this.message?.tpmsParams

        if (tpmsParams[`stpms${tyre.number}TyreTemp`]) {
          if (tpmsParams[`stpms${tyre.number}TyreTemp`] > 200) {
            return 'N/A'
          } else {
            return tpmsParams[`stpms${tyre.number}TyreTemp`]
          }
        }
      }
      return 'N/A'
    },
    getPressure (tyre) {
      if (this.message?.tpmsParams) {
        const tpmsParams = this.message?.tpmsParams === null ? {} : this.message?.tpmsParams
        if (tpmsParams[`stpms${tyre.number}TyrePres`]) {
          if ((tpmsParams[`stpms${tyre.number}TyrePres`] / 6.895).toFixed(2) > 200) {
            return 'N/A'
          } else {
            return (tpmsParams[`stpms${tyre.number}TyrePres`] / 6.895).toFixed(2)
          }
        }
      }
      return 'N/A'
    },
    getTyrePriority (tyre) {
      let priority = 0
      const temperature = this.getTemperature(tyre)
      // Validate temperature
      if (temperature !== 'N/A') {
        if (temperature <= 75) {
          priority = 1
        } else if (temperature > 75 && temperature < 85) {
          priority = 2
        } else if (temperature >= 85) {
          priority = 3
        }
      }
      const pressure = this.getPressure(tyre)

      // Validate pressure
      if (pressure !== 'N/A') {
        if (pressure >= 110.5) {
          if (priority < 1) {
            priority = 1
          }
        } else if (pressure > 101.4 && pressure < 110.5) {
          if (priority < 2) {
            priority = 2
          }
        } else if (pressure <= 101.4) {
          if (priority < 3) {
            priority = 3
          }
        }
      }
      return priority
    }
  }
}
</script>

<style lang="scss" scoped>

// $prioritiesColors: (
//   "priority_0": #C1C1C1,
//   "priority_1": #73B269,
//   "priority_2": #FBC782,
//   "priority_3": #C46868
// );

// $activeColors: (
//   "priority_0": #9E9E9E,
//   "priority_1": #4CAF50,
//   "priority_2": #FFA631,
//   "priority_3": #C62828
// );

// $wheels: "NSR", "NSM", "NSF", "OSR", "OSM", "OSF";

// .trailer__container{
//   @each $wheel in $wheels {
//     @each $priority, $color in $prioritiesColors {
//       &.wheel_#{$wheel}--#{$priority} {
//         #wheel-#{$wheel} {
//           cursor: pointer;
//           &> path {
//             fill: $color !important;
//           }
//           &:hover{
//             &> path {
//               fill: map-get($map: $activeColors, $key: $priority) !important;
//             }
//             g path{
//               fill: #000000 !important;
//             }
//           }
//         }
//       }
//     }
//   }
// }
</style>