export default {
  countries: [],
  measureUnits: [],

  // Telegram Links dialog
  telegramLinks: false,

  // License dialog
  licenses: false,

  // Loading indicator
  isLoading: true,
  isI18nLoading: false,
  /**
   * indicador de cuando ha cargado
   * TODA la informacion del backend
   * solo deberia modificarse una vez
   * al abrir la app por primera vez
  */
  dataHasLoaded: false,

  drawer: false,
  isDark: false,

  whitelabel: {},
  app: {},

  language: {},
  availableLanguages: [],

  width: {
    full: window.innerWidth,
    dialog: window.innerWidth * 0.8333333333 // 10 of 12 columns
  },

  google: null,
  tpmsEvents: [],
  changeTpmsEvents: []
}
