class HTMLMapMarker extends window.google.maps.OverlayView {
  /**
   * 
   * @param {google} window.google
   * @param {position} window.google.maps.LatLng
   * @param {map} window.google.map
   * @param {icon} String html
   */
  constructor ({ google, position, map, icon, course }) {
    super()
    this._map = map
    this._position = position
    this._google = google
    this._icon = icon
    this._html = null
    this._course = course

    this.setMap(map)
    this.width = 25
    this.height = 25
  }

  onAdd () {
    const div = document.createElement('div')
    // div.className = 'marker'
    div.style.position = 'absolute'
    div.style.cursor = 'pointer'
    div.style.width = `${this.width}px`
    div.style.height = `${this.height}px`
    div.style.background = 'transparent'
    div.style.display = 'block'
    div.style.textAlign = 'center'
    div.style.zIndex = 100
    div.style.transform = `rotate(${this._course}deg)`

    div.innerHTML = this._icon

    this._html = div

    // Add the element to the "overlayLayer" pane.
    const panes = this.getPanes()
    panes.overlayLayer.appendChild(div)
  }

  draw () {
    const overlayProjection = this.getProjection()
    let pos = { x: 0, y: 0 }
    try {
      pos = overlayProjection.fromLatLngToDivPixel(this._position)
    } catch (error) {
      console.warning('Unit Marker Error', error.toString())
    }

    const div = this._html

    if (div !== null && pos) {
      div.style.left = `${pos.x - (this.width / 2)}px`
      div.style.top = `${pos.y - (this.height / 2)}px`
    }
  }

  setPosition (position) {
    this._position = new this._google.maps.LatLng(
      position.latitude,
      position.longitude
    )

    this.draw()
  }

  onRemove () {
    this._html.parentNode.removeChild(this._html)
    this._html = null
  }
}

module.exports = HTMLMapMarker