function arrayToDict (array, key) {
  const result = {}
  for (let i = 0; i < array.length; i++) {
    result[array[i][key]] = array[i]
  }
  return result
}

/*
  Asset mutations
*/
export default {
  setList (state, payload) {
    state.list = payload
    state.dictList = Object.assign({}, arrayToDict(payload, 'id'))
  },

  newItem (state, payload) {
    state.list.push(payload)
    state.dictList[payload.id] = payload
  },

  updateItem (state, payload) {
    const list = [...state.list]
    for (let i = 0; i < list.length; i++) {
      if (list[i].id.toString() === payload.id.toString()) {
        list[i] = Object.assign({}, payload)
      }
    }

    state.list = []
    state.list = [...list]
    state.dictList = Object.assign({}, arrayToDict(list, 'id'))
  },

  deleteItem (state, id) {
    const list = state.list.filter(item => item.id.toString() !== id.toString())
    state.list = []
    state.list = [...list]
    state.dictList = Object.assign({}, arrayToDict(list, 'id'))
  },

  setObject (state, payload) {
    state.object = payload
  },

  setDetailsDialog (state, payload) {
    state.detailsDialog = payload
  },

  // eslint-disable-next-line no-unused-vars
  toggle (_, payload) {}
}
