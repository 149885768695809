
class UnitMarker extends window.google.maps.OverlayView {
  /**
   * 
   * @param {google} window.google
   * @param {position} window.google.maps.LatLng
   * @param {map} window.google.map
   * @param {args} Array
   */
  constructor ({ google, position, map, args, name }) {
    super()
    this._map = map
    this._position = position
    this._google = google
    this._name = name

    this._html = null

    this.args = args
    this.setMap(map)
    this.width = 80
    this.height = 24
  }

  onAdd () {
    /*
    <i aria-hidden="true" class="v-icon notranslate mdi mdi-car theme--light black--text"></i>
     */
    const icon = `
      <i
        aria-hidden="true"
        class="v-icon notranslate mdi mdi-truck theme--light primary--text"
        style="font-size: ${this.height}px; display: block;"
      ></i>
      <span class="v-chip v-chip--no-color theme--light v-size--small">
        <span class="v-chip__content">
          <strong>${this._name}</strong>
        </span>
      </span>
    `

    const div = document.createElement('div')
    // div.className = 'marker'
    div.style.position = 'absolute'
    div.style.cursor = 'pointer'
    div.style.width = `${this.width}px`
    div.style.height = `${this.height}px`
    div.style.background = 'transparent'
    div.style.display = 'block'
    div.style.textAlign = 'center'

    div.innerHTML = icon

    this._html = div

    // Add the element to the "overlayLayer" pane.
    const panes = this.getPanes()
    panes.overlayLayer.appendChild(div)
  }

  draw () {
    const overlayProjection = this.getProjection()
    let pos = { x: 0, y: 0 }
    try {
      pos = overlayProjection.fromLatLngToDivPixel(this._position)
    } catch (error) {
      console.warning('Unit Marker Error', error.toString())
    }

    const div = this._html

    if (div !== null && pos) {
      div.style.left = `${pos.x - (this.width / 2)}px`
      div.style.top = `${pos.y - (this.height / 2)}px`
    }
  }

  setPosition (position) {
    this._position = new this._google.maps.LatLng(
      position.latitude,
      position.longitude
    )

    this.draw()
  }

  onRemove () {
    this._html.parentNode.removeChild(this._html)
    this._html = null
  }
}

UnitMarker.prototype.getDraggable = function () { return false }
UnitMarker.prototype.getPosition = function () {
  return this._position
}

module.exports = UnitMarker