export default {
  defaultObject () {
    return {
      id: undefined,
      name: '',
      color: '#AABAFA',
      description: '',
      mode: 'RADIAL',
      category: 'DEPOT',
      subCategory: '',
      path: [],
      address: '',
      town: '',
      county: '',
      postcode: '',
      phone: '',
      email: ''
    }
  },
  modes () {
    return [
      'RADIAL',
      'POLYGON',
      'LINEAR'
    ]
  },
  categories () {
    return [
      'DEPOT',
      'CUSTOMER',
      'INTEREST',
      'SERVICE'
    ]
  },
  subCategories () {
    return [
      'TRAILER',
      'TRUCK / VAN',
      'FRIDGE',
      'TAIL LIFT'
    ]
  },
  depotGeofences (state) {
    return state.list.filter((geofence) => geofence.category === 'DEPOT')
  },
  customerGeofences (state) {
    return state.list.filter((geofence) => geofence.category === 'CUSTOMER')
  },
  interestGeofences (state) {
    return state.list.filter((geofence) => geofence.category === 'INTEREST')
  },
  serviceGeofences (state) {
    return state.list.filter((geofence) => geofence.category === 'SERVICE')
  },
  uncategorizedGeofences (state) {
    return state.list.filter((geofence) => geofence.category === 'UNCATEGORIZED')
  },

  // eslint-disable-next-line no-unused-vars
  userHomeDepot (state, getters, rootState) {
    const userHomeDepot = {}
    // const homeDepot = state.list.filter(())
    // if ()
    return userHomeDepot
  }
}