<template>
  <v-dialog
    :value="dialog"
    scrollable
    width="100%"
    max-width="500px"
    transition="dialog-transition"
    @click:outside="closeDialog"
  >
    <v-card width="100%">
      <v-card-title>
        Change event
      </v-card-title>

      <v-card-text class="pa-4 better-scrollbar">
        <v-row no-gutters>
          <v-col cols="12" class="pa-2">
            <template v-if="item.comments && item.comments.length === 0">
              <p>No comments yet</p>
            </template>
            <template v-for="(msg, i) in item.comments">
              <div
                :key="i"
                :class="{ 'd-flex flex-row-reverse': isMe(msg) }"
              >
                <p>{{ msg.username }}</p>
                <v-chip
                  :color="isMe(msg) ? 'primary' : ''"
                  dark
                  style="height:auto;white-space: normal;"
                  class="pa-4 mb-2"
                  v-on="on"
                >
                  {{ msg.comment }}
                  <sub
                    class="ml-2"
                    style="font-size: 0.5rem;"
                  >{{ formatDate(msg.createdAt) }}</sub>
                  <v-icon
                    v-if="hover"
                    small
                  >
                    expand_more
                  </v-icon>
                </v-chip>
              </div>
            </template>
          </v-col>
          <v-col cols="12" class="pa-2">
            <v-select
              v-model="eventItem.state"
              label="Status"
              :items="eventStatus"
              outlined
              hide-details
              chips
            />
          </v-col>
          <v-col cols="12" class="pa-2">
            <v-textarea
              v-model="eventItem.comment"
              label="Comment"
              placeholder="Comment"
              outlined
              hide-details
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="justify-space-between">
        <v-btn text @click="dialog = false">
          {{ $t('actions.discard') }}
        </v-btn>
        <v-btn color="green" text @click="openConfirmationDialog()">
          {{ $t('actions.send') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <gm-confirmation-dialog 
      v-model="confirmationDialog" 
      :event-type="eventType"
      :event-item="eventItem"
      :close-dialog-confirmation="closeDialog"
    />
  </v-dialog>
</template>

<script>
import dayjs from 'dayjs'
import { mapState } from 'vuex'
import ConfirmationDialog from './ConfirmationDialog.vue'
const advancedFormat = require('dayjs/plugin/advancedFormat')
const weekOfYear = require('dayjs/plugin/weekOfYear')
dayjs.extend(weekOfYear)
dayjs.extend(advancedFormat)
export default {
  components: {
    'gm-confirmation-dialog': ConfirmationDialog
  },
  model: {
    prop: 'showDialog',
    event: 'toggle-dialog'
  },
  props: {
    showDialog: {
      type: Boolean,
      default: () => false
    },
    isMobile: {
      type: Boolean,
      default: () => false
    },
    eventType: {
      type: String,
      default: () => ''
    },
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      confirmationDialog: false,
      eventItem: {}
    }
  },
  computed: {
    ...mapState(['width', 'isDark']),
    eventStatus () {
      return [
        { text: 'Pending', value: 'PENDING' },
        { text: 'Acknowledge', value: 'ACKNOWLEDGED' },
        { text: 'Resolved', value: 'CLOSED' }
      ]
    },
    dialog: {
      get () {
        return this.showDialog
      },

      set (value) {
        this.$emit('toggle-dialog', value)
      }
    }
  },
  watch: {
    showDialog (value) {
      if (value) {
        this.eventItem = this.item
      }
    }
  },
  created () {},
  methods: {
    generateNumber (min = 0, max = 100) {
      return Math.floor(Math.random() * (max - min + 1)) + min
    },
    closeDialog () {
      this.dialog = false
    },
    openConfirmationDialog () {
      this.confirmationDialog = true
    },
    isMe (comment) {
      return comment.userId === this.$store.state.session.id
    },
    formatDate (date) {
      if (date) {
        return dayjs(date * 1000).format('Do MMMM YYYY - hh:mm a')
      } else {
        return ''
      }
    }
  }
}
</script>
