<template>
  <div
    id="units_map"
    ref="map"
    class="grey"
    style="width: 100%;"
    :style="`height: ${height}px;`"
  />
</template>

<script>
import { mapState } from 'vuex'
import { inititalCoordinates } from '@/plugins/constants'

export default {
  props: {
    data: {
      type: Object,
      default: () => null
    },
    showMap: {
      type: Boolean,
      default: () => true
    },
    height: {
      type: Number || String,
      default: () => 400
    },
    mapTypeControl: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      map: null, // Google Maps Map entity
      google: null, // Google Maps object
      defaultCenter: null,

      marker: null
    }
  },
  computed: {
    ...mapState(['width', 'isLoading'])
  },

  watch: {
    showMap (newVal) {
      if (newVal) {
        this.loadMap()
      } else {
        // 
      }
    }
  },

  mounted () {
    if (this.showMap) {
      this.loadMap()
    }
  },

  methods: {
    loadMap () {
      if (!this.$googlemaps.done) {
        this.$googlemaps
          .load()
          .then((google) => {
            this.google = google
            // to use the label drawer "class" window.google must exist and the file is called to be require
            window.google = google
            this.initMap()
            // this.initDrawer()
            // this.startDrawing()
          })
      } else {
        this.google = window.google
        this.initMap()
        // this.initDrawer()
        // this.startDrawing()
      }
    },
    /**
     * Initialize Singleton instance of Google maps
     */
    initMap () {
      if (this.data !== null) {
        this.defaultCenter = new this.google.maps.LatLng(this.data.latitude, this.data.longitude)
      } else {
        this.defaultCenter = new this.google.maps.LatLng(inititalCoordinates.latitude, inititalCoordinates.longitude)
      }

      this.map = new this.google.maps.Map(
        this.$refs.map,
        {
          mapTypeId: 'roadmap',
          zoom: 14,
          center: this.defaultCenter,
          disableDefaultUI: true,
          zoomControl: true,
          mapTypeControl: this.mapTypeControl,
          mapTypeControlOptions: {
            position: this.google.maps.ControlPosition.RIGHT_TOP
          }
        }
      )

      setTimeout(() => {
        if (this.marker) {
          this.clearMarker(this.marker)
          this.marker = null
        }
        this.drawMarker()
      }, 0)
    },

    drawMarker () {
      if (this.data !== null) {
        const coordinates = new this.google.maps.LatLng(this.data.latitude, this.data.longitude)
        this.addMarker(coordinates)
      }
    },

    addMarker (coordinates) {
      return new this.google.maps.Marker({
        position: coordinates,
        map: this.map
      })
    },

    clearMarker (marker) {
      marker.setMap(null)
    }
  }
}
</script>

<style lang="scss">
</style>