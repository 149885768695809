import { render, staticRenderFns } from "./TrailerRender12.vue?vue&type=template&id=5bbe5601&scoped=true"
import script from "./TrailerRender12.vue?vue&type=script&lang=js"
export * from "./TrailerRender12.vue?vue&type=script&lang=js"
import style0 from "./TrailerRender12.vue?vue&type=style&index=0&id=5bbe5601&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bbe5601",
  null
  
)

export default component.exports