<template>
  <v-hover v-slot="{ hover }">
    <div style="position: relative; border-radius: 15px;">
      <div
        id="event_map"
        ref="map"
        class="grey"
        style="width: 100%;"
        :style="`height: ${height}px; border-radius: 15px;`"
      />
      <v-expand-transition>
        <div
          v-if="hover"
          class="transition-fast-in-fast-out v-card--reveal white--text location--container"
          style="height: 35%; width: 100%; z-index: 25; position: absolute; bottom: 0px; border-radius: 15px; padding: 15px; padding-right: 25px; padding-left: 25px;"
        >
          <v-row>
            <v-col cols="12" style="padding-bottom: 0px">
              <h2>{{ $t('units.map.location.label') }}</h2>
            </v-col>
            <v-col cols="12">
              <p style="margin: 0px" class="truncate">{{ address }}</p>
            </v-col>
            <v-col :cols="glanceView ? 5 : 3" style="display: flex; flex-direction: row; align-items: center; justify-content: flex-end;">
              <v-btn icon @click="copyToClipboard">
                <div style="display: flex; flex-direction: row; align-items: center;">
                  <p class="white--text" style="margin: 0px; margin-right: 5px; font-size: 16px">{{ $t('units.map.location.copyLocation') }}</p>
                  <v-icon color="white">mdi-content-copy</v-icon>
                </div>
              </v-btn>
            </v-col>
            <v-col :cols="glanceView ? 6 : 4" style="display: flex; flex-direction: row; align-items: center; justify-content: flex-end;">
              <v-btn icon @click="openOnGoogleMaps">
                <div style="display: flex; flex-direction: row; align-items: center;">
                  <p class="white--text" style="margin: 0px; margin-right: 5px; font-size: 16px">{{ $t('units.map.location.openGoogleMaps') }}</p>
                  <v-icon color="white">mdi-open-in-new</v-icon>
                </div>
              </v-btn>
            </v-col>
            <v-col v-if="!glanceView" cols="4" style="display: flex; flex-direction: row; align-items: center; justify-content: flex-end;">
              <v-menu offset-y top left attach>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <div style="display: flex; flex-direction: row; align-items: center;">
                      <p class="white--text" style="margin: 0px; margin-right: 5px; font-size: 16px">{{ $t('units.map.location.shareLocation') }}</p>
                      <v-icon color="white">mdi-share-variant-outline</v-icon>
                    </div>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="() => generateLocator(false)">
                    <v-list-item-title>{{ $t('units.map.location.basic')  }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="() => generateLocator(true)">
                    <v-list-item-title>{{ $t('units.map.location.advance')  }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </div>
      </v-expand-transition>
    </div>
  </v-hover>
</template>

<script>

import { mapState } from 'vuex'
import { inititalCoordinates, gisUrl } from '@/plugins/constants'
import generateLocator from 'GraphQL/mutations/units/generateLocator.gql'
import loadashGet from 'lodash/get'
import axios from 'axios'

export default {
  props: {
    data: {
      type: Object,
      default: () => null
    },
    unit: {
      type: Object,
      default: () => null
    },
    showMap: {
      type: Boolean,
      default: () => true
    },
    height: {
      type: Number,
      default: () => 400
    },
    mapTypeControl: {
      type: Boolean,
      default: () => true
    }
  },
  data () {
    return {
      map: null, // Google Maps Map entity
      google: null, // Google Maps object
      defaultCenter: null,
      polylines: [],
      marker: null,
      markers: [],
      copyAddress: null
    }
  },
  asyncComputed: {
    address: {
      get: function () {
        const positionObject = {}
        if (this.unit.telemetry?.position?.latitude) {
          positionObject.latitude = this.unit.telemetry.position.latitude
          positionObject.longitude = this.unit.telemetry.position.longitude
        } else {
          return 'the address was unable to retrieve'
        }
        const vueObject = this 
        // eslint-disable-next-line no-unreachable
        const url = `${gisUrl}/reverse?format=jsonv2&lat=${positionObject.latitude}&lon=${positionObject.longitude}`
        return axios.get(url)
          .then(function (response) {
            // handle success
            vueObject.copyAddress = response.data.display_name
            return response.data.display_name
          })
          .catch(function (error) {
            // handle error
            console.error(error)
            return 'the address was unable to retrieve'
          })
      },
      default: 'Loading...'
    }
  },
  computed: {
    ...mapState(['width', 'isLoading']),
    user () { return this.$store.state.session },
    glanceView () { 
      return this.$route.name === 'GlanceView'
    }
  },

  watch: {
    showMap (newVal) {
      if (newVal) {
        this.loadMap()
      } else {
        // 
      }
    },
    unit (newVal) {
      if (newVal) {
        this.loadMap()
      } else {
        // 
      }
    }
  },

  mounted () {
    if (this.showMap) {
      this.loadMap()
    }
  },

  methods: {
    loadashGet: loadashGet,
    loadMap () {
      if (!this.$googlemaps.done) {
        this.$googlemaps
          .load()
          .then((google) => {
            this.google = google
            // to use the label drawer "class" window.google must exist and the file is called to be require
            window.google = google
            this.initMap()
            // this.initDrawer()
            // this.startDrawing()
          })
      } else {
        this.google = window.google
        this.initMap()
        // this.initDrawer()
        // this.startDrawing()
      }
    },
    /**
     * Initialize Singleton instance of Google maps
     */
    initMap () {
      const unitCoordinates = this.getCoordinates()
      // console.log('unitCoordinates ', unitCoordinates)

      if (loadashGet(unitCoordinates, 'latitude') && loadashGet(unitCoordinates, 'longitude')) {
        this.defaultCenter = new this.google.maps.LatLng(unitCoordinates.latitude, unitCoordinates.longitude)
      } else {
        this.defaultCenter = new this.google.maps.LatLng(inititalCoordinates.latitude, inititalCoordinates.longitude)
      }

      this.map = new this.google.maps.Map(
        this.$refs.map,
        {
          mapTypeId: 'roadmap',
          zoom: 17,
          center: this.defaultCenter,
          disableDefaultUI: true,
          zoomControl: true,
          zoomControlOptions: {
            style: this.google.maps.ZoomControlStyle.SMALL,
            position: this.google.maps.ControlPosition.RIGHT
          },
          mapTypeControl: this.mapTypeControl,
          mapTypeControlOptions: {
            position: this.google.maps.ControlPosition.RIGHT_TOP
          }
        }
      )

      setTimeout(() => {
        if (this.marker) {
          this.clearMarker(this.marker)
          this.marker = null
        }
        this.drawMarker()
      }, 0)

      const self = this
      setTimeout(() => {
        self.google.maps.event.trigger(self.map, 'resize')
        self.map.setCenter(self.defaultCenter)
      }, 1000)
    },

    drawMarker () {
      const unitCoordinates = this.getCoordinates()
      if (loadashGet(unitCoordinates, 'latitude') && loadashGet(unitCoordinates, 'longitude')) {
        const coordinates = new this.google.maps.LatLng(
          loadashGet(unitCoordinates, 'latitude'),
          loadashGet(unitCoordinates, 'longitude')
        )
        this.marker = this.addMarker(coordinates)
      }
      this.google.maps.event.addListenerOnce(this.map, 'idle', () => {
        this.google.maps.event.trigger(this.map, 'resize')
        this.map.setCenter(this.defaultCenter)
      })
    },

    addMarker (coordinates) {
      return new this.google.maps.Marker({
        position: coordinates,
        map: this.map
      })
    },

    clearMarker (marker) {
      if (marker) {
        marker.setMap(null)
      }
    },

    clearMarkers () {
      for (const i in this.markers) {
        if (this.markers[i]) {
          this.markers[i].setMap(null)
        }
      }
      this.markers = []
    },
    copyToClipboard () {
      navigator.clipboard.writeText(this.copyAddress)
      this.$store.commit('toggleSnackbar', {
        message: 'The address has been copy to your clipboard',
        color: 'success'
      })
    },
    openOnGoogleMaps () {
      const unitCoordinates = this.getCoordinates()
      const url = `https://www.google.com/maps/?q=${unitCoordinates.latitude},${unitCoordinates.longitude}`
      window.open(url)
    },
    async generateLocator (advanceMode) {
      const mutation = generateLocator
      const name = 'generateLocator'
      const variables = {
        apiToken: this.user.apiToken,
        unitId: this.unit.id,
        advanceMode: advanceMode
      }
      this.$store.commit('toggleSnackbar', {
        message: 'Generating locator link',
        color: 'primary'
      })
      this.$apollo
        .mutate({
          mutation,
          variables,
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          const { status, errors, result } = response.data[name]
          const finalUrl = result.replace('{current_url}', window.location.host)

          switch (status) {
            case 'OK':
              // navigator.clipboard.writeText(finalUrl)
              this.copyLink(finalUrl, finalUrl)
              this.$store.commit('toggleSnackbar', {
                message: 'Your share link has been copy to your clipboard',
                color: 'success'
              })
              break

            case 'NOTFOUND':
              this.$store.commit('toggleSnackbar', {
                message: 'Theres no brake events for the the date selected',
                color: 'orange darken-2'
              })
              break
            case 'UNPROCESSABLE':
              this.$store.commit('toggleSnackbar', {
                message: errors.days,
                color: 'orange darken-2'
              })
              break

            case 'INTERNALERROR':
              this.$store.commit('toggleSnackbar')
              break
          }
        })
        .catch(_ => {
          console.error(_)
          this.$store.commit('toggleSnackbar')
        })
        .finally(() => {
        })
    },
    validateTelemetry () {
      return this.unit?.telemetry
    },
    getAddress () {
      if (this.unit?.telemetry) {
        return `${this.unit.telemetry.address}`
      }
      return 'Address Not available'
    },
    /**
     * Returns the coordinates of the unit
     * or the initial coordinates if the unit has no coordinates
     */
    getCoordinates () {
      if (loadashGet(this.unit, 'telemetry')) {
        // return this.unit.telemetry.position
        return loadashGet(this.unit, 'telemetry.position')
      }
      return {}
    },
    copyLink (url, text) {
      var a = document.createElement('a')
      a.setAttribute('href', url)
      a.appendChild(document.createTextNode(text))
      document.body.appendChild(a)
    
      var range = document.createRange()
      range.selectNode(a)
      window.getSelection().removeAllRanges()
      window.getSelection().addRange(range)
    
      document.execCommand('copy')
      document.body.removeChild(a)
    }
  }
}
</script>

<style lang="scss">
  .location--container {
    background: rgba(10, 10, 10, 0.6) 0% 0% no-repeat padding-box;
    opacity: 1;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  }

  .truncate {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>