<template>
  <v-dialog
    v-model="dialogValue"
    height="70vh"
  >
    <v-card class="pa-4">
      <v-card-title class="pa-0 d-flex justify-end">
        <v-btn
          icon
          @click="closeDialog"
        >
          <v-icon color="#C11A1A">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-row>
        <v-col cols="4">
          <v-row>
            <v-col cols="12">
              <div class="image__container">
                <img :src="truckImage()" width="50%" height="75px">
              </div>
            </v-col>
            <v-col cols="12">
              <p class="title font-weight-bold gm_black--text ma-0">
                {{ unit.name }}
              </p>
            </v-col>
            <v-col cols="12" class="pa-0">
              <v-row no-gutters class="d-flex justify-center align-center">
                <v-col
                  v-for="(item, index) in iconsList" :key="index"
                  cols="2"
                  class="d-flex justify-center align-center flex-column"
                >
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-icon
                        :color="item.color"
                        large
                        v-bind="attrs"
                        v-on="on"
                        @click="item.onClick"
                      >
                        {{ item.icon }}
                      </v-icon>
                      <p>
                        {{ item.value }}
                      </p>
                    </template>
                    <span>{{ item.label }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-list>
                <v-list-item two-line>
                  <v-list-item-content class="pa-0">
                    <v-list-item-title>
                      Last Check
                    </v-list-item-title>
                    <v-list-item-subtitle
                      class="d-flex justify-space-between align-center"
                    >
                      <div
                        class="d-flex align-center"
                      >
                        <v-icon color="green">
                          mdi-checkbox-marked-circle
                        </v-icon>
                        <p class="ma-0 pl-2">
                          {{ getAddress() }}
                        </p>
                      </div>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <div>
                        {{ getLastMessageDate() }}
                      </div>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12">
              <v-card v-if="getUnitPosition(unit)" class="pa-0">
                <units-map
                  style="height: 35vh;"
                  :data="getUnitPosition(unit)"
                  :show-map="dialogValue"
                />
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="8">
          <v-tabs
            v-model="tab"
          >
            <v-tab
              class="tabs__styles font-weight-medium"
            >
              Profile / Detail
            </v-tab>
            <v-tab
              class="tabs__styles font-weight-medium"
            >
              EPBM Diagnostic
            </v-tab>
            <v-tab
              class="tabs__styles font-weight-medium"
            >
              TRIPS
            </v-tab>
            <v-tab
              class="tabs__styles font-weight-medium"
            >
              Reports
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <!-- TRAILER PROFILE TAB -->
            <v-tab-item :key="1">
              <v-card flat class="ml-4 mr-4">
                <v-card-title
                  class="grey--text text--darken-2 font-weight-bold list_title headline pb-1 mb-2"
                >
                  {{ $t('units.vehicleProfile') }}
                </v-card-title>

                <v-row>
                  <!-- TRAILER PROFILE Values -->
                  <!-- Trailer profile switch values -->
                  <v-col cols="3">
                    <v-switch
                      v-model="trailerProfile.vor"
                      inset
                      label="VOR"
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-switch
                      v-model="trailerProfile.ebpmsActive"
                      inset
                      label="EBPMS"
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-switch
                      v-model="trailerProfile.tailLift"
                      inset
                      label="Tail Lift"
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-switch
                      v-model="trailerProfile.moffetMounts"
                      inset
                      label="Moffet Mounts"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-divider :key="`divider__1`" />
                  </v-col>
                  <!-- End Trailer profile switch values -->
                  <v-col cols="6">
                    <v-select
                      v-model="trailerProfile.trailerStyle"
                      :items="styles"
                      dense
                      label="Trailer Style"
                      outlined
                      item-text="label"
                      item-value="value"
                      prepend-inner-icon="mdi-truck-trailer"
                      hide-details="auto"
                      :error="errors.trailerStyle.length > 0"
                      :error-messages="errors.trailerStyle"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="trailerProfile.vin"
                      label="Trailer VIN"
                      outlined
                      dense
                      prepend-inner-icon="mdi-tag"
                      hide-details="auto"
                      :error="errors.vin.length > 0"
                      :error-messages="errors.vin"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="trailerProfile.height"
                      label="Trailer Height"
                      outlined
                      dense
                      prepend-inner-icon="mdi-ruler"
                      hide-details="auto"
                      suffix="mts"
                      type="number"
                      min="0"
                      :error="errors.height.length > 0"
                      :error-messages="errors.height"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="trailerProfile.weight"
                      label="Trailer Weight"
                      outlined
                      dense
                      prepend-inner-icon="mdi-weight"
                      hide-details="auto"
                      suffix="kg"
                      type="number"
                      min="0"
                      :error="errors.weight.length > 0"
                      :error-messages="errors.weight"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="trailerProfile.plannedMonthlyMileage"
                      label="Planned Monthly Mileage"
                      outlined
                      dense
                      prepend-inner-icon="mdi-gauge"
                      hide-details="auto"
                      suffix="km"
                      type="number"
                      min="0"
                      :error="errors.plannedMonthlyMileage.length > 0"
                      :error-messages="errors.plannedMonthlyMileage"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      v-model="trailerProfile.ownerDepotId"
                      :items="depotGeofences"
                      dense
                      label="Owner Depot"
                      outlined
                      item-text="name"
                      item-value="id"
                      prepend-inner-icon="mdi-map-marker"
                      hide-details="auto"
                      :error="errors.ownerDepotId.length > 0"
                      :error-messages="errors.ownerDepotId"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-dialog
                      ref="dialog"
                      v-model="modal"
                      :return-value.sync="trailerProfile.lastService"
                      persistent
                      width="290px"
                    >
                      <template #activator="{ on, attrs }">
                        <v-text-field
                          v-model="trailerProfile.lastService"
                          label="Last Service Date"
                          prepend-inner-icon="mdi-calendar"
                          outlined
                          dense
                          readonly
                          hide-details="auto"
                          v-bind="attrs"
                          :error="errors.lastService.length > 0"
                          :error-messages="errors.lastService"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="trailerProfile.lastService"
                        scrollable
                      >
                        <v-spacer />
                        <v-btn
                          text
                          color="primary"
                          @click="modal = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dialog.save(trailerProfile.lastService)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="trailerProfile.serviceInterval"
                      label="Service Interval"
                      outlined
                      dense
                      prepend-inner-icon="mdi-sort-calendar-ascending"
                      hide-details="auto"
                      suffix="days"
                      :error="errors.serviceInterval.length > 0"
                      :error-messages="errors.serviceInterval"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      v-model="trailerProfile.group"
                      :items="groups"
                      dense
                      label="Group"
                      outlined
                      item-text="label"
                      item-value="value"
                      prepend-inner-icon="mdi-label"
                      hide-details="auto"
                      :error="errors.group.length > 0"
                      :error-messages="errors.group"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      v-model="trailerProfile.vehicleType"
                      :items="vehiclesTypes"
                      dense
                      label="Vehicle Type"
                      outlined
                      item-text="label"
                      item-value="value"
                      prepend-inner-icon="mdi-car-back"
                      hide-details="auto"
                      :error="errors.vehicleType.length > 0"
                      :error-messages="errors.vehicleType"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      v-model="trailerProfile.vehicleMake"
                      :items="vehiclesMakes"
                      dense
                      label="Vehicle Make"
                      outlined
                      item-text="label"
                      item-value="value"
                      prepend-inner-icon="mdi-car-arrow-right"
                      hide-details="auto"
                      :error="errors.vehicleMake.length > 0"
                      :error-messages="errors.vehicleMake"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      v-model="trailerProfile.ebsValveType"
                      :items="ebsValveTypes"
                      dense
                      label="Ebs Valve Type"
                      outlined
                      item-text="label"
                      item-value="value"
                      prepend-inner-icon="mdi-label"
                      hide-details="auto"
                      :error="errors.ebsValveType.length > 0"
                      :error-messages="errors.ebsValveType"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="trailerProfile.operator"
                      label="Operator"
                      outlined
                      dense
                      prepend-inner-icon="mdi-tag"
                      hide-details="auto"
                      :error="errors.operator.length > 0"
                      :error-messages="errors.operator"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="trailerProfile.vehicleGrossWeight"
                      label="Gross Vehicle Weight"
                      outlined
                      dense
                      prepend-inner-icon="mdi-weight"
                      hide-details="auto"
                      suffix="kg"
                      type="number"
                      min="0"
                      :error="errors.vehicleGrossWeight.length > 0"
                      :error-messages="errors.vehicleGrossWeight"
                    />
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
            <!-- EBPM DIAGNOSTIC TAB -->
            <v-tab-item :key="2">
              <v-card v-if="!listView" flat>
                <v-row class="mt-4">
                  <v-col cols="8">
                    <v-select
                      v-model="selectedType"
                      :items="types"
                      dense
                      label="Select Type of chart"
                      outlined
                      item-text="label"
                      item-value="value"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-select
                      v-model="selectedTime"
                      :items="timeRanges"
                      dense
                      label="Time Range"
                      outlined
                      item-text="label"
                      item-value="value"
                    />
                  </v-col>
                  <v-col cols="6">
                    <div class="text__container">
                      <v-icon small color="success" class="mr-2">
                        mdi-check
                      </v-icon>  
                      <div class="mr-4">
                        {{ $t('unitsDialog.brakePerformanceRate.label') }}: 
                      </div>
                      <div class="green__text">
                        {{ getBrakePerfomanceRate() }}
                      </div>
                    </div>
                    <div class="text__container">
                      <v-icon small color="success" class="mr-2">
                        mdi-clock-outline
                      </v-icon>  
                      <div class="mr-4">
                        {{ $t('unitsDialog.brakePerformanceRate.calculated') }}: 
                      </div>
                      <div class="green__text">
                        {{ getBrakePerfomanceCalculatedAt() }}
                      </div>
                    </div>
                    <!-- <div class="text__container">
                      <v-icon small color="warning" class="mr-2">
                        mdi-clock-outline
                      </v-icon>  
                      <div class="mr-4">
                        {{ $t('unitsDialog.brakePerformanceRate.criticalDay') }}: 
                      </div>
                      <div class="green__text">
                        {{ getEstimatedBrakePerformanceRateCriticalDay() }}
                      </div>
                    </div> -->
                  </v-col>
                  <v-col cols="6">
                    <v-row v-if="unit.brakePerformanceRate" class="snackbar__container" :class="brakePerformanceRatePassed() ? 'warning__snackbar__container' : ''" no-gutters>
                      <v-col cols="4" class="d-flex justify-center flex-column align-center snackbar__title">
                        {{ brakePerformanceRatePassed() ? $t('unitsDialog.brakePerformanceRate.warning') : $t('unitsDialog.brakePerformanceRate.congratulations') }}
                      </v-col>
                      <v-col cols="8" class="d-flex justify-center flex-column align-center snackbar__subtitle">
                        {{ brakePerformanceRatePassed() ? $t('unitsDialog.brakePerformanceRate.below', {name: unit.name}) : $t('unitsDialog.brakePerformanceRate.above', {name: unit.name}) }}
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <!-- Brake Events chart -->
                    <div v-if="selectedType === 'brakeEvent'" class="mx-4" style="max-height: 80%">
                      <apexchart
                        width="100%"
                        type="line"
                        :options="chartOptions"
                        :series="series"
                      />
                    </div>
                    <div v-if="selectedType === 'brakePerformanceTrend'" class="mx-4" style="max-height: 80%">
                      <apexchart
                        width="100%"
                        type="line"
                        :options="trendChartOptions"
                        :series="trendSeries"
                      />
                    </div>
                  </v-col>
                  <v-col cols="12" style="display: flex; justify-content: flex-end;">
                    <a class="link__text" @click="changePage">
                      See all the EBPMS Events
                    </a>
                  </v-col>
                </v-row>
              </v-card>
              <v-card v-else flat>
                <v-card-title
                  class="grey--text text--darken-2 font-weight-bold list_title headline pb-1 mb-2"
                >
                  <v-btn
                    icon
                    class="mr-2"
                    @click="changePage"
                  >
                    <v-icon>mdi-arrow-left</v-icon>
                  </v-btn>
                  Go Back
                </v-card-title>
                <div
                  class="scrollbar__seamless pa-1"
                  style="height: 60vh;"
                >
                  <v-row style="margin: 0px">
                    <v-col cols="8">
                      <v-text-field
                        label="Search Unit"
                        outlined
                        dense
                        prepend-inner-icon="mdi-magnify"
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        v-model="selectedTime"
                        :items="timeRanges"
                        dense
                        label="Time Range"
                        outlined
                        item-text="label"
                        item-value="value"
                      />
                    </v-col>
                  </v-row>
                  <v-list
                    class="scrollbar__seamless"
                    style="height: 100%; overflow: auto;"
                  >
                    <template
                      v-for="(item, index) in unit.brakePerformanceRates"
                    >
                      <v-divider :key="`divider__${index}`" />
                      <v-list-item
                        :key="index"
                      >
                        <v-list-item-content>
                          <v-list-item-title
                            class="d-flex align-center justify-space-between"
                          >
                            <h5 class="gm_black--text">
                              EBPMS: {{ item.rate.toFixed(2) }}%
                            </h5>
                            <p
                              class="pb-4 mb-0 blue--text text--darken-4"
                              style="font-size: 14px;"
                            >
                              {{ parseDate(item.calculatedAt) }}
                            </p>
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ item.subtitle }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-list>
                </div>
              </v-card>
            </v-tab-item>
            <!-- trips Tab -->
            <v-tab-item :key="3">
              <v-card flat class="ml-4 mr-4">
                <v-card-title
                  class="grey--text text--darken-2 font-weight-bold list_title headline pb-1 mb-2"
                >
                  Trips
                </v-card-title>
                <v-row>
                  <!-- SELECT REPORT TYPE AND DATETIME -->
                  <v-col cols="5">
                    <v-datetime-picker
                      v-model="initialTimestamp"
                      label="Initial Date"
                      hide-details="auto"
                      dense
                      outlined
                      prepend-inner-icon="mdi-calendar"
                      :datePickerProps="initialTimestampProps"
                    />
                  </v-col>
                  <v-col cols="5">
                    <v-datetime-picker
                      v-model="finalTimestamp"
                      hide-details="auto"
                      dense
                      outlined
                      label="Final Date"
                      prepend-inner-icon="mdi-calendar"
                      :datePickerProps="finalTimestampProps"
                    />
                  </v-col>
                  <v-col cols="1" class="d-flex justify-center">
                    <v-btn
                      class="mt-1"
                      fab
                      dark
                      small
                      color="primary"
                      @click="getTrips"
                    >
                      <v-icon dark>
                        mdi-play
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="1" class="d-flex justify-center">
                    <v-btn
                      class="mt-1"
                      fab
                      dark
                      small
                      color="primary"
                      @click="getTrips"
                    >
                      <v-icon dark>
                        mdi-menu
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12">
                    <v-card flat>
                      <trips-map
                        :height="540"
                        :drawTrips="drawTrip"
                        :trips="trips"
                      />
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
            <!-- Reports Tab -->
            <v-tab-item :key="4">
              <v-card flat class="ml-4 mr-4">
                <v-card-title
                  class="grey--text text--darken-2 font-weight-bold list_title headline pb-1 mb-2"
                >
                  Reports
                </v-card-title>

                <v-row>
                  <!-- SELECT REPORT TYPE AND DATETIME -->
                  <v-col cols="6">
                    <v-select
                      v-model="reportType"
                      :items="reportTypes"
                      
                      label="Report Type"
                      outlined
                      item-text="label"
                      item-value="value"
                      prepend-inner-icon="mdi-file"
                      hide-details="auto"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-dialog
                      ref="dialog"
                      v-model="dateModal"
                      :return-value.sync="reportTimestamp"
                      persistent
                      width="290px"
                    >
                      <template #activator="{ on, attrs }">
                        <v-text-field
                          v-model="reportTimestamp"
                          label="Picker in dialog"
                          prepend-icon="mdi-calendar"
                          readonly
                          hide-details="auto"
                          outlined
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="reportTimestamp"
                        scrollable
                      >
                        <v-spacer />
                        <v-btn
                          text
                          color="primary"
                          @click="dateModal = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dialog.save(reportTimestamp)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
      <v-card-actions v-if="tab === 0" class="card__actions">
        <v-spacer />
        <v-btn
          depressed
          color="success"
          @click="saveTrailerProfile"
        >
          save
        </v-btn>
      </v-card-actions>
      <v-card-actions v-if="tab === 3" class="card__actions">
        <v-spacer />
        <v-btn
          depressed
          color="success"
          :disabled="reportTimestamp === ''"
          @click="generateReport"
        >
          Generate Report
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { i18n } from '@/plugins/i18n'
import { apollo } from '@/plugins/apollo'
import editTrailer from 'GraphQL/mutations/trailerProfiles/edit.gql'
import brakePerformanceRateReport from 'GraphQL/mutations/reports/brakePerformanceRateReport.gql'
import historicTrips from 'GraphQL/queries/messages/historicTrips.gql'
import { mapState } from 'vuex'
import { orm } from '@/mixins/'
import dayjs from 'dayjs'
import generateLocator from 'GraphQL/mutations/units/generateLocator.gql'

import UnitsMap from './UnitsMap.vue'
import EventsMap from './EventsMap.vue'
const utc = require('dayjs/plugin/utc')
export default {
  components: {
    'units-map': UnitsMap,
    'trips-map': EventsMap
  },
  mixins: [orm],
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    unit: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      dateModal: false,
      defaultObject: {
        trailerStyle: '',
        vin: '',
        height: '',
        weight: '',
        plannedMonthlyMileage: '',
        ownerDepotId: '',
        lastService: '',
        serviceInterval: '',
        ebsValveType: '',
        group: '',
        vehicleType: '',
        vehicleMake: '',
        operator: '',
        vehicleGrossWeight: '',
        vor: true,
        ebpmsActive: true,
        tailLift: true,
        moffetMounts: true
      },
      retrievedTrip: false,
      reportType: 'ebpms',
      reportTimestamp: '',
      // trips variables
      drawTrip: false,
      trips: [],
      initialTimestamp: new Date(),
      finalTimestamp: new Date().setUTCHours(23, 59, 59, 999),
      // \trips variables
      listView: false,
      errors: {},
      modal: false,
      tab: 0,
      trailerProfile: {},
      selectedType: 'brakeEvent',
      selectedTime: '1',
      chartOptions: {
        chart: {
          id: 'vuechart'
        },
        markers: {
          size: 5
        },
        xaxis: {
          type: 'numeric',
          title: {
            text: 'Demand pressure - bar',
            offsetX: 0,
            offsetY: 0
          }
        },
        yaxis: {
          type: 'numeric',
          title: {
            text: 'Deceleration - % of g',
            offsetX: 0,
            offsetY: 0
          }
        },
        stroke: {
          width: 3
        },
        colors: ['#db1233', '#4287f5', '#FDD835', '#4ad138']
      },
      series: [
        {
          name: 'Lower limit',
          type: 'line',
          data: [[1, 0], [4.5, 29], [7.5, 45]]
        },
        {
          name: 'Points',
          type: 'scatter',
          data: []
        },
        {
          name: 'Upper Limit',
          type: 'line',
          data: [[0.2, 0], [4.5, 41], [7.5, 65]]
        },
        {
          name: 'Forecast Line',
          type: 'line',
          data: []
        }
      ],
      brakeEventParameters: {
        avgX: 0,
        avgY: 0,
        const: 0
      },
      trendChartOptions: {
        chart: {
          id: 'vuechart'
        },
        markers: {
          size: [5, 0, 0, 0]
        },
        tooltip: {
          enabled: true,
          enabledOnSeries: [0]
        },
        xaxis: {
          type: 'datetime',
          labels: {
            format: 'dd/MM'
          }
        },
        yaxis: {
          type: 'numeric',
          title: {
            text: 'Brake Performance',
            offsetX: 0,
            offsetY: 0
          }
        },
        stroke: {
          width: 3,
          dashArray: [0, 4, 0, 3]
        },
        colors: ['#4287f5', '#4ad138', '#db1233', '#FDD835']
      },
      trendSeries: [
        {
          name: 'Brake Performance Value',
          type: 'scatter',
          data: []
        },
        {
          name: 'Linear (Optimum Performance)',
          type: 'line',
          data: []
        },
        {
          name: 'Linear (Min Performance)',
          type: 'line',
          data: []
        },
        {
          name: 'Linear (Warning Zone)',
          type: 'line',
          data: []
        },
        {
          name: 'Forecast Line',
          type: 'line',
          data: []
        }
      ]
    }
  },
  computed: {
    ...mapState(['isDark']),
    dtcCodes () { return this.$store.state.dtcs.codeDictList },
    depotGeofences () { return this.$store.state.geofences.list.filter((geofence) => geofence.category === 'DEPOT') },
    user () { return this.$store.state.session },
    dialogValue: {
      get: function () {
        return this.dialog
      },
      set: function (value) {
        this.$emit('dialog-change', value)
      }
    },
    types () {
      return [
        {
          label: 'Brake Events',
          value: 'brakeEvent'
        }
        // {
        //   label: 'Brake Perfomance Value Trend line',
        //   value: 'brakePerformanceTrend'
        // }
      ]
    },
    reportTypes () {
      return [
        {
          label: 'EBPMS Report',
          value: 'ebpms'
        }
      ]
    },
    timeRanges () {
      return [
        {
          label: 'Today',
          value: '1'
        },
        {
          label: 'Week',
          value: '7'
        },
        {
          label: 'Month',
          value: '30'
        }
      ]
    },
    styles () {
      return [
        {
          label: 'Flat',
          value: 'flat'
        },
        {
          label: 'Curtainsider',
          value: 'curtainsider'
        },
        {
          label: 'Box Trailer',
          value: 'box trailer'
        },
        {
          label: 'Skeletal',
          value: 'skeletal'
        },
        {
          label: 'Fridge',
          value: 'fridge'
        },
        {
          label: 'Tipper',
          value: 'tipper'
        },
        {
          label: 'Low Loader',
          value: 'low loader'
        },
        {
          label: 'Euroliner',
          value: 'euroliner'
        },
        {
          label: 'Transporter',
          value: 'transporter'
        },
        {
          label: 'Double Decker',
          value: 'double decker'
        },
        {
          label: 'Walking Floor',
          value: 'walking floor'
        }
      ]
    },
    groups () {
      return [
        {
          label: 'Tri-Axle Curtainsinder',
          value: 'tri-axle curtainsinder'
        }
      ]
    },
    vehiclesTypes () {
      return [
        {
          label: 'Single-Axle',
          value: 'single-axle'
        },
        {
          label: 'Twin-Axle',
          value: 'twin-axle'
        },
        {
          label: 'Tri-Axle',
          value: 'tri-axle'
        },
        {
          label: 'quad-Axle',
          value: 'quad-axle'
        }
      ]
    },
    vehiclesMakes () {
      return [
        {
          label: 'SDC trailers',
          value: 'SDC trailers'
        },
        {
          label: 'Krone',
          value: 'Krone'
        },
        {
          label: 'Schmitz',
          value: 'Schmitz'
        },
        {
          label: 'Gray Adams',
          value: 'Gray Adams'
        },
        {
          label: 'Montracon',
          value: 'Montracon'
        },
        {
          label: 'Don Bur',
          value: 'Don Bur'
        },
        {
          label: 'Dennison',
          value: 'Dennison'
        },
        {
          label: 'Tiger',
          value: 'Tiger'
        }
      ]
    },
    ebsValveTypes () {
      return [
        {
          label: 'HALDEX',
          value: 'haldex'
        },
        {
          label: 'Knorr',
          value: 'knorr'
        },
        {
          label: 'Bremse',
          value: 'bremse'
        },
        {
          label: 'Wabco',
          value: 'wabco'
        }
      ]
    },
    dataList () {
      return Array(10).fill({
        title: 'Lorem ipsum',
        subtitle: 'Sunt tempor ullamco pariatur reprehenderit commodo mollit.',
        date: Date.now()
      })
    },
    iconsList () {
      return [
        {
          color: 'light-blue',
          icon: 'mdi-share-variant-outline',
          value: 'Share Location',
          label: 'Share',
          onClick: () => {
            this.generateLocator()
          }
        },
        {
          color: 'green darken-2',
          icon: 'mdi-weight',
          value: this.getWeight(),
          label: 'Weight',
          onClick: () => {}
        },
        {
          color: 'grey',
          icon: 'mdi-email-alert',
          value: 0,
          label: 'Alerts counts',
          onClick: () => {}

        },
        {
          color: 'blue',
          icon: 'mdi-car-brake-alert',
          value: this.getBrakePerfomanceRate(),
          label: 'EBPMS',
          onClick: () => {}
        },
        {
          color: 'grey lighten-1',
          icon: 'mdi-hazard-lights',
          value: this.getDtcCode(),
          label: 'DTC code',
          onClick: () => {}
        }
      ]
    },
    initialTimestampProps () {
      return {
        max: new Date().toISOString()
      }
    },
    finalTimestampProps () {
      return {
        min: new Date(this.initialTimestamp).toISOString(),
        max: new Date().toISOString()
      }
    }
  },
  watch: {
    unit (_) {
      if (this.unit) {
        this.series[1].data = this.unit.events.map((event) => [event.meanDemand.toFixed(2), event.meanDecel.toFixed(2)])
        // Calculate forecast line
        this.brakeEventParameters.avgX = this.getAverage(this.unit.events, 'meanDemand')
        this.brakeEventParameters.avgY = this.getAverage(this.unit.events, 'meanDecel')
        this.brakeEventParameters.const = this.brakeEventParameters.avgY - (8.29 * this.brakeEventParameters.avgX)
        this.series[3].data = [[1, this.calculateForecastPoint(1)], [4.5, this.calculateForecastPoint(4.5)], [7.5, this.calculateForecastPoint(7.5)]]
        this.trendSeries[0].data = this.unit.brakePerformanceRates.map((bpr) => { return { x: bpr.calculatedAt * 1000, y: bpr.rate.toFixed(2) } })
        if (this.unit.brakePerformanceRates.length > 0) {
          let calculatedDate = this.getEstimatedBrakePerformanceRateCriticalDayTimestamp() * 1000
          calculatedDate = calculatedDate < this.unit.brakePerformanceRates[this.unit.brakePerformanceRates.length - 1].calculatedAt * 1000 + (86400 * 2 * 1000) ? this.unit.brakePerformanceRates[this.unit.brakePerformanceRates.length - 1].calculatedAt * 1000 + (86400 * 2 * 1000) : calculatedDate
          this.trendSeries[1].data = [
            {
              x: this.unit.brakePerformanceRates[0].calculatedAt * 1000,
              y: 51
            },
            // Using two dates more for the line
            {
              x: calculatedDate,
              y: 51
            }
          ]
          this.trendSeries[2].data = [
            {
              x: this.unit.brakePerformanceRates[0].calculatedAt * 1000,
              y: 45
            },
            // Using two dates more for the line
            {
              x: calculatedDate,
              y: 45
            }
          ]
          this.trendSeries[3].data = [
            {
              x: this.unit.brakePerformanceRates[0].calculatedAt * 1000,
              y: 46
            },
            // Using two dates more for the line
            {
              x: calculatedDate,
              y: 46
            }
          ]
          this.trendSeries[4].data = [
            {
              x: this.unit.brakePerformanceRates[0].calculatedAt * 1000,
              y: this.unit.brakePerformanceRates[0].rate.toFixed(2)
            },
            {
              x: this.getEstimatedBrakePerformanceRateCriticalDayTimestamp() * 1000,
              y: 45
            }
          ]
        }
        this.trailerProfile = Object.assign({}, this.unit.trailerProfile)
      }
    },
    dialogValue (_) {
      this.initTimestamp = new Date()
      this.finalTimestamp = new Date()
      this.finalTimestamp.setHours(23, 59, 59, 999)
      this.resetErrors()
    },
    tab (_) {
      this.reportTimestamp = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
    }
  },
  beforeDestroy () {
    this.resetErrors()
  },
  created () {
    this.resetErrors()
  },
  methods: {
    getAddress () {
      if (this.unit.telemetry) {
        return this.unit.telemetry.address
      }
      return 'Address Not available'
    },
    getAverage (array, key) {
      const sum = array.reduce((a, b) => parseFloat(a) + parseFloat(b[key]), 0)
      return (sum / array.length) || 0
    },
    calculateForecastPoint (x) {
      return (x * 8.29 + this.brakeEventParameters.const).toFixed(2)
    },
    brakePerformanceRatePassed () {
      return this.unit.brakePerformanceRate.rate < 45
    },
    changePage () {
      this.listView = !this.listView
    },
    getUnitPosition (unit) {
      if (unit.telemetry) {
        return unit.telemetry.position
      }
      return null
    },
    truckImage () {
      return this.$assets.trucks[2]
    },
    closeDialog () {
      this.tab = 0
      this.dialogValue = false
    },
    formatHour (date) {
      if (date) {
        return dayjs(date).format('hh:mm a')
      } else {
        return ''
      }
    },
    getTimeAgo (time) {
      const difference = dayjs().unix() - time
      let text = ''
      if (difference < 3600) {
        const minutes = Math.floor(difference / 60)
        if (minutes === 1) {
          text = 'A minute ago'
        } else {
          text = `${minutes} minutes ago`
        }
      } else if (difference < 86400) {
        const hours = Math.floor(difference / 3600)
        if (hours === 1) {
          text = 'A hour ago'
        } else {
          text = `${hours} hours ago`
        }
      } else {
        const days = Math.floor(difference / 86400)
        if (days === 1) {
          text = 'A day ago'
        } else {
          text = `${days} days ago`
        }
      }
      return text
    },
    getPlate () {
      return this.unit.plate === '' ? 'Plate not configured' : this.unit.plate
    },
    getSpeed () {
      if (this.unit.telemetry) {
        return `${this.unit.telemetry.position.speed} km/h`
      } else {
        return 'Not available'
      }
    },
    getWeight () {
      if (this.unit.telemetry) {
        return `${this.unit.telemetry.axeLoadSum}kg`
      } else {
        return 'Not Available'
      }
    },
    getBrakePerfomanceRate () {
      if (this.unit.brakePerformanceRate) {
        return `${Math.round(this.unit.brakePerformanceRate.rate)}%`
      } else {
        return 'Not Available'
      }
    },
    getBrakePerfomanceCalculatedAt () {
      if (this.unit.brakePerformanceRate) {
        return this.getTimeAgo(this.unit.brakePerformanceRate.calculatedAt)
      } else {
        return 'Not Available'
      }
    },
    getEstimatedBrakePerformanceRateCritical () {
      const brakePerformanceSlope = this.unit.brakePerformanceSlope
      if (this.unit.brakePerformanceSlope) {
        return (45 - brakePerformanceSlope.intercept) / brakePerformanceSlope.slope
      }
      return null
    },
    getEstimatedBrakePerformanceRateCriticalDayTimestamp () {
      const days = this.getEstimatedBrakePerformanceRateCritical()
      if (this.unit.brakePerformanceRates.length > 0) {
        return this.unit.brakePerformanceRates[0].calculatedAt + (Math.abs(days) * 86400)
      }
      return null
    },
    getEstimatedBrakePerformanceRateCriticalDay () {
      const date = this.getEstimatedBrakePerformanceRateCriticalDayTimestamp()
      if (date) {
        return this.parseDate(date)
      }
      return 'Not available'
    },
    getMeanDemand () {
      if (this.unit.lastBrakeEvent) {
        return `${this.unit.lastBrakeEvent.meanDemand.toFixed(2)} bar`
      } else {
        return 'Not Available'
      }
    },
    getDtcCode () {
      if (this.unit.dtcEvent) {
        // Get the DTC Code from the list
        // console.log(this.unit.dtcEvent)
        // console.log(this.dtcCodes)
        // console.log(this.dtcCodes[this.unit.dtcEvent.dtcCodeId])
        const dtcCode = this.dtcCodes[this.unit.dtcEvent.dtcCodeId]
        return dtcCode.shortName
      } else {
        return '--'
      }
    },

    getLastMessageDate () {
      // console.log(this.unit)
      if (this.unit.telemetry) {
        return this.parseDateClean(this.unit.telemetry.receivedAt)
      } else {
        return 'Not Available'
      }
    },

    parseDateClean (date) {
      dayjs.extend(utc)
      if (date === null) {
        return dayjs()
      } else {
        return dayjs(date * 1000).format('Do MMM YYYY - hh:mm a')
      }
    },

    parseDate (date) {
      dayjs.extend(utc)
      if (date === null) {
        return dayjs()
      } else {
        return dayjs(date * 1000).format('DD/MM/YYYY HH:mm')
      }
    },

    convertDateToInt (date) {
      dayjs.extend(utc)
      return dayjs(date).unix()
    },

    async generateReport () {
      const mutation = brakePerformanceRateReport
      const name = 'brakePerformanceRateReport'
      const timestamp = new Date(this.reportTimestamp)
      const variables = {
        apiToken: this.user.apiToken,
        unitId: this.unit.id,
        timestamp: timestamp / 1000
      }

      this.$apollo
        .mutate({
          mutation,
          variables,
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          const { status, errors, result } = response.data[name]

          switch (status) {
            case 'OK':
              // console.log('Report result :>> ', `/public/${result.url}`)
              window.open(process.env.NODE_ENV === 'development' ? `http://localhost:8000/public/${result.url}` : `https://integrate.vanguarder.goldenm.dev/static/${result.url}`, '_blank')

              this.$store.commit('toggleSnackbar', {
                message: this.$i18n.t('reports.success.reports'),
                color: 'success'
              })
              break

            case 'NOTFOUND':
              this.$store.commit('toggleSnackbar', {
                message: 'Theres no brake events for the the date selected',
                color: 'orange darken-2'
              })
              break
            case 'UNPROCESSABLE':
              this.$store.commit('toggleSnackbar', {
                message: errors.days,
                color: 'orange darken-2'
              })
              break

            case 'INTERNALERROR':
              this.$store.commit('toggleSnackbar')
              break
          }
        })
        .catch(_ => {
          this.$store.commit('toggleSnackbar')
        })
        .finally(() => {
        })
    },

    async saveTrailerProfile () {
      const data = Object.assign({}, this.trailerProfile)
      data.lastService = this.convertDateToInt(data.lastService)
      this.resetErrors()
      this.$store.commit('setLoading', true, { root: true })
      await apollo.mutate({
        mutation: editTrailer,
        variables: {
          apiToken: this.user.apiToken,
          data: data
        }
      }).then(response => {
        const { status, errors, result } = response.data.editTrailerProfile

        switch (status) {
          case 'OK':
            this.$emit('update-trailer-profile', result)
            this.$store.commit('toggleSnackbar', { message: i18n.t('success.update.trailerProfile'), color: 'success' }, { root: true })
            break
          case 'BADREQUEST':
            this.errors = this.parseErrors(errors)
            this.$store.commit('toggleSnackbar', { message: i18n.t('errors.invalidFields'), color: 'warning' }, { root: true })
            break
          case 'NOTFOUND':
            this.$store.commit('toggleSnackbar', { message: i18n.t('errors.not_found'), color: 'warning' }, { root: true })
            break
        }
      }).catch(err => {
        console.error(err)
        this.$store.commit('toggleSnackbar', undefined, { root: true })
      }).finally(() => {
        this.$store.commit('setLoading', false, { root: true })
      })
    },
    async getTrips () {
      this.drawTrip = false
      const mutation = historicTrips
      const initTimestamp = this.initialTimestamp
      const finalTimestamp = this.finalTimestamp
      const variables = {
        apiToken: this.user.apiToken,
        unitId: this.unit.id,
        initTimestamp: Math.trunc(new Date(initTimestamp).getTime() / 1000),
        endTimestamp: Math.trunc(new Date(finalTimestamp).getTime() / 1000)
      }

      this.$apollo
        .mutate({
          mutation,
          variables,
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          const { status, errors, result } = response.data.historicTrips
          const result2 = response.data.historicTrips.result
          switch (status) {
            case 'OK':
              this.calculateTrips(result, result2)
              this.$store.commit('toggleSnackbar', {
                message: this.$i18n.t('trips.messages.success'),
                color: 'success'
              })
              break

            case 'NOTFOUND':
              this.$store.commit('toggleSnackbar', {
                message: 'Theres no brake events for the the date selected',
                color: 'orange darken-2'
              })
              break
            case 'UNPROCESSABLE':
              this.$store.commit('toggleSnackbar', {
                message: errors.days,
                color: 'orange darken-2'
              })
              break

            case 'INTERNALERROR':
              this.$store.commit('toggleSnackbar')
              break
          }
        })
        .catch(_ => {
          // console.log(_)
          this.$store.commit('toggleSnackbar')
        })
        .finally(() => {
        })
    },
    async generateLocator () {
      const mutation = generateLocator
      const name = 'generateLocator'
      const variables = {
        apiToken: this.user.apiToken,
        unitId: this.unit.id
      }
      this.$store.commit('toggleSnackbar', {
        message: 'Generating locator link',
        color: 'primary'
      })
      this.$apollo
        .mutate({
          mutation,
          variables,
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          const { status, errors, result } = response.data[name]

          switch (status) {
            case 'OK':
              navigator.clipboard.writeText(result)
              this.$store.commit('toggleSnackbar', {
                message: 'Your share link has been copy to your clipboard',
                color: 'success'
              })
              break

            case 'NOTFOUND':
              this.$store.commit('toggleSnackbar', {
                message: 'Theres no brake events for the the date selected',
                color: 'orange darken-2'
              })
              break
            case 'UNPROCESSABLE':
              this.$store.commit('toggleSnackbar', {
                message: errors.days,
                color: 'orange darken-2'
              })
              break

            case 'INTERNALERROR':
              this.$store.commit('toggleSnackbar')
              break
          }
        })
        .catch(_ => {
          this.$store.commit('toggleSnackbar')
        })
        .finally(() => {
        })
    },
    calculateTrips (messages, trips) {
      this.retrievedTrip = true
      // Clean trips
      this.trips = []
      // For in trips
      for (const i in trips) {
        const currentTrip = trips[i]
        this.trips.push({
          ...currentTrip
        })
      }
      this.drawTrip = true
    }
  }
}
</script>

<style lang="scss" scoped>

.list_title{
  border-bottom: 2px solid;
  border-color: #616161;
}

.tabs__styles {
  font-size: 16px;
}

.mapfiller{
  height: 400px;
  background-color: #67925b;
  width: 100%;
}

.image__container {
  width: 100%;
  height: 90px;
  display: flex; 
  justify-content: center;
  align-items: center;
  background: rgb(151,151,153);
  background: linear-gradient(180deg, rgba(151,151,153,1) 0%, rgba(185,185,185,1) 0%, rgba(247,249,250,1) 100%);
}

.card__actions {
  margin-top: -60px
}

.text__container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.green__text {
  color: #0B6E00;
  font-weight: bold !important;
}

.snackbar__container {
  background-color: #328428;
  color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0px 2px 8px #5D5D5D54;
  padding: 4px 12px;
  height: 100%;
}

.warning__snackbar__container {
  background-color: #FDD835;
}

.snackbar__title {
  color: #FFFFFF;
  font-weight: bold !important;
}

.snackbar__subtitle {
  color: #FFFFFF;
  font-size: 12px;
}

.link__container {
  color: #014E88;
}

</style>