
import { i18n } from '@/plugins/i18n'
import { apollo } from '@/plugins/apollo'

// graphql files
import list from 'GraphQL/queries/reports/list.gql'
import del from 'GraphQL/mutations/reports/delete.gql'

export default {
  async getList ({ rootState, commit }, payload = false) {
    if (payload) {
      await commit('setLoading', true, { root: true })
    }

    await apollo.query({
      query: list,
      variables: {
        apiToken: rootState.session.apiToken
      }
    }).then(response => {
      const { status, result } = response.data.executedReports

      switch (status) {
        case 'OK':
          commit('setList', result)
          break
        case 'ACCESSDENIED':
          commit('toggleSnackbar', { message: i18n.t('errors.accessDenied'), color: 'red darken-2' }, { root: true })
          break
        case 'BADREQUEST':
          commit('toggleSnackbar', { message: i18n.t('errors.invalidFields'), color: 'orange darken-2' }, { root: true })
          break
        case 'UNPROCESSABLE':
          commit('toggleSnackbar', { message: i18n.t('errors.invalidFields'), color: 'orange darken-2' }, { root: true })
          break
        case 'INTERNALERROR':
          commit('toggleSnackbar', { message: i18n.t('helpers.errors.disaster'), color: 'red darken-2' }, { root: true })
          break
      }
    }).catch(err => {
      console.error(err)
      commit('toggleSnackbar', { message: i18n.t('helpers.errors.disaster'), color: 'red darken-2' }, { root: true })
    }).finally(() => {
    })
  },
  async updateList ({ rootState, commit }, payload = false) {
    if (payload) {
      await commit('setLoading', true, { root: true })
    }

    await apollo.query({
      query: list,
      variables: {
        apiToken: rootState.session.apiToken
      }
    }).then(response => {
      const { status, result } = response.data.executedReports

      switch (status) {
        case 'OK':
          commit('updateList', { rootState: rootState, payload: result, commit: commit })
          break
        case 'ACCESSDENIED':
          commit('toggleSnackbar', { message: i18n.t('errors.accessDenied'), color: 'red darken-2' }, { root: true })
          break
        case 'BADREQUEST':
          commit('toggleSnackbar', { message: i18n.t('errors.invalidFields'), color: 'orange darken-2' }, { root: true })
          break
        case 'UNPROCESSABLE':
          commit('toggleSnackbar', { message: i18n.t('errors.invalidFields'), color: 'orange darken-2' }, { root: true })
          break
        case 'INTERNALERROR':
          commit('toggleSnackbar', { message: i18n.t('helpers.errors.disaster'), color: 'red darken-2' }, { root: true })
          break
      }
    }).catch(err => {
      commit('cleanInterval', err)
    }).finally(() => {
    })
  },
  async delete ({ rootState, commit, dispatch }, ids) {
    await commit('setLoading', true, { root: true })
    await apollo.mutate({
      mutation: del,
      variables: {
        apiToken: rootState.session.apiToken,
        ids
      }
    }).then(async response => {
      const { status } = response.data.deleteExecutedReport

      switch (status) {
        case 'OK':
          commit('toggle', { method: 'delete', status, result: null })
          commit('toggleSnackbar', { message: i18n.t('success.itemDeleted'), color: 'success' }, { root: true })
          await dispatch('getList')
          await commit('reports/setUnitsToList', { rootState: rootState, dispatch: dispatch }, { root: true })
          break
        case 'NOTFOUND':
          commit('toggleSnackbar', { message: i18n.t('errors.itemNotFound'), color: 'red darken-2' }, { root: true })
          commit('toggle', { method: 'delete', status, result: null })
          break
        case 'ACCESSDENIED':
          commit('toggleSnackbar', { message: i18n.t('errors.accessDenied'), color: 'red darken-2' }, { root: true })
          commit('toggle', { method: 'delete', status, result: null })
          break
        case 'BADREQUEST':
          commit('toggleSnackbar', { message: i18n.t('errors.invalidFields'), color: 'orange darken-2' }, { root: true })
          commit('toggle', { method: 'delete', status, result: null })
          break
        case 'UNPROCESSABLE':
          commit('toggleSnackbar', { message: i18n.t('errors.invalidFields'), color: 'orange darken-2' }, { root: true })
          commit('toggle', { method: 'delete', status, result: null })
          break
        case 'INTERNALERROR':
          commit('toggleSnackbar', { message: i18n.t('helpers.errors.disaster'), color: 'red darken-2' }, { root: true })
          commit('toggle', { method: 'delete', status, result: null })
          break
      }
    }).catch(err => {
      console.error(err)
      commit('toggleSnackbar', { message: i18n.t('helpers.errors.disaster'), color: 'red darken-2' }, { root: true })
      commit('toggle', { method: 'delete', status: 'INTERNALERROR', result: null })
    }).finally(() => {
      commit('setLoading', false, { root: true })
    })
  }
}
