<template>
  <v-expansion-panels
    :key="title"
    v-model="panelValue"
    accordion
    :mandatory="mandatory"
    :class="{ 'container': expantionAbsolute }"
  >
    <v-expansion-panel
      
      :class="{ 'expantion--absolute': expantionAbsolute }"
    >
      <v-expansion-panel-header
        :style="{
          cursor: `${mandatory ? 'auto' : 'pointer'}`
        }"
        hide-actions
        style="width: 100%;"
      >
        <v-row no-gutters align="center">
          <!-- Title -->
          <v-col
            cols="8"
            class="d-flex align-center"
          >
            <h3
              class="text-uppercase"
              :class="isDark ? 'white--text' : 'grey--text text--darken-1'"
            >
              {{ title.toUpperCase() }}
            </h3>
            <slot
              name="leadingTitle"
            />
          </v-col>
          <v-col cols="4" class="d-flex justify-end align-center">
            <slot name="headerTrail">
              <div
                class="ma-2 accent pa-2 counter__circle d-flex justify-center align-center"
              >
                <b class="white--text pa-0 ma-0" style="font-size: 14px;">
                  {{ value }}
                </b>
              </div>
            </slot>
            <div
              v-if="showDelete"
              class="ma-2 pa-2 counter__circle d-flex justify-center align-center"
            >
              <v-icon color="red darken-4" @click="cleanOption">mdi-close</v-icon>
            </div>
          </v-col>
          <!-- Subtitles -->
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content
        :style="`height: ${expandedHeight}; z-index: 3;`"
      >
        <v-divider />
        <div v-if="value === 0" class="d-flex justify-center align-center" :style="`height: calc(${expandedHeight}); overflow: auto;`">
          <h4 :class="colorText" class="no--elements--text">
            {{ emptyMessage }}
          </h4>
        </div>
        <v-list
          v-else
          :style="`height: calc(${expandedHeight}); overflow: auto;`"
          class="scrollbar__seamless"
        >
          <v-list-item-group>
            <v-virtual-scroll
              :height="`calc(${expandedHeight} - 20px)`"
              item-height="80"
              :bench="3"
              class="scrollbar__seamless"
              :items="data"
            >
              <template #default="{ item, index }">
                <slot
                  name="default"
                  :item="item"
                  :index="index"
                />
                <v-divider :key="`divider__${index}`" />
              </template>
            </v-virtual-scroll>
          </v-list-item-group>
        </v-list>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  props: {
    title: {
      type: String,
      default: () => ''
    },
    value: {
      type: Number,
      default: () => 0
    },
    data: {
      type: Array,
      default: () => []
    },
    panelDefaultOpen: {
      type: Boolean,
      default: () => false
    },
    expandedHeight: {
      type: String,
      default: () => '200px'
    },
    expantionAbsolute: {
      type: Boolean,
      default: () => false
    },
    emptyMessage: {
      type: String,
      default: () => 'No elements to show'
    },
    mandatory: {
      type: Boolean,
      default: () => false
    },
    showDelete: {
      type: Boolean,
      default: () => false
    },
    height: {
      type: String,
      default: () => '300px'
    }
  },
  data () {
    return {
      panelValue: null
    }
  },
  computed: {
    ...mapState(['isDark']),
    ...mapGetters(['colorText'])
  },
  mounted () {
    if (this.panelDefaultOpen) {
      this.panelValue = 0
    }
  },
  methods: {
    cleanOption () {
      this.$emit('clean-option', 'configuration')
    }
  }
}
</script>

<style lang="scss" scoped>

.container {
  position: relative;
  height: 64px;
}

.expantion--absolute {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.no--elements--text {
  text-transform: uppercase;
}

</style>