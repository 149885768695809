import { i18n } from '@/plugins/i18n'
import { apollo } from '@/plugins/apollo'

// graphql files
import list from 'GraphQL/queries/messages/odometerHistoric.gql'

export default {
  async getList ({ rootState, commit, dispatch }, { payload = false, interval = 'today' }) {
    if (payload) {
      await commit('setLoading', true, { root: true })
    }
    await apollo.query({
      query: list,
      variables: {
        apiToken: rootState.session.apiToken,
        interval
      }
    }).then(response => {
      const { status, result } = response.data.odometerHistoric

      switch (status) {
        case 'OK':
          commit('setOdometersv2', { interval, result })
          commit('dailyOdometers/setUnitsToOdometers', { rootState: rootState }, { root: true })
          if (interval !== 'thisweek') {
            switch (interval) {
              case 'today':
                dispatch('getList', { payload: false, interval: 'yesterday' })
                break
              case 'yesterday':
                // dispatch('getList', { payload: false, interval: 'thisweek' })
                break
              case 'thisweek':
                break
              default:
                dispatch('getList', { payload: false, interval: 'today' })
                break
            }
          }
          break
        case 'ACCESSDENIED':
          commit('toggleSnackbar', { message: i18n.t('errors.accessDenied'), color: 'red darken-2' }, { root: true })
          break
        case 'BADREQUEST':
          commit('toggleSnackbar', { message: i18n.t('errors.invalidFields'), color: 'orange darken-2' }, { root: true })
          break
        case 'UNPROCESSABLE':
          commit('toggleSnackbar', { message: i18n.t('errors.invalidFields'), color: 'orange darken-2' }, { root: true })
          break
        case 'INTERNALERROR':
          commit('toggleSnackbar', { message: i18n.t('helpers.errors.disaster'), color: 'red darken-2' }, { root: true })
          break
      }
    }).catch(err => {
      console.error(err)
      commit('toggleSnackbar', { message: i18n.t('helpers.errors.disaster'), color: 'red darken-2' }, { root: true })
    }).finally(() => {
    })
  }
}
