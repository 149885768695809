<template>
  <v-card
    :style="`height: ${height}`"
    style="overflow: hidden;"
    class="pa-2"
  >
    <v-card-title>
      <span
        class="text-uppercase"
        style="font-family: Lato; font-weight: 700 !important;"
      >
        {{ title.toUpperCase() }}
      </span>
    </v-card-title>
    <v-row no-gutters>
      <v-col cols="6" class="pa-2">
        <v-text-field
          v-model="search"
          :label="$t('unitsPage.unitSearch')"
          outlined
          dense
          prepend-inner-icon="mdi-magnify"
        />
      </v-col>
      <v-col cols="6" class="pa-2">
        <v-select
          v-model="sortValue"
          :items="filters"
          dense
          label="Filter by"
          outlined
          item-text="label"
          item-value="value"
        />
      </v-col>
    </v-row>
    <v-divider />
    <v-list
      class="list--container scrollbar__seamless"
    >
      <v-list-item-group
        active-class=" "
        multiple
      >
        <v-virtual-scroll
          :height="`calc(${height} - 180px)`"
          item-height="68"
          :bench="3"
          class="scrollbar__seamless"
          :items="units"
        >
          <template #default="{ item, index }">
            <gm-monitor-list-item
              :key="'list__' + index"
              :class="isItemSelected(item) ? 'grey lighten-3' : ''"
              :index="index"
              :title="item.name"
              icon="mdi-truck"
              :icon-label="item.iconLabel"
              :unit="item"
              :onClick="(unit) => showDetail(unit)"
              :showDetailButton="showDetailButton"
              @clicked-element="clickedElement"
            />
            <v-divider :key="`divider__${index}`" />
          </template>
        </v-virtual-scroll>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>

import dayjs from 'dayjs'
import axios from 'axios'
import MonitorUnitItem from './MonitorUnitItem.vue'
import { gisUrl } from '@/plugins/constants'

const utc = require('dayjs/plugin/utc')

export default {
  components: {
    'gm-monitor-list-item': MonitorUnitItem
  },

  props: {
    height: {
      type: String,
      default: () => '200px'
    },

    items: {
      type: Array,
      default: () => []
    },
    showDetailButton: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: () => ''
    },
    selectedValues: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      sortValue: 'date_desc',
      search: '',
      componentKey: 0,
      model: []
    }
  },
  computed: {
    units () {
      if (this.search === '') {
        return this.sortUnits(this.items, this.sortValue.includes('desc') ? 2 : 0)
      } else {
        const units = []
        for (const i in this.items) {
          if (this.items[i].name.toLowerCase().includes(this.search.toLowerCase())) {
            units.push(this.items[i])
          }
        }
        return this.sortUnits(units, this.sortValue.includes('desc') ? 2 : 0)
      }
    },
    filters () {
      return [
        {
          label: 'Date ASC',
          value: 'date_asc'
        },
        {
          label: 'Date DESC',
          value: 'date_desc'
        },
        {
          label: 'A - Z',
          value: 'name_asc'
        },
        {
          label: 'Z - A',
          value: 'name_desc'
        }
      ]
    }
  },
  watch: {
  },
  methods: {
    sortUnits (list, val = 0) {
      if (this.sortValue.includes('date')) {
        return this.sortByDate(list, val)
      } else if (this.sortValue.includes('name')) {
        return this.sortByName(list, val)
      }
    },
    sortByName (list, val = 0) {
      list.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1 + val
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1 - val
        }
        // a debe ser igual b
        return 0
      })
      return list
    },
    sortByDate (list, val = 0) {
      list.sort((a, b) => {
        if (!(a.telemetry?.receivedAt && b.telemetry?.receivedAt)) {
          return 0
        }
        if (a.telemetry?.receivedAt < b.telemetry?.receivedAt) {
          return -1 + val
        }
        if (a.telemetry?.receivedAt > b.telemetry?.receivedAt) {
          return 1 - val
        }
        // a debe ser igual b
        return 0
      })
      return list
    },
    parseSubtitle (item) {
      if (item.telemetry?.position?.latitude) {
        if (item.telemetry.address === undefined) {
          item.telemetry.adress = 'Loading...'
          this.calculateAddress(item)
        }
        return `${this.parseDate(item.telemetry)} ${item.telemetry.address === undefined ? 'Loading...' : item.telemetry.address}`
      } else {
        return 'Not available'
      }
    },
    calculateAddress (item) {
      return 'Loading'
      // eslint-disable-next-line no-unreachable
      const vueObject = this
      const url = `${gisUrl}/reverse?format=jsonv2&lat=${item.telemetry.position.latitude}&lon=${item.telemetry.position.longitude}`
      axios.get(url)
        .then(function (response) {
          // handle success
          // console.log(response)
          vueObject.forceRerender()
          item.telemetry.address = response.data.display_name
          vueObject.$store.commit('units/updateItem', item)
        })
        .catch(function (error) {
          // handle error
          console.error(error)
          setTimeout(() => vueObject.calculateAddress(item), 500)
        })
        .then(function () {
          // console.log('hello')
          vueObject.forceRerender()
          // always executed
        })
    },
    parseDate (date) {
      dayjs.extend(utc)
      if (date === null) {
        return dayjs()
      } else {
        return dayjs(date.receivedAt * 1000).format('DD/MM/YYYY HH:mm')
      }
    },
    forceRerender () {
      // console.log('called')
      this.componentKey += 1
    },
    async showDetail (unit) {
      // console.log('unit -> hello')
      this.$emit('show-detail', unit)
    },
    clickedElement (unit) {
      this.$emit('clicked-element', unit)
    },
    isItemSelected (unit) {
      return !!this.selectedValues.find(item => item.id.toString() === unit.id.toString())
    }
  }
}
</script>

<style lang="scss" scoped>

.list--container {
  height: 100%;
  overflow: auto;
}

</style>