export default {
  setList (state, payload) {
    // sort alphabetically
    payload = payload.sort((a, b) => a.name.localeCompare(b.name))
    state.list = payload
  },

  setIsEditting (state, payload) {
    state.isEditting = payload
  },

  toggleDialog (state, payload) {
    state.dialog = payload
  },

  newItem (state, payload) {
    state.list.push(payload)
  },

  updateItem (state, payload) {
    const list = [...state.list]
    console.log(payload)
    for (let i = 0; i < list.length; i++) {
      if (list[i].id.toString() === payload.id.toString()) {
        list[i] = Object.assign({}, payload)
      }
    }

    state.list = []
    state.list = [...list]
  },

  deleteItem (state, id) {
    const list = state.list.filter(item => item.id.toString() !== id.toString())
    state.list = []
    state.list = [...list]
  },

  toggle () {}
}