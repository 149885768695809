export default {
  defaultObject () {
    return {
      id: null,
      name: '',
      scheduleType: 'DAILY',
      reportType: 'EBPMS',
      timeOfDay: 0,
      dayOfWeek: 1,
      dayOfMonth: 1,
      emails: [],
      units: []
    }
  }
}