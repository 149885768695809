import { i18n } from '@/plugins/i18n'

export default {
  defaultObject () {
    return {
      id: null,
      name: '',
      notificationObject: null,
      units: [], // should be renamed as unitIds
      groups: [],
      geofences: [], // should be renamed as geofenceIds
      notificationType: null,
      configuration: {},
      message: '',
      emails: [],
      timeInterval: null,
      timeFrom: null,
      timeTo: null,
      // Custom Configuration
      firstCondition: null,
      operator: null,
      secondCondition: null
      // Elements
    }
  },
  tpmsOptions () {
    return [
      { value: 'pressure', text: i18n.t('notification.tpms.type.pressure') },
      { value: 'temperature', text: i18n.t('notification.tpms.type.temperature') }
    ]
  },
  pressureOptions () {
    return [
      { value: 'lessThan', text: i18n.t('notification.tpms.pressure.type.lessThan') },
      { value: 'lessThanVehicleType', text: i18n.t('notification.tpms.pressure.type.lessThanVehicleType') }
    ]
  },
  temperatureOptions () {
    return [
      { value: 'aboveThan', text: i18n.t('notification.tpms.temperature.type.aboveThan') },
      { value: 'lessThan', text: i18n.t('notification.tpms.temperature.type.lessThan') }
    ]
  },
  defaultTpmsConfig () {
    return {
      type: null,
      value: null,
      subType: null
    }
  },
  geofenceOptions () {
    return [
      { value: 'enter', text: i18n.t('notification.geofence.type.enter') },
      { value: 'leave', text: i18n.t('notification.geofence.type.leave') }
    ]
  },
  defaultGeofenceConfig () {
    return {
      type: null
    }
  },
  ebpmsOptions () {
    return [
      { value: 'everyCalculation', text: i18n.t('notification.ebpms.type.everyCalculation') },
      { value: 'below', text: i18n.t('notification.ebpms.type.below') },
      { value: 'red', text: i18n.t('notification.ebpms.type.red') }
    ]
  },
  defaultEbpmsConfig () {
    return {
      type: null,
      value: null
    }
  },
  vorOptions () {
    return [
      { value: 'valuesChange', text: i18n.t('notification.vor.type.valuesChange') },
      { value: 'valuesChangeToTrue', text: i18n.t('notification.vor.type.valuesChangeToTrue') }
    ]
  },
  defaultVorConfig () {
    return {
      type: null
    }
  },
  overWeightOptions () {
    return [
      { value: 'greaterThan', text: i18n.t('notification.overWeight.type.greaterThan') },
      { value: 'greaterThanProfile', text: i18n.t('notification.overWeight.type.greaterThanProfile') }
    ]
  },
  defaultOverWeightConfig () {
    return {
      type: null,
      value: null
    }
  },
  underWeightOptions () {
    return [
      { value: 'lessThan', text: i18n.t('notification.underWeight.type.lessThan') },
      { value: 'lessThanProfilePercent', text: i18n.t('notification.underWeight.type.lessThanProfilePercent') }
    ]
  },
  defaultUnderWeightConfig () {
    return {
      type: null,
      value: null
    }
  },
  dtcOptions () {
    return [
      { value: 'valuesChange', text: i18n.t('notification.dtc.type.valuesChange') },
      { value: 'yellow', text: i18n.t('notification.dtc.type.yellow') },
      { value: 'red', text: i18n.t('notification.dtc.type.red') }
    ]
  },
  defaultDtcConfig () {
    return {
      type: null
    }
  },
  outOfServiceOptions () {
    return [
      { value: 'atTheMoment', text: i18n.t('notification.outOfService.type.atTheMoment') },
      { value: 'inTimeRange', text: i18n.t('notification.outOfService.type.inTimeRange') }
    ]
  },
  defaultOutOfServiceConfig () {
    return {
      type: null,
      value: null
    }
  },
  outOfMaintenanceOptions () {
    return [
      { value: 'atTheMoment', text: i18n.t('notification.outOfMaintenance.type.atTheMoment') },
      { value: 'inTimeRange', text: i18n.t('notification.outOfMaintenance.type.inTimeRange') }
    ]
  },
  defaultOutOfMaintenanceConfig () {
    return {
      type: null,
      value: null
    }
  },
  inTimeRangeOptions () {
    return [
      { value: '1 day', text: i18n.t('notification.inTimeRange.type.1_day') },
      { value: '1 week', text: i18n.t('notification.inTimeRange.type.1_week') },
      { value: '1 month', text: i18n.t('notification.inTimeRange.type.1_month') }
    ]
  },
  customOptions () {
    return [
      { value: 'TPMS', text: i18n.t('notifications.custom.type.tpms') },
      { value: 'EBPMS', text: i18n.t('notifications.custom.type.ebpms') },
      { value: 'VOR', text: i18n.t('notifications.custom.type.vor') },
      { value: 'OVERWEIGHT', text: i18n.t('notifications.custom.type.overweight') },
      { value: 'UNDERWEIGHT', text: i18n.t('notifications.custom.type.underweight') },
      { value: 'DTC', text: i18n.t('notifications.custom.type.dtc') },
      { value: 'GEOFENCE', text: i18n.t('notifications.custom.type.geofence') },
      { value: 'OUTOFSERVICE', text: i18n.t('notifications.custom.type.outOfService') },
      { value: 'OUTOFMAINTENANCE', text: i18n.t('notifications.custom.type.outOfMaintenance') }
    ]
  },
  customOperatorOptions () {
    return [
      { value: 'AND', text: i18n.t('notification.custom.operator.and') },
      { value: 'OR', text: i18n.t('notification.custom.operator.or') }
    ]
  },
  defaultCustomConfig () {
    return {
      firstConditionType: null,
      firstConditionValue: null,
      secondConditionType: null,
      secondConditionValue: null
    }
  }
}