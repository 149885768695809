<template>
  <v-row>
    <v-col cols="12" md="4">
      <icon-label icon="mdi-information-variant" label="Event name" />
      <v-chip
        :color="getDtcCodeColor(event)"
        text-color="white"
        small
      >
        {{ getEventName }}
        <v-icon right>
          {{ getEventIcon }}
        </v-icon>
      </v-chip>
    </v-col>
    <v-col cols="12" md="4" style="cursor: pointer;" @click="showDialog">
      <icon-label icon="mdi-truck" label="Unit" /> 
      <v-chip small style="cursor: pointer; background: rgba(115, 22, 14, 0.25);" @click="showDialog">{{ getUnitName() }}</v-chip>
    </v-col>
    <v-col cols="12" md="4">
      <icon-label icon="mdi-wifi" label="Value" />
      <h3 :class="eventType === 'dtc' ? '' : 'error--text'" style="font-weight: bold">{{ getValue() }}</h3>
    </v-col>
    <v-col cols="12" md="4">
      <icon-label icon="mdi-calendar" label="Init Date" />
      <h3>{{ formatInitDate() }}</h3>
    </v-col>
    <v-col cols="12" md="4">
      <icon-label icon="mdi-calendar" label="Finish Date" />
      <h3>{{ formatFinishDate() }}</h3>
    </v-col>
    <v-col cols="12" md="4">
      <icon-label icon="mdi-calendar" label="Duration" />
      <h3>{{ formatDuration() }}</h3>
    </v-col>
    <v-col cols="12" md="4">
      <icon-label icon="mdi-gauge-empty" label="Initital Odometer" />
      <h3>{{ getInitialOdometer() }}</h3>
    </v-col>
    <v-col cols="12" md="4">
      <icon-label icon="mdi-gauge-full" label="Finish Odometer" />
      <h3>{{ getFinishOdometer() }}</h3>
    </v-col>
    <v-col cols="12" md="4">
      <icon-label icon="mdi-gauge" label="Distance" />
      <h3>{{ getFinalDistance() }}</h3>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { IconLabel } from 'Components/utils'
import dayjs from 'dayjs'
const advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

export default {
  components: {
    'icon-label': IconLabel
  },
  props: {
    address: {
      type: String,
      default: () => 'Address'
    },
    event: {
      type: Object,
      default: () => {}
    },
    hasIntructions: {
      type: Boolean,
      default: () => false
    },
    eventType: {
      type: String,
      default: () => 'dtc'
    }
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState(['isDark']),
    ...mapGetters(['colorText']),
    unitsDict () { return this.$store.state.units.dictList },
    dtcCodes () { return this.$store.state.dtcs.codeDictList },
    getEventName () { 
      if (this.eventType === 'dtc') {
        return 'DTC Event'
      } else if (this.eventType === 'overweight') {
        return 'Overweight Event'
      } else {
        return ''
      }
    },
    getEventIcon () { 
      if (this.eventType === 'dtc') {
        return 'mdi-hazard-lights'
      } else if (this.eventType === 'overweight') {
        return 'mdi-weight'
      } else {
        return ''
      }
    }
  },
  methods: {
    formatInitDate () {
      const date = this.eventType === 'dtc' ? this.event.activatedAt : this.event.receivedAt
      if (date) {
        return dayjs(date * 1000).format('DD/MM/YYYY - hh:mm a')
      } else {
        return ''
      }
    },
    formatFinishDate () {
      const date = this.eventType === 'dtc' ? this.event.finishAt : this.event.finishAt
      if (date) {
        return dayjs(date * 1000).format('DD/MM/YYYY - hh:mm a')
      } else {
        return ''
      }
    },
    formatDuration () {
      const initDate = this.eventType === 'dtc' ? this.event.activatedAt : this.event.receivedAt
      const endDate = this.eventType === 'dtc' ? this.event.finishAt : this.event.finishAt
      let duration = endDate - initDate
      // Parse Hours
      if (duration > 3600) {
        let hours = duration / 3600
        if (hours < 10) {
          hours = `0${hours}`
        }
        
        return `${Math.trunc(hours)} hours`
      } else if (duration < 3600 && duration > 60) {
        let minutes = duration / 60
        if (minutes < 10) {
          minutes = `0${minutes}`
        }
        return `${Math.trunc(minutes)} minutes`
      } else {
        if (duration < 10) {
          duration = `0${duration}`
        }
        return `${Math.trunc(duration)} seconds`
      }
    },
    formatValue (value) {
      return parseFloat(value).toFixed(2)
    },
    getUnitName () {
      if (this.unitsDict[this.event.unitId]) {
        return this.unitsDict[this.event.unitId].name
      } else {
        return '---'
      }
    },
    getDtcCodeColor (unit) {
      if (unit.dtcAmberState === 16 || unit.dtcAmberState === 32) {
        return 'amber'
      } else if (unit.dtcRedState === 4 || unit.dtcRedState === 32) {
        return 'red'
      } else {
        return 'grey'
      }
    },

    async showDialog () {
      const unit = this.unitsDict[this.event.unitId]
      this.$showUnitDialog(unit) 
    },
    getValue () {
      if (this.eventType === 'dtc') {
        return this.dtcValue()
      } else if (this.eventType === 'overweight') {
        return this.overweightValue()
      } else {
        return '---'
      }
    },
    dtcValue () {
      return this.dtcCodes[this.event.dtcCodeId].fullName
    },
    percentage () {
      const difference = this.event.axeLoadSum - this.event.maxWeightAllowed
      return ((difference / this.event.maxWeightAllowed) * 100).toFixed(0)
    },
    overweightValue () {
      return `${this.event.axeLoadSum} kg +${this.percentage()}%`
    },
    getInstructions () {
      return this.dtcCodes[this.event.dtcCodeId].description
    },
    getInitialOdometer () {
      return this.event.initOdometer !== undefined ? `${this.event.initOdometer} km` : 'Not Available'
    },
    getFinishOdometer () {
      return this.event.finalOdometer ? `${this.event.finalOdometer} km` : 'Not Available'
    },
    getFinalDistance () {
      if (this.event.initOdometer !== undefined && this.event.finalOdometer) {
        return `${this.event.finalOdometer - this.event.initOdometer} km`
      }
      return 'Not Available'
    }
  }
}
</script>