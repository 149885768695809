// Main imports
import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: 'es-MX',
  messages: {
    'es-MX': {
      session: {
        expired: 'Su sesión ha expirado'
      },
      helpers: {
        errors: {
          disaster: 'Lo sentimos, pero algo salió mal'
        }
      }
    },
    'en-US': {
      session: {
        expired: 'Your session was expired'
      },
      helpers: {
        errors: {
          disaster: 'We are sorry, but something went wrong'
        }
      }
    }
  },
  silentTranslationWarn: true
})
