/*
  Dtcs state
*/
export default {
  eventList: [],
  eventDictList: [],
  odometers: {
    today: [],
    yesterday: [],
    thisweek: [],
    lastweek: []
  }
}
