<template>
  <v-dialog
    :value="dialog"
    :fullscreen="isMobile"
    :max-width="width.dialog"
    scrollable
    persistent
    transition="dialog-transition"
  >
    <v-card height="90vh">
      <v-row no-gutters style="width: 100%; margin: 0; overflow: hidden;">
        <!-- <v-col
          cols="3"
          class="pa-0"
          :style="{
            position: 'relative'
          }"
        >
          <gm-trailer-render
            key="tpms-dialog-trailer"
            trailer-id="tpms-dialog-trailer"
            :message="unit.telemetry"
            :style="{
              height: '15vh',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%) rotate(90deg)'
            }"
            @wheel-click="wheelClick"
            @wheel-hover="wheelHover"
            @wheel-mouseout="wheelMouseout"
          />
        </v-col> -->
        <v-col cols="12" class="pa-0">
          <v-row no-gutters>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn class="ma-2" icon text @click="discardItem">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-tabs
                v-model="tab"
                align-with-title
              >
                <v-tabs-slider color="yellow" />

                <v-tab
                  v-for="(item, i) in tabItems"
                  :key="`tab-${i}`"
                >
                  {{ item.text }}
                </v-tab>
              </v-tabs>
            </v-col>
            <v-col cols="12">
              <v-tabs-items v-model="tab">
                <v-tab-item
                  key="history-tab"
                >
                  <history-tab :unit="unit" :dialog="showDialog" />
                </v-tab-item>
                <v-tab-item
                  key="tpms-event-log-tab"
                >
                  <tpms-event-log-tab :unit="unit" :tpmsEvents="tpmsEvents" :changeTpmsEvents="changeTpmsEvents" />
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { orm } from '@/mixins'
// import {
//   TrailerRender
// } from 'Components/utils/'

import {
  History,
  TpmsEventLog
} from '@/components/units/unit_sheet/dialogs/tabs'

// import { Message } from '@/components/layo'

// let observer = null

export default {
  components: {
    // 'v-duallist': DualList
    'history-tab': History,
    'tpms-event-log-tab': TpmsEventLog
    // 'gm-trailer-render': TrailerRender
  },
  mixins: [orm],

  model: {
    prop: 'data',
    event: 'set-object'
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    readonly: {
      type: Boolean,
      default: () => false
    },

    showDialog: {
      type: Boolean,
      default: () => false
    },

    unit: {
      type: Object,
      default: () => {}
    },
    tpmsEvents: {
      type: Array,
      default: () => []
    },
    changeTpmsEvents: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      // selectedTab: 'basic',
      // animation: 'happy',
      errors: {},
      hoverWheel: null,
      tab: null,
      loadingChart: false
    }
  },

  computed: {
    ...mapState(['session', 'width', 'isLoading', 'isDark', 'units']),
    ...mapGetters(['color', 'isMobile']),
    // ...mapGetters({
    //   defaultObject: 'groups/defaultObject',
    //   canEdit: 'session/canEdit',
    //   canBilling: 'session/canBilling'
    // }),

    isEditting: {
      get () {
        return !this.readonly
      },
      set (value) {
        this.$emit('set-readonly', !value)
      }
    },

    object: {
      get () {
        return this.data
      },

      set (value) {
        this.$emit('toggle-object', value)
      }
    },

    dialog: {
      get () {
        return this.showDialog
      },

      set (value) {
        this.$emit('toggle-dialog', value)
      }
    },

    title () {
      // eslint-disable-next-line
      if (!!this.object.id) {
        const name = this.object.name
        if (this.isEditting) {
          return this.$i18n.t('groups.title.edit', { name })
        }
        return this.$i18n.t('groups.title.show', { name })
      }
      return this.$i18n.t('groups.title.new')
    },

    unitsItems () {
      return this.units.list
    },

    tabItems () {
      return [
        // { text: 'Tyre Analysis' },
        { text: 'History' },
        { text: 'TPMS Event Log' }
        // { text: 'Tyre Changes' }
      ]
    }
  },

  watch: {
    dialog (_) {
      this.tab = 0
    }
  },

  beforeDestroy () {
    // this.resetErrors()
    // observer()
  },

  created () {
    // this.resetErrors()

    // observer = this.$store.subscribe((mutation) => {
    //   if (mutation.type === 'groups/toggle') {
    //     const payload = mutation.payload
    //     switch (payload.method) {
    //       case 'add':
    //         this.evaluateResponse(payload)
    //         break
    //       case 'edit':
    //         this.evaluateResponse(payload)
    //         break
    //     }
    //   }
    // })
  },

  methods: {
    // ...mapActions({
    //   add: 'groups/add',
    //   edit: 'groups/edit'
    // }),

    discardItem () {
      // this.tab = 0
      this.dialog = false
      this.isEditting = false
      // this.resetErrors()
      // this.object = Object.assign({}, this.defaultObject)
      // this.$emit('set-object')
    },

    wheelClick (_) {
      // this.$emit('wheel-click', wheel)
    },

    wheelHover (wheel) {
      // this.$emit('wheel-hover', wheel)
      this.hoverWheel = wheel
      // console.log('hover enter', wheel)
    },

    wheelMouseout (_) {
      this.hoverWheel = null
      // console.log('hover out')
      // this.$emit('wheel-mouseout')
    }
  }
}
</script>
