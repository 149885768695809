export default {
  methods: {
    employeesFilter (_, search, item) {
      if (search.length === 0) {
        return true
      }

      const scan0 = item.fullName.toLowerCase().includes(search.toLowerCase())
      const scan1 = item.email.toLowerCase().includes(search.toLowerCase())
      const scan2 = item.username.toLowerCase().includes(search.toLowerCase())
      let scan3 = false
      if (item.telegramUsername) {
        scan3 = item.telegramUsername.toLowerCase().includes(search.toLowerCase())
      }
      return scan0 || scan1 || scan2 || scan3
    },

    appsFilter (_, search, item) {
      if (search.length === 0) {
        return true
      }

      const scan0 = item.name.toLowerCase().includes(search.toLowerCase())
      return scan0
    },

    languagesFilter (_, search, item) {
      if (search.length === 0) {
        return true
      }

      const scan0 = item.name.toLowerCase().includes(search.toLowerCase())
      const scan1 = item.code.toLowerCase().includes(search.toLowerCase())
      return scan0 || scan1
    },

    devicesFilter (_, search, item) {
      if (search.length === 0) {
        return true
      }

      const scan0 = item.name.toLowerCase().includes(search.toLowerCase())
      const scan1 = item.ident.toLowerCase().includes(search.toLowerCase())
      return scan0 || scan1
    },

    geofencesFilter (_, search, item) {
      if (search.length === 0) {
        return true
      }

      const scan0 = item.name.toLowerCase().includes(search.toLowerCase())
      return scan0
    },

    triggersFilter (_, search, item) {
      if (search.length === 0) {
        return true
      }

      const scan0 = item.name.toLowerCase().includes(search.toLowerCase())
      return scan0
    },

    actionsFilter (_, search, item) {
      if (search.length === 0) {
        return true
      }

      const scan0 = item.name.toLowerCase().includes(search.toLowerCase())
      return scan0
    },

    assetsFilter (_, search, item) {
      if (search.length === 0) {
        return true
      }

      const scan0 = item.name.toLowerCase().includes(search.toLowerCase())
      const scan1 = item.plate.toLowerCase().includes(search.toLowerCase())
      return scan0 || scan1
    },

    sensorsFilter (items = [], search) {
      if (search.length === 0) {
        return items
      }

      const filter = items.filter(front => front.name.toLowerCase()
        .includes(search.toLowerCase()))
      const filter2 = items.filter(front => front.slug.toLowerCase()
        .includes(search.toLowerCase()))

      return filter || filter2
    },

    tyreChangesFilter (items = [], search) {
      if (search.length === 0) {
        return items
      }

      const filter = items.filter(front => front.name.toLowerCase()
        .includes(search.toLowerCase()))

      return filter
    },

    tagsFilter (_, search, item) {
      const scan0 = item.name.toLowerCase().includes(search.toLowerCase())
      return scan0
    },

    tagsListFilter (currentValue, searchParam, __, _) {
      if (searchParam.length === 0) {
        return true
      }
      const scan1 = currentValue.name.toLowerCase().includes(searchParam.toLowerCase())
      const scan2 = currentValue.color.toLowerCase().includes(searchParam.toLowerCase())
      return scan1 || scan2
    },

    asotiationsFilter (currentValue, searchParam, __, _) {
      if (searchParam.length === 0) {
        return true
      }
      const scan1 = currentValue.name.toLowerCase().includes(searchParam.toLowerCase())
      const scan2 = currentValue.type.toLowerCase().includes(searchParam.toLowerCase())
      const scan3 = this.$t(`tags.${currentValue.type}`).toLowerCase().includes(searchParam.toLowerCase())
      return scan1 || scan2 || scan3
    },

    usersFilter (currentValue, searchParam, __, _) {
      if (searchParam.length === 0) {
        return true
      }
      const scan0 = currentValue.name.toLowerCase().includes(searchParam.toLowerCase())
      const scan1 = currentValue.email.toLowerCase().includes(searchParam.toLowerCase())
      const scan2 = currentValue.username.toLowerCase().includes(searchParam.toLowerCase())
      let scan3 = false
      if (currentValue.telegramUsername) {
        scan3 = currentValue.telegramUsername.toLowerCase().includes(searchParam.toLowerCase())
      }
      return scan0 || scan1 || scan2 || scan3
    }
  }
}
