<template>
  <div
    id="units_map"
    ref="map"
    class="grey"
    style="width: 100%;"
    :style="`height: ${height};`"
  />
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { inititalCoordinates } from '@/plugins/constants'

import MarkerClusterer from '@googlemaps/markerclustererplus'

import loadashGet from 'lodash/get'

let observer = null

export default {
  props: {
    data: {
      type: Object,
      default: () => null
    },
    showMap: {
      type: Boolean,
      default: () => true
    },
    height: {
      type: [Number, String],
      default: () => '400px'
    },
    mapTypeControl: {
      type: Boolean,
      default: () => true
    },
    units: {
      type: Array,
      default: () => []
    },
    geofences: {
      type: Array,
      default: () => []
    },
    isEditting: {
      type: Boolean,
      default: () => false
    },
    trips: {
      type: Object,
      default: () => {}
    },
    showTrips: {
      type: Boolean,
      default: () => true
    },
    showUnits: {
      type: Boolean,
      default: () => true
    }
  },
  data () {
    return {
      map: null, // Google Maps Map entity
      google: null, // Google Maps object
      defaultCenter: null,
      marker: null,
      markers: [],
      polylines: [],
      mapUnitsMarkers: {},
      mapGeofences: {},
      mapGeofenceMarkers: {},
      UnitMarker: null,
      mapClusteringMarkers: null,
      clusterStyle: [
        {
          width: 30,
          height: 30,
          className: 'custom-clustericon-1'
        },
        {
          width: 40,
          height: 40,
          className: 'custom-clustericon-2'
        },
        {
          width: 50,
          height: 50,
          className: 'custom-clustericon-3'
        }
      ]
    }
  },
  computed: {
    ...mapState(['width', 'isLoading']),
    ...mapGetters({
      // Maintenace
      userInitialCoordinates: 'geofences/userHomeDepot', geofenceMarker: 'general/geofenceMarker'
    }),
    unitsDict () { return this.$store.state.units.dictList },
    centerAll () { return this.$store.state.mapMonitor.centerAll },
    clustering () { return this.$store.state.mapMonitor.clustering },
    userHomeDepotId () { return this.$store.state.session.homeDepotId }
  },

  watch: {
    userHomeDepotId (newVal) {
      if (this.map) {
        const geofendeDepot = this.geofences.find(el => el.id === newVal)
        if (geofendeDepot) {
          this.centerGeozone(geofendeDepot, geofendeDepot.mode)
        }
      }
    },
    showMap (newVal) {
      if (newVal) {
        this.loadMap()
      }
    },
    units () {
      this.removeAllUnitsFromMap()
      if (this.showUnits) {
        this.drawUnits()
      }
      if (this.mapClusteringMarkers) {
        this.mapClusteringMarkers.clearMarkers()
      }
      if (this.clustering) {
        const markers = Object.keys(this.mapUnitsMarkers).map(key => {
          return this.mapUnitsMarkers[key]
        })
        this.mapClusteringMarkers = this.drawMarkerClusterer(
          markers
        )
      }
    },
    geofences () {
      this.drawGeofences()
      if (this.map) {
        const geofendeDepot = this.geofences.find(el => el.id === this.userHomeDepotId)
        if (geofendeDepot) {
          this.centerGeozone(geofendeDepot, geofendeDepot.mode)
        }
      }
    },
    showTrips (value) {
      if (value) {
        // this.clearMarker(this.marker)
        this.clearPolylines()
        this.clearMarkers()
        this.drawTrips()
      } else {
        // this.clearMarker(this.marker)
        this.clearMarkers()
        this.clearPolylines()
        // this.drawMarker()
      }
    },
    trips () {
      if (this.showTrips) {
        // this.clearMarker(this.marker)
        this.clearPolylines()
        this.clearMarkers()
        this.drawTrips()
      } else {
        // this.clearMarker(this.marker)
        this.clearMarkers()
        this.clearPolylines()
        // this.drawMarker()
      }
    },
    showUnits (value) {
      if (value) {
        this.drawUnits()
      } else {
        this.removeAllUnitsFromMap()
      }
      if (this.mapClusteringMarkers) {
        this.mapClusteringMarkers.clearMarkers()
      }
      if (this.clustering) {
        const markers = Object.keys(this.mapUnitsMarkers).map(key => {
          return this.mapUnitsMarkers[key]
        })
        this.mapClusteringMarkers = this.drawMarkerClusterer(
          markers
        )
      }
    },
    centerAll (value) {
      if (value && Object.keys(this.mapUnitsMarkers).length > 0) {
        this.centerUnits()
      }
    },
    clustering (value) {
      this.removeAllUnitsFromMap()
      if (this.showUnits) {
        this.drawUnits()
      }
      if (this.mapClusteringMarkers) {
        this.mapClusteringMarkers.clearMarkers()
      }
      if (value) {
        const markers = Object.keys(this.mapUnitsMarkers).map(key => {
          return this.mapUnitsMarkers[key]
        })
        this.mapClusteringMarkers = this.drawMarkerClusterer(
          markers
        )
      }
    }
  },

  created () {
    const self = this
    observer = this.$store.subscribe(mutation => {
      if (mutation.type === 'mapMonitor/panToUnit') {
        if (mutation.payload.telemetry) {
          if (self.map) {
            console.log('Preparate coordinates')
            self.map.panTo({
              lat: mutation.payload.telemetry.position.latitude,
              lng: mutation.payload.telemetry.position.longitude
            })
          }
        }
      } else if (mutation.type === 'units/updatePropertyItem') {
        if (mutation.payload.payload.position) {
          if (self.map) {
            // console.log('Preparate coordinates 2')\            
            if (self.mapUnitsMarkers[mutation.payload.unitId.toString()]) {
              self.clearMarker(self.mapUnitsMarkers[mutation.payload.unitId.toString()])
              const unitsDict = self.unitsDict
              this.$store.commit('mapMonitor/popSelectedUnit', unitsDict[mutation.payload.unitId.toString()])
              const latitude = mutation.payload.payload.position.latitude
              const longitude = mutation.payload.payload.position.longitude
              self.drawUnit({ unit: unitsDict[mutation.payload.unitId.toString()], latitude, longitude })
              this.$store.commit('mapMonitor/pushSelectedUnit', unitsDict[mutation.payload.unitId.toString()])
            }
          }
        }
      }
    })
  },
  beforeDestroy () {
    observer()
  },
  mounted () {
    if (this.showMap) {
      this.loadMap()
    }
  },

  methods: {
    clearMarkers () {
      for (const i in this.markers) {
        if (this.markers[i]) {
          this.markers[i].setMap(null)
        }
      }
      this.markers = []
    },
    clearPolylines () {
      for (const i in this.polylines) {
        if (this.polylines[i]) {
          this.polylines[i].setMap(null)
        }
      }
      this.polylines = []
    },
    drawMarkerClusterer (markers) {
      return new MarkerClusterer(this.map, markers, {
        maxZoom: 15,
        gridSize: 50,
        styles: this.clusterStyle,
        clusterClass: 'custom-clustericon'
        // imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
      })
    },
    removeAllUnitsFromMap () {
      for (const key in this.mapUnitsMarkers) {
        this.mapUnitsMarkers[key].setMap(null)
        this.mapUnitsMarkers[key] = null
        delete this.mapUnitsMarkers[key]
      }
    },
    drawUnits () {
      if (this.showUnits) {
        this.units.forEach(unit => {
          const latitude = loadashGet(unit, 'telemetry.position.latitude')
          const longitude = loadashGet(unit, 'telemetry.position.longitude')
          if (latitude !== undefined && longitude !== undefined) {
            this.drawUnit({ unit, latitude, longitude })
          }
        })
      }
    },
    centerUnits () {
      const bounds = new this.google.maps.LatLngBounds()

      for (const key in this.mapUnitsMarkers) {
        bounds.extend(new this.google.maps
          .LatLng(this.mapUnitsMarkers[key]._position.lat(), this.mapUnitsMarkers[key]._position.lng()))
      }
      this.map.fitBounds(bounds)
    },
    centerGeozone (geofence, mode = null) {
      const bounds = new this.google.maps.LatLngBounds()

      if (mode === 'RADIAL') {
        const overlay = new this.google.maps.Circle({
          center: {
            lat: geofence.path[0].latitude,
            lng: geofence.path[0].longitude
          },
          radius: geofence.path[0].radius,
          fillColor: geofence.color,
          strokeColor: geofence.color,
          fillOpacity: 0.3,
          strokeOpacity: 1,
          strokeWeight: 4,
          clickable: false,
          editable: this.isEditting,
          zIndex: 1
        })
        bounds.union(overlay.getBounds())
      } else {
        for (const key in geofence.path) {
          bounds.extend(new this.google.maps
            .LatLng(geofence.path[key].latitude, geofence.path[key].longitude))
        }
      }

      this.map.fitBounds(bounds)
    },
    drawUnit ({ unit, latitude, longitude }) {
      const coordinates = new this.google.maps.LatLng(
        latitude,
        longitude
      )
      // const imageUrl = 'https://www.svgrepo.com/show/376955/map-marker.svg'
      // const icon = {
      //   url: imageUrl, // url
      //   scaledSize: new this.google.maps.Size(50, 50), // scaled size
      //   origin: new this.google.maps.Point(0, 0), // origin
      //   anchor: new this.google.maps.Point(0, 0) // anchor
      // }
      this.mapUnitsMarkers[unit.id] = new this.UnitMarker({
        position: coordinates,
        map: this.map,
        google: this.google,
        name: unit.name,
        args: unit.telemetry
      })
      // this.mapUnitsMarkers[unit.id] = new this.google.maps.Marker({
      //   position: coordinates,
      //   map: this.map,
      //   icon: icon
      // })
    },

    loadMap () {
      if (!this.$googlemaps.done) {
        this.$googlemaps
          .load()
          .then((google) => {
            this.google = google
            // to use the label drawer "class" window.google must exist and the file is called to be require
            window.google = google

            this.UnitMarker = require('./UnitMarker.js')

            this.initMap()
          })
      } else {
        this.UnitMarker = require('./UnitMarker.js')

        this.google = window.google
        this.initMap()
      }
    },
    /**
     * Initialize Singleton instance of Google maps
     */
    initMap () {
      if (this.data !== null) {
        this.defaultCenter = new this.google.maps.LatLng(this.data.latitude, this.data.longitude)
      } else {
        this.defaultCenter = new this.google.maps.LatLng(inititalCoordinates.latitude, inititalCoordinates.longitude)
      }

      this.map = new this.google.maps.Map(
        this.$refs.map,
        {
          mapTypeId: 'roadmap',
          zoom: 14,
          center: this.defaultCenter,
          disableDefaultUI: true,
          zoomControl: true,
          mapTypeControl: this.mapTypeControl,
          mapTypeControlOptions: {
            position: this.google.maps.ControlPosition.RIGHT_TOP
          }
        }
      )

      setTimeout(() => {
        if (this.marker) {
          this.clearMarker(this.marker)
          this.marker = null
        }
        const geofendeDepot = this.geofences.find(el => el.id === this.userHomeDepotId)
        if (geofendeDepot) {
          this.centerGeozone(geofendeDepot, geofendeDepot.mode)
        }
        this.drawMarker()
        this.drawGeofences()
      }, 0)
    },

    drawMarker () {
      if (this.data !== null) {
        const coordinates = new this.google.maps.LatLng(
          this.data.latitude,
          this.data.longitude
        )
        this.addMarker(coordinates)
      }
    },

    addMarker (coordinates, title = '', icon = null) {
      return new this.google.maps.Marker({
        position: coordinates,
        label: {
          text: title,
          fontWeight: 'bold',
          fontSize: '12px',
          className: 'geozone-marker-label'
        },
        map: this.map,
        title: title,
        labelOrigin: new this.google.maps.Point(26.5, 20),
        anchor: new this.google.maps.Point(26.5, 43),
        icon: icon
      })
    },

    clearMarker (marker) {
      if (marker) {
        marker.setMap(null)
      }
    },
    /**
     * Start draw circle mode
     */
    drawCircle (geofence) {
      this.drawer.setOptions({
        drawingMode: this.google.maps.drawing.OverlayType.CIRCLE,
        circleOptions: {
          fillColor: geofence.color,
          strokeColor: geofence.color, // color
          fillOpacity: 0.3,
          strokeOpacity: 1,
          strokeWeight: 4,
          clickable: false,
          editable: true,
          zIndex: 1
        }
      })

      this.drawer.setMap(this.map)
    },
    /**
     * Start draw polygon mode
     */
    drawPolygon (geofence) {
      this.drawer.setOptions({
        drawingMode: this.google.maps.drawing.OverlayType.POLYGON,
        polygonOptions: {
          fillColor: geofence.color, // color
          strokeColor: geofence.color, // color
          fillOpacity: 0.3,
          strokeOpacity: 1,
          strokeWeight: 4,
          clickable: false,
          editable: true,
          zIndex: 1
        }
      })

      this.drawer.setMap(this.map)
    },
    /**
     * Start draw line mode
     */
    drawLine (geofence) {
      this.drawer.setOptions({
        drawingMode: this.google.maps.drawing.OverlayType.POLYLINE,
        polylineOptions: {
          fillColor: geofence.color,
          strokeColor: geofence.color, // color
          fillOpacity: 0.3,
          strokeOpacity: 1,
          strokeWeight: 7,
          clickable: false,
          editable: true,
          zIndex: 1
        }
      })

      this.drawer.setMap(this.map)
    },
    /**
     * redraw polygon called while editing
     */
    redrawPolygon (geofence) {
      if (geofence) {
        geofence.setMap(null)
        geofence.setOptions({
          fillColor: this.object.color,
          strokeColor: this.object.color
        })
        geofence.setMap(this.map)
      }
    },
    /**
     * called to draw the geofence
     * when the method is called in show or edit mode
     */
    drawGeofence (geofence) {
      let overlay
      let marker
      let paths
      const google = this.google
      const bounds = new this.google.maps.LatLngBounds()
      let i

      switch (geofence.mode) {
        case 'LINEAR':
          paths = geofence.path.map((item) => {
            return {
              lat: item.latitude,
              lng: item.longitude
            }
          })
          overlay = new google.maps.Polyline({
            path: paths,
            geodesic: true,
            fillColor: geofence.color,
            strokeColor: geofence.color,
            fillOpacity: 0.3,
            strokeOpacity: 1,
            strokeWeight: 7,
            clickable: false,
            editable: this.isEditting,
            zIndex: 1
          })
          for (i = 0; i < paths.length; i++) {
            bounds.extend(paths[i])
          }
          marker = this.addMarker(bounds.getCenter(), geofence.name, this.geofenceMarker)
          break
        case 'RADIAL':
          overlay = new google.maps.Circle({
            center: {
              lat: geofence.path[0].latitude,
              lng: geofence.path[0].longitude
            },
            radius: geofence.path[0].radius,
            fillColor: geofence.color,
            strokeColor: geofence.color,
            fillOpacity: 0.3,
            strokeOpacity: 1,
            strokeWeight: 4,
            clickable: false,
            editable: this.isEditting,
            zIndex: 1
          })

          marker = this.addMarker(
            {
              lat: geofence.path[0].latitude,
              lng: geofence.path[0].longitude
            },
            geofence.name,
            this.geofenceMarker
          )

          break
        case 'POLYGON':
          paths = geofence.path.map((item) => {
            return {
              lat: item.latitude,
              lng: item.longitude
            }
          })
          overlay = new google.maps.Polygon({
            paths,
            fillColor: geofence.color,
            strokeColor: geofence.color,
            fillOpacity: 0.3,
            strokeOpacity: 1,
            strokeWeight: 4,
            clickable: false,
            editable: this.isEditting,
            zIndex: 1
          })
          for (i = 0; i < paths.length; i++) {
            bounds.extend(paths[i])
          }
          marker = this.addMarker(bounds.getCenter(), geofence.name, this.geofenceMarker)
          break
      }
      this.mapGeofences[geofence.id] = overlay
      this.mapGeofences[geofence.id].setMap(this.map)
      this.mapGeofenceMarkers[geofence.id] = marker
      this.mapGeofenceMarkers[geofence.id].setMap(this.map)
    },
    removeAllGeofences () {
      for (const key in this.mapGeofences) {
        if (Object.hasOwnProperty.call(this.mapGeofences, key)) {
          this.mapGeofences[key].setMap(null)
          this.mapGeofences[key] = undefined
          this.mapGeofenceMarkers[key].setMap(null)
          this.mapGeofenceMarkers[key] = undefined

          delete this.mapGeofences[key]
          delete this.mapGeofenceMarkers[key]
        }
      }
    },
    removeGeofencesByID (ids) {
      for (let i = 0; i < ids.length; i++) {
        this.mapGeofences[ids[i]].setMap(null)
        this.mapGeofences[ids[i]] = undefined
        this.mapGeofenceMarkers[ids[i]].setMap(null)
        this.mapGeofenceMarkers[ids[i]] = undefined

        delete this.mapGeofences[ids[i]]
        delete this.mapGeofenceMarkers[ids[i]]
      }
    },
    drawGeofences () {
      const idsToRemove = Object.keys(this.mapGeofences).filter(key => !this.geofences.some(geofence => geofence.id === key))

      this.removeGeofencesByID(idsToRemove)
      if (this.geofences.length > 0) {
        this.geofences.forEach((geofence) => {
          if (!Object.keys(this.mapGeofences).includes(geofence.id)) {
            this.drawGeofence(geofence)
          }
        })
      }
    },
    drawTrips () {
      // Generate polylines and bounds
      const bounds = new this.google.maps.LatLngBounds()
      const generalPoints = []
      for (const i in this.trips) {
        const trip = this.trips[i]
        const points = []
        for (const j in trip.messages) {
          if (trip.messages[j].position) {
            if (trip.messages[j].position.latitude) {
              const point = {
                lat: trip.messages[j].position.latitude,
                lng: trip.messages[j].position.longitude
              }
              const marker = new this.google.maps.Marker({
                position: point,
                map: this.map
              })
              this.markers.push(marker)
              points.push(point)
              generalPoints.push(point)
              bounds.extend(point)
            }
          }
        }
        //  Draw polyline
        this.polylines.push(
          this.drawPolilyne(
            points
          )
        )
      }

      this.map.fitBounds(bounds)
    }
  }
}
</script>

<style lang="scss">
</style>