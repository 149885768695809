<template>
  <div
    id="summary_map"
    ref="map"
    class="grey"
    style="width: 100%;"
    :style="`height: ${height}px;`"
  />
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { inititalCoordinates } from '@/plugins/constants'

export default {
  props: {
    data: {
      type: Object,
      default: () => null
    },
    showMap: {
      type: Boolean,
      default: () => true
    },
    height: {
      type: Number,
      default: () => 400
    },
    mapTypeControl: {
      type: Boolean,
      default: () => false
    },
    drawMarkerToggle: {
      type: Boolean,
      default: () => false
    },
    trips: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      map: null, // Google Maps Map entity
      google: null, // Google Maps object
      defaultCenter: null,
      polylines: [],
      marker: null,
      markers: [],
      mapGeofences: {},
      mapGeofenceMarkers: {}
    }
  },
  computed: {
    ...mapState(['width', 'isLoading']),
    ...mapGetters({
      // Maintenace
      userInitialCoordinates: 'geofences/userHomeDepot', geofenceMarker: 'general/geofenceMarker'
    }),
    geofences () { return this.$store.state.geofences.list },
    userHomeDepotId () { return this.$store.state.session.homeDepotId }

  },

  watch: {
    userHomeDepotId (newVal) {
      if (this.map) {
        const geofendeDepot = this.geofences.find(el => el.id === newVal)
        if (geofendeDepot) {
          this.removeGeofencesByID([geofendeDepot.id])
          this.centerGeozone(geofendeDepot, geofendeDepot.mode)
          this.drawGeofence(geofendeDepot)
        }
      }
    },
    geofences () {
      if (this.map) {
        const geofendeDepot = this.geofences.find(el => el.id === this.userHomeDepotId)
        if (geofendeDepot) {
          this.removeGeofencesByID([geofendeDepot.id])
          this.centerGeozone(geofendeDepot, geofendeDepot.mode)
          this.drawGeofence(geofendeDepot)
        }
      }
    },
    showMap (newVal) {
      if (newVal) {
        this.loadMap()
      } else {
        // 
      }
    },
    drawMarkerToggle (_) {
      this.clearMarker(this.marker)
      this.clearMarkers()
      this.clearPolylines()
      this.drawMarker()
    }
  },

  mounted () {
    if (this.showMap) {
      this.loadMap()
    }
  },

  methods: {
    loadMap () {
      if (!this.$googlemaps.done) {
        this.$googlemaps
          .load()
          .then((google) => {
            this.google = google
            // to use the label drawer "class" window.google must exist and the file is called to be require
            window.google = google
            this.initMap()
            // this.initDrawer()
            // this.startDrawing()
          })
      } else {
        this.google = window.google
        this.initMap()
        // this.initDrawer()
        // this.startDrawing()
      }
    },
    /**
     * Initialize Singleton instance of Google maps
     */
    initMap () {
      if (this.data !== null) {
        this.defaultCenter = new this.google.maps.LatLng(this.data.latitude, this.data.longitude)
      } else {
        this.defaultCenter = new this.google.maps.LatLng(inititalCoordinates.latitude, inititalCoordinates.longitude)
      }

      this.map = new this.google.maps.Map(
        this.$refs.map,
        {
          mapTypeId: 'roadmap',
          zoom: 14,
          center: this.defaultCenter,
          disableDefaultUI: true,
          zoomControl: true,
          mapTypeControl: this.mapTypeControl,
          mapTypeControlOptions: {
            position: this.google.maps.ControlPosition.RIGHT_TOP
          }
        }
      )

      setTimeout(() => {
        if (this.marker) {
          this.clearMarker(this.marker)
          this.marker = null
        }
        this.drawMarker()
        const geofendeDepot = this.geofences.find(el => el.id === this.userHomeDepotId)
        if (geofendeDepot) {
          this.removeGeofencesByID([geofendeDepot.id])
          this.centerGeozone(geofendeDepot, geofendeDepot.mode)
          this.drawGeofence(geofendeDepot)
        }
      }, 0)
    },

    centerGeozone (geofence, mode = null) {
      const bounds = new this.google.maps.LatLngBounds()

      if (mode === 'RADIAL') {
        const overlay = new this.google.maps.Circle({
          center: {
            lat: geofence.path[0].latitude,
            lng: geofence.path[0].longitude
          },
          radius: geofence.path[0].radius,
          fillColor: geofence.color,
          strokeColor: geofence.color,
          fillOpacity: 0.3,
          strokeOpacity: 1,
          strokeWeight: 4,
          clickable: false,
          editable: this.isEditting,
          zIndex: 1
        })
        bounds.union(overlay.getBounds())
      } else {
        for (const key in geofence.path) {
          bounds.extend(new this.google.maps
            .LatLng(geofence.path[key].latitude, geofence.path[key].longitude))
        }
      }

      this.map.fitBounds(bounds)
    },

    drawGeofence (geofence) {
      let overlay
      let marker
      let paths
      const google = this.google
      const bounds = new this.google.maps.LatLngBounds()
      let i

      switch (geofence.mode) {
        case 'LINEAR':
          paths = geofence.path.map((item) => {
            return {
              lat: item.latitude,
              lng: item.longitude
            }
          })
          overlay = new google.maps.Polyline({
            path: paths,
            geodesic: true,
            fillColor: geofence.color,
            strokeColor: geofence.color,
            fillOpacity: 0.3,
            strokeOpacity: 1,
            strokeWeight: 7,
            clickable: false,
            editable: this.isEditting,
            zIndex: 1
          })
          for (i = 0; i < paths.length; i++) {
            bounds.extend(paths[i])
          }
          marker = this.addMarker(bounds.getCenter(), geofence.name, this.geofenceMarker)
          break
        case 'RADIAL':
          overlay = new google.maps.Circle({
            center: {
              lat: geofence.path[0].latitude,
              lng: geofence.path[0].longitude
            },
            radius: geofence.path[0].radius,
            fillColor: geofence.color,
            strokeColor: geofence.color,
            fillOpacity: 0.3,
            strokeOpacity: 1,
            strokeWeight: 4,
            clickable: false,
            editable: this.isEditting,
            zIndex: 1
          })

          marker = this.addMarker(
            {
              lat: geofence.path[0].latitude,
              lng: geofence.path[0].longitude
            },
            geofence.name,
            this.geofenceMarker
          )

          break
        case 'POLYGON':
          paths = geofence.path.map((item) => {
            return {
              lat: item.latitude,
              lng: item.longitude
            }
          })
          overlay = new google.maps.Polygon({
            paths,
            fillColor: geofence.color,
            strokeColor: geofence.color,
            fillOpacity: 0.3,
            strokeOpacity: 1,
            strokeWeight: 4,
            clickable: false,
            editable: this.isEditting,
            zIndex: 1
          })
          for (i = 0; i < paths.length; i++) {
            bounds.extend(paths[i])
          }
          marker = this.addMarker(bounds.getCenter(), geofence.name, this.geofenceMarker)
          break
      }
      this.mapGeofences[geofence.id] = overlay
      this.mapGeofences[geofence.id].setMap(this.map)
      this.mapGeofenceMarkers[geofence.id] = marker
      this.mapGeofenceMarkers[geofence.id].setMap(this.map)
    },

    removeGeofencesByID (ids) {
      for (let i = 0; i < ids.length; i++) {
        if (this.mapGeofences[ids[i]]) {
          this.mapGeofences[ids[i]].setMap(null)
          this.mapGeofences[ids[i]] = undefined
          this.mapGeofenceMarkers[ids[i]].setMap(null)
          this.mapGeofenceMarkers[ids[i]] = undefined

          delete this.mapGeofences[ids[i]]
          delete this.mapGeofenceMarkers[ids[i]]
        }
      }
    },

    drawMarker () {
      if (this.data !== null) {
        const coordinates = new this.google.maps.LatLng(this.data.latitude, this.data.longitude)
        this.marker = this.addMarker(coordinates)
        this.map.panTo((coordinates))
      }
    },

    drawPolilyne (points) {
      const polyline = new this.google.maps.Polyline({
        path: points,
        geodesic: true,
        strokeColor: '#FF0000',
        strokeOpacity: 1.0,
        strokeWeight: 2
      })
      polyline.setMap(this.map)
      return polyline
    },

    // addMarker (coordinates) {
    //   return new this.google.maps.Marker({
    //     position: coordinates,
    //     map: this.map
    //   })
    // },

    addMarker (coordinates, title = '', icon = null) {
      return new this.google.maps.Marker({
        position: coordinates,
        label: {
          text: title,
          fontWeight: 'bold',
          fontSize: '12px',
          className: 'geozone-marker-label'
        },
        map: this.map,
        title: title,
        labelOrigin: new this.google.maps.Point(26.5, 20),
        anchor: new this.google.maps.Point(26.5, 43),
        icon: icon
      })
    },

    clearMarker (marker) {
      if (this.marker) {
        marker.setMap(null)
      }
    },

    clearPolylines () {
      for (const i in this.polylines) {
        if (this.polylines[i]) {
          this.polylines[i].setMap(null)
        }
      }
      this.polylines = []
    },

    clearMarkers () {
      for (const i in this.markers) {
        if (this.markers[i]) {
          this.markers[i].setMap(null)
        }
      }
      this.markers = []
    },

    drawTripsMethod () {
      // Generate polylines and bounds
      const bounds = new this.google.maps.LatLngBounds()
      const generalPoints = []
      for (const i in this.trips) {
        const trip = this.trips[i]
        const points = []
        for (const j in trip.messages) {
          if (trip.messages[j].position) {
            if (trip.messages[j].position.latitude) {
              const point = {
                lat: trip.messages[j].position.latitude,
                lng: trip.messages[j].position.longitude
              }
              const marker = new this.google.maps.Marker({
                position: point,
                map: this.map
              })
              this.markers.push(marker)
              points.push(point)
              generalPoints.push(point)
              bounds.extend(point)
            }
          }
        }
        //  Draw polyline
        this.polylines.push(this.drawPolilyne(points))
      }

      this.map.fitBounds(bounds)
    }

    // Center Map
  }
}
</script>

<style lang="scss">
</style>