
import { i18n } from '@/plugins/i18n'
import { apollo } from '@/plugins/apollo'
// graphql files
import list from 'GraphQL/queries/checkTemplates/list.gql'
import detail from 'GraphQL/queries/checkTemplates/details.gql'
import add from 'GraphQL/mutations/checkTemplates/add.gql'
import edit from 'GraphQL/mutations/checkTemplates/edit.gql'
import del from 'GraphQL/mutations/checkTemplates/delete.gql'

export default {
  async getList ({ rootState, commit }, payload = false) {
    if (payload) {
      await commit('setLoading', true, { root: true })
    }

    await apollo.query({
      query: list,
      variables: {
        apiToken: rootState.session.apiToken
      }
    }).then(response => {
      const { status, result } = response.data.checks

      switch (status) {
        case 'OK':
          commit('setList', result)
          break
        case 'ACCESSDENIED':
          commit('toggleSnackbar', { message: i18n.t('errors.accessDenied'), color: 'red darken-2' }, { root: true })
          break
        case 'BADREQUEST':
          commit('toggleSnackbar', { message: i18n.t('errors.invalidFields'), color: 'orange darken-2' }, { root: true })
          break
        case 'UNPROCESSABLE':
          commit('toggleSnackbar', { message: i18n.t('errors.invalidFields'), color: 'orange darken-2' }, { root: true })
          break
        case 'INTERNALERROR':
          commit('toggleSnackbar', { message: i18n.t('helpers.errors.disaster'), color: 'red darken-2' }, { root: true })
          break
      }
    }).catch(err => {
      console.error(err)
      commit('toggleSnackbar', { message: i18n.t('helpers.errors.disaster'), color: 'red darken-2' }, { root: true })
    }).finally(() => {
    })
  },
  async updateList ({ rootState, commit }, payload = false) {
    if (payload) {
      await commit('setLoading', true, { root: true })
    }

    await apollo.query({
      query: list,
      variables: {
        apiToken: rootState.session.apiToken
      }
    }).then(response => {
      const { status, result } = response.data.checks

      switch (status) {
        case 'OK':
          commit('updateList', { rootState: rootState, payload: result, commit: commit })
          break
        case 'ACCESSDENIED':
          commit('toggleSnackbar', { message: i18n.t('errors.accessDenied'), color: 'red darken-2' }, { root: true })
          break
        case 'BADREQUEST':
          commit('toggleSnackbar', { message: i18n.t('errors.invalidFields'), color: 'orange darken-2' }, { root: true })
          break
        case 'UNPROCESSABLE':
          commit('toggleSnackbar', { message: i18n.t('errors.invalidFields'), color: 'orange darken-2' }, { root: true })
          break
        case 'INTERNALERROR':
          commit('toggleSnackbar', { message: i18n.t('helpers.errors.disaster'), color: 'red darken-2' }, { root: true })
          break
      }
    }).catch(err => {
      commit('cleanInterval', err)
    }).finally(() => {
    })
  },
  async details ({ rootState, commit }, id) {
    await commit('setLoading', true, { root: true })
    await apollo.query({
      query: detail,
      variables: {
        apiToken: rootState.session.apiToken,
        id
      }
    }).then(response => {
      const { status, result } = response.data.checks
      switch (status) {
        case 'OK':
          if (result.length === 1) {
            commit('toggle', { method: 'details', status, result: result[0] })
          } else {
            commit('toggleSnackbar', { message: i18n.t('errors.itemNotFound'), color: 'red darken-2' }, { root: true })
            commit('toggle', { method: 'details', status: 'NOTFOUND', result: null })
          }
          break
        case 'ACCESSDENIED':
          commit('toggleSnackbar', { message: i18n.t('errors.accessDenied'), color: 'red darken-2' }, { root: true })
          commit('toggle', { method: 'details', status, result: null })
          break
        case 'BADREQUEST':
          commit('toggleSnackbar', { message: i18n.t('errors.invalidFields'), color: 'orange darken-2' }, { root: true })
          commit('toggle', { method: 'details', status, result: null })
          break
        case 'UNPROCESSABLE':
          commit('toggleSnackbar', { message: i18n.t('errors.invalidFields'), color: 'orange darken-2' }, { root: true })
          commit('toggle', { method: 'details', status, result: null })
          break
        case 'INTERNALERROR':
          commit('toggleSnackbar', { message: i18n.t('helpers.errors.disaster'), color: 'red darken-2' }, { root: true })
          commit('toggle', { method: 'details', status, result: null })
          break
      }
    }).catch(err => {
      console.error(err)
      commit('toggleSnackbar', { message: i18n.t('helpers.errors.disaster'), color: 'red darken-2' }, { root: true })
      commit('toggle', { method: 'details', status: 'INTERNALERROR', result: null })
    }).finally(() => {
      commit('setLoading', false, { root: true })
    })
  },

  async add ({ rootState, commit, dispatch }, data) {
    await commit('setLoading', true, { root: true })
    await apollo.mutate({
      mutation: add,
      variables: {
        apiToken: rootState.session.apiToken,
        data
      }
    }).then(response => {
      const { status, errors, result } = response.data.addCheck

      switch (status) {
        case 'OK':
          commit('toggle', { method: 'add', status, errors, result })
          // dispatch('getList')
          commit('newItem', result)
          break
        case 'NOTFOUND':
          commit('toggleSnackbar', { message: i18n.t('errors.itemNotFound'), color: 'red darken-2' }, { root: true })
          commit('toggle', { method: 'add', status, result: null })
          dispatch('getList')
          break
        case 'ACCESSDENIED':
          commit('toggleSnackbar', { message: i18n.t('errors.accessDenied'), color: 'red darken-2' }, { root: true })
          commit('toggle', { method: 'add', status, errors, result: null })
          break
        case 'BADREQUEST':
          commit('toggleSnackbar', { message: i18n.t('errors.invalidFields'), color: 'orange darken-2' }, { root: true })
          commit('toggle', { method: 'add', status, errors, result: null })
          break
        case 'UNPROCESSABLE':
          commit('toggleSnackbar', { message: i18n.t('errors.invalidFields'), color: 'orange darken-2' }, { root: true })
          commit('toggle', { method: 'add', status, errors, result: null })
          break
        case 'INTERNALERROR':
          commit('toggleSnackbar', { message: i18n.t('helpers.errors.disaster'), color: 'red darken-2' }, { root: true })
          commit('toggle', { method: 'add', status, errors, result: null })
          break
      }
    }).catch(err => {
      console.error(err)
      commit('toggleSnackbar', { message: i18n.t('helpers.errors.disaster'), color: 'red darken-2' }, { root: true })
      commit('toggle', { method: 'add', status: 'INTERNALERROR', result: null })
    }).finally(() => {
      commit('setLoading', false, { root: true })
    })
  },

  async edit ({ rootState, commit, dispatch }, data) {
    await commit('setLoading', true, { root: true })
    await apollo.mutate({
      mutation: edit,
      variables: {
        apiToken: rootState.session.apiToken,
        data
      }
    }).then(response => {
      const { status, errors, result } = response.data.editCheck

      switch (status) {
        case 'OK':
          commit('toggle', { method: 'edit', status, errors, result })
          // dispatch('getList')
          commit('updateItem', result)
          break
        case 'NOTFOUND':
          commit('toggleSnackbar', { message: i18n.t('errors.itemNotFound'), color: 'red darken-2' }, { root: true })
          commit('toggle', { method: 'edit', status, result: null })
          dispatch('getList')
          break
        case 'ACCESSDENIED':
          commit('toggleSnackbar', { message: i18n.t('errors.accessDenied'), color: 'red darken-2' }, { root: true })
          commit('toggle', { method: 'edit', status, errors, result: null })
          break
        case 'BADREQUEST':
          commit('toggleSnackbar', { message: i18n.t('errors.invalidFields'), color: 'orange darken-2' }, { root: true })
          commit('toggle', { method: 'edit', status, errors, result: null })
          break
        case 'UNPROCESSABLE':
          commit('toggleSnackbar', { message: i18n.t('errors.invalidFields'), color: 'orange darken-2' }, { root: true })
          commit('toggle', { method: 'edit', status, errors, result: null })
          break
        case 'INTERNALERROR':
          commit('toggleSnackbar', { message: i18n.t('helpers.errors.disaster'), color: 'red darken-2' }, { root: true })
          commit('toggle', { method: 'edit', status, errors, result: null })
          break
      }
    }).catch(err => {
      console.error(err)
      commit('toggleSnackbar', { message: i18n.t('helpers.errors.disaster'), color: 'red darken-2' }, { root: true })
      commit('toggle', { method: 'edit', status: 'INTERNALERROR', result: null })
    }).finally(() => {
      commit('setLoading', false, { root: true })
    })
  },

  async delete ({ rootState, commit }, ids) {
    await commit('setLoading', true, { root: true })
    await apollo.mutate({
      mutation: del,
      variables: {
        apiToken: rootState.session.apiToken,
        ids
      }
    }).then(response => {
      const { status } = response.data.deleteCheck

      switch (status) {
        case 'OK':
          commit('toggle', { method: 'delete', status, result: null })
          // console.log('delete result', result)
          // result.forEach(notification =>
          //   commit('deleteItem', notification.id)
          // )
          break
        case 'NOTFOUND':
          commit('toggleSnackbar', { message: i18n.t('errors.itemNotFound'), color: 'red darken-2' }, { root: true })
          commit('toggle', { method: 'delete', status, result: null })
          break
        case 'ACCESSDENIED':
          commit('toggleSnackbar', { message: i18n.t('errors.accessDenied'), color: 'red darken-2' }, { root: true })
          commit('toggle', { method: 'delete', status, result: null })
          break
        case 'BADREQUEST':
          commit('toggleSnackbar', { message: i18n.t('errors.invalidFields'), color: 'orange darken-2' }, { root: true })
          commit('toggle', { method: 'delete', status, result: null })
          break
        case 'UNPROCESSABLE':
          commit('toggleSnackbar', { message: i18n.t('errors.invalidFields'), color: 'orange darken-2' }, { root: true })
          commit('toggle', { method: 'delete', status, result: null })
          break
        case 'INTERNALERROR':
          commit('toggleSnackbar', { message: i18n.t('helpers.errors.disaster'), color: 'red darken-2' }, { root: true })
          commit('toggle', { method: 'delete', status, result: null })
          break
      }
    }).catch(err => {
      console.error(err)
      commit('toggleSnackbar', { message: i18n.t('helpers.errors.disaster'), color: 'red darken-2' }, { root: true })
      commit('toggle', { method: 'delete', status: 'INTERNALERROR', result: null })
    }).finally(() => {
      commit('setLoading', false, { root: true })
    })
  }
}
