export default {
  
  avatar (state) {
    if (state.avatar) {
      return state.avatar
    }
    return `${state.name.substring(0, 2).toUpperCase()}`
  },

  hasAvatar (state) {
    return !(state.avatar === undefined || state.avatar === null)
  },

  fullName (state) {
    return `${state.name}`
  },

  canLoginAs (state) {
    let can = false

    if (state.permission) {
      if (state.permission.users) {
        can = state.permission.users === 4
      }
    }

    return can
  },

  canBilling (state) {
    let can = false

    if (state.permission) {
      if (state.permission.users) {
        can = state.permission.users >= 3
      }
    }

    return can
  },

  canSee (state) {
    let apps = false
    let languages = false
    let users = false

    if (state.permission) {
      if (state.permission.apps) {
        apps = state.permission.apps >= 1
      }

      if (state.permission.languages) {
        languages = state.permission.languages >= 1
      }

      if (state.permission.users) {
        users = state.permission.users >= 1
      }
    }

    return {
      apps,
      languages,
      users
    }
  },

  canEdit (state) {
    let users = false
    let apps = false
    let languages = false

    if (state.permission) {
      if (state.permission.users) {
        users = state.permission.users >= 2
      }

      if (state.permission.apps) {
        apps = state.permission.apps === 2
      }

      if (state.permission.languages) {
        languages = state.permission.languages === 2
      }
    }

    return {
      users,
      apps,
      languages
    }
  }
}