export default {
  defaultObject () {
    return {
      id: null,
      name: '',
      email: '',
      username: '',
      password: '',
      avatar: null,
      containerId: null,
      tagsIds: [],
      tags: [],
      profile: {
        companyName: '',
        headquartersId: null,
        billingAddress: '',
        fiscalId: ''
      }
    }
  }
}