<template>
  <v-layout row wrap style="overflow: auto">
    <v-flex xs12>
      <v-card flat class="ml-4 mr-4">
        <v-card-title
          class="grey--text text--darken-2 font-weight-bold list_title headline pb-1 mb-2"
        >
          Vehicle Profile for {{ unit.name }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <!-- TRAILER PROFILE Values -->
            <!-- Trailer profile switch values -->
            <v-col cols="3" md="2" offset="0" offset-md="1">
              <v-switch
                v-model="trailerProfile.vor"
                inset
                label="VOR"
              />
            </v-col>
            <template v-if="unit.style === 'TRAILER' || unit.style === 'TRAILER_12'">
              <v-col cols="3" md="2">
                <v-switch
                  v-model="trailerProfile.ebpmsActive"
                  inset
                  label="Brake Performance"
                />
              </v-col>
              <v-col cols="3" md="2">
                <v-switch
                  v-model="trailerProfile.tailLift"
                  inset
                  label="Tail Lift"
                />
              </v-col>
              <v-col cols="3" md="2">
                <v-switch
                  v-model="trailerProfile.moffetMounts"
                  inset
                  label="Moffet Mounts"
                />
              </v-col>
              <v-col cols="3" md="2">
                <v-switch
                  v-model="trailerProfile.tpmsActive"
                  inset
                  label="TPMS"
                />
              </v-col>
            </template>
            <v-col cols="12">
              <v-divider :key="`divider__1`" />
            </v-col>
            <!-- End Trailer profile switch values -->
            <!-- Trailer Profile cards -->
            <v-col cols="12">
              <v-card elevation="2" style="background: rgba(115, 22, 14, 0.25);">
                <v-card-title
                  class="grey--text text--darken-2 font-weight-bold headline pb-1 mb-2"
                >
                  {{ $t('trailerProfile.trailerDetails').toUpperCase() }}
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="4">
                      <v-select
                        v-model="trailerProfile.trailerStyle"
                        :items="styles"
                        dense
                        label="Trailer Style"
                        outlined
                        item-text="label"
                        item-value="value"
                        prepend-inner-icon="mdi-truck-trailer"
                        hide-details="auto"
                        :error="errors.trailerStyle.length > 0"
                        :error-messages="errors.trailerStyle"
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="trailerProfile.height"
                        label="Trailer Height"
                        outlined
                        dense
                        prepend-inner-icon="mdi-ruler"
                        hide-details="auto"
                        suffix="mts"
                        type="number"
                        min="0"
                        :disabled="unit.ftpEnabled"
                        :error="errors.height.length > 0"
                        :error-messages="errors.height"
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="trailerProfile.description"
                        label="Description"
                        outlined
                        dense
                        prepend-inner-icon="mdi-label"
                        hide-details="auto"
                        :disabled="unit.ftpEnabled"
                        :error="errors.description.length > 0"
                        :error-messages="errors.description"
                      />
                    </v-col>
                    <v-col xs="12" sm="6">
                      <v-text-field
                        v-model="trailerProfile.year"
                        label="Year of manufacture"
                        outlined
                        dense
                        prepend-inner-icon="mdi-label"
                        hide-details="auto"
                        :disabled="unit.ftpEnabled"
                        :error="errors.year.length > 0"
                        :error-messages="errors.year"
                      />
                    </v-col>
                    <v-col xs="12" sm="6">
                      <v-text-field
                        v-model="trailerProfile.colour"
                        label="Colour"
                        outlined
                        dense
                        prepend-inner-icon="mdi-label"
                        hide-details="auto"
                        :disabled="unit.ftpEnabled"
                        :error="errors.colour.length > 0"
                        :error-messages="errors.colour"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card elevation="2" style="background: rgba(128, 128, 128, 0.15);">
                <v-card-title
                  class="grey--text text--darken-2 font-weight-bold headline pb-1 mb-2"
                >
                  {{ $t('trailerProfile.ebpms').toUpperCase() }}
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col xs="12" sm="6" md="4">
                      <v-text-field
                        v-model="trailerProfile.weight"
                        label="Trailer Weight"
                        outlined
                        dense
                        prepend-inner-icon="mdi-weight"
                        hide-details="auto"
                        suffix="kg"
                        type="number"
                        min="0"
                        :error="errors.weight.length > 0"
                        :error-messages="errors.weight"
                      />
                    </v-col>
                    <v-col xs="12" sm="6" md="4">
                      <v-text-field
                        v-model="trailerProfile.group"
                        dense
                        label="Fleet Identification"
                        outlined
                        item-text="label"
                        item-value="value"
                        prepend-inner-icon="mdi-label"
                        hide-details="auto"
                        :disabled="unit.ftpEnabled"
                        :error="errors.group.length > 0"
                        :error-messages="errors.group"
                      />
                    </v-col>
                    <v-col xs="12" sm="6" md="4">
                      <v-text-field
                        v-model="trailerProfile.operator"
                        label="Operator"
                        outlined
                        dense
                        prepend-inner-icon="mdi-tag"
                        hide-details="auto"
                        :disabled="unit.ftpEnabled"
                        :error="errors.operator.length > 0"
                        :error-messages="errors.operator"
                      />
                    </v-col>
                    <v-col xs="12" sm="6">
                      <v-text-field
                        v-model="trailerProfile.vin"
                        label="Trailer VIN"
                        outlined
                        dense
                        prepend-inner-icon="mdi-tag"
                        hide-details="auto"
                        :disabled="unit.ftpEnabled"
                        :error="errors.vin.length > 0"
                        :error-messages="errors.vin"
                      />
                    </v-col>
                    <v-col xs="12" sm="6">
                      <v-text-field
                        v-model="trailerProfile.vehicleType"
                        dense
                        label="Vehicle Type"
                        outlined
                        item-text="label"
                        item-value="value"
                        prepend-inner-icon="mdi-car-back"
                        hide-details="auto"
                        :error="errors.vehicleType.length > 0"
                        :error-messages="errors.vehicleType"
                      />
                    </v-col>
                    <v-col xs="12" sm="6">
                      <v-text-field
                        v-model="trailerProfile.ebsValveType"
                        dense
                        label="Ebs Valve Type"
                        outlined
                        item-text="label"
                        item-value="value"
                        prepend-inner-icon="mdi-label"
                        hide-details="auto"
                        :disabled="unit.ftpEnabled"
                        :error="errors.ebsValveType.length > 0"
                        :error-messages="errors.ebsValveType"
                      />
                    </v-col>
                    <v-col xs="12" sm="6">
                      <v-text-field
                        v-model="trailerProfile.vehicleMake"
                        dense
                        label="Vehicle Make"
                        outlined
                        item-text="label"
                        item-value="value"
                        prepend-inner-icon="mdi-car-arrow-right"
                        hide-details="auto"
                        :error="errors.vehicleMake.length > 0"
                        :error-messages="errors.vehicleMake"
                      />
                    </v-col>
                    <v-col xs="12" sm="6" md="6">
                      <v-dialog
                        ref="lastEbpmsReportDatedialog"
                        v-model="modalLastEbpmsReportDate"
                        :return-value.sync="trailerProfile.lastEbpmsReportDate"
                        persistent
                        width="290px"
                      >
                        <template #activator="{ on, attrs }">
                          <v-text-field
                            v-model="trailerProfile.lastEbpmsReportDate"
                            label="Last Ebpms Report Date"
                            prepend-inner-icon="mdi-calendar"
                            outlined
                            dense
                            readonly
                            hide-details="auto"
                            v-bind="attrs"
                            :error="errors.lastEbpmsReportDate.length > 0"
                            :error-messages="errors.lastEbpmsReportDate"
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          v-model="trailerProfile.lastEbpmsReportDate"
                          scrollable
                        >
                          <v-spacer />
                          <v-btn
                            text
                            color="primary"
                            @click="modalLastEbpmsReportDate = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.lastEbpmsReportDatedialog.save(trailerProfile.lastEbpmsReportDate)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                    <v-col xs="12" sm="6">
                      <v-text-field
                        v-model="trailerProfile.ebpmsInterval"
                        dense
                        label="EBPMS calculation Interval"
                        outlined
                        item-text="label"
                        item-value="value"
                        prepend-inner-icon="mdi-car-arrow-right"
                        hide-details="auto"
                        type="number"
                        :error="errors.ebpmsInterval.length > 0"
                        :error-messages="errors.ebpmsInterval"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card elevation="2" style="background: rgba(115, 22, 14, 0.25);">
                <v-card-title
                  class="grey--text text--darken-2 font-weight-bold headline pb-1 mb-2"
                >
                  {{ $t('trailerProfile.maintenance').toUpperCase() }}
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col xs="12" sm="6" cols="6">
                      <v-text-field
                        v-model="trailerProfile.plannedMonthlyMileage"
                        label="Planned Monthly Mileage"
                        outlined
                        dense
                        prepend-inner-icon="mdi-gauge"
                        hide-details="auto"
                        suffix="km"
                        type="number"
                        min="0"
                        :error="errors.plannedMonthlyMileage.length > 0"
                        :error-messages="errors.plannedMonthlyMileage"
                      />
                    </v-col>
                    <v-col xs="12" sm="6" cols="6">
                      <v-select
                        v-model="trailerProfile.ownerDepotId"
                        :items="depotGeofences"
                        dense
                        label="Owner Depot"
                        outlined
                        item-text="name"
                        item-value="id"
                        prepend-inner-icon="mdi-map-marker"
                        hide-details="auto"
                        :error="errors.ownerDepotId.length > 0"
                        :error-messages="errors.ownerDepotId"
                      />
                    </v-col>
                    <v-col xs="12" sm="6" md="4">
                      <v-dialog
                        ref="lastServicedialog"
                        v-model="modalLastService"
                        :return-value.sync="trailerProfile.lastService"
                        persistent
                        width="290px"
                      >
                        <template #activator="{ on, attrs }">
                          <v-text-field
                            v-model="trailerProfile.lastService"
                            label="Last Service Date"
                            prepend-inner-icon="mdi-calendar"
                            outlined
                            dense
                            readonly
                            hide-details="auto"
                            v-bind="attrs"
                            :error="errors.lastService.length > 0"
                            :error-messages="errors.lastService"
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          v-model="trailerProfile.lastService"
                          scrollable
                        >
                          <v-spacer />
                          <v-btn
                            text
                            color="primary"
                            @click="modalLastService = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.lastServicedialog.save(trailerProfile.lastService)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                    <v-col xs="12" sm="6" md="4">
                      <v-dialog
                        ref="nextServiceDialog"
                        v-model="modalNextService"
                        :return-value.sync="trailerProfile.nextServiceDate"
                        persistent
                        width="290px"
                      >
                        <template #activator="{ on, attrs }">
                          <v-text-field
                            v-model="trailerProfile.nextServiceDate"
                            label="Next Service Date"
                            prepend-inner-icon="mdi-calendar"
                            outlined
                            dense
                            readonly
                            hide-details="auto"
                            v-bind="attrs"
                            :error="errors.nextServiceDate.length > 0"
                            :error-messages="errors.nextServiceDate"
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          v-model="trailerProfile.nextServiceDate"
                          scrollable
                        >
                          <v-spacer />
                          <v-btn
                            text
                            color="primary"
                            @click="modalNextService = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.nextServiceDialog.save(trailerProfile.nextServiceDate)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                    <v-col xs="12" sm="6" md="4">
                      <v-text-field
                        v-model="trailerProfile.serviceInterval"
                        label="Service Interval"
                        outlined
                        dense
                        prepend-inner-icon="mdi-sort-calendar-ascending"
                        hide-details="auto"
                        suffix="days"
                        :disabled="unit.ftpEnabled"
                        :error="errors.serviceInterval.length > 0"
                        :error-messages="errors.serviceInterval"
                      />
                    </v-col>
                    <v-col xs="12" sm="6">
                      <v-dialog
                        ref="lastMotDialog"
                        v-model="modalLastMotDoe"
                        :return-value.sync="trailerProfile.lastMotDoe"
                        persistent
                        width="290px"
                      >
                        <template #activator="{ on, attrs }">
                          <v-text-field
                            v-model="trailerProfile.lastMotDoe"
                            label="Last MOT"
                            prepend-inner-icon="mdi-calendar"
                            outlined
                            dense
                            readonly
                            hide-details="auto"
                            v-bind="attrs"
                            :disabled="unit.ftpEnabled"
                            :error="errors.lastMotDoe.length > 0"
                            :error-messages="errors.lastMotDoe"
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          v-model="trailerProfile.lastMotDoe"
                          scrollable
                        >
                          <v-spacer />
                          <v-btn
                            text
                            color="primary"
                            @click="modalLastMotDoe = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.lastMotDialog.save(trailerProfile.lastMotDoe)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                    <v-col xs="12" sm="6">
                      <v-dialog
                        ref="nextMotDialog"
                        v-model="modalNextMotDoe"
                        :return-value.sync="trailerProfile.nextMotDoe"
                        persistent
                        width="290px"
                      >
                        <template #activator="{ on, attrs }">
                          <v-text-field
                            v-model="trailerProfile.nextMotDoe"
                            label="Next MOT"
                            prepend-inner-icon="mdi-calendar"
                            outlined
                            dense
                            readonly
                            hide-details="auto"
                            v-bind="attrs"
                            :disabled="unit.ftpEnabled"
                            :error="errors.nextMotDoe.length > 0"
                            :error-messages="errors.nextMotDoe"
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          v-model="trailerProfile.nextMotDoe"
                          scrollable
                        >
                          <v-spacer />
                          <v-btn
                            text
                            color="primary"
                            @click="modalNextMotDoe = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.nextMotDialog.save(trailerProfile.nextMotDoe)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card elevation="2" style="background: rgba(128, 128, 128, 0.15);">
                <v-card-title
                  class="grey--text text--darken-2 font-weight-bold headline pb-1 mb-2"
                >
                  {{ $t('trailerProfile.tbd').toUpperCase() }}
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="4">
                      <v-text-field
                        v-model="trailerProfile.tyreSize"
                        label="Tyre Size"
                        outlined
                        dense
                        prepend-inner-icon="mdi-label"
                        hide-details="auto"
                        :disabled="unit.ftpEnabled"
                        :error="errors.tyreSize.length > 0"
                        :error-messages="errors.tyreSize"
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="trailerProfile.axleMake"
                        label="Axle Make"
                        outlined
                        dense
                        prepend-inner-icon="mdi-label"
                        hide-details="auto"
                        :disabled="unit.ftpEnabled"
                        :error="errors.axleMake.length > 0"
                        :error-messages="errors.axleMake"
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="trailerProfile.suspensionMake"
                        label="Suspension Make"
                        outlined
                        dense
                        prepend-inner-icon="mdi-label"
                        hide-details="auto"
                        :disabled="unit.ftpEnabled"
                        :error="errors.suspensionMake.length > 0"
                        :error-messages="errors.suspensionMake"
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="trailerProfile.brakeSize"
                        label="Brake Size"
                        outlined
                        dense
                        prepend-inner-icon="mdi-label"
                        hide-details="auto"
                        :disabled="unit.ftpEnabled"
                        :error="errors.brakeSize.length > 0"
                        :error-messages="errors.brakeSize"
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="trailerProfile.lightingMake"
                        label="Lighting Make"
                        outlined
                        dense
                        prepend-inner-icon="mdi-label"
                        hide-details="auto"
                        :disabled="unit.ftpEnabled"
                        :error="errors.lightingMake.length > 0"
                        :error-messages="errors.lightingMake"
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="trailerProfile.contract"
                        label="Contract"
                        outlined
                        dense
                        prepend-inner-icon="mdi-label"
                        hide-details="auto"
                        :disabled="unit.ftpEnabled"
                        :error="errors.contract.length > 0"
                        :error-messages="errors.contract"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card elevation="2" style="background: rgba(128, 128, 128, 0.15);">
                <v-card-title
                  class="grey--text text--darken-2 font-weight-bold headline pb-1 mb-2"
                >
                  {{ $t('trailerProfile.tbd').toUpperCase() }}
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="4">
                      <v-text-field
                        v-model="trailerProfile.placardPressure"
                        label="Placard pressure"
                        outlined
                        dense
                        prepend-inner-icon="mdi-label"
                        hide-details="auto"
                        :error="errors.placardPressure.length > 0"
                        :error-messages="errors.placardPressure"
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="trailerProfile.underinflationWarning"
                        label="Underinflation Warning"
                        outlined
                        dense
                        prepend-inner-icon="mdi-label"
                        hide-details="auto"
                        :disabled="unit.ftpEnabled"
                        :error="errors.underinflationWarning.length > 0"
                        :error-messages="errors.underinflationWarning"
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="trailerProfile.extremeUnderinflationWarning"
                        label="Extreme Underinflation Warning"
                        outlined
                        dense
                        prepend-inner-icon="mdi-label"
                        hide-details="auto"
                        :disabled="unit.ftpEnabled"
                        :error="errors.extremeUnderinflationWarning.length > 0"
                        :error-messages="errors.extremeUnderinflationWarning"
                      />
                    </v-col>                    
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" style="display: flex; justify-content: flex-end;">
              <v-btn
                depressed
                color="success"
                @click="saveTrailerProfile"
              >
                save
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { i18n } from '@/plugins/i18n'
import { apollo } from '@/plugins/apollo'
import editTrailer from 'GraphQL/mutations/trailerProfiles/edit.gql'
import { mapState } from 'vuex'
import { orm } from '@/mixins/'
import dayjs from 'dayjs'

const utc = require('dayjs/plugin/utc')
export default {
  mixins: [orm],
  props: {
    unit: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      defaultObject: {
        trailerStyle: '',
        vin: '',
        height: '',
        weight: '',
        plannedMonthlyMileage: '',
        ownerDepotId: '',
        lastService: '',
        serviceInterval: '',
        ebsValveType: '',
        group: '',
        vehicleType: '',
        vehicleMake: '',
        operator: '',
        vehicleGrossWeight: '',
        vor: true,
        ebpmsActive: true,
        tailLift: true,
        moffetMounts: true,
        description: '',
        tyreSize: '',
        axleMake: '',
        colour: '',
        year: '',
        suspensionMake: '',
        brakeSize: '',
        lightingMake: '',
        nextServiceDate: '',
        lastMotDoe: '',
        nextMotDoe: '',
        contract: '',
        placardPressure: '',
        underinflationWarning: '',
        extremeUnderinflationWarning: '',
        lastEbpmsReportDate: '',
        ebpmsInterval: ''
      },
      errors: {},
      trailerProfile: {},
      modalLastService: false,
      modalNextService: false,
      modalLastMotDoe: false,
      modalNextMotDoe: false,
      modalLastEbpmsReportDate: false
    }
  },
  computed: {
    ...mapState(['isDark']),
    depotGeofences () { return this.$store.state.geofences.list.filter((geofence) => geofence.category === 'DEPOT') },
    user () { return this.$store.state.session },
    dialogValue: {
      get: function () {
        return this.dialog
      },
      set: function (value) {
        this.$emit('dialog-change', value)
      }
    },
    types () {
      return [
        {
          label: 'Brake Events',
          value: 'brakeEvent'
        }
        // {
        //   label: 'Brake Perfomance Value Trend line',
        //   value: 'brakePerformanceTrend'
        // }
      ]
    },
    reportTypes () {
      return [
        {
          label: 'EBPMS Report',
          value: 'ebpms'
        }
      ]
    },
    timeRanges () {
      return [
        {
          label: 'Today',
          value: '1'
        },
        {
          label: 'Week',
          value: '7'
        },
        {
          label: 'Month',
          value: '30'
        }
      ]
    },
    styles () {
      return [
        {
          label: '------',
          value: ''
        },
        {
          label: 'Flat',
          value: 'flat'
        },
        {
          label: 'Curtainsider',
          value: 'curtainsider'
        },
        {
          label: 'Box Trailer',
          value: 'box trailer'
        },
        {
          label: 'Skeletal',
          value: 'skeletal'
        },
        {
          label: 'Fridge',
          value: 'fridge'
        },
        {
          label: 'Tipper',
          value: 'tipper'
        },
        {
          label: 'Low Loader',
          value: 'low loader'
        },
        {
          label: 'Euroliner',
          value: 'euroliner'
        },
        {
          label: 'Transporter',
          value: 'transporter'
        },
        {
          label: 'Double Decker',
          value: 'double decker'
        },
        {
          label: 'Walking Floor',
          value: 'walking floor'
        }
      ]
    },
    groups () {
      return [
        {
          label: 'Tri-Axle Curtainsinder',
          value: 'tri-axle curtainsinder'
        }
      ]
    },
    vehiclesTypes () {
      return [
        {
          label: 'Single-Axle',
          value: 'single-axle'
        },
        {
          label: 'Twin-Axle',
          value: 'twin-axle'
        },
        {
          label: 'Tri-Axle',
          value: 'tri-axle'
        },
        {
          label: 'quad-Axle',
          value: 'quad-axle'
        }
      ]
    },
    vehiclesMakes () {
      return [
        {
          label: 'SDC trailers',
          value: 'SDC trailers'
        },
        {
          label: 'Krone',
          value: 'Krone'
        },
        {
          label: 'Schmitz',
          value: 'Schmitz'
        },
        {
          label: 'Gray Adams',
          value: 'Gray Adams'
        },
        {
          label: 'Montracon',
          value: 'Montracon'
        },
        {
          label: 'Don Bur',
          value: 'Don Bur'
        },
        {
          label: 'Dennison',
          value: 'Dennison'
        },
        {
          label: 'Tiger',
          value: 'Tiger'
        }
      ]
    },
    ebsValveTypes () {
      return [
        {
          label: 'HALDEX',
          value: 'haldex'
        },
        {
          label: 'Knorr',
          value: 'knorr'
        },
        {
          label: 'Bremse',
          value: 'bremse'
        },
        {
          label: 'Wabco',
          value: 'wabco'
        }
      ]
    },
    initialTimestampProps () {
      return {
        max: new Date().toISOString()
      }
    },
    finalTimestampProps () {
      return {
        min: new Date(this.initialTimestamp).toISOString(),
        max: new Date().toISOString()
      }
    }
  },
  watch: {
    unit (_) {
      this.trailerProfile = Object.assign({}, this.unit.trailerProfile)
    }
  },
  beforeDestroy () {
    this.resetErrors()
    this.trailerProfile = Object.assign({}, this.unit.trailerProfile)
  },
  created () {
    this.resetErrors()
    this.trailerProfile = Object.assign({}, this.unit.trailerProfile)
  },
  methods: {
    formatHour (date) {
      if (date) {
        return dayjs(date).format('hh:mm a')
      } else {
        return ''
      }
    },
    parseDate (date) {
      dayjs.extend(utc)
      if (date === null) {
        return dayjs()
      } else {
        return dayjs(date * 1000).format('DD/MM/YYYY HH:mm')
      }
    },

    convertDateToInt (date) {
      dayjs.extend(utc)
      return dayjs(date).unix()
    },

    async saveTrailerProfile () {
      const data = Object.assign({}, this.trailerProfile)
      data.lastService = this.convertDateToInt(data.lastService)
      data.nextServiceDate = this.convertDateToInt(data.nextServiceDate)
      data.lastMotDoe = this.convertDateToInt(data.lastMotDoe)
      data.nextMotDoe = this.convertDateToInt(data.nextMotDoe)
      data.lastEbpmsReportDate = this.convertDateToInt(data.lastEbpmsReportDate)
      this.resetErrors()
      this.$store.commit('setLoading', true, { root: true })
      await apollo.mutate({
        mutation: editTrailer,
        variables: {
          apiToken: this.user.apiToken,
          data: data
        }
      }).then(response => {
        const { status, errors, result } = response.data.editTrailerProfile

        switch (status) {
          case 'OK':
            this.updateUnitTrailerProfile(result)
            this.$store.commit('toggleSnackbar', { message: i18n.t('success.update.trailerProfile'), color: 'success' }, { root: true })
            break
          case 'BADREQUEST':
            this.errors = this.parseErrors(errors)
            this.$store.commit('toggleSnackbar', { message: i18n.t('errors.invalidFields'), color: 'warning' }, { root: true })
            break
          case 'NOTFOUND':
            this.$store.commit('toggleSnackbar', { message: i18n.t('errors.not_found'), color: 'warning' }, { root: true })
            break
        }
      }).catch(err => {
        console.error(err)
        this.$store.commit('toggleSnackbar', undefined, { root: true })
      }).finally(() => {
        this.$store.commit('setLoading', false, { root: true })
      })
    },
    updateUnitTrailerProfile (trailerProfile) {
      const unit = {
        ...this.unit
      }
      unit.trailerProfile = trailerProfile
      this.$store.commit('units/updateItem', unit)
      this.$store.commit('units/updateSelectedUnit', unit)
      this.$store.commit('units/updateUnit', unit)
      this.$store.commit('units/updateUnitDialog', false)
      this.resetUnitDialog()
    },
    resetUnitDialog () {
      setTimeout(() => {
        this.$store.commit('units/updateUnitDialog', true)
      }, 500)
    }
  }
}
</script>

<style lang="scss" scoped>

.list_title{
  border-bottom: 2px solid;
  border-color: #616161;
}

.tabs__styles {
  font-size: 16px;
}

.mapfiller{
  height: 400px;
  background-color: #67925b;
  width: 100%;
}

.image__container {
  width: 100%;
  height: 90px;
  display: flex; 
  justify-content: center;
  align-items: center;
  background: rgb(151,151,153);
  background: linear-gradient(180deg, rgba(151,151,153,1) 0%, rgba(185,185,185,1) 0%, rgba(247,249,250,1) 100%);
}

// .card__actions {
//   margin-top: -60px
// }

.text__container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.green__text {
  color: #0B6E00;
  font-weight: bold !important;
}

.snackbar__container {
  background-color: #328428;
  color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0px 2px 8px #5D5D5D54;
  padding: 4px 12px;
  height: 100%;
}

.warning__snackbar__container {
  background-color: #FDD835;
}

.snackbar__title {
  color: #FFFFFF;
  font-weight: bold !important;
}

.snackbar__subtitle {
  color: #FFFFFF;
  font-size: 12px;
}

.link__container {
  color: #014E88;
}

</style>