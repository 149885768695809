// import { i18n } from '@/plugins/i18n'

export default {
  defaultObject () {
    return {
      id: null,
      name: '',
      lastName: '',
      username: ''
    }
  }
}