<template>
  <div class="inverted_expandable">
    <div
      :class="{
        'content': true,
        'active--expanded': expanded
      }"
      :style="`bottom: ${headerHeight}; height: ${expanded ? contentHeight : '0px'}`"
    >
      <slot
        name="content"
        class="expansion--animated_content"
      >
        Content
      </slot>
    </div>
    <div
      class="header"
      @click="toggleExpanded"
    >
      <slot name="header">
        Header
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    headerHeight: {
      type: String,
      default: () => '40px'
    },
    contentHeight: {
      type: String,
      default: () => '40px'
    }
  },
  data () {
    return {
      expanded: false
    }
  },
  methods: {
    toggleExpanded () {
      this.expanded = !this.expanded
    }
  }
}
</script>

<style lang="scss">

.inverted_expandable {
  position: relative;

  .content {
    position: absolute;
    z-index: 3;
    left: 0;
    right: 0;
    overflow: hidden;

    transition: height 0.5s;
    height: 0px;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;

    &.active--expanded {
      animation-play-state: running;
      animation-name: unfold;
    }
  }

  .header {
    position: relative;
    cursor: pointer;
  }
}

@keyframes unfold {
  0% {
    overflow: hidden;
  }

  99% {
    overflow: hidden;
  }

  100% {
    overflow: visible;
  }
}
</style>