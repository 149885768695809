<template>
  <div>
    <transition name="component-fade" mode="out-in">
      <v-text-field
        v-show="showSearch"
        v-model="search"
        prepend-inner-icon="mdi-magnify"
        :placeholder="searchPlaceholder"
        :color="color"
        hide-details
        solo
        class="mb-2"
        @keyup.esc="searchBar = false"
      />
    </transition>

    <transition name="component-fade" mode="out-in">
      <v-btn
        v-if="selection.length > 0"
        block
        depressed
        color="red darken-2 white--text"
        @click="deleteFunction"
      >
        {{ $t('helpers.delete.confirm') }}
      </v-btn>
    </transition>

    <v-virtual-scroll
      v-if="dataRow.length > 0"
      :items="search === '' ? dataRow : searchData" 
      :item-height="itemHeight"
      :height="height"
      :max-height="maxHeight"
      class="better-scrollbar"
    >
      <template #default="{ item }">
        <v-list-item @click="() => ''">
          <v-list-item-avatar>
            <slot :item="item" name="avatar" />
          </v-list-item-avatar>
          <v-list-item-content @click="$emit('show-data', item.id)">
            <slot :item="item" name="content" />
          </v-list-item-content>
          <div class="text-right">
            <slot :item="item" name="actions" />
          </div>
        </v-list-item>
      </template>
    </v-virtual-scroll>
    <span v-else class="text-center">
      {{ $t('helpers.emptyData') }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    showSearch: {
      type: Boolean,
      default: () => false
    },
    searchParam: {
      type: String,
      default: () => 'name'
    },
    filter: {
      type: Function,
      default: null
    },
    height: {
      type: Number,
      required: false,
      default: null
    },
    maxHeight: {
      type: Number,
      default: 500
    },
    itemHeight: {
      type: Number,
      default: 55
    },
    searchPlaceholder: {
      type: String,
      default: () => 'Search'
    },
    color: {
      type: String,
      default: () => 'primary'
    },
    selection: {
      type: Array,
      default: () => []
    },
    deleteFunction: {
      type: Function,
      default: () => () => null
    },
    dark: {
      type: Boolean,
      default: () => false
    }
  },

  data () {
    return {
      search: '',
      searchData: [],
      filtered: {
        options: []
      }
    }
  },

  computed: {
    searchBar: {
      get () {
        return this.showSearch
      },

      set (value) {
        this.$emit('set-search-bar', value)
      }
    },
    dataRow () {
      return this.data
      // return this.data.map((item, index) => {
      //   return {
      //     ...item,
      //     optIdx: index
      //   }
      // }).filter((item, index) => this.filtered.options.includes(index))
    }

  },

  watch: {
    // search () {
    //   this.filterLists()
    // },
    search () {
      if (this.filter !== null) {
        this.searchData = this.data.filter(
          (item, index, array) => this.filter(item, this.search, index, array)
        )
      } else {
        this.searchData = this.data.filter(
          item => item[this.searchParam].toLowerCase().includes(this.search.toLowerCase())
        )
      }
    }
  },

  mounted () {
    // this.filterLists()
  },

  methods: {
    // filterLists () {
    //   this.filtered = {
    //     options: []
    //   }

    //   for (const i in this.data) {
    //     if (this.validatePresence(this.data[i])) {
    //       this.filtered.options.push(parseInt(i))
    //     }
    //   }
    // },
    // validatePresence (item) {
    //   const filter = this.search

    //   if (filter.length === 0) {
    //     return true
    //   }

    //   const field = item.name
    //   if (field) {
    //     return field.toLowerCase().includes(filter.toLowerCase())
    //   }

    //   return false
    // }
  }
}
</script>
