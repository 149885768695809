import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['app', 'isDark']),
    
    hasWhitelabel () {
      return !!this.app.id
    },
    
    appLogo () {
      if (this.hasWhitelabel) {
        return this.app.designInformation.logos[this.isDark ? 'white' : 'normal']
      }
      
      return this.$assets.logo[this.isDark ? 'white' : 'normal']
    },

    hirecoAppLogo () {
      if (this.hasWhitelabel) {
        return this.app.designInformation.logos[this.isDark ? 'white' : 'normal']
      }
      
      return this.$assets.hirecoAppLogo[this.isDark ? 'white' : 'normal']
    },

    appFavicon () {
      if (this.hasWhitelabel) {
        return this.app.designInformation.favicons[this.isDark ? 'white' : 'normal']
      }
      
      return this.$assets.favicon[this.isDark ? 'white' : 'normal']
    },

    appWhiteFavicon () {
      if (this.hasWhitelabel) {
        return this.app.designInformation.favicons.white
      }
      
      return this.$assets.favicon.white
    },

    footerFormat () {
      if (this.hasWhitelabel) {
        return this.app.designInformation.footerFormat
      } else {
        return 'MADEWITH'
      }
    },

    appName () {
      if (this.hasWhitelabel) {
        return this.app.name
      }
      
      return this.$i18n.t('app.site.layrz')
    },
    
    vinnieLogo () {
      return this.$assets.vinnieLogo
    },

    loginBackground () {
      return this.$assets.loginBackground
    }
  }
}