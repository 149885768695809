var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-sheet',{staticClass:"better-scrollbar",style:({
      height: 'calc(90vh - 100px)',
      'overflow-y': 'auto',
      'overflow-x': 'hidden',
    })},[_c('v-container',{key:`dark-${_vm.isDark}`,attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.timeRanges,"dense":"","label":"Time Range","outlined":"","item-text":"label","item-value":"value"},on:{"change":_vm.getTpmsMessages},model:{value:(_vm.selectedTime),callback:function ($$v) {_vm.selectedTime=$$v},expression:"selectedTime"}})],1),_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12"}},[_c('v-chip',{staticClass:"my-2 mr-2",attrs:{"color":"primary","outlined":!_vm.showTemperatureSeries,"label":""},on:{"click":function($event){_vm.showTemperatureSeries = !_vm.showTemperatureSeries}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-thermometer-high")]),_vm._v(" Temperature (°C) ")],1),_c('v-chip',{staticClass:"my-2",attrs:{"color":"primary","outlined":!_vm.showPressureSeries,"label":""},on:{"click":function($event){_vm.showPressureSeries = !_vm.showPressureSeries}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-air-filter")]),_vm._v(" Air pressure (psi) ")],1),(_vm.loadingChart)?_c('div',{staticClass:"loader",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-4 primary"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"white"}})],1)],1):_vm._e(),(!_vm.loadingChart)?_c('v-card',{staticStyle:{"position":"relative","overflow":"hidden"},attrs:{"outlined":"","height":"65vh"}},[_c('div',{style:({
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              opacity: 0.4
            })}),_c('apexchart',{key:`chart-${_vm.isDark}-${_vm.showTemperatureSeries}-${_vm.showPressureSeries}-${_vm.selectedTime}`,attrs:{"type":"line","height":"100%","options":_vm.chartOptions,"series":_vm.totalSeries}})],1):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }