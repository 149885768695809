<template>
  <div>
    <v-sheet
      class="better-scrollbar"
      :style="{
        height: 'calc(80vh - 100px)',
        'overflow-y': 'auto',
        'overflow-x': 'hidden'
      }"
    >
      <v-container fluid>
        <v-row no-gutters>
          <v-col cols="12">
            <v-card outlined>
              <v-container fluid>
                <v-row no-gutters>
                  <v-col cols="12" md="4" class="pa-2">
                    <small class="text-uppercase">Name</small>
                    <p>Lorem ipsum</p>
                  </v-col>
                  <v-col cols="12" md="4" class="pa-2">
                    <small class="text-uppercase">Date installed</small>
                    <p>{{ currentDay }}</p>
                  </v-col>
                  <v-col cols="12" md="4" class="pa-2">
                    <small class="text-uppercase">Serial Number</small>
                    <p>{{ `ABC-${getRndInteger(100, 999)}` }}</p>
                  </v-col>

                  <v-col cols="12" md="4" class="pa-2">
                    <small class="text-uppercase">Size</small>
                    <p>{{ getRndInteger() }}</p>
                  </v-col>
                  <v-col cols="12" md="4" class="pa-2">
                    <small class="text-uppercase">Last Rotation</small>
                    <p>{{ lastRotation }}</p>
                  </v-col>
                  <v-col cols="12" md="4" class="pa-2">
                    <small class="text-uppercase">Last Position</small>
                    <p>{{ `er-${getRndInteger()}` }}</p>
                  </v-col>

                  <v-col cols="12" md="4" class="pa-2">
                    <small class="text-uppercase">Manufacturer</small>
                    <p>Lorem ipsum</p>
                  </v-col>
                  <v-col cols="12" md="4" class="pa-2">
                    <small class="text-uppercase">Mileage Since Installation</small>
                    <p>Lorem ipsum</p>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" class="pt-3 d-flex justify-end">
            <v-btn disabled color="primary" outlined>
              Make a change
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { orm } from '@/mixins'

import dayjs from 'dayjs'
const advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

export default {
  components: {
  },
  mixins: [orm],

  model: {
    prop: 'data',
    event: 'set-object'
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },

    unit: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      currentDay: null,
      lastRotation: null
    }
  },

  computed: {
    ...mapState(['session', 'width', 'isLoading', 'isDark', 'units']),
    ...mapGetters(['color', 'isMobile']),

    object: {
      get () {
        return this.data
      },

      set (value) {
        this.$emit('toggle-object', value)
      }
    }

  },

  watch: {
  },

  beforeDestroy () {
  },

  created () {
    this.currentDay = dayjs().format('MMMM DD, YYYY')
    this.lastRotation = dayjs().format('DD/MM/YYYY')
  },

  methods: {
    getRndInteger (min = 0, max = 100) {
      return Math.floor(Math.random() * (max - min)) + min
    }
  }
}
</script>
