export default {
  setTelegramDialog (state, payload) {
    state.telegramLinks = payload
  },

  setLicensesDialog (state, payload) {
    state.licenses = payload
  },

  setCountries (state, payload) {
    state.countries = payload
  },

  setMeasureUnits (state, payload) {
    state.measureUnits = payload
  },

  toggleDark (state) {
    state.isDark = !state.isDark
    localStorage.setItem('operative.dark.mode', state.isDark)
  },

  setDark (state, payload) {
    state.isDark = payload
    localStorage.setItem('operative.dark.mode', state.isDark)
  },

  setAvailableLanguages (state, payload) {
    state.availableLanguages = payload
    state.isI18nLoading = false
  },

  setLanguage (state, payload) {
    const locale = state.availableLanguages.filter(item => item.code === payload)
    if (locale.length > 0) {
      state.language = locale[0]
      localStorage.setItem('operative.current.locale.id', locale[0].id)
    }
    localStorage.setItem('operative.current.locale.code', payload)
  },

  setDrawer (state, payload) {
    state.drawer = payload
  },

  setLoading (state, payload) {
    state.isLoading = payload
  },

  setWhitelabel (state, payload) {
    state.whitelabel = payload
  },
  
  setApp (state, payload) {
    state.app = payload
  },

  setWidth (state, payload) {
    if (payload < 930) {
      state.width = {
        full: payload,
        dialog: payload
      }
      state.drawer = false
    } else {
      state.width = {
        full: payload,
        dialog: payload * 0.8333333333
      }
    }
  },

  setDataHasLoaded (state, payload) {
    state.dataHasLoaded = payload
  },

  setGoogle (state, payload) {
    state.google = payload
  },

  toggleSnackbar () {}
}