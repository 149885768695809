/*
  Dtcs state
*/
export default {
  configuration: [
    {
      type: 'amberdtcs'
    },
    {
      type: 'reddtcs'
    },
    {
      type: 'overweightevents'
    },
    {
      type: 'poorbrakealerts'
    },
    {
      type: 'vor'
    },
    {
      type: 'mostdistance'
    }
  ],
  countersMode: 'maintenance'
}
