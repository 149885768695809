<template>
  <v-row style="height: 100%;">
    <v-col
      cols="12"
      md="6"
      sm="6"
      xs="12"
    >
      <gm-unit-information :unit="unit" />
    </v-col>
    <v-col
      cols="12"
      md="6"
      sm="6"
      xs="12"
    >
      <gm-unit-map :unit="unit" />
    </v-col>
    <v-col
      cols="12"
      xs="12"
    >
      <gm-unit-sensors :unit="unit" :tab="currentTab" />
    </v-col>
    <v-col
      cols="4"
      xs="4"
      class="pa-0 fill-height"
    >
      <!-- <gm-unit-listing /> -->
    </v-col>
  </v-row>
</template>

<script>

import {
  Sensors,
  UnitInformation,
  UnitMap
  // Listing
} from 'Components/units/unit_sheet/tabs/summary'

export default {
  components: {
    'gm-unit-sensors': Sensors,
    'gm-unit-information': UnitInformation,
    'gm-unit-map': UnitMap
    // 'gm-unit-listing': Listing
  },
  props: {
    unit: {
      type: Object,
      default: () => {}
    },
    currentTab: {
      type: Number,
      default: () => 0
    }
  },
  methods: {
    getMessage () {
      return this.unit?.telemetry
    }
  }
}
</script>