import { Loader } from '@googlemaps/js-api-loader'

const googlemaps = {}

googlemaps.install = (Vue) => {
  Vue.prototype.$googlemaps = new Loader({
    // ESTA API KEY DEBE SER OTRA EN PRODUCCION
    apiKey: 'AIzaSyDJjkFcQqy3SI6U-Qdq25qGHCGGMnZkWUY', // api key de powertrakker
    version: '3.45',
    libraries: ['drawing', 'visualization'],
    language: 'en'
  })
}

export default googlemaps