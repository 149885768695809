<template>
  <v-app>
    <transition name="component-fade" mode="out-in">
      <router-view />
    </transition>

    <snackbar />
    <loader-indicator />

    <!-- <licenses-dialog />
    <telegram-dialog /> -->
  </v-app>
</template>

<script>
import { Loader, Snackbar } from 'Components/utils'
import { mapGetters } from 'vuex'
// import { Licenses, Telegram } from '@/components/legally'

export default {
  components: {
    'loader-indicator': Loader,
    Snackbar
    // 'licenses-dialog': Licenses,
    // 'telegram-dialog': Telegram
  },

  computed: mapGetters(['isMobile'])
}
</script>

<style>
.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .3s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
